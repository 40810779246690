import axios from 'axios';

export default axios.create({
    baseURL: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}`,
    headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_FIREBASE_API_KEY}`,
        "AppVersion": 99,
        "Content-Type": 'application/json'
    }
});
