import { useState, useContext } from 'react';
import './sign-in-form.styles.scss';
import { useNavigate } from 'react-router-dom';
import { sendEmailVerification } from '../../utils/customer-api/customer-service';
import { signInWithGooglePopup, signInAuthUserWithEmailAndPassword, signOutUser, sendPasswordReset } from '../../utils/firebase/firebase.utils';
import { UserContext } from '../../contexts/user.context';
import { Stack, Typography, TextField, Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const defaultFormFields = {
    email: '',
    password: '',
}

const SignInForm = ({ navigateLink }) => {

    const { setCurrentUser } = useContext(UserContext);
    const [formFields, setFormFields] = useState(defaultFormFields);
    const [showPassword, setShowPassword] = useState(false);
    const { email, password } = formFields;
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const credential = await signInAuthUserWithEmailAndPassword(email, password);
            if (credential.user.emailVerified) {
                resetFormFields();
                if (navigateLink) {
                    navigate(navigateLink);
                } else {
                    navigate('/');
                }

            } else {
                await signOutUser();
                setCurrentUser(null, null);
                navigate("/");
                alert('Verify Email\n\n You have not verified your email address.\n\n');
            }
        } catch (error) {

            switch (error.code) {
                case 'auth/wrong-password':
                    alert('incorrect password for email');
                    break;
                case 'auth/user-not-found':
                    alert('no user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };

    const resetFormFields = () => {
        setFormFields(defaultFormFields)
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const signInWithGoogle = async () => {
        await signInWithGooglePopup();
    }

    const resendVerificationEmail = () => {
        if (email) {
            sendEmailVerification(email);
            alert(`A verification email has been sent to ${email}\n\nAccess your email and click on the link.  If you have not received an email within 5 minutes, be sure to check your spam and/or junk folder.`)
        } else {
            alert("Please enter an email address.")
        }
    }

    const resetPassword = () => {
        if (email) {
            sendPasswordReset(email);
            alert(`A password reset email has been sent to ${email}\n\nAccess your email and click on the link.  If you have not received an email within 5 minutes, be sure to check your spam and/or junk folder.`)
        } else {
            alert("Please enter an email address.")
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={1}>
                <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" mb={1} ml={1}>
                    Sign In
                </Typography>

                <Typography ml={2} textAlign={'left'} fontStyle={'Helvetica'} color={'gray'} variant="subtitle1" component="div" mb={2}>
                    Sign in with your email and password
                </Typography>

                <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="customer-signin-email" variant="outlined" label="Email Address" type="email" name="email" value={email} onChange={handleChange} ></TextField>
                <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="customer-signin-password" variant="outlined" label="Password" type={showPassword ? 'text' : 'password'} name="password" value={password} onChange={handleChange}
                    InputProps={{
                        readOnly: false,
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={toggleShowPassword}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton></InputAdornment>
                    }}></TextField>
                <Button type="submit" variant="contained">Sign In</Button>
                <div className='password-reset-and-resend-email-container'>
                    <span className='password-reset' onClick={resetPassword}>Password Reset</span>
                    <span className='resend-verifification-email' onClick={resendVerificationEmail}>Resend Email Verification</span>
                </div>
            </Stack>
        </form>

    )
};
export default SignInForm;