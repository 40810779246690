import { FacilityCreateBlackoutContainer } from "./facility-create-blackout.styles"
import BlackoutCreate from "../../../components/facility/blackout-create/blackout-create.component"

const FacilityCreateBlackout = () => {

    return (

        <FacilityCreateBlackoutContainer>
            <BlackoutCreate></BlackoutCreate>
        </FacilityCreateBlackoutContainer>

    )

}

export default FacilityCreateBlackout