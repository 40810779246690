import { MembershipAllListRowContainer } from './memberships-list-all-row.styles'
import emptycheckboximg from "../../assets/empty-checkbox.svg";
import checkedboximag from "../../assets/checked-checkbox.svg";

const MembershipsListAllRow = ({ membership, row, selectedMemberships, selectMembership }) => {

    const clickCustomerCell = () => {
        selectMembership(membership)
    }

    let rowColor = "white";
    let textColor = "#14254C";
    let selected = false;

    if (selectedMemberships && selectedMemberships.includes(membership.facilityMembershipId)) {
        selected = true;
    }

    return (
        <MembershipAllListRowContainer $textcolor={textColor} $rowcolor={rowColor} onClick={clickCustomerCell}>
            <img src={selected ? checkedboximag : emptycheckboximg} alt='img' className='checkbox-img' />
            <span className='area-name'  >{membership.name}</span>
        </MembershipAllListRowContainer>
    )

}

export default MembershipsListAllRow