import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const FormOfPaymentsList = ({ formOfPayments, setFormOfPayment, selectedFormOfPayment, closeList }) => {

    const getFormOfPaymentName = (fop) => {
        if (fop) {
            if (fop.card) {
                const card = fop.card;
                let exp_month = `${card.exp_month}`;
                if (card.exp_month < 10) {
                    exp_month = `0${exp_month}`
                }
                const cardDescription = `${card.brand.toUpperCase()} *${card.last4} EXP ${exp_month}/${card.exp_year}`;
                return cardDescription;
            } else if (fop.id === "Add Form Of Payment") {
                return "Add Form Of Payment"
            }
        }

        return '';
    }

    const selectCard = (event) => {
        const {value} = event.target;
        setFormOfPayment(value);
    }

    return (
        <TextField
            id="outlined-select-form-of-payment"
            select
            label="Select Card"
            value={selectedFormOfPayment ? selectedFormOfPayment : ''}
            onChange={selectCard}
        >
            {formOfPayments.map((fop) => (
                 <MenuItem key={fop.id} id={fop.id} value={fop.id}>
                    {getFormOfPaymentName(fop)}
                </MenuItem>
            ))}
        </TextField>
    )

}

export default FormOfPaymentsList;