import React from 'react';
import { Navigate } from 'react-router-dom';

const SignedOutGuardedRoute = ({ currentUser, children }) => {
    if (currentUser) {
        return <Navigate to="/" replace />;
    }

    return children;
}

export default SignedOutGuardedRoute;