import styled from 'styled-components';

export const BookingResevationSummaryContainer = styled.div`
position: relative;
display: flex;
flex-direction: column;
margin-top: 1.5vh;
margin-bottom: 1.5vh;

.reservation-title-container {
    margin-left: 2.5vw;
    margin-right: 5.0vw;
}

.totals-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.subs-container {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.subtotal-header {
    color: #14254C;
    font-weight: bold;
    text-align: left;
    margin-top: 1.0vh;
    margin-left: 1.0vw;
    margin-right: 1.0vw;
    margin-bottom: 1.0vh;
    width: 70%;
}

.subtotal {
    color: gray;
    text-align: right;
    margin-right: 2.0vw;
        margin-top: 1.0vh;
    width: 30%;
}

.total-title-and-value {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-top: 2.0vh;
}

.service-charge-header {
    color: #14254C;
    font-weight: bold;
    text-align: left;
    margin-top: 1.0vh;
    margin-left: 1.0vw;
    margin-right: 1.0vw;
    width: 70%;
}

.service-charge-total {
    color: gray;
    text-align: right;
    margin-right: 2.0vw;
    margin-top: 1.0vh;
    width: 30%;
    height: 100%;
}

.total-separator {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.0vh;
    margin-bottom: 1.0vh;
    margin-right: 1.0vw;

    .ttl-sep {
        width: 45%;
        height: 0.25vh;
        background-color: #DEDEDE;
    }

}

.total-for-all {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.0vh;
    margin-bottom: 1.0vh;
    margin-right: 1.0vw;
}

.total-header {
    color: #14254C;
    font-weight: bold;
    text-align: left;
    margin-top: 1.0vh;
    margin-left: 1.0vw;
    margin-right: 1.0vw;
    width: 70%;
}

.total {
    color: gray;
    text-align: right;
    margin-right: 2.0vw;
    width: 30%;
}

.separator {
    background-color: #14254C;
    margin: auto;
    margin-top: 1.0vh;
    margin-bottom: 1.0vh;
    width: 90%;
    height: 0.25vh;
}

.button-container {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    margin: auto;
    margin-top: 1.0vh;
}
`