import styled from 'styled-components';

export const SportsListContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => `${props.$flexdirection}`};
    position: ${(props) => `${props.$position}`};;
    width: ${(props) => `${props.$width}`};
    max-height: 55vh;
    z-index: 10;
    background-color: white;
    border-radius: 5px;
    box-shadow: ${(props) => `${props.$boxshadow}`};
    margin-left: -1vw;
    margin-top: -1vh;
    gap: 1.0vh;
    overflow: hidden;
    padding: 1.5vh;

    .button-bar {
        display: flex;
        margin-top: 1.0vh;
        height: 10vh;

        .buttons {
            margin: auto;
        }
    }
`