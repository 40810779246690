import styled from 'styled-components';

export const FacilityReservationSearchResultsContainer = styled.div`
grid-column: 2 / 2;
grid-row: 1;
position: relative;
top: 10%;
height: 87vh;
width: 99%;
display: flex;
flex-direction: column;
align-items: stretch;
background-color: white;
border-radius: 5px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
margin: 1.0rem;

.bottom-button-bar {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 2.0rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
`


export const FacilityReservationListContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
justify-content: space-evenly;
align-items: stretch;
background-color: white;
border-radius: 5px;
overflow: scroll;
overflow-y: scroll;

.default-view {
  position: absolute;
  color: #DEDEDE;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-view {
  position: absolute;
  color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 3px 5px;
  margin: 1rem;

  .icon-img {
      max-width: 100px;
      display: block;
      margin-left: auto;
      margin-right: auto;
  }

  .icon-title {
    display: block;
    margin-top: 1.0rem;
    text-align: center;
  }
}

.date-header {
  text-align: left;
  font-weight: bold;
  background-color: #14254C;
  color: white;
  margin-left: 1.0rem;
  margin-right: 1.0rem;
  border-radius: 5px;
  line-height: 1.5rem;
}

.date-header-text {
  margin: 1.0rem;
  font-size: 1.05rem;
}

.search-results {
  list-style-type: none;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: scroll;
}

.search-result-unselected {
  background-color: white;
  color: #14254C;
  display: flex;
  flex-direction: row;
  text-align: left;
  text-decoration: none;
  margin-left: 1.0rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1.0rem;
  cursor: pointer;
  border-radius: 5px;
}

.search-result-unselected:hover {
  // background-color: #14254C;
  // color: white;
  border: 1px solid #14254C;
}

.box-and-area-container {
  display: flex;
  flex-direction: column;
  line-height: 1.25rem;
}

.checkbox-img {
  max-width: 25px;
}

.search-result-selected {
  background-color: #eaeaea;
  color: #14254C;
  display: flex;
  flex-direction: row;
  text-align: left;
  text-decoration: none;
  margin-left: 1.0rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1.0rem;
  cursor: pointer;
  border-radius: 5px;
}

.search-result-selected:hover {
  border: 1px solid #14254C;
}

.search-result-areaReserved {
  margin-left: 1.0rem;
  margin-right: 1.0rem;
  font-weight: bold;
}

.search-result-time {
  margin-left: 1.5rem;
  margin-right: 1.0rem;
}

.search-result-price {
  margin-left: 1.5rem;
  margin-right: 1.0rem;
}



`