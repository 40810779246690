import { DisplayContext } from "../../../contexts/dispaly-options.context";
import { UserContext } from "../../../contexts/user.context";
import { useContext, useState, useEffect } from 'react';
import FacilityCustomerAdd from '../../../components/facility/facility-customer-add/facility-customer-add.component';
import { searchFacilityCustomers, getFacilityCustomers } from '../../../utils/facility-api/facility-service';
import { Button, TextField } from '@mui/material';
import { Box, Stack } from '@mui/material';
import FacilityViewCustomer from '../facility-view-customer/facility-view-customer.component';
import FacilityCustomerViewRow from './facility-customers-view-row.component';
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";


const FacilityCustomersView = ({ selectRow, selectFacilityCustomer, selectedCustomers, marginTop = '10.0vh' }) => {

    const [customers, setCustomers] = useState([]);
    const [startSearchWithLastName, setStartSearchWithLastName] = useState(null);
    const [pageIsLoading, setPageIsLoading] = useState(false);
    const [displaySearchResults, setDisplaySearchResults] = useState(false);
    const [searchBtnPressed, setSearchButtonPressed] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [hasMoreCustomers, setHasMoreCustomers] = useState(false);
    const [refreshCustomers, setRefreshCustomers] = useState(false);
    const [customerToView, setCustomerToView] = useState(null);

    const { isViewOpen, addOpenView, removeOpenView } = useContext(DisplayContext);
    const { userData, userIsLoading, hasReservationPrivileges, currentUser } = useContext(UserContext);
    const [pageCount, setPageCount] = useState(0);

    const selectCustomer = (facilityCustomer) => {
        if (selectFacilityCustomer) {
            selectFacilityCustomer(facilityCustomer);
        }
    }

    useEffect(() => {
        async function getCustomers() {
            setPageIsLoading(true);
            if (currentUser && userData) {
                let allCustomers = [];
                let refreshCustomers = false;
                if (displaySearchResults) {
                    if (searchBtnPressed) {
                        refreshCustomers = true;
                        const response = await searchFacilityCustomers(userData.facilityId, searchInput, false);
                        if (response && response.data && response.data.customers) {
                            allCustomers = response.data.customers;
                        }
                        setHasMoreCustomers(false);
                        setSearchButtonPressed(false);
                        setPageCount(0);
                    }
                } else {
                    refreshCustomers = true;

                    let prevLastName = null;
                    let endAtLastName = null;

                    if (startSearchWithLastName) {
                        let searchParms = startSearchWithLastName.split(",");
                        if (searchParms[0] === 'next') {
                            prevLastName = searchParms[1];
                        } else {
                            endAtLastName = searchParms[1];
                        }
                    }

                    const response = await getFacilityCustomers(userData.facilityId, prevLastName, endAtLastName);
                    if (response && response.data && response.data.customers) {
                        allCustomers = response.data.customers.filter((customer) => {
                            return customer.userLastName !== startSearchWithLastName;
                        });

                        if (response && response.data && response.data.hasMoreCustomers) {
                            setHasMoreCustomers(true);
                        } else {
                            setHasMoreCustomers(false);
                        }

                        if (allCustomers.length <= 0) {
                            refreshCustomers = false;
                        }

                    } else {
                        allCustomers = [];
                    }

                }
                if (refreshCustomers) {
                    setCustomers(allCustomers);
                }
                setPageIsLoading(false);
            }
        }
        getCustomers();
    }, [userData, startSearchWithLastName, displaySearchResults, searchBtnPressed, refreshCustomers]);

    const prevPage = async () => {
        const firstCustomer = customers[0].userLastName;
        // setPreviousLastName(firstCustomer);
        setStartSearchWithLastName(`prev,${firstCustomer}`);
        setPageCount(pageCount - 1);
    }

    const nextPage = async () => {
        let count = customers.length;
        if (count > 0) {
            // setPreviousLastName(startSearchWithLastName);
            const lastCustomer = customers[count - 1].userLastName;
            setStartSearchWithLastName(`next,${lastCustomer}`);
            setPageCount(pageCount + 1);
        }
    }

    const refreshCustomerList = () => {
        setRefreshCustomers(true);
    }

    const closeCustomerAdd = () => {
        removeOpenView('facilityaddcustomer');
    }

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };

    const searchForCustomer = () => {

        if (searchInput === ""){
            alert("No Search Input Provided");
            return;
        }

        setPageIsLoading(true);
        setDisplaySearchResults(true);
        setPageIsLoading(false);
        setSearchButtonPressed(true);
    }

    const clearCustomerSearch = () => {
        setDisplaySearchResults(false);
        setSearchButtonPressed(false);
        setSearchInput("");
        setStartSearchWithLastName(null);
    }

    const addNewCustomer = () => {
        addOpenView('facilityaddcustomer');
    }

    const onKeyUp = (event) => {
        if (event.keyCode === 13) {
            if (searchInput !== "") {
                searchForCustomer();
            } else {
                clearCustomerSearch();
            }
        }
    }

    const viewCustomer = (customer) => {
        setCustomerToView(customer);
    }

    let row = 0;
    return (
        <Box sx={{
            height: '85vh',
            width: '98%',
            marginLeft: '1.0vw',
            marginRight: '1.0vw'
        }}>
            <MySportSpaceLoadingView isOpen={pageIsLoading}></MySportSpaceLoadingView>
            <FacilityViewCustomer facilityCustomerIn={customerToView} close={viewCustomer} refreshCustomerList={refreshCustomerList} ></FacilityViewCustomer>
            <FacilityCustomerAdd isOpen={isViewOpen('facilityaddcustomer') ? true : false} close={closeCustomerAdd} refresh={refreshCustomerList}></FacilityCustomerAdd>
            {
                !userData || !userData.isFacilityUserAccount && !userIsLoading &&
                <div>
                    <span>You are not authorized to view this page.</span>
                </div>
            }
            {
                userData && userData.isFacilityUserAccount &&
                <Box sx={{ height: '80vh', marginTop: marginTop, marginBottom: '10%' }}>
                    <Stack spacing={2} mt={2} mb={2} ml={5} mr={5} direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <TextField size='small' fullWidth required id='facility-customer-search-bar' variant="outlined" label="Search by Name, Email or Phone Number" type="text" name="searchInput" value={searchInput} onChange={handleChange} onKeyUp={onKeyUp}></TextField>
                        <Button size='small' onClick={searchForCustomer} variant='contained'>Search</Button>
                        <Button size='small' onClick={clearCustomerSearch} variant='outlined'>Clear</Button>
                        {
                            hasReservationPrivileges &&
                            <Button sx={{ width: '15.0vw' }} size='small' onClick={addNewCustomer} variant='outlined'>New Customer</Button>
                        }
                    </Stack>
                    <Stack>
                        {
                            customers.map((customer) => {
                                return <FacilityCustomerViewRow viewCustomer={viewCustomer} key={row} row={row += 1} facilityCustomer={customer} selectRow={selectRow} selectFacilityCustomer={selectCustomer} selectedCustomers={selectedCustomers}></FacilityCustomerViewRow>
                            })
                        }
                        <Stack spacing={2} mt={1} mb={2} display={'flex'} justifyContent={'flex-end'} direction={'row'}>
                            {
                                pageCount > 0 &&
                                <Button variant="contained" onClick={prevPage}>Previous</Button>
                            }
                            {hasMoreCustomers &&
                                <Button variant="contained" onClick={nextPage}>Next</Button>
                            }
                        </Stack>
                    </Stack>
                </Box>
            }
        </Box>

    )
}

export default FacilityCustomersView