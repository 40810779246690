import LoadingIndicator from '../../loading-indicator/loading-indicator.component';
import LabelWithHeader from '../../label-with-header/label-with-header.component';
import { Fragment, useContext, useState } from 'react';
import CustomerList from '../../customer-list/customer-list.component';
import MembershipList from '../../membership-list/membership-list.component';
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context';
import ViewReservationHeader from './view-reservation-header-component';
import { removeBlackout } from '../../../utils/facility-api/facility-service';
import MySportSpaceAlert from '../../alert/alert.component';

const ViewBlackout = () => {

    const { reservationToView,  setReservationToView } = useContext(FacilityReservationsContext);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [removeMessage, setRemoveMessage] = useState(null);
    const [removedMessage, setRemovedMessage] = useState(null);
    const [cancelReservationActions, setCancelReservationActions] = useState(['Remove Blackout']);
    const [editReservationActions, setEditReservationActions] = useState(["Edit Notes"]);

    const closeView = () => {
        setReservationToView(null);
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const showAlertMessage = (message) => {
        setAlertMessage(message);
    }

    const showRemoveMessage = (text) => {
        setRemoveMessage(text);
    }

    const closeRemoveMessage = () => {
        setRemoveMessage(null)
    }

    const showRemovedMessage = (text) => {
        setRemovedMessage(text);
    }

    const actionSelected = async (action) => {

        switch (action) {
            case 'Remove Blackout':
                showRemoveMessage("Are you sure you want to remove this blackout?")
                break;
            case 'Edit Notes':
                break;
            default:
                console.log("Error!");
        }

    }

    const removeTheBlackout = async () => {
        setRemoveMessage(null);
        setIsLoading(true);
        const response = await removeBlackout(reservationToView);
        const { status, error } = response.data;
        if (status === 'success') {
            showRemovedMessage('The blackout has been removed.');
        } else {
            if (error) {
                showAlertMessage(error);
            } else {
                showAlertMessage('The blackout was not removed.');
            }
        }
        setIsLoading(false)
    }

    return (
        <Fragment>
            <div className='header-and-details'>
                {isLoading &&
                    <LoadingIndicator></LoadingIndicator>
                }
                {
                    alertMessage &&
                    <MySportSpaceAlert message={alertMessage} okButtonText={'OK'} okAction={closeAlert}></MySportSpaceAlert>
                }
                {
                    removeMessage &&
                    <MySportSpaceAlert message={removeMessage} okButtonText={'Remove'} okAction={removeTheBlackout} cancelButtonText={'DO NOT REMOVE'} cancelAction={closeRemoveMessage}></MySportSpaceAlert>
                }
                {
                    removedMessage &&
                    <MySportSpaceAlert message={removedMessage} okButtonText={'OK'} okAction={closeView} ></MySportSpaceAlert>
                }
                {
                    !isLoading &&
                    <Fragment>
                        <ViewReservationHeader reservation={reservationToView} cancelReservationActions={cancelReservationActions} editReservationActions={editReservationActions} title={"Blackout Details"} closeAction={closeView} actionSelected={actionSelected} ></ViewReservationHeader>
                        <div className='main-body'>
                            <div className='label-grouping'>
                                <div className='column-container'>
                                    <LabelWithHeader headerText={"Area"} labelText={reservationToView.mainAreaName} headerFont={'1.0rem'} labelFont={'0.95rem'}></LabelWithHeader>
                                </div>
                            </div>
                            <div className='label-grouping'>
                                <div className='column-container'>
                                    <LabelWithHeader headerText={"Date"} labelText={reservationToView.resStartDate} headerFont={'1.0rem'} labelFont={'0.95rem'}></LabelWithHeader>
                                </div>
                                <div className='column-container'>
                                    <LabelWithHeader headerText={"Time"} labelText={`${reservationToView.resStartTime} - ${reservationToView.resEndTime}`} headerFont={'1.0rem'} labelFont={'0.95rem'}></LabelWithHeader>
                                </div>
                            </div>
                            <div className='label-grouping'>
                                <div className='column-container'>
                                    <LabelWithHeader headerText={"Reason"} labelText={`${reservationToView.title} - ${reservationToView.subTitle}`} headerFont={'1.0rem'} labelFont={'0.95rem'}></LabelWithHeader>
                                </div>
                            </div>
                            <div className='label-grouping'>
                                <div className='column-container'>
                                    {!reservationToView.reservedForCustomersList &&
                                        <LabelWithHeader headerText={"Reserved For Customers"} labelText={'Not Applicable'} headerFont={'1.0rem'} labelFont={'0.95rem'}></LabelWithHeader>
                                    }
                                    {reservationToView.reservedForCustomersList &&
                                        <div className='reserved-for-group'>
                                            <span className="reserved-for-label">Reserved For Customers:</span>
                                            <div className='reserved-for-list'>
                                                <CustomerList customers={reservationToView.reservedForCustomersList} isEditable={false}></CustomerList>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='label-grouping'>
                                <div className='column-container'>
                                    {
                                        !reservationToView.reservedForMembershipsList &&
                                        <LabelWithHeader headerText={"Reserved For Memberships"} labelText={'Not Applicable'} headerFont={'1.0rem'} labelFont={'0.95rem'}></LabelWithHeader>
                                    }
                                    {
                                        reservationToView.reservedForMembershipsList &&
                                        <div className='reserved-for-group'>
                                            <span className="reserved-for-label">Reserved For Memberships:</span>
                                            <div className='reserved-for-list'>
                                                <MembershipList memberships={reservationToView.reservedForMembershipsList} isEditable={false}></MembershipList>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='label-grouping'>
                                <div className='column-container'>
                                    <LabelWithHeader headerText={"Notes"} labelText={reservationToView.notes ? reservationToView.notes : "None"} headerFont={'1.0rem'} labelFont={'0.95rem'}></LabelWithHeader>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment>
    )
}

export default ViewBlackout