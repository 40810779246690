import { BookingsReviewReservationsContainer } from "./bookings-review-reservations.styles";
import { Fragment, useContext, useEffect, useState } from 'react';
import { SearchForReservationContext } from '../../../contexts/search-for-reservation/search-for-reservation.context';
import { Navigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Divider, Typography, Button, CardActions, Stack } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const Decimal = require('decimal.js');

const getServiceCharge = (booking) => {
    let serviceCharge = '';
    if (booking.charges) {
        for (const charge of booking.charges) {
            const { type, amount } = charge;
            if (type === 'serviceCharge') {
                let serviceDecimal = new Decimal(amount).div(100.0);
                serviceDecimal = serviceDecimal.toDecimalPlaces(2);
                serviceCharge = `$${serviceDecimal.toFixed(2)}`;
            }
        }
    }

    return serviceCharge;
}

const BookingsReviewReservations = ({ showEmptyResults = false }) => {

    const { searchResultsToBook, removeSearchResultByReservationId } = useContext(SearchForReservationContext);
    const [bookingsByDate, setBookingsByDate] = useState({});
    const [reservationIdToRemove, setReservationIdToRemove] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        const bookingsByDate = {};
        for (const result of searchResultsToBook) {
            const startKey = moment(result.startDate, "YYYY-MM-DD").format('dddd, MMM DD, YYYY');
            let bookingsForDateKey = bookingsByDate[startKey];
            if (!bookingsForDateKey) {
                bookingsForDateKey = [];
            }
            bookingsForDateKey.push(result);

            bookingsByDate[startKey] = bookingsForDateKey;
        }
        setBookingsByDate(bookingsByDate);
    }, [searchResultsToBook]);


    if (!showEmptyResults && (!searchResultsToBook || searchResultsToBook.length <= 0)) {
        return <Navigate to="/" replace />;
    }

    const removeBooking = (e) => {
        e.preventDefault();
        const bookingResultReservationId = e.currentTarget.dataset.id;
        setReservationIdToRemove(bookingResultReservationId);
    }

    const performRemoveBooking = () => {
        removeSearchResultByReservationId(reservationIdToRemove);
        setReservationIdToRemove(null);
    }

    const handleClose = () => {
        setReservationIdToRemove(null);
    };

    return (
        <BookingsReviewReservationsContainer>
            <Dialog
                open={reservationIdToRemove}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Remove From List?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this reservation from the list?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Do Not Remove</Button>
                    <Button variant="contained" onClick={performRemoveBooking} autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>

            {Object.keys(bookingsByDate).length <= 0 &&
                <Paper elevation={0}>
                    <Typography mt={2} textAlign={'center'} gutterBottom color={"#14254C"} component="div" variant="subtitle1">
                        You have selected no reservations to book
                    </Typography>
                </Paper>
            }
            {Object.keys(bookingsByDate).length > 0 &&
                <Paper elevation={0} sx={{ overflow: 'scroll', height: '78vh', marginLeft: '1.0vw' }}>
                    {
                        Object.keys(bookingsByDate).map((date) => {
                            return <div key={date} className="booking-row">
                                <Typography mt={1} gutterBottom color={"#14254C"} component="div" variant="h6">
                                    {date}
                                </Typography>
                                {
                                    bookingsByDate[date].map((booking) => {

                                        return <Card variant="outlined" sx={{ marginLeft: '0.25vw', marginRight: '0.25vw' }}>
                                            {/* <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}> */}
                                            <CardContent>
                                                <Typography gutterBottom color={"#14254C"} component="div" variant="h6">
                                                    {booking.facilityName}
                                                </Typography>
                                                <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }}></Divider>
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    {booking.areaReserved} - {booking.facilityAreaType}
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    {booking.reservedForSport}
                                                </Typography>
                                                <Typography gutterBottom fontWeight={'bold'} variant="subtitle1" color="#14254C" component="div">
                                                    {moment(booking.startDate, "YYYY-MM-DD h:mm a").format("h:mm a")} - {moment(booking.endDate, "YYYY-MM-DD h:mm a").format("h:mm a")}
                                                </Typography>
                                                <Stack mt={1} mb={2} direction={'row'} spacing={2}>
                                                    <Typography fontWeight={'bold'} gutterBottom color={"#14254C"} component="div" variant="subtitle1">
                                                        Price ${new Decimal(booking.price).toFixed(2)}
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} gutterBottom color={"#14254C"} component="div" variant="subtitle1">
                                                        Service Fee {getServiceCharge(booking)}
                                                    </Typography>
                                                </Stack>

                                                {new Decimal(booking.price).gt(new Decimal(0)) &&
                                                    <Fragment>
                                                        {
                                                            booking.cancelPolicies.map((policy) => {
                                                                return <Typography color={"#14254C"} component="div" variant="body2"> {policy} </Typography>
                                                            })
                                                        }
                                                    </Fragment>
                                                }
                                            </CardContent>
                                            <CardActions>
                                                {
                                                    booking.termsAndConditionsUrl &&
                                                    <a style={{color: 'white', backgroundColor: '#14254C', padding: '1.0em', borderRadius: '5px'}} href={booking.termsAndConditionsUrl} target="_blank" rel="noreferrer">
                                                        VIEW TERMS AND CONDITIONS
                                                    </a>
                                                }
                                                {
                                                    Object.keys(bookingsByDate).length > 1 &&
                                                    <Button data-id={booking.reservationId} onClick={removeBooking.bind(this)} size="large" color="primary">
                                                        Remove From List
                                                    </Button>
                                                }

                                            </CardActions>
                                            {/* </Box> */}
                                        </Card>
                                    })
                                }
                            </div>
                        })
                    }
                </Paper>
            }
        </BookingsReviewReservationsContainer>
    )
}

export default BookingsReviewReservations;