import styled from 'styled-components';

export const CustomerFormOfPaymentContainer = styled.div`

    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 32vh;

    .loading-container {
        height: 100%;
        width: 100%;
        margin: auto;
    }

    .credit-card-header {
        display: flex;
        flex-direction: column;
        color: #14254C;
        font-weight: bold;
        margin-top: 1.0vh;
        margin-left: 1.0vw;
        margin-right: 1.0vw;
    }

    .form-of-payment-list {
        display: flex;
        flex-direction: column;
        margin-top: 1.0vh;
        margin-bottom: 1.0vh;
        margin-left: 1.0vw;
        margin-right: 1.0vw;
        overflow: scroll;
    }

    .form-of-payment-row {
        display: flex;
        margin-left: 1.0vw;
        margin-right: 1.0vw;
        border-bottom: #DEDEDE solid 1px;

        .fop-details-container {
            display: flex; 
            flex-direction: column;

            .fop-name-lbl {
                padding: .25vh;
                margin: auto;
                margin-left: 0.25vw;
                color: gray;
            }
    
            .fop-value-lbl {
                padding: .25vh;
                margin: auto;
                margin-left: 0.25vw;
                color: #14254C;
            }
        }

        .check-box-img {
            height: 2.0vh;
            width: 2.0vw;
            margin: auto;
            margin-left: 0.0vw;
            margin-right: 0.0vw;
        }

    }

    .form-of-payments {
        overflow: scroll;
    }

.add-payment-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.full-screen-for-pop-up-container {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 5005;
    background-color: RGBA(20,37,76,0.46);
}

.display-popup-containter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: ${(props) => `${props.$margin}px`};
}

.add-payment-info-container {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: visible;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
}

.add-payment-button {
    display: flex;
    margin-left: 2.0vw;
    margin-top: 0.5vh;

    .add-payment-text {
        width: 50%;
        margin: auto;
        margin-left: 0.25vw;
    }
    
    .check-box-img {
        height: 2.0vh;
        width: 2.0vw;
        margin: auto;
        margin-left: 0.0vw;
        margin-right: 0.25vw;
    }
}

`