import BottomButtonBar from "../bottom-button-bar/bottom-button-bar.component";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment } from "react";

const MySportSpaceAlert = ({ title, message, okButtonText, cancelButtonText, okAction, cancelAction, isOpen = true }) => {

    const messagesArray = message ? message.split("\n") : ['test message'];
    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {
                messagesArray.length > 0 &&
                <Fragment>
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        {
                            messagesArray.map((m) => {
                                return <DialogContentText key={m} id="alert-dialog-description">
                                    {m}
                                </DialogContentText>
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <BottomButtonBar okButtonText={okButtonText} okAction={okAction} cancelButtonText={cancelButtonText} cancelAction={cancelAction}></BottomButtonBar>
                    </DialogActions>
                </Fragment>
            }
        </Dialog>
    );
}

export default MySportSpaceAlert;