import { Fragment } from "react";
import * as React from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';


const SubAreas = ({ subAreas, selectFacilityArea, selectedArea, sport }) => {

    let subAreaArr = [];
    if (subAreas) {
        for (const subAreaId of Object.keys(subAreas)) {
            const subArea = subAreas[subAreaId];
            subArea.id = subAreaId;
            subAreaArr.push(subArea);
        }
    }

    const handleClick = (e) => {
        const areaId = e.currentTarget.dataset.id;
        const areas = subAreaArr.filter(area => area.id === areaId);
        selectFacilityArea(areas[0]);
    }

    return (
        <Fragment>
            {
                subAreaArr.filter(sArea => sArea.sports.includes(sport) || sport === 'Any' || sport === '' || !sport).map((subArea) => {
                    const { name, id, sport } = subArea
                    return <ListItemButton key={id} sx={{ pl: 4, pb: 0 }} selected={selectedArea && selectedArea.id === id} divider={false} onClick={handleClick.bind(this)} data-id={id}>
                        <ListItemText key={`${id}-list-item`} primary={name} primaryTypographyProps={{
                            letterSpacing: 0,
                            color: '#14254C'
                        }} ></ListItemText>
                    </ListItemButton>
                })
            }
        </Fragment>
    )
}

export default SubAreas