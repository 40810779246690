import { useEffect, useState, useContext, Fragment } from "react";
import { FacilityAreaContext } from "../../../contexts/facility/facility-area-provider.context";
import { facilityReservationSearch } from "../../../utils/facility-api/facility-service.js";
import { rescheduleReservation } from "../../../utils/reservation-api/reservation-service";
import { UserContext } from "../../../contexts/user.context";
import InputLabel from '@mui/material/InputLabel';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Stack, Button, TextField } from "@mui/material";
import MaterialUIDatePicker from "../../material-ui/date-picker/material-ui-date-picker.component.jsx";
import MaterialUITimePicker from "../../material-ui/time-picker/material-ui-time-picker.component.jsx";
import { formatReservationLengthOrDuration } from "../../../utils/reservation-utils/reservation-utils.js";
import FacilityAreaListAll from "../facility-area-list-all/facility-area-list-all.component.jsx";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MySportSpaceAlert from "../../alert/alert.component.jsx";
import dayjs from "dayjs";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component.jsx";

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const defaultFormFields = {
    rescheduleResStartDate: dayjs(),
    startTime: dayjs().startOf('hour'),
    reservationLength: 1,
    facilityArea: null,
    formOfPayment: null,
    formOfPaymentType: null
}

const FacilityRescheduleReservation = ({ isOpen, reservationToView, facilityCustomer, close, closeResView }) => {

    const { facilityAreas } = useContext(FacilityAreaContext);
    const [facilityAreaPopOverAnchorEl, setFacilityAreaPopOverAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { rescheduleResStartDate, startTime, reservationLength, facilityArea, formOfPayment } = formFields;
    const [alertMessage, setAlertMessage] = useState('');
    const [rescheduledMessage, setRescheduledMessage] = useState(null);
    const { userData } = useContext(UserContext);

    const closeView = () => {
        close()
    }

    const rescheduleWasSuccessful = () => {
        closeResView()
    }

    useEffect(() => {
        setIsLoading(true);

        let facilityArea = null;
        for (const area of facilityAreas) {
            if (area.id === reservationToView.mainAreaReserved) {
                facilityArea = area;
                break;
            } else if (area.subAreaList) {
               
                for (const sArea of area.subAreaList) {
                    if (sArea.id === reservationToView.mainAreaReserved) {
                        facilityArea = sArea;
                        break;
                    }
                }
            }
        }


        const startDate = dayjs(`${reservationToView.dateKey} ${reservationToView.resStartTime}`, "YYYY-MM-DD h:mm A")
        const endDate = dayjs(`${reservationToView.dateKey} ${reservationToView.resEndTime}`, "YYYY-MM-DD h:mm A")

        const resLength = (endDate.diff(startDate, 'minutes') / 60.0);

        let defaultFormFields = {
            rescheduleResStartDate: dayjs(reservationToView.dateKey, "YYYY-MM-DD"),
            startTime: dayjs(`${reservationToView.dateKey} ${reservationToView.resStartTime}`, "YYYY-MM-DD h:mm A"),
            reservationLength: resLength,
            facilityArea: facilityArea,
            formOfPayment: reservationToView.paymentDetails,
            formOfPaymentType: reservationToView.isPayingWithCredit ? 'Credit Card' : reservationToView.isPayingByInvoice ? "Invoice" : "Cash/Check"
        }
        setFormFields(defaultFormFields);
        setIsLoading(false);
    }, [isOpen]);

    const handleAddAreaClick = (event) => {
        event.preventDefault();
        setFacilityAreaPopOverAnchorEl(facilityAreaPopOverAnchorEl ? null : event.currentTarget);
    };

    const handleCloseAddAreaClick = () => {
        setFacilityAreaPopOverAnchorEl(null);
    };

    const selectRescheduleDate = (rescheduleDate) => {
        if (rescheduleDate) {
            setFormFields({ ...formFields, 'rescheduleResStartDate': rescheduleDate });
        } else {
            setFormFields({ ...formFields, 'rescheduleResStartDate': null });
        }
    }

    const selectStartTime = (startTime) => {
        setFormFields({ ...formFields, 'startTime': startTime });
    }

    const onDurationChange = (event) => {
        let dur = formatReservationLengthOrDuration(event.target.value, reservationLength, true);
        setFormFields({ ...formFields, 'reservationLength': dur.length });
    }

    const selectFacilityAreaId = (facilityAreaId) => {

        for (const fArea of facilityAreas) {
            if (fArea.id === facilityAreaId) {
                setFormFields({ ...formFields, 'facilityArea': fArea });
                break;
            }
            if (fArea.subAreas) {
                const sAreaKeys = Object.keys(fArea.subAreas)
                for (const key of sAreaKeys) {
                    if (key === facilityAreaId) {
                        setFormFields({ ...formFields, 'facilityArea': fArea.subAreas[key] });
                        break;
                    }
                }
            }
        }

        handleCloseAddAreaClick();
    }

    const performReschedule = async () => {
        setIsLoading(true);
        let reservationSearchInput = {
            searchDate: rescheduleResStartDate,
            customer: facilityCustomer,
            startTime: startTime,
            endTime: startTime,
            facilityArea: facilityArea,
            facilityAreaType: null,
            selectedSport: reservationToView.reservedForSport,
            searchAreaSubType: "",
            repeatsInterval: null,
            repeatsUntilDate: null,
            repeatDaysOfWeek: [],
            repeatExceptionDates: [],
            reservationLength: reservationLength,
            reschedulingResId: reservationToView.reservationId,
            numberOfSpaces: 1
        }

        const response = await facilityReservationSearch(reservationSearchInput, null, reservationToView.facilityId, userData.internalBookingId, true);
        if (response.data) {
            const { searchResults, errorMessage } = response.data;
            if (response.status === 200 && searchResults && searchResults.length > 0) {
                const resIdForReschedule = searchResults[0].reservationId;
                const rescheduleResponse = await rescheduleReservation(reservationToView, resIdForReschedule, formOfPayment ? formOfPayment.id : null, false, true);
                if (rescheduleResponse.status === 200 && rescheduleResponse.data && rescheduleResponse.data.status === 'success') {
                    setRescheduledMessage(rescheduleResponse.data.message);
                } else {
                    setAlertMessage(rescheduleResponse.data.error ? rescheduleResponse.data.error : "The reservation is unable to be rescheduled at this time.");
                }
            } else {
                if (errorMessage) {
                    setAlertMessage(errorMessage);
                } else {
                    setAlertMessage("The reservation is unable to be rescheduled at this time.");
                }

            }
        } else {
            setAlertMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    }

    const closeAlertMessage = () => {
        setAlertMessage(null);
    }

    const subAreas = [];
    if (facilityArea && facilityArea.subAreas) {
        const subAreaIds = Object.keys(facilityArea.subAreas)
        for (const id of subAreaIds) {
            const subArea = facilityArea.subAreas[id]
            subAreas.push(subArea);
        }
    }
    const open = Boolean(facilityAreaPopOverAnchorEl);
    return (
        <Fragment>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            {
                alertMessage &&
                <MySportSpaceAlert message={alertMessage} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
            }
            {
                rescheduledMessage &&
                <MySportSpaceAlert message={rescheduledMessage} okButtonText={'OK'} okAction={rescheduleWasSuccessful}></MySportSpaceAlert>
            }
            <Dialog
                open={isOpen && !isLoading && !alertMessage && !rescheduledMessage}
                onClose={closeView}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Reschedule Reservation
                </DialogTitle>
                <DialogContent>
                    <Stack mt={2} ml={2} spacing={1}>
                        <Stack spacing={2} direction={'row'} justifyContent={'stretch'}>
                            <MaterialUIDatePicker labelText="Reschedule Date" onChange={selectRescheduleDate} value={rescheduleResStartDate}></MaterialUIDatePicker>
                            <MaterialUITimePicker headerText="Start Time" views={['hours', 'minutes']} format={'h:mm A'} value={startTime} onChange={selectStartTime} />
                            <TextField sx={{ minWidth: '150px' }} id="duration" variant="outlined" label="Duration (Hours)" required type="number" onChange={onDurationChange} name="duration" value={reservationLength}
                                inputProps={{
                                    pattern: "^\d*\.\d{2}$",
                                    step: userData && userData.isFacilityUserAccount ? "0.25" : "0.5",
                                    max: "24",
                                    min: "0.5"
                                }}></TextField>
                        </Stack>
                        <InputLabel required={true} id="selection-list-label">Reserved Area</InputLabel>
                        <Button variant="outlined" onClick={handleAddAreaClick} >
                            {facilityArea ? facilityArea.name : defaultFormFields ? defaultFormFields.facilityArea && defaultFormFields.facilityArea.name : "Please Choose Area"}
                        </Button>
                        <FacilityAreaListAll anchorEl={facilityAreaPopOverAnchorEl} selectedAreaIds={facilityArea ? [facilityArea.id] : []} selectFacilityAreaId={selectFacilityAreaId} closeList={handleCloseAddAreaClick} ></FacilityAreaListAll>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeView}>Cancel</Button>
                    <Button variant="contained" onClick={performReschedule} autoFocus>
                        Reschedule
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default FacilityRescheduleReservation