import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const DeerCreekBaseball = () => {

    useEffect(() => {
        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfs2fU10YqjAl662MRoOzkk-rkSRqQXdkczaKQfUbXMr9kRbQ/viewform?pli=1';
    }, []);

    return <Box>
        <Typography>You are being redirected to The Deer Creek Baseball Stadium Sign Form.</Typography>
        <Link sx={{ fontWeight: 'bold', color: 'dark-gray', fontSize: {xs: '1.0vh', sm: '1.75vh', md: '1.75vh', lg: '1.75vh', xl: '1.5vh'} }} underline="none" variant="subtitle1" href="https://docs.google.com/forms/d/e/1FAIpQLSfs2fU10YqjAl662MRoOzkk-rkSRqQXdkczaKQfUbXMr9kRbQ/viewform?pli=1">Click Here if not redirected</Link>
    </Box>
}

export default DeerCreekBaseball;