import { Popover, Box, Stack, Button, TextField, Typography, Divider } from "@mui/material";
import { useState, Fragment, useEffect } from "react";
import { DAYS_OF_WEEK_INT_TO_TEXT, DAYS_OF_WEEK_SORT_ORDER } from "../../../utils/constants/constants";
import { DeciamlFormat } from "../../../utils/text-field-utils/text-field-utils";
import MaterialUITimePicker from "../../material-ui/time-picker/material-ui-time-picker.component";
import MaterialUIDatePicker from "../../material-ui/date-picker/material-ui-date-picker.component";
import dayjs from "dayjs";
import DaysOfWeekSelection from "../../days-of-week-selection/days-of-week-selection.component";

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

const EditAreaPrice = ({ isOpen = false, anchorEl, priceDataIn, updatePrice, closeView, isExceptions }) => {

    const [priceData, setPriceData] = useState(priceDataIn);
    const [needsDayOfWeek, setNeedsDayOfWeek] = useState(false);

    useEffect(() => {

        function setupPriceData(pricing) {
            setPriceData(pricing);
            if (pricing) {
                if (pricing.daysOfWeek && pricing.daysOfWeek.length > 0) {
                    setNeedsDayOfWeek(true);
                } else {
                    setNeedsDayOfWeek(false);
                }
            }

        }
        setupPriceData(priceDataIn);
    }, [priceDataIn]);

    const handleItemChange = (event) => {
        const { name, value } = event.target;
        setPriceData({ ...priceData, [name]: parseFloat(value) });
    }

    const close = () => {

        if (closeView) {
            closeView();
        }
    }

    const update = () => {

        const beginTime = dayjs(`1970-01-01 ${priceData.beginTime}`, 'YYYY-MM-DD h:mm A');
        const endTime = dayjs(`1970-01-01 ${priceData.endTime}`, 'YYYY-MM-DD h:mm A');

        if (beginTime.isSameOrAfter(endTime)) {
            alert("Start Time must be less than End Time");
            return;
        }

        if (updatePrice) {
            updatePrice(priceData);
        }

        if (closeView) {
            closeView();
        }

    }

    const selectBeginTime = (time) => {
        setPriceData({ ...priceData, ['beginTime']: time.format("h:mm A") });
    }

    const selectEndTime = (time) => {
        setPriceData({ ...priceData, ['endTime']: time.format("h:mm A") });
    }

    const selectBeginDate = (date) => {

        const daysDiff = date.diff(priceData.endDate, 'day');
        let daysOfWeek = [];
        if (daysDiff > 6) {
            setNeedsDayOfWeek(true);
        } else {
            setNeedsDayOfWeek(false);
        }
        setPriceData({ ...priceData, ['beginDate']: date.format("YYYY-MM-DD"), ['daysOfWeek']: daysOfWeek });
    }

    const selectEndDate = (date) => {
        let daysDiff = date.diff(priceData.beginDate, 'day');
        if (daysDiff < 0) {
            daysDiff = daysDiff * -1;
        }

        if (daysDiff > 6) {
            setNeedsDayOfWeek(true);
        } else {
            setNeedsDayOfWeek(false);
        }

        setPriceData({ ...priceData, ['endDate']: date.format("YYYY-MM-DD") });
    }

    const selectDayOfWeek = (value, closeView) => {
        const convertedDowsOfWeek = [];
        for (const dow of value) {
            convertedDowsOfWeek.push(DAYS_OF_WEEK_SORT_ORDER[dow]);
        }
        setPriceData({ ...priceData, ['daysOfWeek']: convertedDowsOfWeek });
    }

    const getSelectedDaysOfWeek = () => {
        const selectedDaysOfWeek = [];
        if (priceData.daysOfWeek) {
            for (const dayOfWeekInt of priceData.daysOfWeek) {
                selectedDaysOfWeek.push(DAYS_OF_WEEK_INT_TO_TEXT[parseInt(dayOfWeekInt)]);
            }
        }
        return selectedDaysOfWeek;
    }

    const title = isExceptions ? "Exception" : priceData ? `for ${DAYS_OF_WEEK_INT_TO_TEXT[parseInt(priceData.dayOfWeek)]}` : '';

    return (
        <Popover
            id={'edit-area-price-popover'}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{ width: '50vh' }} mt={1} mb={1} ml={1} mr={1}>
                {
                    priceData && priceData.beginTime &&
                    <Fragment>
                        <Typography mt={1} mb={1} key={`edit-price-popup-header`} fontFamily={'Helvetica'} variant="h6" fontWeight={'bold'} color={'#14254C'}>Edit Price {title}</Typography>
                        <Divider sx={{ marginBottom: '1.25vh' }}></Divider>
                    </Fragment>
                }
                {
                    priceData && !priceData.beginTime &&
                    <Fragment>
                        <Typography mt={1} mb={1} key={`edit-price-popup-header`} fontFamily={'Helvetica'} variant="h6" fontWeight={'bold'} color={'#14254C'}>Edit Default Price for {`${DAYS_OF_WEEK_INT_TO_TEXT[parseInt(priceData.dayOfWeek)]}`}</Typography>
                        <Divider sx={{ marginBottom: '1.25vh' }}></Divider>
                    </Fragment>
                }
                {
                    priceData && priceData.beginDate &&
                    <Stack mt={2} spacing={1} direction={'row'}>
                        <MaterialUIDatePicker labelText="Start Date:" onChange={selectBeginDate} value={dayjs(priceData.beginDate, "YYYY-MM-DD")}></MaterialUIDatePicker>
                        <MaterialUIDatePicker labelText="End Date:" minDate={dayjs(priceData.beginDate, "YYYY-MM-DD")} onChange={selectEndDate} value={dayjs(priceData.endDate, "YYYY-MM-DD")}></MaterialUIDatePicker>
                    </Stack>
                }
                {
                    priceData && priceData.beginTime &&
                    <Stack mt={2} spacing={1} direction={'row'}>
                        <MaterialUITimePicker headerText="Start Time:" views={['hours', 'minutes']} format={'h:mm A'} value={dayjs(`1970-01-01 ${priceData.beginTime}`, 'YYYY-MM-DD h:mm A')} onChange={selectBeginTime} />
                        <MaterialUITimePicker headerText="End Time:" views={['hours', 'minutes']} format={'h:mm A'} value={dayjs(`1970-01-01 ${priceData.endTime}`, 'YYYY-MM-DD h:mm A')} onChange={selectEndTime} />
                    </Stack>
                }
                {
                    needsDayOfWeek &&
                    <DaysOfWeekSelection selectedDayOfWeek={getSelectedDaysOfWeek()} dayOfWeekSelected={selectDayOfWeek}></DaysOfWeekSelection>
                }
                {
                    priceData &&
                    <Fragment>
                        <TextField sx={{ marginTop: '1.0vh' }} required fullWidth id="halfHourPrice" variant="outlined" label="Half Hour Price" type="text" name="halfHourPrice" value={priceData.halfHourPrice} onChange={handleItemChange}
                            InputProps={{
                                inputComponent: DeciamlFormat
                            }}></TextField>
                        <TextField sx={{ marginTop: '1.0vh' }} required fullWidth id="halfHourPrice" variant="outlined" label="Hour Price" type="text" name="hourlyPrice" value={priceData.hourlyPrice} onChange={handleItemChange}
                            InputProps={{
                                inputComponent: DeciamlFormat
                            }}></TextField>
                        <Stack mt={2} spacing={1} direction={'row'} display={'flex'} justifyContent={'center'}>
                            <Button variant="outlined" onClick={close}>Cancel</Button>
                            <Button variant="contained" onClick={update}>Update</Button>
                        </Stack>
                    </Fragment>
                }
            </Box>
        </Popover>
    )
}

export default EditAreaPrice;