import styled from 'styled-components';

export const TextFormContainer = styled.div`

    display: flex;
    flex-direction: column;
    color: #14254C;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 0.5vh;

.label-and-help-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    color: #14254C;
    margin-bottom: 0.5vh;

    .icon-img{
        height: 3.0vh;
        width: 3.0vh;
        cursor: pointer;
        background-color: white;
        border-radius: 5px;
        margin: 0.5rem;
    }

}

.text-input {
    display: flex;
    text-align: left;
    color: #14254C;
    margin-left: 1.5rem;
    margin-right: 1.0rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    padding: 0 15px 0 15px;
    font-size: 1.45vh;
    line-height: 1.5rem;
}

.text-label {
    display: flex;
    text-align: left;
    align-items: center;
    font-weight: bold;
    font-size: 1.50vh;
    color: #14254C;
}
`