import styled from 'styled-components';

export const CalendarOpenReservationContainer = styled.div`
grid-column: ${(props) => `${props.$gridcolumn}`};
grid-row: ${(props) => `${props.$gridrowstart}`} / ${(props) => `${props.$gridrowend}`};
display: grid;
grid-template-columns: repeat(${(props) => `${props.$numberofcolumns}`}, 1fr);
grid-template-rows: 1fr;
background-color: ${(props) => `${props.$backgroundcolor}`};
border-radius: 4px 4px 4px 4px;

&:hover {
    cursor: pointer;
    background-color: ${(props) => `${props.$hovercolor}`};;
}

.click-to-book-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}
`

export const SelectedContainer = styled.div`
    background-color: ${(props) => `${props.$backgroundcolor}`};

    &:hover {
         cursor: pointer;
    }
`

export const CalendarSubAreaOpenReservationContainer = styled.div`
    display: grid;
    grid-column: ${(props) => `${props.$spancolumn}`};
    grid-row: ${(props) => `${props.$spanrow}`};
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    color: #14254C;
    font-size: 1.0rem;
    height: 100%;
    width: 100%;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    overflow: scroll;

    &:hover {
        border: 2px solid #14254C;
        box-shadow: 0 0 5px 5px #14254C;
    }
`