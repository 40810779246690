import styled from 'styled-components';

export const FacilityCustomerListContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: white;

    .facility-customer-list {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999999;
        max-height: 50vh;
        overflow: scroll;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        margin-top: -1.0vh;
    }
`