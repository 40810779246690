import styled from 'styled-components';

export const IconOnClickMenuContainer = styled.div`
position: absolute;
z-index: ${(props) => `${props.$zindex}`};;
margin: 30px;
background: white;
width: ${(props) => `${props.$width}`};
height: ${(props) => `${props.$height}`};
top: 0;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.6);
border-radius: 10px;
color: $14254C;
display: flex;
overflow: scroll;
flex-flow: row wrap;
justify-content: flex-start;

.icon-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.6);
    border-radius: 10px;
    padding: 5px 3px 5px;
    margin: 1rem;
    min-width: ${(props) => `${props.$minwidth}`};;
    min-height: ${(props) => `${props.$minheight}`};;
    .icon-img {
        max-width: 30px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    .icon-title {
        font-size: 0.9vw;
        text-align: center;
        width: 100%;
    }
}
`