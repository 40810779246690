import styled from 'styled-components';

export const HomeContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    color: #14254C;

    .main-container {
        position: relative;
        display: grid;
        height: 100%;
        grid-template-columns: 50% 50%;
        grid-template-rows: 100%;
        grid-template-areas:
        "logo-area signin";
    
        .logo {
            grid-area: logo-area;
            height: 200px;
            line-height: 200px;
            text-align: center;
            
            .logo-image {
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                width: 200px;
            }
        }
    
        .sign-in {
            grid-area: signin;
            height: 200px;
            line-height: 200px;
            text-align: center;

            .text-display {
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                text-align: center;
            }
            
        }
    }

`
  