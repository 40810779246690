import styled from 'styled-components';

export const BookingsConfirmationContainer = styled.div`
position: relative;
display: flex;
flex-direction: column;
margin-top: 1.5vh;
margin-bottom: 1.5vh;

.back-to-search-container {
    display: flex;
    height: 3.0vh;
    margin-bottom: 2.0vw;
    
    .back-img {
        width: 3.0vw;
        height: 3.0vh;
        cursor: pointer;
    }

    .back-text {
        margin: auto;
        margin-left: 0.5vw;
        color: gray;
        cursor: pointer;
    }
}

.thank-you-header {
    font-weight: bold;
    margin-bottom: 0.5vh;
}

.email-verification-message {
    margin-bottom: 0.5vh;
}

.reservation-title-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.0vh;
    margin-top: 2.0vh;

    .reservation-title-header {
        font-weight: bold;
    }

    .reservation-title {
        margin-left: 1.0vw;
        margin-top: 0.5vh;
    }
}

.separator {
    background-color: #14254C;
    margin: auto;
    margin-top: 1.0vh;
    margin-bottom: 1.0vh;
    width: 96%;
    height: 0.25vh;
}

.reservation-summary {
    display: flex;

    .billing-data-container {
        width: 70%;
        display: flex;
        flex-direction: column;
    }

    .totals-container {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .message {
        line-height: 1.5rem;
        text-align: left;
        margin-left: 1.0rem;
        margin-right: 1.0rem;
        margin-top: 0.5vh;
        margin-bottom: 0.5vh;
    }
    
    .subtotal-header {
        width: 45%;
        color: #14254C;
        font-weight: bold;
        text-align: left;
        margin-top: 1.0vh;
        margin-left: 1.0vw;
        margin-right: 1.0vw;
    }
    
    .subtotal {
        width: 95%;
        color: gray;
        text-align: right;
    }
    
    .service-charge-header {
        width: 45%;
        color: #14254C;
        font-weight: bold;
        text-align: left;
        margin-top: 1.0vh;
        margin-left: 1.0vw;
        margin-right: 1.0vw;
    }
    
    .service-charge-total {
        width: 95%;
        color: gray;
        text-align: right;
    }
    
    .total-separator {
        display: flex;
        justify-content: flex-end;
        margin-top: 1.0vh;
        margin-bottom: 1.0vh;
        margin-right: 5%;
    
        .ttl-sep {
            width: 45%;
            height: 0.25vh;
            background-color: #DEDEDE;
        }
    
    }
    
    .total-header {
        width: 45%;
        color: #14254C;
        font-weight: bold;
        text-align: left;
        margin-top: 1.0vh;
        margin-left: 1.0vw;
        margin-right: 1.0vw;
    }
    
    .total {
        width: 95%;
        color: #14254C;
        text-align: right;
    }
}

.all-bookings-container {
    display: flex;
    flex-direction: column;
    row-gap: 1.0vh;
    margin-top: 2.0vh;
    margin-bottom: 2.0vh;
}

.bottom-button-bar {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    margin-bottom: 0.5vh;
    background-color: white;
}

.booking-row {
    display: flex;
    flex-direction: column;
    
    .booking-date-header {
        color: #14254C;
        margin-left: 1.0vw;
        font-weight: bold;
    }

    .booking-info-container {
        display: flex;
        border-radius: 5px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);              
        margin-left: 1.5vw;
        margin-right: 0.5vw;
        margin-top: 0.5vh;
        margin-bottom: 0.5vh;

        .area-photo-image {
            margin: auto;
            margin-left: 0.5vw;
            width: 16vw;
            height: 9vh;
            border-radius: 5px;
        }

        .price-and-remove-container {
            display: flex; 
            flex-direction: column;
            justify-content: flex-end;
            width: 30vw;
            .price-and-service-charge-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-left: 0.5vw;
                margin-top: 1.0vh;
                margin-bottom: 1.0vh;

                .price-container {
                    display: flex;
                    justify-content: flex-end;
                    margin-right: 1.0vw;

                    .price-header-lbl {
                        margin-bottom: 0.5vh;
                        color: #14254C;
                        font-weight: bold;
                        text-align: left;
                        width: 10.0vw;
                    }
                    .booking-price {
                        width: 4.5vw;
                        margin-left: 1.0vw;
                        margin-bottom: 0.5vh;
                        color: gray;
                        text-align: right;
                    }
                }
                .service-charge-container {
                    display: flex;
                    justify-content: flex-end;
                    margin-right: 1.0vw;

                    .service-charge-header-lbl {
                        margin-bottom: 0.5vh;
                        color: #14254C;
                        font-weight: bold;
                        text-align: left;
                        width: 10.0vw;
                    }
                    .service-charge {
                        width: 4.5vw;
                        margin-left: 1.0vw;
                        color: gray;
                        text-align: right;
                    }
                }

            }
        }

        .booking-info {
            width: 75%;
            display: flex;
            flex-direction: column;
            row-gap: 0.5vh;
            margin-left: 1.5vw;
            margin-right: 0.5vw;
            margin-top: 0.5vh;
            margin-bottom: 0.5vh;

            .facility-name {
                margin-top: 1.0vh;
                margin-left: 0.5vw;
                margin-bottom: 0.25vh;
                color: #14254C;
                font-weight: 600;
                font-size: 2.0vh;
                padding-bottom: 0.5vh;
                border-bottom: 1px solid #DEDEDE;
            }
            .area-name {
                margin-left: 0.5vw;
                color: #14254C;
            }
            .area-type {
                margin-left: 0.5vw;
                color: gray;
            }

            .reserved-for-sport {
                margin-left: 0.5vw;
                color: gray;
            }

            .booking-time {
                text-align: left;
                margin-left: 0.5vw;
                color: #14254C;
            }

            .cancel-policies {
                display: flex;
                flex-direction: column;
                margin-left: 0.5vw;

                .cancel-policy-lbl {
                    color: gray;
                    margin-bottom: 0.25vh;
                }

                .cancel-policy {
                    color: gray;
                    margin-left: 0.5vw;
                }
            }

            .terms-and-conditions {
                margin-top: 1.0vh;
                color: #14254C;
                margin-left: 0.5vw;
            }
        }
    }
}


`