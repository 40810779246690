import { verifyPassword } from '../../../utils/firebase/firebase.utils';
import { useState, useEffect } from "react";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, TextField } from '@mui/material';

const defaultFormFields = {
    password: ''
}

const FacilityVerifyPassword = ({ isOpen, verifySuccessful, verifyFailed, closeView }) => {

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { password } = formFields;
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setFormFields(defaultFormFields);
    }, []);

    const closeThisView = () => {
        setFormFields(defaultFormFields);
        closeView();
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            await verifyPassword(password);
            setFormFields(defaultFormFields);
            verifySuccessful();
        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('incorrect password for email');
                    break;
                case 'auth/user-not-found':
                    alert('no user associated with this email');
                    break;
                default:
                    alert(error.message);
                    console.log(error);
            }
            verifyFailed();
        }
        setIsLoading(false);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    };

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="adjust-amount-dialog-title"
            aria-describedby="adjust-amount-dialog-description"
        >
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <DialogTitle id="adjust-amount-dialog-title">
                Enter Password
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You must enter your password to continue
                </DialogContentText>
                <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw', mt: '1.0vh' }} id="customer-signin-password" variant="outlined" label="Password" type={showPassword ? 'text' : 'password'} name="password" value={password} onChange={handleChange}
                    InputProps={{
                        readOnly: false,
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={toggleShowPassword}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton></InputAdornment>
                    }}></TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeThisView}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleSubmit}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FacilityVerifyPassword