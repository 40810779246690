import { createContext, useEffect, useContext, useReducer } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { UserContext } from "../user.context";
import { createAction } from "../../utils/reducer/reducer.utils";

export const FacilityHoursOfOperationContext = createContext({
    hoursOfOperation: null,
    setHoursOfOperation: () => null,
    hoursOfOperationExceptions: null,
    setHoursOfOperationExceptions: null,
});

export const FACILITY_HOURS_ACTION_TYPES = {
    SET_FACILITY_HOURS: 'SET_FACILITY_HOURS',
}

const INITIAL_STATE = {
    hoursOfOperation: null,
    hoursOfOperationExceptions: null
}

const facilityAreaReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case FACILITY_HOURS_ACTION_TYPES.SET_FACILITY_HOURS:
            return {
                ...state, //this keeps everything else and doesn't overwrite
                ...payload
            }
        default:
            throw new Error(`Unhandled type ${type}`);
    }

}

export const FacilityHoursOfOperationProvider = ({ children }) => {

    const [{ hoursOfOperation, hoursOfOperationExceptions }, dispatch] = useReducer(facilityAreaReducer, INITIAL_STATE);
    const { currentUser, userData } = useContext(UserContext);

    const setHoursOfOperation = (hoop) => {
        dispatch(createAction(FACILITY_HOURS_ACTION_TYPES.SET_FACILITY_HOURS, {hoursOfOperation: hoop}));
    }

    const setHoursOfOperationExceptions = (hoopExceptions) => {
        dispatch(createAction(FACILITY_HOURS_ACTION_TYPES.SET_FACILITY_HOURS, {hoursOfOperationExceptions: hoopExceptions}));
    }


    useEffect(() => {

        const setupHoursOfOperation = (snapshot) => {
            setHoursOfOperation(snapshot.val());
        }

        if (currentUser && userData) {
            const db = getDatabase();
            const hoursRef = ref(db, `facility-user-accounts/${userData.facilityId}/facilityAccountInfo/hoursOfOperation`)
            const unsubscribe = onValue(hoursRef, (snapshot) => {
                if (snapshot.exists()) {
                    setupHoursOfOperation(snapshot);
                } 
            })
            return unsubscribe;
        }
    }, [currentUser, userData]);

    useEffect(() => {

        const setupHoursOfOperationExpceptions = (snapshot) => {
            if (snapshot.exists()) {
                setHoursOfOperationExceptions(snapshot.val());
            } 
        }

        if (currentUser && userData) {
            const db = getDatabase();
            const hoursRef = ref(db, `facility-user-accounts/${userData.facilityId}/facilityAccountInfo/hoursOfOperationExceptions`)
            const unsubscribe = onValue(hoursRef, (snapshot) => {
                setupHoursOfOperationExpceptions(snapshot);
            })
            return unsubscribe;
        }
    }, [currentUser, userData]);

    const value = { hoursOfOperation, setHoursOfOperation, hoursOfOperationExceptions, setHoursOfOperationExceptions }
    return <FacilityHoursOfOperationContext.Provider value={value}>{children}</FacilityHoursOfOperationContext.Provider>

}