import { Fragment, useContext, useEffect, useState } from "react";
import { GetBirthdayParties, CopyBirthdayParties, UpdateBirthdayPartiesStatus, GroupBirthdayParties } from "../../../utils/birthday-api/birthday-service";
import { UserContext } from "../../../contexts/user.context";
import { Modal, Paper, Stack, Typography, Box, Button, IconButton, Popover, FormLabel, Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../../../utils/data-grid-utils/data-grid-utilities";
import BirthdayPartySetup from "./birthday-party-setup.component";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import MySportSpaceSnackbarAlert from "../../alert-snackbar/alert-snackbar.component";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BirthdayPartyBookings from "./birthday-party-bookings.component";
import MySportSpaceAlert from "../../alert/alert.component";
import { CustomDisableInput } from "../../../utils/text-field-utils/text-field-utils";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { SketchPicker } from 'react-color';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import { uploadFileToStorageLocation } from "../../../utils/firebase/upload-facility-image";

const PAGE_SIZE = 10;

const validFileTypes = ["image/png", "image/jpeg", "image/webp"];

function getRowId(row) {
    return row.id;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const BirthdayPartyGroupSetup = ({ selectedGroup, close, refresh, index = "1" }) => {
    const { userData, isAdmin } = useContext(UserContext);
    const [alertMessage, setAlertMessage] = useState(null);
    const [birthdayParties, setBirthdayParties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPartyId, setSelectedPartyId] = useState(null);
    const [viewSignupsId, setViewSignupsId] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedParties, setSelectedParties] = useState([]);
    const [openAlert, setOpenAlert] = useState(null);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [updateGroup, setUpdateGroup] = useState(false);
    const [birthdayPartyGroup, setBirthdayPartyGroup] = useState(selectedGroup);
    const [originalBirthdayPartyGroup, setOriginalBirthdayPartyGroup] = useState(Object.assign({}, selectedGroup));
    const [addingPartyToGroup, setAddingPartyToGroup] = useState(false);
    const [showAddPartyDialog, setShowAddPartyDialog] = useState(false);
    const [showMainColorPicker, setShowMainColorPicker] = useState(false);
    const [showSecondaryColorPicker, setShowSecondaryColorPicker] = useState(false);
    const [popoverAnchorEl, setPopOverAnchorEl] = useState(null);
    const [newImageFile, setNewImageFile] = useState(null);

    const { id, groupName, searchTerm, description, mainTextColor = "#14254C", secondaryTextColor = '#A9A9A9', imageLocationURL, imageStorageLocation } = birthdayPartyGroup;

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100
    });

    const viewButtonClick = (e, row) => {
        e.stopPropagation();
        setSelectedPartyId(row.id);
    };

    const viewSignupsButtonClick = (e, row) => {
        e.stopPropagation();
        setViewSignupsId(row.id);
    };

    const columns = [
        {
            field: 'name',
            renderHeader: () => (
                <strong>
                    {'Name '}
                </strong>
            ),
            flex: 1.5,
        },
        {
            field: 'status',
            flex: 0.5,
            minWidth: 25,
            renderHeader: () => (
                <strong>
                    {'Status '}
                </strong>
            ),
        },
        {
            field: 'price',
            renderHeader: () => (
                <strong>
                    {'Price'}
                </strong>
            ),
            flex: 0.5,
            valueGetter: (value) => {
                if (!value) {
                    return ""
                }

                return `$${parseFloat(value).toFixed(2)}`
            },
        },
        {
            field: 'initialDeposit',
            renderHeader: () => (
                <strong>
                    {'Initial Deposit'}
                </strong>
            ),
            flex: 0.5,
            valueGetter: (value) => {
                if (!value) {
                    return ""
                }

                return `$${parseFloat(value).toFixed(2)}`
            },
        },
        {
            field: 'viewButton',
            headerName: '',
            description: '',
            sortable: false,
            flex: 1.0,
            renderCell: (params) => {
                return (
                    <Stack spacing={1} mt={1} direction={'row'}>
                        {
                            !isEditMode &&
                            <Button
                                onClick={(e) => viewButtonClick(e, params.row)}
                                variant="contained"
                            >
                                View
                            </Button>
                        }
                        {
                            !isEditMode &&
                            <Button
                                onClick={(e) => viewSignupsButtonClick(e, params.row)}
                                variant="contained"
                            >
                                Sign Ups
                            </Button>
                        }
                        {
                            isEditMode &&
                            <IconButton onClick={(e) => viewButtonClick(e, params.row)}>
                                <EditOutlinedIcon />
                            </IconButton>
                        }
                    </Stack>
                );
            },
        }
    ];

    async function getBirthdayParties(nextPage) {
        setIsLoading(true);
        let parties = [];
        if (userData && userData.facilityId) {

            let startAfterId = null;
            let startAt = null;
            let endAt = null;
            if (nextPage) {
                startAfterId = birthdayParties.length > 0 ? birthdayParties[birthdayParties.length - 1].name : null;
            } else if (birthdayParties.length > 0) {
                startAt = birthdayParties[0].name;
            }

            const response = await GetBirthdayParties(userData.facilityId, startAfterId, startAt, endAt, id);
            if (response && response.status === 200) {
                if (!response.data.status || response.data.status === 'success') {
                    parties = response.data.birthdayParties;
                }
            }

            setBirthdayParties(parties);
            setIsLoading(false);
        }

    }

    useEffect(() => {
        getBirthdayParties(true);

    }, [userData, paginationModel.page]);

    const closeView = (e) => {
        if (close) {
            close(index);
        }
    }

    const selectParty = (id) => {
        setSelectedPartyId(id);
    }

    const closeSelectedParty = () => {
        setSelectedPartyId(null);
        getBirthdayParties();
    }

    const edit = () => {

        if (!isAdmin) {
            setAlertMessage({
                title: 'Not Authorized',
                message: 'You are not authorized to edit.'
            });
        } else {
            setIsEditMode(true);
        }
    }

    const saveWithNewIds = (birthdayPartyIds) => {
        save(birthdayPartyIds)
    }

    const saveWithoutNewIds = () => {
        save(null);
    }

    const save = async (birthdayPartyIds) => {

        if (!groupName || groupName.length <= 0) {
            setOpenAlert({ type: 'needs_group_name', message: "You must provide a package name to group parties.", buttonText: "OK" });
            return;
        }

        if (!description || description.length <= 0) {
            setOpenAlert({ type: 'needs_description', message: "Please provide a short description of this group.", buttonText: "OK" });
            return;
        }

        setIsLoading(true);

        let imageLocationData = null;
        if (newImageFile) {
            try {
                const uploadResponse = await uploadFileToStorageLocation(newImageFile, `birthdayPartyGroupImages/${userData.facilityId}/${birthdayPartyGroup.id}`);
                imageLocationData = {
                    imageLocationURL: uploadResponse.downloadURL,
                    imageStorageLocation: uploadResponse.storageLocation
                };

                // setFormFields({ ...formFields, 'imageLocationURL': uploadResponse.downloadURL, 'imageStorageLocation': uploadResponse.storageLocation});
            } catch (error) {
                console.log(error);
                setAlertMessage({ title: "Image Upload Error", message: `Unable to upload the new area image.  Please try again.`, type: 'ok' });
                return;
            }
        } else {
            imageLocationData = {
                imageLocationURL: imageLocationURL,
                imageStorageLocation: imageStorageLocation
            };
        }

        let partyIds = [];
        if (!birthdayPartyIds) {
            for (const party of birthdayParties) {
                partyIds.push(party.id);
            }
        } else {
            partyIds = birthdayPartyIds;
        }

        const response = await GroupBirthdayParties(userData.facilityId, partyIds, groupName, id, searchTerm, description, mainTextColor, secondaryTextColor, imageLocationData);
        if (response && response.status === 200 && response.data.status === 'success') {
            setOpenAlert({ type: 'grouping_successful', message: "Birthday Party Group Saved", buttonText: "OK" });
            const newBirthdayPartyGroup = { ...birthdayPartyGroup, ['imageLocationURL']: imageLocationData.imageLocationURL, ['imageStorageLocation']: imageLocationData.imageStorageLocation };
            setBirthdayPartyGroup(newBirthdayPartyGroup);
            setOriginalBirthdayPartyGroup(Object.assign({}, newBirthdayPartyGroup));
            getBirthdayParties(false);
            setIsEditMode(false);
            setUpdateGroup(false);
            setSelectedParties([]);
        } else {
            setIsLoading(false);
            setOpenAlert({ type: 'grouping_error', message: "Birthday Party Group Save Error", buttonText: "" });
        }

        if (refresh) {
            refresh();
        }
    }

    const cancelEdit = () => {
        setSelectedParties([]);
        setIsEditMode(false);
        setBirthdayPartyGroup(originalBirthdayPartyGroup);
        setUpdateGroup(false);
        setNewImageFile(null);
    }

    const inActivate = () => {
        updateStatus('inactive');
    }

    const activate = () => {
        updateStatus('active');
    }

    const removeParties = async () => {

        setShowRemoveDialog(false);

        const newPartiesForGroup = [];
        for (const party of birthdayParties) {
            if (!selectedParties.includes(party.id)) {
                newPartiesForGroup.push(party.id);
            }
        }

        if (newPartiesForGroup.length === 0) {
            setOpenAlert({ type: 'needs_one', message: "A birthday party group must have at least 1 party.", buttonText: "OK" });
            return;
        }

        saveWithNewIds(newPartiesForGroup);
    }

    const addPartiesToGroup = async () => {
        setShowAddPartyDialog(false);
        setAddingPartyToGroup(true);
    }

    const closeAddingPartyToGroup = () => {
        setAddingPartyToGroup(false);
        getBirthdayParties(false);
    }

    const showTheAddToPartyDialog = () => {
        setShowAddPartyDialog(true);
    }

    const removePartiesFromGroup = async () => {

        if (selectedParties.length <= 0) {
            setOpenAlert({ type: 'needs_one_to_remove', message: "Please select at least 1 party to remove from the group.", buttonText: "OK" });
            return;
        }

        setShowRemoveDialog(true);
    }

    const closeRemoveDialog = () => {
        setShowRemoveDialog(false);
    }

    const updateStatus = async (status) => {

        if (selectedParties.length <= 0) {
            setOpenAlert({ type: 'needs_selection', message: "Please select at least 1 party to update.", buttonText: "" });
            return;
        }

        setIsLoading(true);
        const response = await UpdateBirthdayPartiesStatus(userData.facilityId, selectedParties, status);
        if (response && response.status === 200 && response.data.status === 'success') {
            setOpenAlert({ type: 'status_update_successful', message: "Status Updated", buttonText: "" });
            getBirthdayParties(false);
        } else {
            setOpenAlert({ type: 'status_update_error', message: "Status Update Error", buttonText: "" });
            setIsLoading(false);
        }
    }

    const updateGroupData = async () => {
        setUpdateGroup(true);
    }

    const copy = async () => {

        if (selectedParties.length <= 0) {
            setOpenAlert({ type: 'min_copies_reached', message: "Please select at least 1 party to copy.", buttonText: "" });
            return;
        }

        if (selectedParties.length > 5) {
            setOpenAlert({ type: 'max_reached', message: "Only 5 Copies Are Allowed At A Time", buttonText: "Clear" });
        } else {
            setIsLoading(true);
            const response = await CopyBirthdayParties(userData.facilityId, selectedParties);
            if (response && response.status === 200 && response.data.status === 'success') {

                const newBirthdayPartyArray = birthdayParties.concat(response.data.newBirthdayParties);
                const sortedParties = newBirthdayPartyArray.sort((party1, party2) => {
                    if (party1.name < party2.name) {
                        return -1;
                    }

                    if (party1.name > party2.name) {
                        return 1;
                    }

                    return 0;
                });
                setBirthdayParties(sortedParties);
                setSelectedParties([]);
                setOpenAlert({ type: 'copy_successful', message: "Birthday Parties Copied", buttonText: "" });
            } else {
                setOpenAlert({ type: 'copy_error', message: "Copy Error", buttonText: "" });
            }
            setIsLoading(false);
        }
    }

    const closeAlertMessage = () => {
        setOpenAlert(null);
        setAlertMessage(null);
    }

    const clearSelectedRows = () => {
        setSelectedParties([])
    }

    const closeViewBookings = (refresh) => {
        setViewSignupsId(null);
        if (refresh) {
            getBirthdayParties();
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBirthdayPartyGroup({ ...birthdayPartyGroup, [name]: value });
    }

    const cancelAddingParty = () => {
        setShowAddPartyDialog(false);
    }

    const addExistingParty = () => {
        closeView("1");
    }

    const closePopover = () => {
        setShowSecondaryColorPicker(false);
        setShowMainColorPicker(false);
        setPopOverAnchorEl(null);
    }

    const handleMainColorSelected = (color) => {

        if (color.hex === '#ffffff') {
            setAlertMessage("White cannot be your main color.")
            return;
        }

        setBirthdayPartyGroup({ ...birthdayPartyGroup, ["mainTextColor"]: color.hex });
    };

    const handleSecondaryColorSelected = (color) => {

        if (color.hex === '#ffffff') {
            setAlertMessage("White cannot be your secondary color.")
            return;
        }

        setBirthdayPartyGroup({ ...birthdayPartyGroup, ["secondaryTextColor"]: color.hex });
    };

    const openMainColorPicker = (event) => {
        if (updateGroup) {
            setPopOverAnchorEl(event.currentTarget);
            setShowSecondaryColorPicker(false);
            setShowMainColorPicker(!showMainColorPicker);
        } else {
            setAlertMessage({
                title: 'Pick Group Color',
                message: "Must be in edit mode to update the primary text color."
            });
        }
    }

    const openSecondaryColorPicker = (event) => {
        if (updateGroup) {
            setPopOverAnchorEl(event.currentTarget);
            setShowMainColorPicker(false);
            setShowSecondaryColorPicker(!showSecondaryColorPicker);
        } else {
            setAlertMessage({
                title: 'Pick Group Color',
                message: "Must be in edit mode to update the secondary text color."
            });
        }
    }

    const newImageFileUploaded = (event) => {

        const files = Array.from(event.target.files);

        if (files.length > 1) {
            setAlertMessage({
                title: 'Too Many Files',
                message: "Only one file is allowed."
            });
            return;
        }

        if (files && files.length) {
            for (const file of files) {
                if (!validFileTypes.includes(file.type)) {
                    setAlertMessage({
                        title: 'Invalid Image Format',
                        message: "Image must be in .png, .jpeg or .webp format."
                    });
                    return;
                }

                const size = (file.size / 1024 / 1024).toFixed(2);
                if (size > 4) {
                    setAlertMessage({
                        title: 'Image Too Large',
                        message: "File size cannot be greater than 4MB."
                    });
                    return;
                }
            }
        }

        setNewImageFile(files[0]);
    }

    const handleDeleteImage = () => {
        setNewImageFile(null);
        setBirthdayPartyGroup({ ...birthdayPartyGroup, ['imageLocationURL']: null });
    }

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'50px'} left={'0%'} right={'0%'} display={'flex'}>
            <Stack ml={3} sx={{
                width: '96%'
            }}>
                <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
                <Box mt={1} mb={2} display="flex" justifyContent="flex-start" alignItems="flex-start">
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Button onClick={closeView} startIcon={<CloseOutlinedIcon />}>
                        </Button>
                    </Box>
                    <Typography sx={{ width: '50%' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h5" component="div" ml={1} mt={1} mb={1}>
                        Birthday Party Group
                    </Typography>
                    {
                        !isEditMode &&
                        <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end" alignItems="center">
                            {
                                updateGroup &&
                                <Stack spacing={1} direction={'row'}>
                                    <Button size="small" startIcon={<SaveOutlinedIcon />} id={'save-button'} onClick={saveWithoutNewIds} variant="contained" >Save</Button>
                                    <Button size="small" startIcon={<CancelOutlinedIcon />} id={'cancel-button'} onClick={cancelEdit} variant="contained" >Cancel</Button>
                                </Stack>
                            }
                            {
                                !updateGroup &&
                                <Box mt={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Button size="small" startIcon={<EditOutlinedIcon />} id={'add-button'} onClick={updateGroupData} variant="contained" >Edit</Button>
                                </Box>
                            }
                        </Box>
                    }
                </Box>
                {
                    !isEditMode &&
                    <Stack sx={{ width: '100%', border: `${isEditMode ? '1px solid #A9A9A9' : 'none'}`, borderRadius: '5px' }} direction={'row'}>
                        <Stack mt={1} ml={1} mr={1} >
                            <Stack width={'100%'} direction={'row'} spacing={2}>
                                <Stack width={'100%'}>
                                    <CustomDisableInput size="small" disabled={!updateGroup} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="groupName" variant="outlined" label="Group Name" type="text" name="groupName" value={groupName ? groupName : ""} onChange={handleChange} ></CustomDisableInput>
                                    <CustomDisableInput helperText={`Customer link: www.mysportspace.com/birthday-parties/${userData.searchTerm ? userData.searchTerm : userData.facilityId}/package/${searchTerm ? searchTerm : id}`} size="small" disabled={!updateGroup} fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="searchTerm" variant="outlined" label="Group Web Url" type="text" name="searchTerm" value={searchTerm ? searchTerm : ""} onChange={handleChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!updateGroup} required fullWidth sx={{ mt: '1.0vh', mb: '2.0vh' }} id="description" variant="outlined" label="Description" type="text" name="description" value={description ? description : ""} onChange={handleChange} ></CustomDisableInput>
                                </Stack>
                            </Stack>
                            <Divider></Divider>
                            <Stack mt={2} mb={2} spacing={4} direction={'row'} display={'flex'} alignContent={'center'}>
                                <Stack mt={5} ml={2} mr={2} spacing={1}>
                                    <Stack spacing={1} direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                        <FormLabel sx={{ fontWeight: 'bold', color: "#14254C" }}>Group Party Image</FormLabel>
                                        {
                                            updateGroup &&
                                            <Button
                                                component="label"
                                                role={undefined}
                                                tabIndex={-1}
                                                startIcon={<EditOutlinedIcon />}
                                            >
                                                <VisuallyHiddenInput onChange={newImageFileUploaded} type="file" />
                                            </Button>
                                        }
                                        {
                                            updateGroup && imageLocationURL &&
                                            <Button startIcon={<DeleteIcon />} onClick={handleDeleteImage}></Button>
                                        }
                                    </Stack>
                                    <Typography variant="subtitle2" color={"darkgray"}>Use an image in 16X9 format for best resolution.  This image will be displayed to the customer when viewing party group</Typography>
                                    <Divider></Divider>
                                    <Box sx={{ height: '25vh', width: '20vh', backgroundColor: 'lightgray' }}>
                                        <img
                                            srcSet={`${newImageFile ? URL.createObjectURL(newImageFile) : imageLocationURL && imageLocationURL.includes('blob') ? imageLocationURL : imageLocationURL + '&w=164&h=164&fit=crop&auto=format&dpr=2 2x'}`}
                                            src={`${newImageFile ? URL.createObjectURL(newImageFile) : imageLocationURL && imageLocationURL.includes('blob') ? imageLocationURL : imageLocationURL + '&w=164&h=164&fit=crop&auto=format&dpr=2 2x'}`}
                                            alt={isEditMode ? 'No Image' : "Edit to Add Image"}
                                            loading='lazy'
                                            width={'100%'}
                                            height={'100%'}
                                            style={{ backgroundColor: `${imageLocationURL || newImageFile ? 'white' : 'gray'}`, objectFit: 'contain' }}
                                        />
                                    </Box>
                                </Stack>
                                <Stack mt={1} mb={2} spacing={1}>
                                    <FormLabel sx={{ fontWeight: 'bold', color: "#14254C" }}>Group Party Colors</FormLabel>
                                    <Typography variant="subtitle2" color={"darkgray"}>Colors used when displying Group Card to customer</Typography>
                                    <Divider></Divider>
                                    <Stack spacing={4} direction={'row'} display={'flex'} justifyContent={'left'} alignContent={'center'} alignItems={'center'}>
                                        <Typography sx={{ fontSize: '2.0vh', width: '75%' }} variant="subtitle1" color={"#14254C"}>Main Text Color</Typography>
                                        <Box display={'flex'} justifyContent={'center'} sx={{
                                            height: '25px',
                                            width: '25px',
                                            backgroundColor: `${mainTextColor}`,
                                            borderRadius: '50%',
                                            display: 'inline-block',
                                            cursor: 'pointer'
                                        }} onClick={openMainColorPicker}>
                                        </Box>

                                        <Popover
                                            id={'main-text-color-popover'}
                                            open={showMainColorPicker}
                                            anchorEl={popoverAnchorEl}
                                            onClose={closePopover}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <SketchPicker color={mainTextColor} onChangeComplete={handleMainColorSelected} ></SketchPicker>
                                        </Popover>
                                    </Stack>
                                    <Stack spacing={4} direction={'row'} display={'flex'} justifyContent={'left'} alignContent={'center'} alignItems={'center'}>
                                        <Typography sx={{ fontSize: '2.0vh', width: '75%' }} variant="subtitle1" color={"#14254C"}>Secondary Text Color</Typography>
                                        <Box display={'flex'} justifyContent={'center'} sx={{
                                            height: '25px',
                                            width: '25px',
                                            backgroundColor: `${secondaryTextColor}`,
                                            borderRadius: '50%',
                                            display: 'inline-block',
                                            cursor: 'pointer'
                                        }} onClick={openSecondaryColorPicker} >
                                        </Box>
                                        <Popover
                                            id={'main-text-color-popover'}
                                            open={showSecondaryColorPicker}
                                            anchorEl={popoverAnchorEl}
                                            onClose={closePopover}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <SketchPicker color={secondaryTextColor} onChangeComplete={handleSecondaryColorSelected}></SketchPicker>
                                        </Popover>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                }
                <Box mt={2} mb={1} sx={{ width: '100%' }} display="flex" justifyContent="flex-end" alignItems="center">
                    <Stack spacing={1} direction={'row'}>
                        {
                            !isEditMode && !updateGroup &&
                            <Stack spacing={1} direction={'row'}>
                                <Button size="small" startIcon={<EditOutlinedIcon />} id={'create-button'} onClick={edit} variant="contained" >Edit</Button>
                            </Stack>
                        }
                        {
                            isEditMode && !updateGroup &&
                            <Fragment>
                                <Button size="small" startIcon={<AddOutlinedIcon />} id={'add-button'} onClick={showTheAddToPartyDialog} variant="contained" >Add</Button>
                                <Button size="small" startIcon={<GroupWorkOutlinedIcon />} id={'remove-button'} onClick={removePartiesFromGroup} variant="contained" >Remove</Button>
                                <Button size="small" startIcon={<ContentCopyOutlinedIcon />} id={'copy-button'} onClick={copy} variant="contained" >Copy</Button>
                                <Button size="small" startIcon={<ToggleOffOutlinedIcon />} id={'inactive-button'} onClick={inActivate} variant="contained" >Inactive</Button>
                                <Button size="small" startIcon={<ToggleOnOutlinedIcon />} id={'activate-button'} onClick={activate} variant="contained" >Activate</Button>
                                <Button size="small" startIcon={<CancelOutlinedIcon />} id={'cancel-button'} onClick={cancelEdit} variant="contained" >Cancel</Button>
                            </Fragment>
                        }
                    </Stack>
                </Box>

                {
                    !updateGroup &&
                    <DataGrid sx={{
                        minHeight: '300px',
                        font: 'Helvetica', color: '#14254C',
                        fontWeight: 400, '--DataGrid-overlayHeight': '1000px'
                    }} getRowId={getRowId} loading={isLoading} rowCount={1} initialState={{ pagination: paginationModel }} pageSizeOptions={[100]} onPaginationModelChange={setPaginationModel} paginationMode="server"
                        rows={birthdayParties} columns={columns} checkboxSelection={isEditMode} disableRowSelectionOnClick={true} rowSelectionModel={selectedParties} onRowSelectionModelChange={(ids) => {
                            if (!isEditMode) {
                                selectParty(ids);
                            } else {
                                setSelectedParties(ids)
                            }
                        }}
                        slots={{ noRowsOverlay: CustomNoRowsOverlay }} />
                }

            </Stack>
            <Modal disableScrollLock={true} sx={{ overflow: 'auto', overflowY: 'scroll', position: 'absolute', top: '1%', bottom: '1%', left: '5%', right: '5%' }} open={selectedPartyId ? true : false}>
                <Paper sx={{ overflow: 'scroll' }}>
                    <BirthdayPartySetup key={'birthday-party-setup-modal'} birthdayPartyId={selectedPartyId} close={closeSelectedParty} isModal={true}></BirthdayPartySetup>
                </Paper>
            </Modal>
            <Modal sx={{ position: 'absolute', top: '10%', bottom: '-10%', left: '10%', right: '10%' }} open={viewSignupsId ? true : false}>
                <BirthdayPartyBookings close={closeViewBookings} birthdayPartyId={viewSignupsId} ></BirthdayPartyBookings>
            </Modal>
            <Modal disableScrollLock={true} sx={{ overflow: 'auto', overflowY: 'scroll', position: 'absolute', top: '1%', bottom: '1%', left: '1%', right: '1%' }} open={addingPartyToGroup}>
                <Paper sx={{ overflow: 'scroll' }}>
                    <BirthdayPartySetup key={'birthday-party-create-setup-modal'} close={closeAddingPartyToGroup} isModal={true} birthdayGroupId={id}  ></BirthdayPartySetup>
                </Paper>
            </Modal>
            <MySportSpaceSnackbarAlert isOpen={openAlert ? true : false} close={closeAlertMessage} message={openAlert ? openAlert.message : ""} buttonText={openAlert ? openAlert.buttonText : ''} action={openAlert && openAlert.type === 'max_reached' ? clearSelectedRows : closeAlertMessage} />
            <Dialog
                open={showRemoveDialog}
                onClose={closeRemoveDialog}
            >
                <DialogTitle>Remove From Group?</DialogTitle>
                <DialogContent>
                    <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        These birthday parties will be removed from the group.  Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeRemoveDialog}>No</Button>
                    <Button onClick={removeParties} variant="contained">Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showAddPartyDialog}
                onClose={closeRemoveDialog}
            >
                <DialogTitle>Add Party To Group</DialogTitle>
                <DialogContent>
                    <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        How would you like to add a party to this group?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelAddingParty}>Cancel</Button>
                    <Button onClick={addPartiesToGroup} variant="contained">New Party</Button>
                    <Button onClick={addExistingParty} variant="contained">Existing Party</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default BirthdayPartyGroupSetup;