import styled from 'styled-components';

export const AddressFormContainer = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: auto;
        width: 100%;
    }

`