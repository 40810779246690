import { LeftSideBarContainer } from "./left-side-bar.styles";
import FacilityCalendarDatePicker from "../../facility-calendar-date-picker/facility-calendar-date-picker.component";
import { DisplayContext } from "../../../../contexts/dispaly-options.context";
import { Fragment, useContext, useRef, useEffect } from "react";
import { UserContext } from "../../../../contexts/user.context";
import { SearchForReservationContext } from "../../../../contexts/search-for-reservation/search-for-reservation.context";
import { useNavigate } from "react-router-dom";
import AddBoxIcon from '@mui/icons-material/AddBox';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Box } from "@mui/material";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from "@mui/material/ListItem";

const LeftSideBar = ({closeMenu}) => {

    const { resetAllDisplays } = useContext(DisplayContext);
    const { reset } = useContext(SearchForReservationContext);

    const navigate = useNavigate();

    const { hasReservationPrivileges } = useContext(UserContext);

    const startNewSearch = () => {
        resetAllDisplays();
        reset();
        navigate('/facility/reservation-search');
    }

    const createBlackout = () => {
        resetAllDisplays();
        reset();
        navigate('/facility/create-blackout');
    }

    const closeView = () => {
        if (closeMenu){
            closeMenu();
        }
    }

    return (
        <Box
            sx={{ width: 500}}
            role="presentation">

            {hasReservationPrivileges &&
                <List>
                    <ListItem key={'New Reservation'} disablePadding>
                        <ListItemButton onClick={startNewSearch}>
                            <ListItemIcon>
                                <AddBoxIcon color="primary"></AddBoxIcon>
                            </ListItemIcon>
                            <ListItemText sx={{color: '#14254C'}} primary={'New Reservation'} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key={'Create Blackout'} disablePadding>
                        <ListItemButton onClick={createBlackout}>
                            <ListItemIcon>
                                <NoteAddIcon color="primary"></NoteAddIcon>
                            </ListItemIcon>
                            <ListItemText sx={{color: '#14254C'}} primary={'Create Blackout'} />
                        </ListItemButton>
                    </ListItem>

                </List>
            }
            <FacilityCalendarDatePicker closeView={closeView}></FacilityCalendarDatePicker>

        </Box >

    )
}

export default LeftSideBar