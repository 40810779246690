import { Divider, Typography, Box, Stack, Button, CardActions, Paper, Container, Popover } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { BirthdayPartyContext } from "../../../contexts/birthday-party/birthday-party.context";
import { useParams } from 'react-router-dom';
import { Fragment, useState, useEffect, useContext } from "react";
import { GetBirthdayPartyDetails } from "../../../utils/birthday-api/birthday-service";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import Fab from '@mui/material/Fab';
import BirthdaySearchDateTime from "./birthday-search-date-time.component";
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../../../contexts/user.context";
import { SearchForBirthdayParty } from "../../../utils/birthday-api/birthday-service";
import { buildReservationSearchRequest } from "../../../utils/facility-api/facility-service";
import BirthdayBooking from "./birthday-booking.component";
import BirthdayPayment from "./birthday-payment.component";
import BirthdayConfirmation from "./birthday-confirmation.component";
import MySportSpaceAlert from "../../alert/alert.component";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import BirthdayPartyTimeSlots from "./birthday-party-time-slots.component";
import { DAYS_OF_WEEK_INT_TO_TEXT } from '../../../utils/constants/constants';
import dayjs from "dayjs";

const style = {
    position: 'absolute',
    top: '5%',
    left: '10%',
    right: '10%',
    bottom: '5%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};

const BirthdayPartyDetails = () => {

    const { userData } = useContext(UserContext);
    const { facilityName, birthdayPartySearchTerm, birthdayPartyDate } = useParams();
    const [isFetching, setIsFetching] = useState(false);
    const [ signInAlertMessage, setSignInAlertMessage ] = useState(null);
    const { isLoading, setIsLoading, partyInput, setPartyInput, alertMessage, setAlertMessage, setPreparedSummary, selectedBirthdayParty, setSelectedBirthdayParty, searchResponse, setSearchResponse } = useContext(BirthdayPartyContext);
    const [popoverAnchorEl, setPopOverAnchorEl] = useState(null);
    const { name, price, initialDeposit, maxCapicity, description, descriptions = [], length, ancillaries = [], status, timeSlots = [], daysOffered = [] } = selectedBirthdayParty && selectedBirthdayParty.details ? selectedBirthdayParty.details : {};
    const { mainTextColor = '#14254C', secondaryTextColor = "#A9A9A9" } = selectedBirthdayParty && (!userData || (userData && !userData.isFacilityUserAccount)) ? selectedBirthdayParty : {};

    const navigate = useNavigate();

    let theme = createTheme({
        palette: {
            primary: {
                main: mainTextColor
            },
            secondary: {
                main: secondaryTextColor,
            },
        },
        typography: {
            fontFamily: ['Helvetica'],
        },
        components: {
            MuiDateCalendar: {
                styleOverrides: {
                    root: {
                        color: mainTextColor,
                    },
                },
            },
            MuiDivider: {
                defaultProps: {
                    color: secondaryTextColor,
                }
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        color: mainTextColor,
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    color: "primary",
                    inputProps: {
                        style: {
                            color: mainTextColor,
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: ({ theme }) =>
                        theme.unstable_sx({
                            color: mainTextColor,
                        })
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        color: mainTextColor,
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        color: mainTextColor,
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        color: mainTextColor,
                    },
                },
            },
        },
    });

    const fetchPartyDetails = async () => {
        setIsFetching(true);
        const detailsResponse = await GetBirthdayPartyDetails(facilityName, birthdayPartySearchTerm);
        if (detailsResponse && detailsResponse.status == 200 && detailsResponse.data && detailsResponse.data.status === 'success') {
            setSelectedBirthdayParty(detailsResponse.data.birthdayParty);
        } else {
            setSelectedBirthdayParty(null);
        }
        setIsFetching(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPartyDetails();

        setPartyDate();

    }, []);

    const setPartyDate = () => {
        if (birthdayPartyDate){
            setPartyInput({ ...partyInput, 'date': dayjs(birthdayPartyDate, "YYYY-MM-DD") });
        }
    }

    const closeBooking = () => {
        setSearchResponse(null);
    }

    const closeConfirmation = () => {
        navigate(`/birthday-parties/${facilityName}`)
    }

    const closeDetails = () => {
        navigate(`/birthday-parties/${facilityName}`)
    }

    const closePopover = () => {
        setPopOverAnchorEl(null);
    }

    const openPopover = (event) => {
        setPopOverAnchorEl(event.currentTarget);
    }

    const closeSignInAlert = () => {
        setSignInAlertMessage(null);
    }

    const signIn = () => {
        navigate(`/auth?redirectUrl=/birthday-parties/${facilityName}/${birthdayPartySearchTerm}/${partyInput.date.format("YYYY-MM-DD")}`);
    }

    const searchForParty = async (date, startTime, endTime, timeSlotId) => {

        const isFacilitySearch = userData && userData.isFacilityUserAccount;
        if (!isFacilitySearch && (!status || status !== 'active')){
            setAlertMessage({ message: 'This party is not available at the moment.' });
        } else if  (!userData) {
            setSignInAlertMessage({ message: 'You must be signed in to book a Birthday Party.' });
        } else if (userData.facilityId && !partyInput.customer) {
            setAlertMessage({ message: 'You must select a customer.' });
        } else {
            setIsLoading(true);
            const reservationSearchData = {
                selectedSport: 'Birthday Party Package',
                startTime: startTime,
                endTime: endTime ? endTime : startTime,
                reservationLength: selectedBirthdayParty.details.length,
                searchDate: date,
                customer: userData.facilityId ? partyInput.customer : userData,
                timeSlotId: timeSlotId
            }
            const searchRequest = buildReservationSearchRequest(reservationSearchData, null, selectedBirthdayParty.facilityId, null, userData ? userData.isFacilityUserAccount : false);
            const response = await SearchForBirthdayParty(selectedBirthdayParty.facilityId, selectedBirthdayParty.id, date.format("YYYY-MM-DD"), searchRequest, userData ? userData.isFacilityUserAccount : false);
            if (response && response.data && response.data.status === 'success') {
                setPopOverAnchorEl(null);
                setSearchResponse(response.data);
            } else {
                setSearchResponse(null);
                let message = "This birthday party is not available for this date/time. Please try another date/time."
                setAlertMessage({ message: response && response.data.message ? response.data.message : message });
            }

            setIsLoading(false);
        }
    }

    const closeAlert = () => {

        if (alertMessage.failedReason && alertMessage.failedReason === 'res_quotes_are_invalid') {
            setSearchResponse(null);
            setPreparedSummary(null);
        }

        setAlertMessage(null);
    }

    let dowText = daysOffered.length > 0 ? "" : "All";
    for (const dow of daysOffered) {
        dowText = `${dowText} ${DAYS_OF_WEEK_INT_TO_TEXT[dow]}`
    }

    const popoverIsOpen = Boolean(popoverAnchorEl);
    return (
        <ThemeProvider theme={theme}>
            <Fragment>
                <MySportSpaceLoadingView isOpen={isFetching || isLoading}></MySportSpaceLoadingView>
                {
                    !isLoading && !isFetching && !selectedBirthdayParty &&
                    <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'9.0vh'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
                        <Box sx={{ width: '100%' }} display={'flex'} justifyContent={'center'}>
                            <Typography variant="h6">We could not find the birthday party are looking for</Typography>
                        </Box>
                    </Box>
                }
                {
                    selectedBirthdayParty &&
                    <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'9.0vh'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
                        <Fab
                            variant="extended"
                            sx={{
                                position: 'fixed',
                                top: '9.0vh',
                                right: '10%',
                                marginTop: '1%',
                                backgroundColor: `${secondaryTextColor}`,
                                color: 'white'
                            }}
                            onClick={openPopover}
                        >
                            Book This Party
                        </Fab>

                        <Popover
                            id={'search-for-birthday-popover'}
                            open={popoverIsOpen && !isLoading}
                            anchorEl={popoverAnchorEl}
                            onClose={closePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            {
                                timeSlots.length <= 0 &&
                                <BirthdaySearchDateTime mainTextColor={mainTextColor} secondaryTextColor={secondaryTextColor} searchForParty={searchForParty} close={closePopover}></BirthdaySearchDateTime>
                            }
                            {
                                timeSlots.length > 0 &&
                                <BirthdayPartyTimeSlots searchForParty={searchForParty} close={closePopover} birthdayPartyDetails={selectedBirthdayParty ? selectedBirthdayParty.details : {}}></BirthdayPartyTimeSlots>
                            }

                        </Popover>
                        {
                            searchResponse &&
                            <BirthdayBooking key={'birthday-party-booking-modal'} close={closeBooking} ></BirthdayBooking>
                        }
                        <BirthdayPayment></BirthdayPayment>
                        <BirthdayConfirmation close={closeConfirmation}></BirthdayConfirmation>
                        <MySportSpaceAlert key={'facility-birthday-parties-alert'} isOpen={alertMessage ? true : false} message={alertMessage ? alertMessage.message : ""} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
                        <MySportSpaceAlert key={'facility-birthday-parties-sign-im-alert'} isOpen={signInAlertMessage ? true : false} message={signInAlertMessage ? signInAlertMessage.message : ""} okButtonText={'Sign In'} okAction={signIn} cancelAction={closeSignInAlert} cancelButtonText={"Cancel"} ></MySportSpaceAlert>
                        <Stack ml={1} mr={1} sx={{ width: '100%' }} display="flex" alignItems={'center'} >
                            <Box sx={{ width: '90%' }} display="flex" justifyContent="flex-start" alignItems="flex-start" >
                                <Stack sx={{ width: '100%', height: '100%', marginLeft: '2.0vw', marginRight: '2.0vw', marginBottom: '1.0vh', marginTop: '1%' }}>
                                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                        <Button sx={{ color: `${secondaryTextColor}` }} onClick={closeDetails} startIcon={<ArrowBackIcon />}>
                                            Back
                                        </Button>
                                    </Box>
                                    <Typography mt={2} textAlign={'center'} gutterBottom component="div" variant="h3">
                                        {name}
                                    </Typography>
                                    <Typography mt={2} textAlign={'center'} gutterBottom paragraph component="div" variant="h6">
                                        {description}
                                    </Typography>
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                                    <Typography ml={2} mt={2} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h5">
                                        Party Details
                                    </Typography>
                                    <Stack direction={'row'}>
                                        <Typography ml={5} textAlign={'left'} gutterBottom component="div" variant="h6">
                                            Price ${price}
                                        </Typography>
                                        {initialDeposit &&
                                            <Typography ml={5} textAlign={'left'} gutterBottom component="div" variant="h6">
                                                Deposit ${initialDeposit}
                                            </Typography>
                                        }
                                        <Typography ml={5} textAlign={'left'} gutterBottom component="div" variant="h6">
                                            Participants {maxCapicity}
                                        </Typography>
                                        <Typography ml={5}  textAlign={'left'} gutterBottom component="div" variant="h6">
                                            Length {length} Hours
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                                    <Typography ml={2} mt={2} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h5">
                                        Available On
                                    </Typography>
                                    <Typography ml={5} textAlign={'left'} gutterBottom component="div" variant="h6">
                                        {dowText}
                                    </Typography>
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                                    {
                                        descriptions.length > 0 &&
                                        descriptions.map((desc) => {
                                            const { title, details, id } = desc;
                                            let detailsIndex = 0;
                                            return <Fragment key={`frag-title-key-${id}`}>
                                                <Typography key={`title-${id}`} ml={2} mt={2} textAlign={'left'} fontWeight={'bold'} gutterBottom component="div" variant="h5">
                                                    {title}
                                                </Typography>
                                                <Stack>
                                                    {
                                                        details.split("\n").map((bdaydetails) => {
                                                            return <Typography key={`${id}-bday-details-${detailsIndex += 1}`} ml={5} textAlign={'left'} gutterBottom component="div" variant="h6">
                                                                {bdaydetails}
                                                            </Typography>
                                                        })
                                                    }
                                                </Stack>
                                                <Divider key={`divider-${id}`} sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                                            </Fragment>
                                        })
                                    }
                                    {
                                        ancillaries && ancillaries.length > 0 &&
                                        <Typography ml={2} mt={2} textAlign={'left'} fontWeight={'bold'} gutterBottom component="div" variant="h5">
                                            Optional Items
                                        </Typography>
                                    }
                                    <Stack ml={5} mt={2} spacing={2} direction={'row'} useFlexGap flexWrap="wrap" justifyContent="center" alignItems="center">
                                        {
                                            ancillaries && ancillaries.length > 0 &&
                                            ancillaries.map((anc) => {
                                                const { id, name, description, price } = anc;
                                                return <Card key={`anc-card-${id}`} raised sx={{
                                                    width: "25vw",
                                                    height: "25vh", display: "flex", flexDirection: 'column', alignItems: 'stretch', boxShadow: `0 3px 10px ${secondaryTextColor}`
                                                }}>
                                                    <CardContent key={`anc-card-content-${id}`} sx={{ display: "flex", flexDirection: 'column', flexGrow: '1', alignItems: 'stretch' }}>
                                                        <Typography key={`anc-card-content-name-${id}`} fontWeight={'semi'} textAlign={'left'} gutterBottom component="div" variant="h6">
                                                            {name}
                                                        </Typography>
                                                        <Divider></Divider>
                                                        <Typography key={`anc-card-content-description-${id}`} mt={1} ml={2} textAlign={'left'} gutterBottom component="div" variant="subtitle1">
                                                            {description}
                                                        </Typography>
                                                    </CardContent>
                                                    <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
                                                        <Divider sx={{ width: '25%', marginRight: '1.0vw' }}></Divider>
                                                    </Box>
                                                    <CardActions >
                                                        <Typography key={`anc-card-content-price ${id}`} width={'98%'} ml={1} mr={1} textAlign={'right'} fontWeight={'bold'} gutterBottom component="div" variant="h6">
                                                            ${price}
                                                        </Typography>
                                                    </CardActions>
                                                </Card>
                                            })
                                        }
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                }
            </Fragment>
        </ThemeProvider>
    )
}

export default BirthdayPartyDetails;