import './react-calendar.styles.scss'
import dayjs from "dayjs";
import { useContext } from 'react';
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const FacilityCalendarDatePicker = ({ closeView }) => {

    const { reservationsDate, setReservationsDate } = useContext(FacilityReservationsContext);

    const calendarDateSelected = (date) => {
        setReservationsDate(date.toDate())

        if (closeView) {
            closeView();
        }
    }

    return (
        // <ReservationCalendarPickerContainer>
        //     {
        //         reservationsDate &&
        //         <MaterialUIDatePicker labelText="Calendar Date" onChange={calendarDateSelected} value={dayjs(reservationsDate)}></MaterialUIDatePicker>
        //     }

        // </ReservationCalendarPickerContainer>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
            <StaticDatePicker
                label={"Calendar Date"}
                value={dayjs(reservationsDate)}
                onChange={calendarDateSelected}
                slotProps={{
                    actionBar: {
                        actions: ['today'],
                      },
                    layout: {
                        sx: {
                            '.MuiDateCalendar-root': {
                                color: '#14254C',
                                borderRadius: 2,
                                borderWidth: 1,
                                borderColor: '#14254C',
                                border: '1px solid',
                                backgroundColor: 'white',
                            }
                        }
                    }
                }}
                sx={{
                    "& .MuiInputLabel-root.Mui-focused": { color: "#979797" }, //styles the label
                    "& .MuiOutlinedInput-root": {
                        color: "#14254C"
                    },
                }}
            />
        </LocalizationProvider>
    )
}

export default FacilityCalendarDatePicker;