import FormOfPayment from "../form-of-payment/form-of-payment.component";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

const FormOfPaymentAlert = ({ isOpen, facilityCustomer, formOfPayment, formOfPaymentType, selectFormOfPayment, selectFormOfPaymentType, message, okButtonText, cancelButtonText, okAction, cancelAction }) => {

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="form-of-payment-alert-title"
            aria-describedby="form-of-payment-alert-description"
        >
            <DialogTitle id="form-of-payment-alert-title">
               Form of Payment Needed
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
                <FormOfPayment key={'cancel-form-of-payment-type-key'} facilityCustomer={facilityCustomer} formOfPayment={formOfPayment} formOfPaymentType={'Credit Card'} selectFormOfPayment={selectFormOfPayment} selectFormOfPaymentType={selectFormOfPaymentType} allowFOPSelection={false} ></FormOfPayment>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelAction}>
                    {cancelButtonText}
                </Button>
                <Button variant='contained' onClick={okAction}>
                    {okButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FormOfPaymentAlert;