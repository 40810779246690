import styled from 'styled-components';

export const CustomerSignInOrSignupContainer = styled.div`
    position: absolute;
    top: 15%;
    bottom: -10%;
    left: 0;
    right: 0;
    display: grid;
    height: 100%;
    width: 80%;
    margin: auto;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas:
    "signup signin";

    .sign-up-container {
        grid-area: signup;
    }

    .sign-in-container {
        grid-area: signin;
        display: flex;
        flex-direction: column;
    }

`