import styled from 'styled-components';

export const MembershipListAllContainer = styled.div`

    display: flex;
    flex-direction: column;
    height: 90%;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow: scroll;
    border-radius: 5px;
    border: 1px solid #eaeaea;

    .membership-list {
        list-style:none;
        padding-left:0;
        margin-left: 1.0rem;
        min-height: 100px;
        background-color: white;
        overflow: scroll;
    }

`