import styled from 'styled-components';

export const SearchLocationInputContainer = styled.div`
display: flex;
flex-direction: column;
position: absolute;
width: 20vw;
z-index: 10;
background-color: white;
border-radius: 5px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
margin-left: -1vw;
margin-top: -1vh;
gap: 1.0vh;
padding: 1.5vh;

    .input-container {
        display: flex;
        width: 100%;
        height: 5vh;
        margin-top: 1.0vh;
    }

    .clear-btn {
        margin: auto;
        margin-left: 0.5vw;
        height: 2vh;
        width: 2vh;
        cursor: pointer;
    }

    .or-label {
        margin: auto;
        color: #14254C;
    }

    .icon {
        margin: auto;
        margin-left: 0.5vw;
        height: 2vh;
        width: 2vh;
        cursor: pointer;
    }

    .title-and-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin-left: 0.5vw;
        margin-right: 0.5vw;
        cursor: pointer;

        .title {
            height: 9vh;
            width: 100%;
            font-size: 1.5vh;
            color: gray;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-top: 0.5vh;
        }

        .text-container {
            display: flex;
            width: 100%;
            height: 90vh;
            cursor: pointer;

            .text-input {
                width: 100%;
                margin: auto;
                margin-top: 0.5vh;
                margin-left: 0.25vw;
                border: none; 
                border-width: 0; 
                box-shadow: none;
                border-bottom: 1px solid #DEDEDE;
                outline: none;
                padding-left: 10px;
                font-size: 1.75vh;
                color: #14254C;
            }

            input::placeholder {
                color: #14254C;
            }

            .text {
                width: 87%;
                margin: auto;
                margin-left: 1.0vw;
                font-size: 1.75vh;
                color: #14254C;
                cursor: pointer;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: left;
                overflow: hidden;
                border-bottom: 1px solid #DEDEDE;
            }
        }
    }
`