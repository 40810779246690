import { Routes, Route } from 'react-router-dom';
import FacilityReservationSearch from '../facility/facility-reservation-search/facility-reservation-search.component';
import FacilityUserGuardedRoute from '../guarded-routes/facility-user-guarded-route.component';
import FacilityCalendarHome from '../facility/facility-calendar-home/facility-calendar-home.component';
import FacilityCustomers from '../facility/facility-customers/facility-customers.component';
import FacilityReservationBook from '../facility/facility-reservation-book/facility-reservation-book.component';
import FacilityReservationBookSummary from '../facility/facility-reservation-book-results/facility-reservation-book-results.component';
import FacilityCreateBlackout from '../facility/facility-create-blackout/facility-create-blackout.component';
import FacilityCustomerViewReservations from '../facility/facility-customer-view-reservations/facility-customer-view-reservations.component';
import { UserContext } from '../../contexts/user.context';
import { useContext } from 'react';
import { FacilityReservationsProvider } from '../../contexts/facility/facility-reservations.context';
import { FacilityMembershipsProvider } from '../../contexts/facility/facility-memberships.context';
import { FacilityOpenBookingTimesProvider } from '../../contexts/facility/facility-open-booking-times.context';
import { FacilityHoursOfOperationProvider } from '../../contexts/facility/facility-hours-of-operation.context'
import FacilitySettings from './facility-settings/facility-settings-component';
import WebCalendarSettings from '../../components/facility/web-calendar-settings/web-calendar-settings.component';
import FacilityBirthdayPartyRoutes from './facility-birthday-parties/facility-birthday-parties.component';
import FacilityAreaRoutes from './facility-areas/facility-areas.component';
import ReservationSettings from '../../components/facility/reservation-settings/reservation-settings.component';
import ReservationTimeAdjustments from '../../components/facility/reservation-time-adjustments/reservation-time-adjustments.component';
import { ReservationTimeAdjustmentsProvider } from '../../contexts/facility/reservation-time-adjustments.context';

const Facility = () => {

    const { userData, userIsLoading } = useContext(UserContext);

    return (
        <FacilityReservationsProvider>
            <FacilityHoursOfOperationProvider>
                <FacilityMembershipsProvider>
                    <FacilityOpenBookingTimesProvider>
                        <ReservationTimeAdjustmentsProvider>
                            <Routes>
                                <Route path='reservation-search'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><FacilityReservationSearch /></FacilityUserGuardedRoute>}
                                />
                                <Route path='calendar'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><FacilityCalendarHome /></FacilityUserGuardedRoute>}
                                />
                                <Route path='customers'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><FacilityCustomers /></FacilityUserGuardedRoute>}
                                />
                                <Route path='reservation-book'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><FacilityReservationBook /></FacilityUserGuardedRoute>}
                                />
                                <Route path='reservation-booking-results'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><FacilityReservationBookSummary /></FacilityUserGuardedRoute>}
                                />
                                <Route path='create-blackout'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><FacilityCreateBlackout /></FacilityUserGuardedRoute>}
                                />
                                <Route path='customer-view-reservations'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><FacilityCustomerViewReservations /></FacilityUserGuardedRoute>}
                                />
                                <Route path='settings'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><FacilitySettings /></FacilityUserGuardedRoute>}
                                />
                                <Route path='web-calendar-settings'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><WebCalendarSettings /></FacilityUserGuardedRoute>}
                                />
                                <Route path='reservation-settings'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><ReservationSettings /></FacilityUserGuardedRoute>}
                                />
                                <Route path='reservation-time-adjustments'
                                    element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><ReservationTimeAdjustments /></FacilityUserGuardedRoute>}
                                />
                                <Route path='birthday-parties/*'
                                    element={<FacilityBirthdayPartyRoutes> </FacilityBirthdayPartyRoutes>}
                                />
                                <Route path='areas/*'
                                    element={<FacilityAreaRoutes> </FacilityAreaRoutes>}
                                />
                            </Routes>
                        </ReservationTimeAdjustmentsProvider>
                    </FacilityOpenBookingTimesProvider>
                </FacilityMembershipsProvider>
            </FacilityHoursOfOperationProvider>
        </FacilityReservationsProvider>
    );
}

export default Facility;