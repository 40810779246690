import { createContext, useEffect, useReducer } from "react";
import { GetCalendarSettings } from '../../utils/facility-api/facility-service';
import { createAction } from "../../utils/reducer/reducer.utils";

const calendarSettingsDefault = {
    mainFacilityColor: '#14254C',
    secondaryFacilityColor: '#8B8681',
    allowOnlineReservations: true,
    allowWebCalendar: true,
    daysOutForReservations: 120,
    membershipsOnly: false,
    showReservationTitlesOnWebCalendar: true,
    allowWebCalendarBookings: false,
    searchTerm: ""
}

const clickToBookCoordinates = {
    top: 0,
    left: 0,
    margin: 0
}

export const CalendarSettingsContext = createContext({
    calendarSettings: Object.assign({}, calendarSettingsDefault),
    setCalendarSettings: () => { },
    facilityId: null,
    setFacilityId: () => { },
    isLoadingSettings: false,
    setIsLoadingSettings: () => { },
    clickToBookCoordinates: Object.assign({}, clickToBookCoordinates),
    setClickToBookCoordinates: () => { },
    resetClickToBookCoordinates: () => { },
    reservationsDate: new Date(),
    setReservationsDate: () => { }
});

export const CALENDAR_SETTINGS_ACTION_TYPES = {
    SET_SETTINGS: 'SET_SETTINGS',
    SET_FACILITY_ID: 'SET_FACILITY_ID',
    SET_LOADING_SETTINGS: 'SET_LOADING_SETTINGS',
    SET_CLICK_TO_BOOK_OPEN_SLOT: "SET_CLICK_TO_BOOK_OPEN_SLOT",
    SET_CLICK_TO_BOOK_COORDINATES: "SET_CLICK_TO_BOOK_COORDINATES",
    SET_RESERVATIONS_DATE: "SET_RESERVATIONS_DATE"
}

const INITIAL_STATE = {
    calendarSettings: Object.assign({}, calendarSettingsDefault),
    facilityId: null,
    isLoadingSettings: false,
    clickToBookCoordinates: Object.assign({}, clickToBookCoordinates),
    reservationsDate: new Date(),
}

const calendarSettingsReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case CALENDAR_SETTINGS_ACTION_TYPES.SET_SETTINGS:
            return {
                ...state, //this keeps everything else and doesn't overwrite
                ...payload
            }
        case CALENDAR_SETTINGS_ACTION_TYPES.SET_FACILITY_ID:
            return {
                ...state,
                facilityId: payload
            }
        case CALENDAR_SETTINGS_ACTION_TYPES.SET_LOADING_SETTINGS:
            return {
                ...state,
                isLoadingSettings: payload
            }
        case CALENDAR_SETTINGS_ACTION_TYPES.SET_CLICK_TO_BOOK_OPEN_SLOT:
            return {
                ...state,
                clickToBookOpenSlot: payload
            }
        case CALENDAR_SETTINGS_ACTION_TYPES.SET_CLICK_TO_BOOK_COORDINATES:
            return {
                ...state,
                clickToBookCoordinates: payload
            }
        case CALENDAR_SETTINGS_ACTION_TYPES.SET_RESERVATIONS_DATE:
            return {
                ...state,
                reservationsDate: payload
            }
        default:
            throw new Error(`Unhandled type ${type}`);
    }

}



export const CalendarSettingsProvider = ({ children }) => {

    const [{ calendarSettings, facilityId, isLoadingSettings, clickToBookCoordinates, reservationsDate }, dispatch] = useReducer(calendarSettingsReducer, INITIAL_STATE);

    const setCalendarSettings = (settings) => {
        dispatch(createAction(CALENDAR_SETTINGS_ACTION_TYPES.SET_SETTINGS, { calendarSettings: settings }));
    }

    const setFacilityId = (id) => {
        dispatch(createAction(CALENDAR_SETTINGS_ACTION_TYPES.SET_FACILITY_ID, id));
    }

    const setIsLoadingSettings = (bool) => {
        dispatch(createAction(CALENDAR_SETTINGS_ACTION_TYPES.SET_LOADING_SETTINGS, bool));
    }

    const setClickToBookCoordinates = (coords) => {
        dispatch(createAction(CALENDAR_SETTINGS_ACTION_TYPES.SET_CLICK_TO_BOOK_COORDINATES, coords));
    }

    const resetClickToBookCoordinates = () => {
        dispatch(createAction(CALENDAR_SETTINGS_ACTION_TYPES.SET_CLICK_TO_BOOK_COORDINATES, Object.assign({}, clickToBookCoordinates)));
    }

    const setReservationsDate = (date) => {
        dispatch(createAction(CALENDAR_SETTINGS_ACTION_TYPES.SET_RESERVATIONS_DATE, date));
    }

    useEffect(() => {

        const fetchCalendarSettings = async () => {
            setIsLoadingSettings(true);
            const calendarSettingsResponse = await GetCalendarSettings(facilityId);
            if (calendarSettingsResponse.status === 200) {
        
                const { calendarSettings } = calendarSettingsResponse.data;
                if (calendarSettings) {
                    setCalendarSettings(calendarSettings);
                }
            }
            setIsLoadingSettings(false);
        }

        fetchCalendarSettings();
    }, [facilityId]);

    const value = { calendarSettings, facilityId, clickToBookCoordinates, isLoadingSettings, reservationsDate, setReservationsDate, resetClickToBookCoordinates, setIsLoadingSettings, setFacilityId, setCalendarSettings, setClickToBookCoordinates }
    return <CalendarSettingsContext.Provider value={value}>{children}</CalendarSettingsContext.Provider>

}