import { Divider, Typography, Box, Stack, Grid } from "@mui/material";
import { BirthdayPartyContext } from "../../../contexts/birthday-party/birthday-party.context";
import { useParams } from 'react-router-dom';
import { useEffect, useContext } from "react";
import { GetBirthdayPartiesForFacility } from "../../../utils/birthday-api/birthday-service";
import BirthdayPartyInfoCard from "./birthday-info-card.component";
import BirthdayPartyGroupInfoCard from "./birthday-group-info-card.component";
import { UserContext } from "../../../contexts/user.context";

const logoImageStyle = {
    height: '4.0vh',
    width: '4.0vw',
    objectFit: 'contain'
};

const FacilityBirthdayPartiesView = () => {

    const { isLoading, setIsLoading, facilityData, setFacilityData } = useContext(BirthdayPartyContext);
    const { facilityName } = useParams();
    const { userData } = useContext(UserContext);

    const { facilityInfo = {}, birthdayParties = [], birthdayPartyGroups } = facilityData;
    const { mainFacilityColor = "#14254C", secondaryFacilityColor = "#A9A9A9"} =  !userData || ( userData && !userData.isFacilityUserAccount ) && facilityInfo ? facilityInfo : {};

    const fetchFacilityInfo = async () => {
        setIsLoading(true);
        const facilityResponse = await GetBirthdayPartiesForFacility(facilityName, userData && userData.isFacilityUserAccount);
        if (facilityResponse && facilityResponse.status == 200 && facilityResponse.data && facilityResponse.data.status === 'success') {
            setFacilityData(facilityResponse.data);
        } else {
            setFacilityData({});
        }
        setIsLoading(false);
    }

    useEffect(() => {

        window.scrollTo(0, 0);
        fetchFacilityInfo();

    }, []);

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'75px'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
            <Stack sx={{
                height: '100%',
                width: '98%',
            }}>
                <Box>
                    <Stack>
                        <Grid alignItems={'center'} justifyContent={'center'} sx={{ width: '98vw', marginLeft: '1.0vw', marginRight: '1.0vw' }} container>
                            <Grid item xs={1}>
                                <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                                    <img style={logoImageStyle} className="facility-logo-image" src={facilityInfo.facilityLogoUrl ? facilityInfo.facilityLogoUrl : "/logo192.png"} alt="Logo" />
                                </Box>
                            </Grid>
                            <Grid item xs={10}>
                                <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                                    <Typography fontSize={{xs: '2.5vh', sm: '2.5vh', md: '3.5vh', lg: '5.0vh', xl: '5.0vh'}} mt={2} textAlign={'center'} gutterBottom color={mainFacilityColor} component="div" variant={facilityInfo.facilityName ? "h2" : "body1"}>
                                        {facilityInfo.facilityName ? facilityInfo.facilityName : !isLoading ? 'We were unable to find the facility you are looking for.' : ''}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                                    <img style={logoImageStyle} className="facility-logo-image" src={facilityInfo.facilityLogoUrl ? facilityInfo.facilityLogoUrl : "/logo192.png"} alt="Logo" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Divider sx={{ marginBottom: '5.0vh' }}></Divider>
                    <Stack sx={{ marginBottom: '2.0vh', width: '100%' }} direction="row" spacing={2} useFlexGap flexWrap="wrap" justifyContent={'center'}>
                        {
                            birthdayParties.map((birthdayParty) => {
                                return <BirthdayPartyInfoCard key={birthdayParty.id} birthdayParty={birthdayParty} facilityName={facilityName}></BirthdayPartyInfoCard>
                            })
                        }
                        {
                            birthdayPartyGroups && Object.keys(birthdayPartyGroups).map((bkey) => {
                                return <BirthdayPartyGroupInfoCard key={bkey} birthdayPartyGroup={birthdayPartyGroups[bkey]} facilityName={facilityName}></BirthdayPartyGroupInfoCard>
                            })
                        }
                    </Stack>
                </Box>
            </Stack>
        </Box >
    )
}

export default FacilityBirthdayPartiesView;