import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { Button, Stack, Typography, Box, FormLabel, IconButton, Popover, Divider } from "@mui/material";
import { FacilityAreaContext } from "../../../contexts/facility/facility-area-provider.context";
import FacilityAreaListAll from "../facility-area-list-all/facility-area-list-all.component";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useState, useContext, Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { findAreaForId } from '../../../utils/reservation-utils/reservation-utils';

const addOrRemoveAreaId = (selectedFacilityAreaIds, areaIdToAdd) => {
    //search for existing cart item , using the find method.
    //if a result is found, then we need to remove from the selected results
    const existing = selectedFacilityAreaIds.find((id) => id === areaIdToAdd);
    if (existing) {
        return selectedFacilityAreaIds.filter(id => id !== areaIdToAdd);
    }

    //this just creates a new arrray from the product to add with an extra quanity property
    return [...selectedFacilityAreaIds, areaIdToAdd]
}

const addOrRemoveAreaGroupId = (areaGroupings, groupIdToAdd) => {
    return areaGroupings.filter(grp => grp.id !== groupIdToAdd);
}



const findById = (list, idToFind) => {
    const existing = list.find(item => item.id === idToFind);
    if (existing) {
        return existing;
    }
    return null;
}

const BirthdayPartyAreaGroupings = ({ formFields, updateFormFields, close, isEditMode }) => {

    const [activeGroupId, setActiveGroupId] = useState(null);
    const { facilityAreas } = useContext(FacilityAreaContext);
    const [facilityAreaPopOverAnchorEl, setFacilityAreaPopOverAnchorEl] = useState(null);
    const [helpAnchorEl, setHelpAnchorEl] = useState(null);

    const { areaGroupings = [] } = formFields;

    const handleHelpOpen = (event) => {
        setHelpAnchorEl(event.currentTarget);
    };

    const handleHelpClose = () => {
        setHelpAnchorEl(null);
    };

    const handleCloseAddAreaClick = () => {
        setFacilityAreaPopOverAnchorEl(null);
    };

    const selectFacilityAreaId = (facilityAreaId) => {

        const areaGroup = findById(formFields['areaGroupings'], activeGroupId);
        const newSelectedAreas = addOrRemoveAreaId(areaGroup.selectedAreaIds, facilityAreaId);

        let filteredList = areaGroupings.filter(grp => grp.id !== activeGroupId);
        let newAreaGroupings = [...filteredList, { ...areaGroup, 'selectedAreaIds': newSelectedAreas }]
        updateFormFields('areaGroupings', newAreaGroupings);
    };

    const deleteSelectedArea = (e) => {
        const ids = e.currentTarget.dataset.id.split(`~`)
        const facilityAreaId = ids[1];
        const groupId = ids[0];
        const areaGroup = findById(formFields['areaGroupings'], groupId);
        const newSelectedAreas = addOrRemoveAreaId(areaGroup.selectedAreaIds, facilityAreaId);

        let filteredList = areaGroupings.filter(grp => grp.id !== groupId);
        let newAreaGroupings = [...filteredList, { ...areaGroup, 'selectedAreaIds': newSelectedAreas }]
        updateFormFields('areaGroupings', newAreaGroupings);
    };

    const deleteAreaGrouping = (event) => {
        event.preventDefault();
        const groupId = event.currentTarget.dataset.id;
        let newAreaGroupings = addOrRemoveAreaGroupId(areaGroupings, groupId);
        newAreaGroupings = newAreaGroupings.sort((a1, a2) => {
            if (a1.sortOrder < a2.sortOrder) {
                return -1;
            }

            if (a1.sortOrder > a2.sortOrder) {
                return 1;
            }

            return 0;
        })

        updateFormFields('areaGroupings', newAreaGroupings);
    }

    const handleAddAreaClick = (event) => {
        event.preventDefault();
        const groupId = event.currentTarget.dataset.id;
        setActiveGroupId(groupId);
        setFacilityAreaPopOverAnchorEl(facilityAreaPopOverAnchorEl ? null : event.currentTarget);
    };

    const handleAddAreaGrouping = () => {
        let newAreaGroupings = [...areaGroupings, {
            id: uuid(),
            selectedAreaIds: [],
            sortOrder: areaGroupings.length + 1
        }];
        newAreaGroupings = newAreaGroupings.sort((a1, a2) => {
            if (a1.sortOrder < a2.sortOrder) {
                return -1;
            }

            if (a1.sortOrder > a2.sortOrder) {
                return 1;
            }

            return 0;
        })

        updateFormFields('areaGroupings', newAreaGroupings);
    }

    const open = Boolean(facilityAreaPopOverAnchorEl);
    const helpOpen = Boolean(helpAnchorEl);
    return (
        <Stack>
            <Box sx={{ mb: 1 }} display="flex" justifyContent="left" alignItems="center" >
                <FormLabel sx={{ mr: 1, fontWeight: 'bold', color: "#14254C" }}>Area Groupings *</FormLabel>
                {
                    isEditMode &&
                    <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddAreaGrouping}></Button>
                }
                <HelpOutlineOutlinedIcon onMouseEnter={handleHelpOpen} onMouseLeave={handleHelpClose} sx={{ color: 'lightgray' }} ></HelpOutlineOutlinedIcon>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={helpOpen}
                    anchorEl={helpAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleHelpClose}
                    disableRestoreFocus
                >
                    <Box>
                        <Typography sx={{ marginLeft: '2.0vw', marginRight: '2.0vw' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" ml={1}>
                            Area Groupings
                        </Typography>
                        <Divider></Divider>
                        <Typography sx={{ marginLeft: '2.0vw', marginRight: '2.0vw' }} textAlign={'center'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div" ml={1}>
                            These are the area(s) that will be booked for a birthday party, 1 area from each area group will be booked.
                        </Typography>
                    </Box>

                </Popover>
            </Box>
            <Divider></Divider>
            <Box sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }}>
                {
                    !areaGroupings || areaGroupings.length <= 0 &&
                    <FormLabel sx={{ mt: 1, ml: 1, mr: 1, color: "#14254C" }}>No area groups selected.  You must select at least 1 area group.  Click + to add group.</FormLabel>
                }
            </Box>
            {
                areaGroupings && areaGroupings.length > 0 &&
                areaGroupings.sort((grp1, grp2) => {
                    if (grp1.sortOrder < grp2.sortOrder) {
                        return -1;
                    }

                    if (grp1.sortOrder > grp2.sortOrder) {
                        return 1;
                    }

                    return 0;
                }).map((group) => {
                    const { selectedAreaIds, sortOrder, id } = group;
                    return <Box key={id} sx={{ marginLeft: '1.0vw', marginBottom: '1.0vh' }}>
                        <Box sx={{ mb: 1 }} display="flex" justifyContent="left" alignItems="center" >
                            <FormLabel sx={{ mr: 1, fontWeight: 'bold', color: "#14254C" }}>Area Group {sortOrder}</FormLabel>
                            {
                                isEditMode &&
                                <Stack spacing={2} direction={'row'}>
                                    <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddAreaClick.bind(this)} data-id={id}></Button>
                                    <IconButton edge="end" aria-label="delete" onClick={deleteAreaGrouping.bind(this)} data-id={id}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>

                            }
                            <FacilityAreaListAll anchorEl={facilityAreaPopOverAnchorEl} selectedAreaIds={selectedAreaIds} selectFacilityAreaId={selectFacilityAreaId} closeList={handleCloseAddAreaClick} ></FacilityAreaListAll>
                        </Box>
                        {
                            !selectedAreaIds || selectedAreaIds.length <= 0 &&
                            <FormLabel sx={{ ml: 1, mr: 1, color: "#14254C" }}>No areas selected.  You must select at least 1 area per group.  Click + to add area.</FormLabel>
                        }

                        {
                            selectedAreaIds && selectedAreaIds.length > 0 &&
                            <List sx={{ borderRadius: '5px', borderWidth: '1px', borderColor: '#A9A9A9', borderStyle: 'solid' }} key={'birthday-party-setup-facility-area-list-key'}>
                                {
                                    selectedAreaIds.map((areaId) => {
                                        const area = findAreaForId(facilityAreas, areaId);
                                        if (area) {
                                            return <Fragment key={areaId}>
                                                {
                                                    <ListItem key={`${areaId}-list-del-item`}
                                                        secondaryAction={
                                                            isEditMode &&
                                                            <IconButton edge="end" aria-label="delete" onClick={deleteSelectedArea.bind(this)} data-id={`${id}~${areaId}`}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }>

                                                        <ListItemText key={`sel-${areaId}-list-item`} primary={area.name} primaryTypographyProps={{
                                                            fontWeight: 'bold',
                                                            letterSpacing: 0,
                                                            color: '#14254C'
                                                        }}></ListItemText>
                                                    </ListItem>
                                                }
                                            </Fragment>
                                        }
                                    })
                                }
                            </List>
                        }
                    </Box>
                })
            }
        </Stack>
    )
}

export default BirthdayPartyAreaGroupings;