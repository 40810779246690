import { FacilityViewCustomerFormContainer } from './facility-view-customer.styles';
import userprofileImg from "../../../assets/user-profile.svg";
import { useState, useContext, useEffect, Fragment } from 'react';
import { UserContext } from '../../../contexts/user.context';
import AdditionalEmailRow from './additionalEmailRow.componet';
import FacilityViewCustomerReservations from '../facility-view-customer-reservations/facility-view-customer-reservations.component';
import { Button, Divider, Stack, TextField, Typography, FormControlLabel, Checkbox, IconButton, Modal, Box } from '@mui/material';
import FacilityInvoiceCustomersList from '../facility-invoice-customers-list/facility-invoice-customers-list.component';
import { updateCustomerInvoiceStatus, sendCustomerInvoiceInvoiceEmail } from '../../../utils/facility-api/facility-service';
import MySportSpaceAlert from '../../alert/alert.component';
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context';
import FacilityViewReservation from '../facility-view-reservation/facility-view-reservation.component';
import { saveFacilityCustomer } from '../../../utils/firebase/facility-firebase-utils';
import validator from 'validator';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { CustomDisableInput } from '../../../utils/text-field-utils/text-field-utils';
import { PhoneFormat } from '../../../utils/text-field-utils/text-field-utils';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const style = {
    position: 'absolute',
    top: '10%',
    left: '1%',
    bottom: '1%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};

const removeAdditionalEmail = (additionalEmails, additionalEmail) => {
    const existingEmail = additionalEmails.find((email) => email === additionalEmail);
    if (existingEmail) {
        return additionalEmails.filter(email => email !== additionalEmail);
    }

    return [...additionalEmails];
}

const FacilityViewCustomer = ({ facilityCustomerIn, close, refreshCustomerList }) => {

    const [isEditMode, setIsEditMode] = useState(false);
    const { isAdmin, userData } = useContext(UserContext);
    const [isSaving, setIsSaving] = useState(false);
    const [facilityCustomer, setFacilityCustomer] = useState(null);
    const [originalFacilityCustomer, setOriginalFacilityCustomer] = useState(null);
    const [contactInfo, setContactInfo] = useState([]);
    const [upcomingDate, setUpcomingDate] = useState(moment().add(30, 'day').format("YYYY-MM-DD"));
    const [showInvoiceCustomers, setShowInvoiceCustomers] = useState(false);
    const [invoiceTo, setInvoiceTo] = useState(null);
    const { invoiceBillingCustomerName, isWeekly } = { ...invoiceTo };
    const [alertMessage, setAlertMessage] = useState(null);
    const [profileSavedMessage, setProfileSavedMessage] = useState(null);
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);

    const { isLoadingReservations, reservationToView } = useContext(FacilityReservationsContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (facilityCustomerIn) {
            setOriginalFacilityCustomer(Object.assign({}, facilityCustomerIn));
            setFacilityCustomer(facilityCustomerIn);
            setContactInfo(facilityCustomerIn.contactInfo);
            setInvoiceTo(facilityCustomerIn.invoiceTo)
        } else {
            setOriginalFacilityCustomer(null);
            setFacilityCustomer(null);
            setContactInfo(null);
            setInvoiceTo(null)
        }
    }, [facilityCustomerIn]);

    const closeView = () => {
        close();
    }

    const cancelEdit = () => {
        setIsEditMode(false);
        setShowCancelDialog(false);
        setFacilityCustomer(Object.assign({}, originalFacilityCustomer))
        setContactInfo(originalFacilityCustomer.contactInfo);
    }

    const handleSaveDialogClose = () => {
        setShowSaveDialog(false);
    };

    const handleCancelDialogClose = () => {
        setShowCancelDialog(false)
    }

    const handleFacilityUserChange = (event) => {
        const { name, value } = event.target;
        setFacilityCustomer({ ...facilityCustomer, [name]: value });
    }

    const handleContactInfoChange = (event) => {
        const { name, value } = event.target;
        setContactInfo({ ...contactInfo, [name]: value });
    }

    const editAdditionalEmail = (email, index) => {
        additionalEmails[index] = email
        setContactInfo({ ...contactInfo, 'additionalEmails': additionalEmails });
    }

    const addAdditionalEmail = (event) => {

        if (additionalEmails) {
            const emptyEmail = additionalEmails.filter(email => email === " ");
            if (emptyEmail.length <= 0) {
                setContactInfo({ ...contactInfo, 'additionalEmails': [...additionalEmails, " "] });
            }
        } else {
            setContactInfo({ ...contactInfo, 'additionalEmails': [" "] });
        }
    }

    const deleteAdditionalEmail = (email) => {
        setContactInfo({ ...contactInfo, 'additionalEmails': removeAdditionalEmail(additionalEmails, email) });
    }

    const toggleEditMode = () => {

        if (isEditMode) {
            setShowCancelDialog(true);
        } else {
            setIsEditMode(!isEditMode)
        }

    }

    const handleShowSaveDialog = () => {
        setShowSaveDialog(true);
    }

    const saveUserDetails = async () => {

        setShowSaveDialog(false);
        if (contactInfo) {
            if (contactInfo.additionalEmails) {
                for (const additionalEmail of contactInfo.additionalEmails) {
                    if (!validator.isEmail(additionalEmail)) {
                        setAlertMessage(`${additionalEmail} is not a valid email address`);
                        return
                    }
                }
            }
            facilityCustomer.contactInfo = contactInfo;
        }

        if (!validator.isEmail(userEmailAddress)) {
            setAlertMessage(`Invlaid User Email Address`);
            return
        }

        if (mailingZipCode && !validator.isPostalCode(`${mailingZipCode}`, "any")) {
            setAlertMessage(`Invlaid Mailing Zip Code`);
            return
        }

        if (billingZipCode && !validator.isPostalCode(`${billingZipCode}`, "any")) {
            setAlertMessage(`Invlaid Billing Zip Code`);
            return
        }

        setIsSaving(true);

        const response = await saveFacilityCustomer(userData.facilityId, facilityCustomer);
        if (!response.succeeded) {
            setOriginalFacilityCustomer(Object.assign({}, facilityCustomer));
            setAlertMessage(response.message);
        } else {
            if (refreshCustomerList) {
                refreshCustomerList();
            }
            const saveInvoiceResponse = await updateCustomerInvoiceStatus(userData.facilityId, facilityCustomer.userId, allowedToInvoice, invoiceTo && invoiceTo.billingCustomerId ? invoiceTo.billingCustomerId : facilityCustomer.userId, invoiceTo ? invoiceTo.isWeekly : false);
            if (saveInvoiceResponse.status === 200 && saveInvoiceResponse.data && saveInvoiceResponse.data.status === 'success') {
                setIsEditMode(false);

                if (allowedToInvoice) {
                    sendCustomerInvoiceInvoiceEmail(userEmailAddress, userData.facilityId, userFirstName, userLastName);
                }
                setProfileSavedMessage("Saved.  The customer's profile has been updated.");
            } else {
                setAlertMessage('An error occurred.  The customer profile was not saved.');
            }
        }

        setIsSaving(false);
    }

    const showMemberships = () => {

    }

    const showReservations = () => {
        navigate("/facility/customer-view-reservations", { state: { facilityCustomer } });
    }

    const showEvents = () => {

    }
    const selectWeeklyInvoice = () => {
        if (isEditMode) {
            setInvoiceTo({ ...invoiceTo, 'isWeekly': !isWeekly });
        }
    }

    const selectMonthlyInvoice = () => {
        if (isEditMode) {
            setInvoiceTo({ ...invoiceTo, 'isWeekly': false });
        }
    }

    const selectAllowToInvoice = () => {
        if (isEditMode) {
            setFacilityCustomer({ ...facilityCustomer, ["allowedToInvoice"]: !allowedToInvoice });
        }
    }

    const selectBillToCustomer = (billToCustomer) => {
        setInvoiceTo({ ...invoiceTo, 'billingCustomerId': billToCustomer.userId, 'invoiceBillingCustomerName': `${billToCustomer.userFirstName} ${billToCustomer.userLastName}`, 'invoiceBillingEmail': billToCustomer.userEmailAddress, 'invoiceBillingPhone': billToCustomer.userPhoneNumber, 'invoiceBillingStripeKey': billToCustomer.stripeCustomerId });
        closeInvoiceCustomersList();
    }

    const showInvoiceCustomerList = () => {
        if (isEditMode) {
            setShowInvoiceCustomers(true);
        }
    }

    const closeInvoiceCustomersList = () => {
        setShowInvoiceCustomers(false);
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const closeProfileSavedAlert = () => {
        setProfileSavedMessage(null);
    }

    const currentDate = moment().format("YYYY-MM-DD");
    let emailIndex = -1;

    const { mailingAddress1, mailingAddress2, mailingState, mailingCity, mailingZipCode, billingAddress1, billingAddress2, billingState, billingCity, billingZipCode, additionalEmails = [] } = contactInfo ? contactInfo : {};
    const { userId, userFirstName, userLastName, userPhoneNumber, userEmailAddress, allowedToInvoice } = facilityCustomer ? facilityCustomer : {};

    return (
        <Modal open={facilityCustomerIn ? true : false}>
            <Box sx={{ ...style, width: '98%' }}>
                <MySportSpaceLoadingView isOpen={isSaving || isLoadingReservations}></MySportSpaceLoadingView>
                <MySportSpaceAlert isOpen={profileSavedMessage ? true : false} message={profileSavedMessage} okButtonText={'OK'} okAction={closeProfileSavedAlert} ></MySportSpaceAlert>
                <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
                {
                    !isSaving && !isLoadingReservations && reservationToView &&
                    <FacilityViewReservation reservationToView={reservationToView}></FacilityViewReservation>
                }
                {
                    !isSaving && !isLoadingReservations && !reservationToView && facilityCustomer &&

                    <FacilityViewCustomerFormContainer>
                        <div className='user-image'>
                            <Stack spacing={3} mr={1}>
                                <Stack mt={1} mb={1} direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <IconButton onClick={closeView}>
                                        <CloseOutlinedIcon />
                                    </IconButton>
                                </Stack>
                                <img src={userprofileImg} alt='user-profile' className='user-profile-img'></img>
                            </Stack>

                        </div>
                        <div className='user-profile'>
                            <div className='user-details'>
                                {
                                    isAdmin &&
                                    <Stack mt={1} mb={1} direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-end'}>
                                        <Button variant="contained" onClick={toggleEditMode} startIcon={!isEditMode ? <EditOutlinedIcon /> : <EditOffOutlinedIcon />}>{!isEditMode ? `Edit` : 'Cancel'}
                                        </Button>
                                        {
                                            isEditMode &&
                                            <Button variant="contained" onClick={handleShowSaveDialog} startIcon={<SaveOutlinedIcon />}>{`Save`}
                                            </Button>
                                        }
                                    </Stack>
                                }
                                <Stack mt={2} mb={1} direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="firstName" variant="outlined" label="First Name" type="text" name="userFirstName" value={userFirstName} onChange={handleFacilityUserChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="lastName" variant="outlined" label="Last Name" type="text" name="userLastName" value={userLastName} onChange={handleFacilityUserChange} ></CustomDisableInput>
                                </Stack>
                                <CustomDisableInput size="small" fullWidth required sx={{ mt: '1.0vh', mb: '1.0vh' }} id="phoneNumber" variant="outlined" label="Phone Number" type="text" name="userPhoneNumber" value={userPhoneNumber} onChange={handleFacilityUserChange}
                                    InputProps={{
                                        inputComponent: PhoneFormat,
                                        readOnly: !isEditMode
                                    }}></CustomDisableInput>

                                <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="emailAddress" variant="outlined" label="Email Address" type="text" name="userEmailAddress" value={userEmailAddress} onChange={handleFacilityUserChange} ></CustomDisableInput>

                                <Typography ml={1} fontWeight={'bold'} mt={2} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                                    Mailing Address
                                </Typography>
                                <Divider></Divider>
                                <Stack mt={2} mb={1} direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="mailingAddress1" variant="outlined" label="Address 1" type="text" name="mailingAddress1" value={mailingAddress1} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="mailingAddress2" variant="outlined" label="Address 2" type="text" name="mailingAddress2" value={mailingAddress2} onChange={handleContactInfoChange} ></CustomDisableInput>
                                </Stack>
                                <Stack mt={1} mb={1} direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth id="mailingCity" variant="outlined" label="City" type="text" name="mailingCity" value={mailingCity} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="mailingState" variant="outlined" label="State" type="text" name="mailingState" value={mailingState} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="mailingZipCode" variant="outlined" label="Zip Code" type="text" name="mailingZipCode" value={mailingZipCode} onChange={handleContactInfoChange} ></CustomDisableInput>
                                </Stack>
                                <Typography ml={1} fontWeight={'bold'} mt={2} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                                    Billing Address
                                </Typography>
                                <Divider></Divider>
                                <Stack mt={2} mb={1} direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="billingAddress1" variant="outlined" label="Address 1" type="text" name="billingAddress1" value={billingAddress1} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="billingAddress2" variant="outlined" label="Address 2" type="text" name="billingAddress2" value={billingAddress2} onChange={handleContactInfoChange} ></CustomDisableInput>
                                </Stack>
                                <Stack mt={1} mb={1} direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="billingCity" variant="outlined" label="City" type="text" name="billingCity" value={billingCity} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="billingState" variant="outlined" label="State" type="text" name="billingState" value={billingState} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="billingZipCode" variant="outlined" label="Zip Code" type="text" name="billingZipCode" value={billingZipCode} onChange={handleContactInfoChange} ></CustomDisableInput>
                                </Stack>

                                <Typography ml={1} fontWeight={'bold'} mt={2} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                                    Invoicing
                                </Typography>
                                <Divider></Divider>
                                <Stack spacing={1} mt={1} ml={1} direction={'row'} display={'flex'} alignItems={'center'} alignContent={'center'}>
                                    <FormControlLabel control={<Checkbox disabled={!isEditMode} checked={allowedToInvoice} onChange={selectAllowToInvoice} />} label="Allow Invoicing" />
                                    {allowedToInvoice &&
                                        <Fragment>
                                            <FormControlLabel control={<Checkbox disabled={!isEditMode} checked={isWeekly} onChange={selectWeeklyInvoice} />} label="Weekly" />
                                            <FormControlLabel control={<Checkbox disabled={!isEditMode} checked={!isWeekly} onChange={selectMonthlyInvoice} />} label="Monthly" />
                                            <Typography textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                                Bill To:
                                            </Typography>
                                            <Button variant='outlined' disabled={!isEditMode} onClick={showInvoiceCustomerList} >{invoiceBillingCustomerName ? invoiceBillingCustomerName : "This Account"}</Button>
                                            <FacilityInvoiceCustomersList isOpen={showInvoiceCustomers} closeList={closeInvoiceCustomersList} selectCustomer={selectBillToCustomer}></FacilityInvoiceCustomersList>
                                        </Fragment>
                                    }
                                </Stack>
                                <Stack mb={1} spacing={1} display={'flex'}>
                                    <Stack mb={1} spacing={1} display={'flex'} direction={'row'}>
                                        <Typography ml={1} fontWeight={'bold'} mt={1} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                                            Additional Emails
                                        </Typography>
                                        {isEditMode &&
                                            <IconButton size='small' variant="contained" onClick={addAdditionalEmail}>
                                                <AddCircleOutlineOutlinedIcon />
                                            </IconButton>
                                        }
                                    </Stack>

                                    <Divider></Divider>

                                    <Stack mb={1} spacing={1} display={'flex'}>
                                        {
                                            additionalEmails && additionalEmails.map(email => (
                                                <AdditionalEmailRow key={email} isEditMode={isEditMode} email={email} index={emailIndex += 1} editAdditionalEmail={editAdditionalEmail} deleteAdditionalEmail={deleteAdditionalEmail}></AdditionalEmailRow>
                                            ))
                                        }
                                    </Stack>
                                </Stack>
                            </div>
                            <Stack mt={1} spacing={2} sx={{ height: '90%', borderRadius: '5px' }}>
                                <Box display={'flex'} justifyContent={'flex-end'}>
                                    <Button sx={{ width: '50%' }} variant="contained" onClick={showReservations}>{`View Reservations`}</Button>
                                </Box>
                                <Stack sx={{ maxHeight: '60%', minHeight: '15%', overflow: 'scroll', boxShadow: '0 3px 10px rgb(0 0 0 / 0.5)' }}>
                                    <Typography ml={1} fontWeight={'bold'} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                                        Upcoming Reservations
                                    </Typography>
                                    <Divider sx={{ marginLeft: '1.0vw', marginRight: '1.0vw' }}></Divider>
                                    {userId &&
                                        <FacilityViewCustomerReservations key={'upcoming-reservations'} customerId={userId} startDate={currentDate} endDate={upcomingDate} failedOnly={false}></FacilityViewCustomerReservations>
                                    }
                                </Stack>
                                <Stack mt={2} sx={{ minHeight: '10%', overflow: 'scroll', boxShadow: '0 3px 10px rgb(0 0 0 / 0.5)' }}>
                                    <Typography ml={1} fontWeight={'bold'} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                                        Failed Payments
                                    </Typography>
                                    <Divider sx={{ marginLeft: '1.0vw', marginRight: '1.0vw' }}></Divider>
                                    {userId &&
                                        <FacilityViewCustomerReservations key={'failed-payments'} customerId={userId} startDate={null} endDate={null} failedOnly={true}></FacilityViewCustomerReservations>
                                    }
                                </Stack>
                            </Stack>
                        </div>
                    </FacilityViewCustomerFormContainer>
                }
                <Dialog
                    open={showSaveDialog}
                    onClose={handleSaveDialogClose}
                >
                    <DialogTitle>Save Customer Profile?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will update a customer's My Sport Space profile.  The customer's email log on will be affected (if changed) and all customer data will be updated in their My Sport Space profile.
                        </DialogContentText>

                        <DialogContentText mt={1}>
                            By updating a customer's My Sport Space profile you are confirming that you have the customer's permission.
                        </DialogContentText>

                        <DialogContentText mt={1}>
                            If you are updating the customer's email address, the customer will receive an email informing them that their profile has changed.
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSaveDialogClose}>Cancel</Button>
                        <Button onClick={saveUserDetails} variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showCancelDialog}
                    onClose={handleCancelDialogClose}
                >
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Any unsaved changes will be lost.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDialogClose}>No</Button>
                        <Button onClick={cancelEdit} variant="contained">Yes</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Modal>
    )

}

export default FacilityViewCustomer