import { CalendarReservationContainer, BlackoutTitleSpanContainer } from './calendar-reservation.styles'
import { ResDetailDivContainer, ResTitleSpanContainer, ResTitleAndEmojiContainer, EmojiSpanContainer } from './calendar-reservation.styles';
import { Fragment, useState, useContext } from "react";
import { UserContext } from '../../../../contexts/user.context';
import { FacilityReservationsContext } from '../../../../contexts/facility/facility-reservations.context';
import { DisplayContext } from '../../../../contexts/dispaly-options.context';
import { Box, Divider, Popover, Typography } from '@mui/material';

const getFormOfPaymentText = (reservation) => {

    if (reservation.isInternalBooking) {
        return "Internal Booking"
    }

    if (reservation.facilityMembershipId) {
        return "Membership";
    }

    if (reservation.isPayingByInvoice) {
        return "Invoice";
    }

    if (reservation.isPayingWithCredit) {
       return "Credit Card";
    }

    return "Cash/Check";
}

function wc_hex_is_light(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
}

const sportToEmoji = (reservation) => {
    const upperCase = reservation.reservedForSport ? reservation.reservedForSport.toUpperCase() : "";
    let emoji = null;
    if (upperCase.includes("BASKETBALL")) {
        emoji = "🏀";
    }

    if (upperCase.includes("BASEBALL")) {
        emoji = "⚾️";
    }

    if (upperCase.includes("LACROSSE")) {
        emoji = "🥍";
    }

    if (upperCase.includes("SOCCER")) {
        emoji = "⚽️";
    }

    if (upperCase.includes("SOFTBALL")) {
        emoji = "🥎";
    }

    if (upperCase.includes("TRAINING")) {
        emoji = "⏱️";
    }

    if (upperCase.includes("VOLLEYBALL")) {
        emoji = "🏐";
    }

    if (upperCase.includes("BIRTHDAY")) {
        emoji = "🎂";
    }

    if (emoji) {
        return `${emoji}${reservation.notes ? "💬" : ""}`;
    } else {
        return reservation.notes ? "💬" : null;
    }

}

const CalendarReservation = ({ facilityArea, reservation, hoursOfOperation }) => {

    const { setReservationToView } = useContext(FacilityReservationsContext);
    const { calendarDimensions } = useContext(DisplayContext);
    const { userData } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (!hoursOfOperation) {
        return null;
    }

    const { subAreas } = facilityArea;

    const handleClick = (e) => {
        e.preventDefault();
        setReservationToView(reservation);
    }

    const { title, subTitle, mainAreaReservedType, resStartTime, resEndTime, paidOrUnpaid } = reservation;
    let numberOfColumns = 1;
    let spanColumns = '1 / 1';
    if (subAreas) {

        let subAreaArr = [];
        for (const subAreaId of Object.keys(subAreas)) {
            const subArea = subAreas[subAreaId];
            subArea.id = subAreaId;
            subAreaArr.push(subArea);
        }

        subAreaArr.sort((area1, area2) => {
            if (area1.sortOrder < area2.sortOrder) {
                return -1;
            }

            if (area1.sortOrder > area2.sortOrder) {
                return 1;
            }

            return 0;
        });

        if (mainAreaReservedType !== 'sub') {
            let columnStart = -1;
            let areasFound = 0;
            let subCount = 1;
            for (const subArea of subAreaArr) {
                for (const reservationAreaId of reservation.areaIds) {
                    if (subArea.id === reservationAreaId) {
                        areasFound += 1;
                        if (columnStart === -1) {
                            columnStart = subCount;
                        }
                    }
                }
                subCount += 1;
            }
            spanColumns = `${columnStart} / ${areasFound + 1}`;
            numberOfColumns = areasFound;
        } else {
            let subAreaCount = 1;
            let columnNumber = 1;
            for (const subArea of subAreaArr) {
                if (reservation.areaIds.includes(subArea.id)) {
                    columnNumber = subAreaCount;
                }
                subAreaCount += 1;
            }
            if (columnNumber > 0) {
                spanColumns = `${columnNumber} / ${columnNumber + 1}`;
            } else {
                spanColumns = `1 / 1`;
            }

        }
    }

    let titleColumnSpan = spanColumns;
    if (mainAreaReservedType === 'sub') {
        titleColumnSpan = '1';
    }

    const rowStartTimesToRowStart = {};
    if (hoursOfOperation) {

        let startTime = hoursOfOperation.startDateTime.clone();
        let endTime = hoursOfOperation.endDateTime.clone();

        let rowStart = 0;
        while (startTime.isBefore(endTime)) {
            rowStartTimesToRowStart[startTime.format('h A')] = rowStart;
            startTime.add(60, 'minutes');
            rowStart += 4;
        }
    }

    const resStartMinutes = parseInt(reservation.startDate.format("m"));
    let rowsAdd = 1;
    if (resStartMinutes === 15) {
        rowsAdd += 1
    } else if (resStartMinutes === 30) {
        rowsAdd += 2
    } else if (resStartMinutes === 45) {
        rowsAdd += 3
    }

    const startResInRow = (rowStartTimesToRowStart[reservation.startDate.format('h A')]) + rowsAdd;
    const resLengthInMinutes = reservation.endDate.diff(reservation.startDate, 'minutes');
    const fifteenMinutesIntervals = resLengthInMinutes / 15;
    const endResInRow = startResInRow + fifteenMinutesIntervals;

    let paidStatusColor = 'orange';
    if (reservation.paymentFailed) {
        paidStatusColor = 'red';
    } else {
        if (reservation.paidOrUnpaid && reservation.paidOrUnpaid.toUpperCase() === 'PAID') {
            paidStatusColor = 'green';
        } else if (reservation.isInternalBooking) {
            paidStatusColor = '#14254C';
        }
    }

    let resTitleBackgroudColor = 'RGBA(20,37,76,0.25)';
    let resTitleColor = 'black';
    if (reservation.bookedById) {
        if (userData.subUsers) {
            if (userData.subUsers[reservation.bookedById] && userData.subUsers[reservation.bookedById].userColor) {
                const backgroundColor = userData.subUsers[reservation.bookedById].userColor;
                if (!wc_hex_is_light(backgroundColor)) {
                    resTitleColor = 'white';
                }
                resTitleBackgroudColor = `${userData.subUsers[reservation.bookedById].userColor}`;
            }
        }
    }

    return (
        <CalendarReservationContainer $numberofcolumns={numberOfColumns} $gridrowstart={`${startResInRow}`} $gridrowend={`${endResInRow}`} $gridcolumn={spanColumns}>
            <ResDetailDivContainer $paidsdtatuscolor={reservation.isBlackOutPeriod ? "black" : paidStatusColor} $spancolumn={titleColumnSpan} $spanrow={'1'} >
                {
                    !reservation.isBlackOutPeriod &&
                    <ResTitleAndEmojiContainer $backgroundcolor={resTitleBackgroudColor}>
                        <ResTitleSpanContainer onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose} $fontSize={calendarDimensions.facility_calendar_height === '100%' ? '1.5vh' : '2.0vh'} $titlecolor={resTitleColor} $backgroundcolor={resTitleBackgroudColor} onClick={handleClick.bind(this)}>
                            {`${title}`}
                        </ResTitleSpanContainer>
                        {
                            sportToEmoji(reservation) &&
                            <EmojiSpanContainer className='emoji' role="img" aria-label="dog" $backgroundcolor={resTitleBackgroudColor}>{sportToEmoji(reservation)}</EmojiSpanContainer>
                        }
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <Box>
                                <Typography ml={1} mr={1} textAlign={'center'} color={"#14254C"} variant='h6' sx={{ p: 1 }}>{`${title}`}</Typography>
                                <Divider ml={2} mr={2} ></Divider>
                                <Typography ml={1} mr={1} textAlign={'left'} color={"#14254C"}  sx={{ p: 1 }}>{`${resStartTime} - ${resEndTime}`}</Typography>
                                <Typography ml={1} mr={1} textAlign={'left'} color={"#14254C"}  sx={{ p: 1 }}>{`${getFormOfPaymentText(reservation)} - ${paidOrUnpaid.toUpperCase()}`}</Typography>
                            </Box>

                        </Popover>

                    </ResTitleAndEmojiContainer>
                }
                {
                    reservation.isBlackOutPeriod &&
                    <Fragment>
                        <BlackoutTitleSpanContainer onClick={handleClick.bind(this)} $fontSize={calendarDimensions.facility_calendar_height === '100%' ? '1.5vh' : '2.0vh'}>
                            {!subTitle ? `${title}` : `${title} - ${subTitle}`}
                            {/* <CalendarPreviewBlackout reservation={reservation}></CalendarPreviewBlackout> */}
                        </BlackoutTitleSpanContainer>
                    </Fragment>
                }
            </ResDetailDivContainer>
        </CalendarReservationContainer>
    )

}

export default CalendarReservation;