import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';
import { useContext } from 'react';


const CustomerUserGuardedRoute = ({children }) => {

    const location = useLocation();
    const redirectUrl = location.pathname;

    const { userData, userIsLoading } = useContext(UserContext);
    if (!userData && !userIsLoading) {
        return <Navigate to={`/auth?redirectUrl=${redirectUrl}`} replace={true} />;
    }

    if (userData && userData.isFacilityUserAccount) {
        return <Navigate to="/" replace />;
    }
    
    return children;
}

export default CustomerUserGuardedRoute;