import { Routes, Route } from 'react-router-dom';
import { UserContext } from '../../../contexts/user.context';
import FacilityUserGuardedRoute from '../../guarded-routes/facility-user-guarded-route.component';
import { useContext } from 'react';
import AreaManageList from '../../../components/facility/areas/area-manage-list.component';

const FacilityAreaRoutes = () => {

    const { userData, userIsLoading } = useContext(UserContext);

    return (
        <Routes>
            <Route path='/'
                element={<FacilityUserGuardedRoute userIsLoading={userIsLoading} userData={userData}><AreaManageList /></FacilityUserGuardedRoute>}
            />
        </Routes>
    )

}

export default FacilityAreaRoutes;