import { initializeApp } from 'firebase/app';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, signInWithRedirect, signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider, createUserWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail, browserSessionPersistence, setPersistence } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'
import { getDatabase, ref, get, child, set, remove, onDisconnect } from "firebase/database";
import { v4 as uuid } from 'uuid';


const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};

// Initialize Firebase
initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account"
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);
export const signInWithGoogleRedirect = () => signInWithRedirect(auth, googleProvider);


export const db = getFirestore();

export const getUserData = async (userAuth) => {
  const dbRef = ref(getDatabase());
  const facilityUserSnapshot = await get(child(dbRef, `facility-user-accounts/${userAuth.uid}/userId`));
  const userData = {editId: uuid()};
  if (facilityUserSnapshot.exists()) {
    userData.facilityId = userAuth.uid;
    userData.isFacilityUserAccount = true;
    userData.userPermission = 'Admin';
    userData.userId = facilityUserSnapshot.val()
    

    await getBasicUserInfo(dbRef, userAuth, 'facility-user-accounts', userData);
    await getFacilityInfo(dbRef, userAuth.uid, userData);

  } else {
    const facilitySubUserSnapshot = await get(child(dbRef, `facility-user-sub-accounts/${userAuth.uid}`));
    if (facilitySubUserSnapshot.exists()) {
      const userDbData = facilitySubUserSnapshot.val();
      userData.userFirstName = userDbData.firstName;
      userData.userLastName = userDbData.lastName;
      userData.userEmailAddress = userDbData.emailAddress;
      userData.facilityId = userDbData.parentId;
      userData.isFacilityUserAccount = true;

      await getFacilityInfo(dbRef, userDbData.parentId, userData);

      const userPermissionsSnapshot = await get(child(dbRef, `facility-user-accounts/${userDbData.parentId}/user-sub-accounts/${userAuth.uid}`));
      if (userPermissionsSnapshot.exists()) {
        const facilityUserData = userPermissionsSnapshot.val();
        userData.userPermission = facilityUserData.userPermission;
      } else {
        userData.userPermission = "Read_Only"
      }

    } else {
      await getBasicUserInfo(dbRef, userAuth, 'customer-user-accounts', userData);
    }

  }
  return userData;
};

const getFacilityInfo = async (dbRef, facilityId, userData) => {
  const facilityAccountInfoSnapshot = await get(child(dbRef, `facility-user-accounts/${facilityId}/facilityAccountInfo`));
  if (facilityAccountInfoSnapshot.exists()) {
    userData.facilityAccountInfo = facilityAccountInfoSnapshot.val();
  }

  const facilityOrganizationNameSnapShot = await get(child(dbRef, `facility-user-accounts/${facilityId}/organizationName`));
  if (facilityOrganizationNameSnapShot.exists()) {
    userData.organizationName = facilityOrganizationNameSnapShot.val();
  }

  const facilityEmailSnapShot = await get(child(dbRef, `facility-user-accounts/${facilityId}/userEmailAddress`));
  if (facilityEmailSnapShot.exists()) {
    userData.userEmailAddress = facilityEmailSnapShot.val();
  }

  const facilityPhoneNumberSnapShot = await get(child(dbRef, `facility-user-accounts/${facilityId}/userPhoneNumber`));
  if (facilityPhoneNumberSnapShot.exists()) {
    userData.userPhoneNumber = facilityPhoneNumberSnapShot.val();
  }

  const facilityStripeIdSnapShot = await get(child(dbRef, `facility-user-accounts/${facilityId}/stripeCustomerId`));
  if (facilityStripeIdSnapShot.exists()) {
    userData.stripeCustomerId = facilityStripeIdSnapShot.val();
  }
  const subUsersSnapShot = await get(child(dbRef, `facility-user-accounts/${facilityId}/user-sub-accounts`));
  if (subUsersSnapShot.exists()) {
    userData.subUsers = subUsersSnapShot.val();
  }

  const internalBookingCustomerId = await get(child(dbRef, `facility-user-accounts/${facilityId}/internalBookingId`));
  if (internalBookingCustomerId.exists()) {
    userData.internalBookingId = internalBookingCustomerId.val();
  } else {
    const internalBookingId = uuid();
    set(child(dbRef, `facility-user-accounts/${facilityId}/internalBookingId`), internalBookingId);
    userData.internalBookingId = internalBookingId;

    const internalBookingCustomerRef = ref(getDatabase(), `customer-user-accounts/${internalBookingId}`);
    set(internalBookingCustomerRef, {userId: internalBookingId, userFirstName: "Internal", userLastName: "Booking", userEmailAddress: userData.userEmailAddress, userPhoneNumber: userData.userPhoneNumber, userType: 'customer', userMustResetPassword: false});
  }

  const searchTerm = await get(child(dbRef, `facility-search-terms/${facilityId}/searchTerm`));
  if (searchTerm.exists()) {
    userData.searchTerm = searchTerm.val();
  }

}

const getBasicUserInfo = async (dbRef, userAuth, userKey, userData) => {

  const userIdSnapshot = await get(child(dbRef, `${userKey}/${userAuth.uid}/userId`));
  if (userIdSnapshot.exists()) {
    userData.userId = userAuth.uid;
    const userEmailAddressSnapshot = await get(child(dbRef, `${userKey}/${userAuth.uid}/userEmailAddress`));
    if (userEmailAddressSnapshot.exists()) {
      userData.userEmailAddress = userEmailAddressSnapshot.val()
    }

    const userFirstNameSnapshot = await get(child(dbRef, `${userKey}/${userAuth.uid}/userFirstName`));
    if (userFirstNameSnapshot.exists()) {
      userData.userFirstName = userFirstNameSnapshot.val()
    }

    const userLastNameSnapShot = await get(child(dbRef, `${userKey}/${userAuth.uid}/userLastName`));
    if (userLastNameSnapShot.exists()) {
      userData.userLastName = userFirstNameSnapshot.val();
    }

    const stipeIdSnapShot = await get(child(dbRef, `${userKey}/${userAuth.uid}/stripeCustomerId`));
    if (stipeIdSnapShot.exists()) {
      userData.stripeCustomerId = stipeIdSnapShot.val();
    }

    const constactInfoSnapShot = await get(child(dbRef, `${userKey}/${userAuth.uid}/contactInfo`));
    if (constactInfoSnapShot.exists()) {
      userData.contactInfo = constactInfoSnapShot.val();
    }

  }
}

export const getEditLock = async (lockName, userData) => {
  const dbRef = ref(getDatabase());
  const lockReference = await get(child(dbRef, `facility-user-accounts/${userData.facilityId}/editLocks/${lockName}`));
  if (lockReference.exists()) {
      return false;
  } else {
    const facilityAreaRef = ref(getDatabase(), `facility-user-accounts/${userData.facilityId}/editLocks/${lockName}`);
    set(facilityAreaRef, {editId: userData.editId});
    onDisconnect(facilityAreaRef).remove();
    return true;
  }
}

export const removeEditLock = (lockName, userData) => {
  remove(ref(getDatabase(), `facility-user-accounts/${userData.facilityId}/editLocks/${lockName}`));
}

export const getFacilityBlackoutReasons = async (facilityId) => {
  const dbRef = ref(getDatabase());
  const blackoutReasonsSnapShot = await get(child(dbRef, `facility-user-accounts/${facilityId}/blackoutReasons`));
  let blackoutReasons = ["Reserved", "Maintenance", "Tournament", "Area Closed"];
  if (blackoutReasonsSnapShot.exists()) {
    blackoutReasons = blackoutReasons.concat(blackoutReasonsSnapShot.val());
  }

  const filteredReasons = blackoutReasons.filter((reason) => {
    return reason !== 'Add Custom';
  });
  filteredReasons.push('Clear History')

  return [...new Set(filteredReasons)];
}

export const clearFacilityBlackoutReasonHistory = async (facilityId) => {
  const dbRef = ref(getDatabase());
  remove(child(dbRef, `facility-user-accounts/${facilityId}/blackoutReasons`));
}

export const isFacilityUserAccount = async (userAuth) => {
  const dbRef = ref(getDatabase());
  const facilityUserSnapshot = await get(child(dbRef, `facility-user-accounts/${userAuth.uid}/userId`));
  if (facilityUserSnapshot.exists()) {
    return true;
  }

  return false;
};

export const createUserDocumentFromAuth = async (userAuth, additionalInformation) => {

  if (!userAuth) return;

  const userDocRef = doc(db, 'users', userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);
  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        ...additionalInformation
      })
    } catch (error) {
      console.log('error creating the user', error.mesage);
    }

  }
  return userDocRef;
};

export const sendPasswordReset = async (email) => {
  if (!email) return 'must provide an email';
  try {
    await sendPasswordResetEmail(auth, email);
    return 'success'
  } catch (error) {
    console.log(error);
    return error.message;
  }
}

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  try {
    const createResponse = await createUserWithEmailAndPassword(auth, email, password);

    return { userId: createResponse.user.uid, errorMessage: null };
  } catch (error) {

    let errorMessage = error.message;
    if (error.code && error.code) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = "Email is already in use.";
          break;
        case 'auth/invalid-email':
          errorMessage = "Email address is invalid.";
          break;
        case 'auth/weak-password':
          errorMessage = "The password does not meet requirements."
          break;
        default:
          errorMessage = error.message;
          break;
      }

    }

    return { userId: null, errorMessage: errorMessage };
  }
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  await setPersistence(auth, browserSessionPersistence)

  return await signInWithEmailAndPassword(auth, email, password);
};

export const verifyPassword = async (password) => {
  if (!password || password.length <= 0) {
    throw new Error('You must enter a password.');
  }

  const user = auth.currentUser
  const credential = EmailAuthProvider.credential(
    user.email,
    password
  );

  return await reauthenticateWithCredential(user, credential);
}

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);