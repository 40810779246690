import { Stack, Box, Button, Typography, Divider, TextField, IconButton, Modal } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Fragment, useContext, useEffect } from "react";
import { BirthdayPartyContext } from "../../../contexts/birthday-party/birthday-party.context";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import dayjs from "dayjs";
import { PrepareOrBookBirthdaySummary } from "../../../utils/birthday-api/birthday-service";
import { UserContext } from "../../../contexts/user.context";

const style = {
    position: 'absolute',
    top: '10%',
    left: '10%',
    right: '10%',
    bottom: '5%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};


const BirthdayBooking = ({ close }) => {

    const { userData } = useContext(UserContext);
    const { isLoading, setIsLoading, setAlertMessage, selectedBirthdayParty = {}, formFields, setFormFields, resetFormFields, searchResponse, setSearchResponse, updateAncillary, updateCustomField, setPreparedSummary, partyInput } = useContext(BirthdayPartyContext);

    const { name = "", price = "", details = {} } = selectedBirthdayParty ? selectedBirthdayParty : {};
    const { initialDeposit, maxCapicity, length, ancillaries = [], customFields } = details;
    const { startDate, endDate, startTime, endTime } = searchResponse ? searchResponse : {};
    const { mainTextColor = '#14254C', secondaryTextColor = "#A9A9A9" } = selectedBirthdayParty && (!userData || (userData && !userData.isFacilityUserAccount)) ? selectedBirthdayParty : {};


    useEffect(() => {
        const setupFormFields = () => {
            setFormFields({
                customFields: customFields,
                ancillaries: ancillaries
            })
        }
        setupFormFields();
    }, []);

    const closeBooking = () => {
        setSearchResponse(null);
        resetFormFields();
        if (close) {
            close();
        }
    }

    const handleAncillaryChangeMinus = (e) => {
        const ancillaryId = e.currentTarget.dataset.id;
        updateAncillary(ancillaryId, -1)
    }

    const handleAncillaryChangeAdd = (e) => {
        const ancillaryId = e.currentTarget.dataset.id;
        updateAncillary(ancillaryId, 1)
    }

    const proceedToPayment = async () => {

        let proceed = true;
        if (formFields.customFields) {
            for (const field of formFields.customFields) {
                if (!field.answer || field.answer.trim().length === 0) {
                    proceed = false;

                    setAlertMessage({
                        message: "You must fill out all required info."
                    });
                }
            }
        }

        if (proceed) {

            const acceptedAncillaries = formFields.ancillaries.filter((anc) => {
                return anc.quantity && anc.quantity > 0
            });

            setIsLoading(true);
            const response = await PrepareOrBookBirthdaySummary(selectedBirthdayParty, acceptedAncillaries, partyInput.customer ? partyInput.customer.userId : userData.userId, searchResponse.summaryId, false, partyInput, formFields.customFields);
            setIsLoading(false);
            if (response && response.status === 200 && response.data) {
                if (response.data.status === 'success') {
                    setPreparedSummary(response.data.summary);
                } else {
                    const errMessage = "Something went wrong.  We are unable to proceed to the payment summary at this time.";
                    setAlertMessage({
                        message: response.data.message ? response.data.message : errMessage, reason: response.data.failedReason ? response.data.failedReason : 'alert_only'
                    });
                }
            } else {
                setAlertMessage({
                    message: "Something went wrong.  We are unable to proceed to the payment summary at this time."
                });
            }
        }

    }

    const handleCustomFieldChange = (event) => {
        const { name, value, id } = event.target;
        updateCustomField(name, value, id);
    }

    return (
        <Modal open={!isLoading}>
            <Box sx={{ ...style, width: '80%' }}>
                <Box ml={5} display="flex" justifyContent="flex-start" alignItems="flex-start">
                    <Button onClick={closeBooking} startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Box>
                <Box sx={{ width: '100%' }} display="flex" justifyContent="center" alignItems="center" >
                    <Stack ml={5} mr={5} sx={{ width: '100%' }} >
                        <Typography ml={2} mt={2} mr={2} textAlign={'left'} gutterBottom color={mainTextColor} fontWeight={'bold'} component="div" variant="h6">
                            Party Details
                        </Typography>
                        <Stack spacing={4} direction={'row'} justifyContent={'space-between'}>
                            <Stack>
                                <Typography ml={5} mt={2} textAlign={'left'} gutterBottom color={mainTextColor} fontWeight={'bold'} component="div" variant="body1">
                                    Birthday Party Name
                                </Typography>
                                <Typography ml={5} textAlign={'left'} gutterBottom color={mainTextColor} component="div" variant="body1">
                                    {name}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography ml={5} mt={2} textAlign={'left'} gutterBottom color={mainTextColor} fontWeight={'bold'} component="div" variant="body1">
                                    Price
                                </Typography>
                                <Typography ml={5} textAlign={'left'} gutterBottom color={mainTextColor} component="div" variant="body1">
                                    ${price}
                                </Typography>
                            </Stack>

                            {initialDeposit &&
                                <Stack>
                                    <Typography ml={5} mt={2} textAlign={'left'} gutterBottom color={mainTextColor} fontWeight={'bold'} component="div" variant="body1">
                                        Deposit
                                    </Typography>
                                    <Typography ml={5} textAlign={'left'} gutterBottom color={mainTextColor} component="div" variant="body1">
                                        ${initialDeposit}
                                    </Typography>
                                </Stack>
                            }
                            <Stack>
                                <Typography ml={5} mt={2} textAlign={'left'} gutterBottom color={mainTextColor} fontWeight={'bold'} component="div" variant="body1">
                                    Participants
                                </Typography>
                                <Typography ml={5} textAlign={'center'} gutterBottom color={mainTextColor} component="div" variant="body1">
                                    {maxCapicity}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography ml={5} mt={2} textAlign={'left'} gutterBottom color={mainTextColor} fontWeight={'bold'} component="div" variant="body1">
                                    Length
                                </Typography>
                                <Typography ml={5} textAlign={'left'} gutterBottom color={mainTextColor} component="div" variant="body1">
                                    {length} Hours
                                </Typography>
                            </Stack>
                        </Stack>
                        <Divider color={`${secondaryTextColor}`} sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                        <Stack direction={'row'} spacing={4} >
                            <Stack>
                                <Typography ml={5} mt={1} textAlign={'left'} gutterBottom color={mainTextColor} fontWeight={'bold'} component="div" variant="body1">
                                    Date
                                </Typography>
                                <Typography ml={5} textAlign={'left'} gutterBottom color={mainTextColor} component="div" variant="body1">
                                    {dayjs(startDate).format('dddd, MMM D, YYYY')}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography ml={5} mt={1} textAlign={'left'} gutterBottom color={mainTextColor} fontWeight={'bold'} component="div" variant="body1">
                                    Time
                                </Typography>
                                <Typography ml={5} textAlign={'left'} gutterBottom color={mainTextColor} component="div" variant="body1">
                                    {startTime} to {endTime}
                                </Typography>
                            </Stack>
                        </Stack>
                        {
                            formFields.ancillaries && formFields.ancillaries.length > 0 &&
                            <Divider color={`${secondaryTextColor}`} sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                        }

                        {
                            formFields.ancillaries && formFields.ancillaries.length > 0 &&

                            <Stack ml={2} mt={2} direction={'row'} spacing={1}>
                                <Typography textAlign={'left'} gutterBottom color={mainTextColor} fontWeight={'bold'} component="div" variant="h6">
                                    Optional Items
                                </Typography>
                                <Box display="flex" justifyContent="center" alignItems="flex-end" >
                                    <Typography textAlign={'left'} gutterBottom color={"gray"} component="div" variant="body2">
                                        (Enter Quantity)
                                    </Typography>
                                </Box>
                            </Stack>
                        }
                        {
                            formFields.ancillaries && formFields.ancillaries.length > 0 &&
                            <Fragment>
                                <Stack mt={2} ml={5} spacing={2}>
                                    {
                                        formFields.ancillaries.map((anc) => {
                                            const { id, quantity = 0 } = anc;
                                            return <Stack key={`stack-${id}`} spacing={4} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                <Typography key={`desc-${id}`} sx={{ width: '50%' }} textAlign={'left'} gutterBottom color={mainTextColor} component="div" variant="body1">
                                                    {anc.description}
                                                </Typography>
                                                <Typography key={`price-${id}`} textAlign={'left'} gutterBottom color={mainTextColor} component="div" variant="body1">
                                                    ${anc.price}
                                                </Typography>
                                                <Stack key={`stack-2-${id}`} direction={'row'} spacing={1}>
                                                    <IconButton key={`icon-btn-${id}`} edge="end" aria-label="delete" onClick={handleAncillaryChangeMinus.bind(this)} data-id={id}>
                                                        <RemoveOutlinedIcon />
                                                    </IconButton>
                                                    <TextField key={`quantity-${id}`} size="small" InputProps={{ readOnly: true }} sx={{ width: '6.0vw', textAlign: "center" }} id={id} variant="outlined" label="Quantity" type="number" name="quantity" value={quantity} ></TextField>
                                                    <IconButton key={`icon-btn-2-${id}`} edge="end" aria-label="delete" onClick={handleAncillaryChangeAdd.bind(this)} data-id={id}>
                                                        <AddOutlinedIcon key={`add-icon-${id}`} />
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        })
                                    }
                                </Stack>
                            </Fragment>
                        }
                        <Divider color={`${secondaryTextColor}`} sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                        <Box ml={2} mr={2} mb={2}>
                            {
                                formFields.customFields && formFields.customFields.length > 0 &&
                                <Fragment>
                                    <Typography mt={2} textAlign={'left'} gutterBottom color={mainTextColor} fontWeight={'bold'} component="div" variant="h6">
                                        Additional Info
                                    </Typography>

                                    <Stack mt={2} ml={5} spacing={2}>
                                        {
                                            formFields.customFields.map((field) => {
                                                const { id, text, answer } = field;
                                                return <TextField key={id} required id={id} variant="outlined" label={text} type="text" name="answer" value={answer ? answer : ''} onChange={handleCustomFieldChange} ></TextField>
                                            })
                                        }
                                    </Stack>
                                </Fragment>
                            }
                        </Box>
                        <Divider color={`${secondaryTextColor}`} sx={{ marginRight: '1.0vw', marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                        <Box sx={{ width: '100%', marginTop: '5.0vh', marginBottom: '5.0vh' }} display="flex" justifyContent="center" alignItems="center">
                            <Button sx={{
                                backgroundColor: `${mainTextColor}`, ':hover': {
                                    bgcolor: secondaryTextColor,
                                    color: 'white',
                                }
                            }} variant="contained" onClick={proceedToPayment}>
                                Proceed To Payment
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    )
}

export default BirthdayBooking;