import { TextFormContainer } from './text-form-input.styles';
import helpIcon from '../../assets/help-icon.svg';
import { useState } from 'react';

const TextFormInput = ({ label, helpText, ...otherProps }) => {

    const [isHovering, setIsHovering] = useState(false);
    const toggleHover = () => {
        setIsHovering(!isHovering);
    }

    return (
        <TextFormContainer>
            <div className='label-and-help-text'>
                <span className="text-label">{label}</span>
                {
                    helpText &&
                    <img onMouseLeave={() => toggleHover()} onMouseEnter={() => toggleHover()} src={helpIcon} alt='img' className='icon-img' />
                }
                {isHovering &&
                    <div className='help-text-container'>
                        <span className='help-text'> {helpText}</span>
                    </div>
                }
            </div>
            <input className="text-input" {...otherProps} />
        </TextFormContainer>
    )

}

export default TextFormInput;