import { FacilityMembershipsPickerContainer, FacilityMembershipsPickerListContainer } from "./facility-memberships-picker.styles";
import FormHeader from "../../form-header/form-header.component";
import MembershipsListAll from "../../memberships-list-all/memberships-list-all.component";
import Button from "../../button/button.component";


const FacilityMembershipsPicker = ({ closeView, selectMembership, selectedMemberships, selectAll, clearAll }) => {

    const closeFacilityMemberships = () => {
        closeView();
    }

    const selectAllMemberships = () => {
        selectAll();
    }

    const clearSelections = () => {
        clearAll();
    }
    return (
        <FacilityMembershipsPickerContainer>
            <FacilityMembershipsPickerListContainer>
                <FormHeader title={'Facility Memberships'}></FormHeader>
                <div className='membership-list-container'>
                    <MembershipsListAll selectMembership={selectMembership} selectedMemberships={selectedMemberships}></MembershipsListAll>
                </div>
                <div className="button-div">
                    <Button onClick={closeFacilityMemberships} buttonType='inverted'>Done</Button>
                    <Button onClick={selectAllMemberships}>Select All</Button>
                    <Button onClick={clearSelections}>Clear All</Button>
                </div>
            </FacilityMembershipsPickerListContainer>
        </FacilityMembershipsPickerContainer>

    )

}

export default FacilityMembershipsPicker