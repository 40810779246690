import { Fragment, useContext, useState, useEffect } from "react";
import * as React from 'react';
import { FacilityAreaListContainer } from './facility-area-list.styles';
import SubAreas from "../../facility/sub-areas/sub-areas.component";
import { FacilityAreaContext } from "../../../contexts/facility/facility-area-provider.context";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { TextField, Popover } from "@mui/material";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";

const FacilityAreaList = ({ facilityIdOverride = null, marginLeft = '0.0vw', marginRight = '0.0vw' }) => {

    const { facilityAreas, setFacilityIdOverride } = useContext(FacilityAreaContext);
    const [showList, setShowList] = useState(false);
    const { reservationSearchData, setReservationSearchData } = useContext(SearchForReservationContext);
    const { selectedSport, facilityArea } = reservationSearchData;
    const [ listAnchorEl, setListAnchorEl ] = useState(null);

    useEffect(() => {
        setFacilityIdOverride(facilityIdOverride);
    }, []);

    const handleClick = (e) => {
        const areaId = e.currentTarget.dataset.id;
        let request = Object.assign({}, reservationSearchData);
        if (areaId === 'Any') {
            request.facilityArea = null;
        } else {
            const areas = facilityAreas.filter(area => area.id === areaId);
            request.facilityArea = areas[0];
        }
        setReservationSearchData(request);
        setShowList(false);
        setListAnchorEl(null);
    }

    const handleFacilityAreaClick = (e) => {
        setShowList(true);
        setListAnchorEl(e.currentTarget);
    }

    const handleFacilityAreaArrowClick = (e) => {
        if (listAnchorEl){
            setListAnchorEl(null);
        } else {
            setListAnchorEl(e.currentTarget);
        }
    }
    
    const closeList = () => {
        setListAnchorEl(null);
    }

    const subAreaSelected = (subArea) => {

        let request = Object.assign({}, reservationSearchData);
        request.facilityArea = subArea;
        setReservationSearchData(request);

        setShowList(false);
        setListAnchorEl(null);
    }

    return (

        <FacilityAreaListContainer key={'facility-area-list-const'}>
            <TextField sx={{ marginLeft: marginLeft, marginRight: marginRight }} id="facility-area" variant="outlined" label="Facility Area" onClick={handleFacilityAreaClick} type="text" name="facilityareaname" value={facilityArea ? facilityArea.name : "Any"}
                InputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end">
                        <IconButton onClick={handleFacilityAreaArrowClick}>
                            {showList ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </IconButton></InputAdornment>
                }}></TextField>
            <Popover
                id={'facility-area-list-popover'}
                open={listAnchorEl ? true : false}
                anchorEl={listAnchorEl}
                onClose={closeList}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List key={'facility-area-list-key'}>
                    <ListItemButton selected={!facilityArea} divider={true} key={'Any'} onClick={handleClick.bind(this)} data-id={'Any'}>
                        <ListItemText primary={"Any"} primaryTypographyProps={{
                            fontWeight: 'bold',
                            letterSpacing: 0,
                            color: '#14254C'
                        }}></ListItemText>
                    </ListItemButton>
                    {
                        facilityAreas.map((mainArea) => {
                            const { name, id, subAreas, sports } = mainArea
                            return <Fragment>
                                {
                                    (sports.includes(selectedSport) || selectedSport === 'Any' || selectedSport === '' || !selectedSport) &&
                                    <ListItemButton selected={facilityArea && facilityArea.id === id} divider={true} key={id} onClick={handleClick.bind(this)} data-id={id}>
                                        <ListItemText key={`${id}-list-item`} primary={name} primaryTypographyProps={{
                                            fontWeight: 'bold',
                                            letterSpacing: 0,
                                            color: '#14254C'
                                        }}></ListItemText>
                                    </ListItemButton>
                                }
                                <SubAreas key={`${id}-sub-area`} selectFacilityArea={subAreaSelected} selectedArea={facilityArea} subAreas={subAreas} sport={selectedSport}></SubAreas>
                            </Fragment>
                        })
                    }

                </List>
            </Popover>

        </FacilityAreaListContainer>

    )

}

export default FacilityAreaList