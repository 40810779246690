import styled from 'styled-components';

export const SearchDateExceptionsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 20vw;
    z-index: 10;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-left: -1vw;
    margin-top: -1vh;
    gap: 1.0vh;
    padding: 1.5vh;
`