import { DAYS_OF_WEEK_SORT_ORDER } from '../../utils/constants/constants.js';
import dayjs from "dayjs";
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

export const getDaysOfWeekText = (daysOfWeek) => {
    let daysOfWeekText = '';

    if (daysOfWeek.includes("Sunday")) {
        daysOfWeekText = `${daysOfWeekText} S `;
    }

    if (daysOfWeek.includes("Monday")) {
        daysOfWeekText = `${daysOfWeekText} M `;
    }

    if (daysOfWeek.includes("Tuesday")) {
        daysOfWeekText = `${daysOfWeekText} T `;
    }

    if (daysOfWeek.includes("Wednesday")) {
        daysOfWeekText = `${daysOfWeekText} W `;
    }

    if (daysOfWeek.includes("Thursday")) {
        daysOfWeekText = `${daysOfWeekText} Th `;
    }

    if (daysOfWeek.includes("Friday")) {
        daysOfWeekText = `${daysOfWeekText} F `;
    }
    if (daysOfWeek.includes("Saturday")) {
        daysOfWeekText = `${daysOfWeekText} Sa `;
    }

    return daysOfWeekText;

}

export const getDaysOfWeekTextFromInt = (daysOfWeek) => {
    let daysOfWeekText = '';

    if (daysOfWeek.includes(0)) {
        daysOfWeekText = `${daysOfWeekText} S `;
    }

    if (daysOfWeek.includes(1)) {
        daysOfWeekText = `${daysOfWeekText} M `;
    }

    if (daysOfWeek.includes(2)) {
        daysOfWeekText = `${daysOfWeekText} T `;
    }

    if (daysOfWeek.includes(3)) {
        daysOfWeekText = `${daysOfWeekText} W `;
    }

    if (daysOfWeek.includes(4)) {
        daysOfWeekText = `${daysOfWeekText} Th `;
    }

    if (daysOfWeek.includes(5)) {
        daysOfWeekText = `${daysOfWeekText} F `;
    }
    if (daysOfWeek.includes(6)) {
        daysOfWeekText = `${daysOfWeekText} Sa `;
    }

    return daysOfWeekText;

}


export const generateDatesForReservation = (startDate, repeatsUntilDate, repeatDaysOfWeek ) => {

    let reservationDates = [];
    let newDate = startDate.startOf('day');
    if (repeatsUntilDate) {
        let repeatsUntilMoment = repeatsUntilDate.endOf('day');
        while (newDate.isSameOrBefore(repeatsUntilMoment)) {
            reservationDates.push(newDate.clone());
            newDate = newDate.add(1, 'day');
        }
    } else {
        reservationDates = [newDate];
    }

    if (repeatDaysOfWeek && repeatDaysOfWeek.length > 0) {
        const dayOfWeekReservationDates = [];
        for (const resdate of reservationDates) {
            const resDateDOW = resdate.day();
            let isDayOfWeek = false;
            for (const dow of repeatDaysOfWeek) {
                if (DAYS_OF_WEEK_SORT_ORDER[dow] === resDateDOW){
                    isDayOfWeek = true;
                }
            }

            if (isDayOfWeek){
                dayOfWeekReservationDates.push(resdate);
            }
        }
        return dayOfWeekReservationDates;
    } else {
        return reservationDates;
    }
}

export const generateDayJSDatesForReservation = (startDate, repeatsUntilDate, repeatDaysOfWeek  ) => {
    
    let reservationDates = [];
    let newDate = startDate.startOf('day');
    
    if (repeatsUntilDate) {
        let repeatsUntilMoment = repeatsUntilDate.endOf('day');
        while (newDate.isSameOrBefore(repeatsUntilMoment)) {
            reservationDates.push(newDate);
            newDate = newDate.add(1, 'day');
        }
    } else {
        reservationDates = [newDate];
    }
    
    if (repeatDaysOfWeek && repeatDaysOfWeek.length > 0) {
        const dayOfWeekReservationDates = [];
        for (const resdate of reservationDates) {
            const resDateDOW = resdate.day();
            let isDayOfWeek = false;
            for (const dow of repeatDaysOfWeek) {
                if (DAYS_OF_WEEK_SORT_ORDER[dow] === resDateDOW){
                    isDayOfWeek = true;
                }
            }

            if (isDayOfWeek){
                dayOfWeekReservationDates.push(resdate);
            }
        }
        return dayOfWeekReservationDates;
    } else {
        return reservationDates;
    }
}

export const getReservationDatesList = (startDate, repeatsUntilDate, repeatDaysOfWeek, repeatExceptionDates, withExceptions, dateFormat) => {
    let reservationDates = generateDatesForReservation(startDate, repeatsUntilDate, repeatDaysOfWeek);
    if (!withExceptions && repeatExceptionDates) {
        for (let exceptionDate of repeatExceptionDates) {
            reservationDates = reservationDates.filter((resDate) => {
                return resDate.format(dateFormat) !== moment(exceptionDate, 'ddd, MMM D, YYYY').format(dateFormat);
            });

        }
    }

    const datesToReturn = [];
    for (const date of reservationDates) {
        datesToReturn.push(date.format(dateFormat));
    }
    return datesToReturn;
}