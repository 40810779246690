import ReservationSearchForm from "../../../components/facility/reservation-calendar/reservation-search-form/reservation-search-form.component";
import FacilityReservationList from "../../../components/facility/facility-reservation-list/facility-reservation-list.component";
import { FacilityReservationSearchContainer } from './facility-reservation-search.styles';
import FacilityReservationNewList from "../../../components/facility/facility-reservation-list/facility-reservation-new-list.component";

const FacilityReservationSearch = () => {

    return (

        <FacilityReservationSearchContainer>
            <ReservationSearchForm></ReservationSearchForm>
            <FacilityReservationNewList></FacilityReservationNewList>
        </FacilityReservationSearchContainer>

    )

}

export default FacilityReservationSearch