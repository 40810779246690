import styled from 'styled-components';

export const ReservationSearchContainer = styled.div
  `
grid-column: 1 / 2;
grid-row: 1;
position: relative;
top: 10%;
height: 87vh;
width: 99%;
display: flex;
flex-direction: column;
background-color: white;
border-radius: 5px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
margin: 1.0vw;
overflow: scroll;

.bottom-button-bar {
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 2.0rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 3px 5px;
    margin: 1rem;
  
    .icon-img {
        max-width: 100px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
  
    .icon-title {
      display: block;
        text-align: center;
    }
  }
`
export const ReservationFormContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
justify-content: space-evenly;
background-color: white;
border-radius: 5px;
`

export const ReservationForm2Container = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
background-color: white;
border-radius: 5px;
height: 100%;
width: 100%;
`

export const ReservationFormRow = styled.div`
    display: flex;  
    flex-direction: ${(props) => `${props.$flexdirection}`};;
    justify-content:${(props) => `${props.$justifycontent}`};
    margin-left: 1rem;
    margin-right: 1rem;
    color: #14254C;

    .label-grouping {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      column-gap: 1.0vw;
    }

    .search-by-range-label {
        display: flex;
        text-align: left;
        color: #14254C;
        margin-right: 1.0rem;
        font-size: 1.45vh;
        line-height: 1.5rem;
        font-weight: bold;
    }

    .search-range-container {
        display: flex;
        border: 1px solid #DEDEDE;
        border-radius: 5px;
        margin-top: 0.5vh;
        margin-bottom: 2.0vh;
        margin-left: 1.0vw;

        .search-range-inner-conatiner {
          display: flex;
          margin-top: 2.0vh;
          margin-bottom: 2.0vh;
          margin-left: 1.0vw;
          margin-right: 1.0vw;
        }
    }

    .tile-and-checkbox-container {
      display: flex;
    }

    .checkbox-img {
      max-width: 25px;
    }

    .bottom-button-bar {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        line-height: 2.0rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .time-grouping {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

        .label {
          display: flex;
          text-align: left;
          color: #14254C;
          margin-right: 1.0rem;
          font-size: 1.45vh;
          line-height: 1.5rem;
        }

      .duration-input {
          height: 100%;
      }
    }

    .label {
        display: flex;
        width: 100%;
        text-align: left;
        margin-left: .01rem;
        padding: 0 15px 0 15px;
        align-items: center;
        font-weight: bold;
    }

    .small-label {
        display: flex;
        width: 25%;
        text-align: left;
        font-size: 0.75rem;
        margin-left: .01rem;
        padding: 0 15px 0 15px;
        align-items: center;
        font-weight: bold;
    }

    .half-label {
        display: flex;
        width: 50%;
        text-align: left;
        margin-left: .01rem;
        padding: 0 15px 0 15px;
        align-items: center;
    }

    .half-row {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;

        .label {
            display: flex;
            width: 100%;
            text-align: left;
            margin-left: .01rem;
            padding: 0 15px 0 15px;
            font-weight: bold;
    }

        .display-text {
            display: flex;
            width: 100%;
            text-align: left;
            justify-content: center;
            border-radius: 5px;
            border: 1px solid #eaeaea;
            cursor: pointer;
    }
    }

    .title {
        display: flex;
        width: 100%;
        text-align: right;
        font-size: 1.25rem;
        margin-left: 1.0rem;
        font-weight: bold;
        justify-content: flex-start;
        align-items: center;
    }

    .display-text {
        display: flex;
        text-align: left;
        margin-left: 1.0rem;
        margin-right: 1.0rem;
        justify-content: flex-start;
        border-radius: 5px;
        border: 1px solid #eaeaea;
        cursor: pointer;
    }

    .display-text:hover {
        background-color: #eaeaea;
      }

      .small-display-text {
        display: flex;
        width: 25%;
        text-align: center;
        font-size: 0.5rem;
        padding: 0 15px 0 15px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #eaeaea;
        cursor: pointer;
    }

    .small-display-text:hover {
        background-color: #eaeaea;
      }

    .close-x {
        width: 5%;
        display: flex;
        text-align: left;
        font-weight: bold;
        font-size: 1.0rem;
        justify-content: center;
        align-items: center;
      }

      .close-x: hover {
        cursor: pointer;
      }
`