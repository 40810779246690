import styled from 'styled-components';

export const HomePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5.0vh;
    width: 100%;
    height: 100%;
    position: relative;

    .top-search-bar {
        display: flex;
        justify-content: center;
        margin-top: 10vh;
        margin-left: 5vw;
        margin-right: 5vw;
        background-color: #14254C;
        border-radius: 5px;
        background-size: cover;
        height: 65vh;
    }

    .mysportspace-temp-container {
        display: flex;
        height: 25vh;
        margin-bottom: 25vh;
    }
`

export const HomePageMainContainer = styled.div`
position: relative;
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
`