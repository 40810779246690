import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Divider, Typography, Box } from '@mui/material';
import AreaSelect from './area-select.component';
import { useContext, useState } from 'react';


const CopyPricingDialog = ({ isOpen = false, copyPricing, close }) => {

    const [areaSelected, setSelectedArea] = useState(null);

    const handleClose = () => {
        if (close) {
            close();
        }
        setSelectedArea(null);
    }

    const areaWasSelected = (area) => {
        setSelectedArea(area);
    }

    const copyExpectionPricingOnly = () => {
        copyPricing(areaSelected, false, true);
        setSelectedArea(null);
    }

    const copyDayOfWeekPricingOnly = () => {
        copyPricing(areaSelected, true, false);
        setSelectedArea(null);
    }

    const copyBoth = () => {
        copyPricing(areaSelected, true, true);
        setSelectedArea(null);
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="add-area-dialog-title"
            aria-describedby="add-area-dialog-description">
            <DialogTitle sx={{ color: '#14254C' }} id="add-area-dialog-title">
                {"Copy Area Pricing"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="add-area-dialog-description">
                    Select the type of pricing your want to copy and the area you will be copying the pricing details from.
                </DialogContentText>
                <Typography color={'#14254C'} fontFamily={'Helvetica'} fontWeight={'bold'} variant='subtitle1' mt={2}>Day Of Week Pricing</Typography>
                <Typography ml={4} fontFamily={'Helvetica'} color={'gray'}>This is the deault day of week pricing and any time specific pricing for that day of week.</Typography>
                <Typography color={'#14254C'} fontFamily={'Helvetica'} fontWeight={'bold'} variant='subtitle1' mt={2}>Exception Pricing</Typography>
                <Typography ml={4} fontFamily={'Helvetica'} color={'gray'} >The pricing exceptions set up for specific dates.</Typography>
                <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }} ></Divider>
                <Box mt={1} ml={4} mr={5}>
                    <AreaSelect areaSelected={areaWasSelected}></AreaSelect>
                </Box>

                <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }} ></Divider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button disabled={areaSelected ? false : true} variant="contained" onClick={copyDayOfWeekPricingOnly} autoFocus>
                    Copy Default Pricing
                </Button>
                {
                    areaSelected && areaSelected.pricingexceptions &&
                    <Button disabled={areaSelected ? false : true} variant="contained" onClick={copyExpectionPricingOnly} autoFocus>
                        Copy Expection Pricing
                    </Button>
                }
                {
                    areaSelected && areaSelected.pricingexceptions &&
                    <Button disabled={areaSelected ? false : true} variant="contained" onClick={copyBoth} autoFocus>
                        Copy Both
                    </Button>
                }


            </DialogActions>
        </Dialog>
    )
}

export default CopyPricingDialog;