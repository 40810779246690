import { FormHeaderContainer } from './form-header.styles'


const FormHeader = ({ title, closeAction, description }) => {
    return (
        <FormHeaderContainer>
            <div className='description-and-title'>
                <div className="title"> {title} </div>
                {
                    closeAction &&
                    <div className="close-x" onClick={closeAction}> X </div>
                }
            </div>

            {
                description &&
                <span className="form-description">
                    <p>
                        {description}
                    </p>
                </span>
            }

        </FormHeaderContainer>
    )
}

export default FormHeader