import { useContext } from "react";
import { FacilityAreaContext } from "../../../../contexts/facility/facility-area-provider.context";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Stack, Typography, Box } from "@mui/material";
import { DisplayContext } from "../../../../contexts/dispaly-options.context";

const AreasCalendarHeader = () => {

    const { facilityAreas } = useContext(FacilityAreaContext);
    const { calendarDimensions } = useContext(DisplayContext);

    const mainFontSize = calendarDimensions.facility_calendar_width === '100%' ? 'clamp(0.01vh, -0.1250vh + 13.5000vh, 1.5vh)' : 'auto';
    const subFontSize = calendarDimensions.facility_calendar_width === '100%' ? 'clamp(0.01vh, -0.1250vh + 13.5000vh, 1.25vh)' : 'auto'
    return (
        <Grid sx={{ marginLeft: '4.0vw' }} container columns={facilityAreas.length}>
            {
                facilityAreas.map((facilityArea) => {
                    const { id, name, subAreaList, searchStatus } = facilityArea ?? {};
                    return <Grid key={`main-grid-${id}`} xs={1}>
                        {
                            subAreaList &&
                            <Stack justifyContent={'flex-end'} sx={{ height: '100%', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
                                <Box sx={{ height: '100%', backgroundColor: `${searchStatus === 'inactive' ? 'red' : 'white'}` }} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                                    <Typography sx={{ fontSize: `${mainFontSize}` }} fontFamily={"Helvetica"} color={"#14254C"} fontWeight={'bold'} textAlign={'center'}>{name}</Typography>
                                </Box>
                                <Grid sx={{ width: '100%', height: '100%' }} container columns={subAreaList.length}>
                                    {
                                        facilityArea.subAreaList.map((subArea) => {
                                            return <Grid key={`sub-grid-${subArea.id}`} xs={1}>
                                                <Box sx={{height: '100%', backgroundColor: `${subArea.searchStatus === 'inactive' ? 'red' : '#eaeaea'}`, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }} display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                                                    <Typography key={`sub-area-hdr-${subArea.id}`} marginLeft={'0.25vw'} marginRight={'0.25vw'} fontFamily={"Helvetica"} color={"#14254C"} sx={{ fontSize: `${subFontSize}`}} textAlign={'center'}>{subArea.name}</Typography>
                                                </Box>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Stack>

                        }
                        {
                            !facilityArea.subAreaList &&
                            <Box sx={{ height: '100%', width: '100%', backgroundColor: `${searchStatus === 'inactive' ? 'red' : 'white'}`, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                <Typography fontFamily={"Helvetica"} color={"#14254C"} fontWeight={'bold'} sx={{ width: '100%', fontSize: `${mainFontSize}` }} textAlign={'center'}>{name}</Typography>
                            </Box>

                        }
                    </Grid>
                })
            }
        </Grid>
    )

}

export default AreasCalendarHeader;