import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActions, Box } from '@mui/material';

const FacilityCard = ({ facility, filterType }) => {

    const { reset } = useContext(SearchForReservationContext);
    const { facilityName, facilitySearchTerm, facilityPhotoUrl, facilityId, sportsAvailable, address = {}, } = facility;
    const { streetAddress, city, state, zipCode } = address;

    const navigate = useNavigate();

    const handleClick = () => {
        reset();
        if (filterType === 'birthday-parties') {
            navigate(`birthday-parties/${facilitySearchTerm ? facilitySearchTerm : facilityId}`);
        } else {
            navigate(`/facility-tour/${facilityId}`);
        }

    }
    sportsAvailable.map((sport) => {
        return <span key={sport} className="sport">{sport} </span>
    })

    return (
        <Card onClick={handleClick} sx={{
            width: "20vw", minHeight: "45vh", maxHeight: "55vh", display: "flex", flexDirection: 'column', alignItems: 'stretch', cursor: "pointer", ":hover": { boxShadow: "0 3px 10px #14254C" }
        }}>
            <CardMedia
                component="img"
                image={facilityPhotoUrl}
                height="140"
                alt="facility photo"
            />
            <CardContent sx={{ display: "flex", flexDirection: 'column', flexGrow: '1', alignItems: 'stretch' }}>
                <Typography gutterBottom textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={'#14254C'} variant="h5" component="div">
                    {facilityName}
                </Typography>
                <Typography textAlign={'right'} fontStyle={'Helvetica'} color={'#14254C'} paragraph gutterBottom variant="subtitle1" component="div" mt={0} mb={0}>
                    {streetAddress}
                </Typography>
                <Typography textAlign={'right'} fontStyle={'Helvetica'} color={'#14254C'} paragraph gutterBottom variant="subtitle1" component="div" mt={0}>
                    {city}, {state} {zipCode}
                </Typography>
                {
                    !filterType &&
                    <Typography variant="body2" color="text.secondary" >
                        {sportsAvailable.toString()}
                    </Typography>
                }
            </CardContent>

            {
                filterType === 'birthday-parties' &&
                <CardActions>
                    <Box sx={{ width: '95%', marginBottom: '2.0vh'}} display="flex" justifyContent="center" alignItems="center">
                        <Button variant="contained" onClick={handleClick}>
                            View Parties
                        </Button>
                    </Box>
                </CardActions>
            }

        </Card >
    )
}

export default FacilityCard;