import { SearchTimeRangeContainer } from "./search-time-range.styles";
import { Fragment, useContext, useEffect, useState } from "react";
import TwoButtonBar from '../../two-button-bar/two-button-bar.component';
import MaterialUITimePicker from "../../material-ui/time-picker/material-ui-time-picker.component";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { UserContext } from "../../../contexts/user.context";
import { formatReservationLengthOrDuration } from "../../../utils/reservation-utils/reservation-utils";
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

const SearchTimeRange = ({ showTimeRangeInput, timeRangeWasSelected }) => {

    const { userData } = useContext(UserContext);
    const { reservationSearchData, setReservationSearchData } = useContext(SearchForReservationContext);
    const { startTime, endTime } = reservationSearchData;
    const [duration, setDuration] = useState(reservationSearchData.reservationLength);

    useEffect(() => {

        const setReservationTimes = () => {
            let request = Object.assign({}, reservationSearchData);

            request.startTime = dayjs(`${searchDate.format("YYYY-MM-DD")} 6:00 AM`, 'YYYY-MM-DD h:mm A');
            request.endTime = dayjs(`${searchDate.format("YYYY-MM-DD")} 10:00 PM`, 'YYYY-MM-DD h:mm A');
            setReservationSearchData(request);
        }
        
        const { searchDate } = reservationSearchData;
        if (searchDate.isAfter(dayjs().startOf('day'))) {
            setReservationTimes();
        }

    }, [reservationSearchData.searchDate]);

    const selectStartTime = (time) => {
        let end = endTime;
        if (time.isAfter(endTime)) {
            end = time;
        } 
        timeRangeWasSelected(time, end, duration);
    }

    const selectEndTime = (time) => {
        let start = startTime;
        if (time.isBefore(startTime)) {
            start = time;
        } 
        timeRangeWasSelected(start, time, duration);
    }

    const onDurationChange = (event) => {
        let dur = formatReservationLengthOrDuration(event.target.value, duration, false);
        setDuration(parseFloat(dur.length));

        timeRangeWasSelected(startTime, endTime, dur);
    }

    const doneButtonClicked = () => {
        if (timeRangeWasSelected) {
            timeRangeWasSelected(startTime, endTime, duration, false);
        }
    }

    const clearButtonClicked = () => {

        const tenPm = dayjs().startOf('day').add(22, 'hour');
        const startOfHour = dayjs().startOf('hour');
        if (startOfHour.add(1, 'hour').isAfter(tenPm)){
            timeRangeWasSelected(startOfHour, startOfHour.add(1, 'hour'), 1.0);
        } else {
            timeRangeWasSelected(startOfHour, tenPm, 1.0);
        }

    }

    return (
        <Fragment>
            {
                showTimeRangeInput &&
                <SearchTimeRangeContainer>
                    <MaterialUITimePicker headerText="Earliest Rental Start Time" views={['hours', 'minutes']} format={'h:mm A'} value={startTime} onChange={selectStartTime} minuteInterval={userData && userData.isFacilityUserAccount ? 15 : 30} />
                    <MaterialUITimePicker headerText="Latest Rental Start Time" views={['hours', 'minutes']} format={'h:mm A'} value={endTime} onChange={selectEndTime} minuteInterval={userData && userData.isFacilityUserAccount ? 15 : 30}/>
                    <TextField  id="duration" variant="outlined" label="Duration (Hours)" required   type="number"  onChange={onDurationChange} name="duration" value={duration} 
                    inputProps={{
                        pattern: "^\d*\.\d{2}$",
                        step: userData && userData.isFacilityUserAccount ? "0.25" : "0.5",
                        max: "24",
                        min: "1"
                    }}></TextField>
                    <div className="button-bar">
                        <TwoButtonBar button1Text={'Reset'} button1Action={clearButtonClicked} button2Text={'Done'} button2Action={doneButtonClicked}></TwoButtonBar>
                    </div>
                </SearchTimeRangeContainer>
            }
        </Fragment>
    )
}

export default SearchTimeRange