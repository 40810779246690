import styled from 'styled-components';

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
    height: 18%;
    cursor: pointer;

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.5vh;
        text-align: center;
        background-color: #14254C;
        border-radius: 5px;
        margin-left: 0.25vw;
        margin-right: 0.25vw;
        .button-text {
            margin-left: 0.5vw;
            margin-right: 0.5vw;
            color: white;
        }
        cursor: pointer;
    }
`