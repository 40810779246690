import StateSelect from '../state-select/state-select.component';
import { AddressFormContainer } from './address-form.styles';
import { TextField, Stack } from "@mui/material";

const AddressForm = ({ handleChange, defaultFormFields, turnOffAutoComplete, disabled = false }) => {

    const { address1, address2, city, state, zipCode } = defaultFormFields;

    const handleAddressChange = (event) => {
        if (event.preventDefault) {
            event.preventDefault();
        }

        if (handleChange) {
            handleChange(event);
        }

    };
    const autoComplete = turnOffAutoComplete ? 'new-password' : 'on'
    return (
        <Stack spacing={1}>
            <TextField disabled={disabled} autoComplete={`${autoComplete}`} fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="facility-customer-address1" variant="outlined" label="Address 1" type="text" name="address1" value={address1} onChange={handleAddressChange} ></TextField>
            <TextField disabled={disabled} autoComplete={`${autoComplete}`} fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="facility-customer-address2" variant="outlined" label="Address 2" type="text" name="address2" value={address2} onChange={handleAddressChange} ></TextField>
            <TextField disabled={disabled} autoComplete={`${autoComplete}`} fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="facility-customer-city" variant="outlined" label="City" type="text" name="city" value={city} onChange={handleAddressChange} ></TextField>
            <StateSelect disabled={disabled} selectState={handleAddressChange} selectedState={state}></StateSelect>
            <TextField disabled={disabled} autoComplete={`${autoComplete}`} fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="facility-customer-zip-code" variant="outlined" label="Zip Code" type="text" name="zipCode" value={zipCode} onChange={handleAddressChange} ></TextField>
        </Stack>

    )

}

export default AddressForm;