import styled from 'styled-components';

export const SearchDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: ${(props) => `${props.$position}`};;
    z-index: 10;
    background-color: white;
    border-radius: 5px;
    box-shadow: ${(props) => `${props.$boxshadow}`};
    margin-left: -1vw;
    margin-top: -1vh;
    gap: 1.0vh;
    padding: 1.5vh;
    `