
import { FacilityViewResContainer } from './facility-view-reservation.styles';
import { getReservation } from '../../../utils/reservation-api/reservation-service';
import { useEffect, useState, useContext } from 'react';
import MySportSpaceAlert from '../../alert/alert.component';
import ViewReservation from './view-reservation.component';
import ViewBlackout from './view-blackout.component';
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context';
import BirthdayPartyViewBooked from '../birthday-party/birthday-party-view-booked.component';
import { GetBookedBirthdayParty } from '../../../utils/birthday-api/birthday-service';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';

const FacilityViewReservation = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [alertMessage, setAlertMessage] = useState(null);
    const { reservationToView, setReservationToView } = useContext(FacilityReservationsContext);
    const [bookedBirthdayParty, setBookedBirthdayParty] = useState(null);

    const getReservationDetails = async () => {
        setIsLoading(true);

        if (reservationToView.reservationType && reservationToView.reservationType === 'birthdayParty') {
            const response = await GetBookedBirthdayParty(reservationToView.reservationEventId);
            const { status, data = {} } = response;

            if (status === 200 && data.status === 'success') {
                setBookedBirthdayParty(data.bookedBirthdayParty);
            } else {
                setAlertMessage("Your reservation details are not available.")
                setBookedBirthdayParty(null);
            }

        } else {
            const reservationResponse = await getReservation(reservationToView.facilityId, reservationToView.isInternalBooking ? null : reservationToView.customerId, reservationToView.dateKey, reservationToView.reservationId);
            if (reservationResponse && reservationResponse.status === 200 && reservationResponse.data.status === 'success') {
                setReservationToView(reservationResponse.data.reservation);
            } else {
                setAlertMessage("Your reservation details are not available.")
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (reservationToView) {
            getReservationDetails();
        }

    }, []);

    const close = () => {
        setReservationToView(null);
    }

    const closeBirthdayParty = () => {
        setBookedBirthdayParty(null);
        setReservationToView(null);
    }

    return (
        <FacilityViewResContainer>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={close} ></MySportSpaceAlert>
            <ViewReservation isOpen={!isLoading && reservationToView && !reservationToView.isBlackOutPeriod}></ViewReservation>
            {
                !isLoading && reservationToView && reservationToView.isBlackOutPeriod &&
                <ViewBlackout reservation={reservationToView}></ViewBlackout>
            }
            {
                !isLoading && bookedBirthdayParty &&
                <BirthdayPartyViewBooked bookedParty={bookedBirthdayParty} close={closeBirthdayParty} ></BirthdayPartyViewBooked>
            }

        </FacilityViewResContainer >
    )
}

export default FacilityViewReservation