import { TextField, Button } from '@mui/material';
import { BookingResevationSummaryContainer } from './booking-reservation-summary.styles';
import { useContext, useState } from 'react';
import { SearchForReservationContext } from '../../../contexts/search-for-reservation/search-for-reservation.context';
import AlertWithTitle from '../../alert-with-title/alert-with-title..component';
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';


const BookingReservationSummary = ({ bookTheReservations }) => {

    const { totalWithServiceCharge, totalWithoutServiceCharge, totalServiceCharge } = useContext(SearchForReservationContext);
    const [title, setTitle] = useState(null);
    const [cancelMessage, setCancelMessage] = useState(null);

    const navigate = useNavigate();

    const bookReservations = () => {

        if (bookTheReservations) {
            bookTheReservations(title);
        }

    }

    const cancelReservationsBooking = () => {
        setCancelMessage('Are you sure you want to cancel this reservation booking?')
    }

    const handleChange = (event) => {
        const { value } = event.target;
        setTitle(value);
    };

    const goToHomePage = () => {
        navigate('/');
    }

    const clearCancelAlert = () => {
        setCancelMessage(null);
    }

    return (
        <BookingResevationSummaryContainer>
            {
                cancelMessage &&
                <AlertWithTitle okAction={goToHomePage} okButtonText={'Yes'} cancelButtonText={"No"} cancelAction={clearCancelAlert} title="Cancel Booking?" message={cancelMessage}></AlertWithTitle>
            }
            <div className='reservation-title-container'>
                <TextField required fullWidth id={`id-${title}`} variant="outlined" label="Reservation Title" type="text" name="reservationtitle" value={title} onChange={handleChange}>
                </TextField>
                {
                    !title &&
                    <FormHelperText error id="component-error-text">
                        Reservation Title is required
                    </FormHelperText>
                }
            </div>

            <div className='separator'></div>
            <div className='totals-container'>
                <div className='subs-container'>
                    <div className='total-title-and-value'>
                        <span className='subtotal-header'>
                            Reservations(s) Total
                        </span>
                        <span className='subtotal'>${totalWithoutServiceCharge ? totalWithoutServiceCharge.toFixed(2) : '0.00'}</span>
                    </div>
                    <div className='total-title-and-value'>
                        <span className='service-charge-header'>
                            Total Service Charges
                        </span>
                        <span className='service-charge-total'>${totalServiceCharge ? totalServiceCharge.toFixed(2) : '0.00'}</span>
                    </div>
                    <div className='total-separator'>
                        <div className='ttl-sep'></div>
                    </div>
                    <div className='total-title-and-value'>
                        <span className='total-header'>
                            Total
                        </span>
                        <span className='total'>${totalWithServiceCharge ? totalWithServiceCharge.toFixed(2) : '0.00'}</span>
                    </div>
                </div>
            </div>

            <div className='separator'></div>
            <div className='button-container'>
                <Button variant='standard' onClick={cancelReservationsBooking} >Cancel</Button>
                <Button variant='contained' onClick={bookReservations} >Book Reservations</Button>
            </div>
        </BookingResevationSummaryContainer >
    )
}

export default BookingReservationSummary;