import styled from 'styled-components';

export const FacilityReservationUpdateFormOfPaymentContainer = styled.div`
position: absolute;
width: 100%;
height: 100%;
display: flex;
flex-direction: row;
background-color: white;
border-radius: 5px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
background-color: RGBA(20,37,76,0.46);
z-index: 12;
`

export const FacilityPayFormContainer = styled.div`

position: relative;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
height: 50%;
display: grid;
grid-template-columns: auto;
grid-template-rows: 20% 20% 60% ;
background-color: white;
border-radius: 5px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);


.header {
    width: 100%;
    font-size: 2.0vh;
    font-weight: bold;
    margin-top: 2.0vh;
    margin-bottom: 2.0vh;
    margin-left: 1.0vw;
}

.form-of-payment-row {
    margin-left: 1.5vw;
    margin-right: 1.5vw;
}

.bottom-button-bar {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    line-height: 2.0rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`