import { PaginationBarContainer } from "./pagination-bar.styles";

const PaginationBar = ({ numberOfPages, currentPage, setCurrentPage, showMore, setShowMore }) => {

    const pageNumbers = [...Array(numberOfPages + 1).keys()].slice(1);

    const prevPage = async () => {
        if (currentPage !== 1)
            setCurrentPage(currentPage - 1)
    }

    const nextPage = async () => {
        if (currentPage !== numberOfPages)
            setCurrentPage(currentPage + 1)
    }

    const loadMore = async () => {
        if (setShowMore) {
            setShowMore();
        }
    }

    return (
        <PaginationBarContainer>
            <ul className="page-list">
                {
                    currentPage !== 1 &&
                    <li className="page-item">
                        <a className="page-link" onClick={prevPage} href='#'>Previous</a>
                    </li>
                }
                {
                    pageNumbers.map(pgNumber => (
                        <li key={pgNumber}
                            className="page-item">

                            <a onClick={() => setCurrentPage(pgNumber)} className={`page-link${currentPage == pgNumber ? '-active' : ""}`} href='#'>
                                {pgNumber}
                            </a>
                        </li>
                    ))
                }
                {currentPage !== numberOfPages && numberOfPages > 1 &&
                    <li className="page-item">
                        <a className="page-link" onClick={nextPage} href='#'>Next</a>
                    </li>
                }
                {
                    showMore &&
                    <li className="page-item">
                    <a className="page-link" onClick={loadMore} href='#'>More</a>
                </li>
                }
            </ul>

        </PaginationBarContainer>
    )
}

export default PaginationBar