import styled from 'styled-components';

export const CustomerSignupContainer = styled.div`
    display: flex;
    flex-direction: column;

    .form-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: auto;
        margin-top: 2.0vh;
        width: 75%;
    }

    .button-container {
        margin: auto;
    }
`