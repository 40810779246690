import baseApiService from "../base-api/base-api-service";

export const rescheduleReservation = async (originalReservation, reservationQuoteId, paymentMethodId, overridePriceIncrease, isFacilityReschedule) => {

   const RescheduleReqest = {
      customerId: originalReservation.customerId,
      facilityId: originalReservation.facilityId,
      rescheduleResId: originalReservation.reservationId,
      reservationQuoteId: reservationQuoteId,
      paymentMethodId: paymentMethodId,
      isFacilityReschedule: isFacilityReschedule,
      rescheduleResStartDate: originalReservation.dateKey,
      overridePriceIncrease: overridePriceIncrease
   }

   try {
      let url = `bookings/reservation-reschedule`;
      return await baseApiService.post(url, RescheduleReqest);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const isReservationAvailable = async (facilityId, rescheduleResId, rescheduleResStartDate, isFacilityReschedule) => {
   const RescheduleReqest = {
      facilityId: facilityId,
      rescheduleResId: rescheduleResId,
      rescheduleResStartDate: rescheduleResStartDate,
      isFacilityReschedule: isFacilityReschedule
   }

   try {
      let url = `bookings/is-reschedule-allowed`;
      return await baseApiService.post(url, RescheduleReqest);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const getReservation = async (facilityId, customerId, reservationDate, reservationId) => {

   try {
      let url = `reservations/reservation?facilityId=${facilityId}&reservationDate=${reservationDate}&reservationId=${reservationId}`;
      if (customerId) {
         url = `${url}&customerId=${customerId}`;
      }
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const createBlackout = async (blackoutInput, facilityId) => {

   const areas = [];
   for (const area of blackoutInput.selectedAreas) {
      if (area.subAreas || !area.parentAreaId) {
         areas.push({
            id: area.id,
            name: area.name,
            type: "main"
         })
      } else {
         const parentAreaExists = blackoutInput.selectedAreas.find(selectedArea =>
            selectedArea.id === area.parentAreaId);
         if (!parentAreaExists) {
            areas.push({
               id: area.id,
               name: area.name,
               type: "sub",
               parentAreaId: area.parentAreaId
            })
         }
      }
   }

   const CreateBlackoutRequest = {
      facilityId: facilityId,
      areas: areas,
      startDate: blackoutInput.startDate.format("YYYY-MM-DD"),
      startTime: blackoutInput.startTime.format("h:mm A"),
      endTime: blackoutInput.endTime.format("h:mm A"),
      repeatsUntilDate: blackoutInput.repeatsUntilDate ? blackoutInput.repeatsUntilDate.format("YYYY-MM-DD") : null,
      repeatsDaysOfWeek: blackoutInput.repeatsDaysOfWeek,
      repeatExceptionDates: blackoutInput.repeatExceptionDates,
      notes: blackoutInput.notes,
      title: blackoutInput.title,
      subTitle: blackoutInput.subTitle,
      reservedForCustomers: blackoutInput.reservedForCustomers.length > 0 ? blackoutInput.reservedForCustomers : null,
      reservedForMemberships: blackoutInput.reservedForMemberships.length > 0 ? blackoutInput.reservedForMemberships : null
   }

   try {
      let url = `reservations/blackouts`;
      return await baseApiService.post(url, CreateBlackoutRequest);
   } catch (err) {
      console.log(err);
      return err;
   }
}