import styled from 'styled-components';

export const BlackoutCreateContainer = styled.div
`
position: absolute;
top: 10vh;
width: 100%;
height: 87vh;
display: flex;
flex-direction: column;
background-color: white;
border-radius: 5px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
margin: 1.0rem;
overflow: scroll;

.bottom-button-bar {
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 2.0rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

`
export const BlackoutCreateRow = styled.div`
    position: relative;
    display: flex;  
    justify-content: ${(props) => `${props.$justifycontent}`};;
    column-gap: 1.0vw;
    margin-left: 2.0rem;
    margin-right: 1rem;
    margin-top: 1.0vh;
    line-height: 1.5rem;
      `

export const DatesRow = styled.div`
    display: grid;  
    column-gap: 1.0vw;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 2.0rem;
    margin-right: 1rem;
    margin-top: 1.0vh;
    line-height: 1.5rem;
`

export const RepeatsRow = styled.div`
    display: grid;  
    column-gap: 0.25vw;
    grid-template-columns: 49% 49%;
    margin-left: 2.0rem;
    margin-right: 1rem;
    margin-top: 1.0vh;
    line-height: 2.5rem;
`