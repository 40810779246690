import { createContext, useEffect, useReducer } from "react";
import { onAuthStateChangedListener, getUserData, signOutUser } from '../utils/firebase/firebase.utils';
import { createAction } from "../utils/reducer/reducer.utils";

//as the actual value you want to access
export const UserContext = createContext({
    currentUser: null,
    setCurrentUser: () => null,
    userData: false,
    setUserData: () => { },
    userIsLoading: false,
    setUserIsLoading: () => { },
    hasReservationPrivileges: false,
    setHasReservationPrivileges: () => { },
    isAdmin: false,
    setIsAdmin: () => { },
    isInactive: false,
    setIsInactive: () => { }
});

const isReservationOrAdmin = (userData) => {
    return userData && (userData.userPermission === 'Admin' || userData.userPermission === 'Reservation');
}

const isAdminUser = (userData) => {
    return userData && (userData.userPermission === 'Admin');
}

const isInactiveUser = (userData) => {
    return userData && (userData.userPermission === 'Inactive');
}

export const USER_ACTION_TYPES = {
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    SET_USER_IS_LOADING: 'SET_USER_IS_LOADING'
}

const INITIAL_STATE = {
    currentUser: null,
    userData: null,
    hasReservationPrivileges: false,
    isAdmin: false,
    isInactive: false,
    userIsLoading: true
}

const userReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case USER_ACTION_TYPES.SET_CURRENT_USER:
            return {
                ...state, //this keeps everything else and doesn't overwrite
                ...payload
            }
        case USER_ACTION_TYPES.SET_USER_IS_LOADING:
            return {
                ...state,
                userIsLoading: payload
            }
        default:
            throw new Error(`Unhandled type ${type}`);
    }

}

export const UserProvider = ({ children }) => {
    const [{ currentUser, userData, hasReservationPrivileges, isAdmin, isInactive, userIsLoading }, dispatch] = useReducer(userReducer, INITIAL_STATE);

    const setCurrentUser = (user, userData) => {
        const hasReservationPrivileges = isReservationOrAdmin(userData);
        const isAdmin = isAdminUser(userData);
        const isInactive = isInactiveUser(userData);
        dispatch(createAction(USER_ACTION_TYPES.SET_CURRENT_USER, { currentUser: user, userData: userData, hasReservationPrivileges: hasReservationPrivileges, isAdmin: isAdmin, isInactive: isInactive }));
    }

    const setUserIsLoading = (bool) => {
        dispatch(createAction(USER_ACTION_TYPES.SET_USER_IS_LOADING, bool));
    }

    const value = { userData, currentUser, setCurrentUser, userIsLoading, setUserIsLoading, hasReservationPrivileges, isAdmin, isInactive };

    //useEffect is called when compent mounts
    useEffect(() => {
        const unsubscribe = onAuthStateChangedListener(async (user) => {
            setUserIsLoading(true);
            //use this once we put in the ability for a facility or customer to sign up 
            //on the web portal
            if (user && user.emailVerified) {
                const theUserData = await getUserData(user);
                if (isInactiveUser(theUserData)){
                    setCurrentUser(null, null);
                    await signOutUser();
                    alert("This account has been inactivated by the facility.");
                } else {
                    setCurrentUser(user, theUserData);
                }
                
            } else {
                setCurrentUser(null, null);
            }

            setUserIsLoading(false);
        })
        //this is returned when the component unmounts
        return unsubscribe;
    }, []);

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}