import { Fragment } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';

const FacilityAreaRow = ({ facilityArea, row, selectedFacilityAreas, selectFacilityArea }) => {

    const clickCell = () => {
        selectFacilityArea(facilityArea)
    }
    console.log(selectedFacilityAreas)
    let selected = false;
    if (selectedFacilityAreas && selectedFacilityAreas.includes(facilityArea.id)) {
        selected = true;
    }

    return (
        <Fragment>
            {
                !facilityArea.parentAreaId &&
                <ListItem key={facilityArea.id} divider={true} onClick={clickCell} disablePadding data-id={facilityArea.id}>
                    <ListItemText sx={{ my: 0, ml: 1 }} primary={facilityArea.name} primaryTypographyProps={{
                        fontWeight: 'bold',
                        letterSpacing: 0,
                        color: '#14254C'
                    }} ></ListItemText>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={selected}
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                </ListItem>
            }
            {
                facilityArea.parentAreaId &&
                <ListItem key={facilityArea.id} divider={false} onClick={clickCell} data-id={facilityArea.id} disablePadding>
                    <ListItemText sx={{ pl: 4 }} primary={facilityArea.name}></ListItemText>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={selected}
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                </ListItem>
            }
        </Fragment>
    )

}

export default FacilityAreaRow