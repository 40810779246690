import BottomButtonBar from "../bottom-button-bar/bottom-button-bar.component";
import { AlertContainer } from './alert-accept-booking-terms.styles';

const AlertAcceptBookingTerms = ({ showChargeInfo, okAction, cancelAction, isOpen = true }) => {

    return isOpen ? (
        <AlertContainer tabIndex={-1}>
            <div className="alert-box" tabIndex={-1}>
                <span className="alert-title">Book Reservation(s)?</span>
                <div className="separator"></div>
                {showChargeInfo &&
                    <ul>
                        <li className="message">
                            <span>Your credit card will be billed 48 hours before each reservation.</span>
                        </li>
                        <li className="message">
                            <span>For rentals booked within 48 hours of reservation start time, your card will be billed immediately.</span>
                        </li>
                        <li className="message">
                            <span>For invoice customers, your invoice will be updated.</span>
                        </li>
                    </ul>
                }
                <p className="centered-message" >
                    <br></br>
                    <b>By booking this reservation, you are agreeing to comply with the Facility's Policies and pay any applicable cancellation and/or service fees.</b>
                </p>
                <BottomButtonBar okButtonText={'Book Reservation(s)'} okAction={okAction} cancelButtonText={"Cancel"} cancelAction={cancelAction}></BottomButtonBar>
            </div>
        </AlertContainer>
    ) : (
        <></>
    );
}

export default AlertAcceptBookingTerms;