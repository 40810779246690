import { Fragment, useContext, useEffect, useState } from "react";
import { Modal, Paper, Stack, Typography, Box, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../../../utils/data-grid-utils/data-grid-utilities";
import { FacilityAreaContext } from "../../../contexts/facility/facility-area-provider.context";
import { UserContext } from "../../../contexts/user.context";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import AreaSetup from "./area-setup.component";
import { AddCircleOutline } from "@mui/icons-material";
import AddAreaDialog from "./area-add-dialog.component";
import { v4 as uuid } from 'uuid';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MySportSpaceAlert from "../../alert/alert.component";
import { updateSearchStatus } from "../../../utils/firebase/facility-area-utils";
import CopyPricingDialog from "./copy-pricing-dialog.component";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyAreaPricing } from "../../../utils/facility-api/facility-service";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import AreaCopyPricing from "./area-copy-pricing.componet";
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { saveFacilityAreaReorder } from "../../../utils/firebase/facility-firebase-utils";

const Decimal = require('decimal.js');

function getRowId(row) {
    return row.id;
}

const AreaManageList = () => {

    const { userIsLoading, isAdmin, userData } = useContext(UserContext);
    const { allFacilityAreas, facilityAreasAreLoading } = useContext(FacilityAreaContext);
    const [orderedFacilityAreas, setOrderedFacilityAreas] = useState([]);
    const [selectedAreaIds, setSelectedAreasIds] = useState([]);
    const [areasForPricingCopy, setAreasForPricingCopy] = useState([]);
    const [updateSearchStatusType, setUpdateSearchStatusType] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const [showAreaAreaDialog, setShowAddAreaDialog] = useState(false);
    const [showCopyPricingDialog, setShowCopyPricingDialog] = useState(false);
    const [showInactivateMessage, setShowInactivateMessage] = useState(false);
    const [areaToCopyPricing, setAreaToCopyPricing] = useState(null);
    const [showCopyInstructions, setShowCopyInstructions] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [isReordering, setIsReordering] = useState(false);

    useEffect(() => {

        const setUpFacilityAreas = (areas) => {
            setOrderedFacilityAreas(areas);
        }

        setUpFacilityAreas(allFacilityAreas);

    }, [allFacilityAreas]);

    const moveRowUp = (e, row) => {
        e.stopPropagation();
        if (!row.parentAreaId) {
            moveMainAreaRow(row, true);
        } else {
            moveSubAreaRow(row, true);
        }
    };

    const moveRowDown = (e, row) => {
        e.stopPropagation();
        if (!row.parentAreaId) {
            moveMainAreaRow(row, false);
        } else {
            moveSubAreaRow(row, false);
        }
    };

    const moveSubAreaRow = (subArea, movingUp) => {

        const wholeValue = parseFloat(`${subArea.sortOrder}`.split('.')[0]);
        const decimalValue = `${subArea.sortOrder}`.split('.')[1];
        let increment = decimalValue.length === 1 ? 0.1 : 2 ? .01 : .001;
        let decrement = decimalValue.length === 1 ? -0.1 : 2 ? -.01 : -.001;
        const areaIncrement = movingUp ? increment : decrement;

        const subAreasForMainArea = orderedFacilityAreas.filter((area) => {
            return area.parentAreaId === subArea.parentAreaId;
        });

        const decSortOrder = new Decimal(subArea.sortOrder).minus(new Decimal(areaIncrement));
        // let newSortOrder = parseFloat(subArea.sortOrder) - areaIncrement;
        let newSortOrder = parseFloat(decSortOrder.toFixed(decimalValue.length));
        if (newSortOrder <= wholeValue) {
            newSortOrder = subAreasForMainArea[subAreasForMainArea.length - 1].sortOrder;
        }

        if (newSortOrder > subAreasForMainArea[subAreasForMainArea.length - 1].sortOrder) {
            newSortOrder = subAreasForMainArea[0].sortOrder;
        }

        const replaceThisSubArea = subAreasForMainArea.filter(a => a.id !== subArea.id && a.sortOrder === newSortOrder)[0];
        replaceThisSubArea.sortOrder = subArea.sortOrder;

        subArea.sortOrder = newSortOrder;

        const otherSubAreas = subAreasForMainArea.filter(s => s.id !== subArea.id && s.id !== replaceThisSubArea.id);
        otherSubAreas.push(subArea);
        otherSubAreas.push(replaceThisSubArea);

        let allOtherAreas = orderedFacilityAreas.filter(s => s.parentAreaId !== subArea.parentAreaId);
        allOtherAreas = allOtherAreas.concat(otherSubAreas);

        allOtherAreas.sort((area1, area2) => {
            if (area1.sortOrder < area2.sortOrder) {
                return -1;
            }

            if (area1.sortOrder > area2.sortOrder) {
                return 1;
            }

            return 0;
        });
        setOrderedFacilityAreas(allOtherAreas);

    }

    const moveMainAreaRow = (facilityArea, movingUp) => {
        const areaIncrement = movingUp ? 1 : -1;
        let newSortOrder = facilityArea.sortOrder - areaIncrement;
        if (newSortOrder < 1) {
            newSortOrder = 1;
        }
        const mainAreasOnly = orderedFacilityAreas.filter((area) => {
            return !area.parentAreaId;
        })

        if (newSortOrder > mainAreasOnly.length) {
            newSortOrder = mainAreasOnly.length;
        }

        const replaceThisFacilityArea = orderedFacilityAreas.filter(a => a.id !== facilityArea.id && a.sortOrder === newSortOrder && !a.parentAreaId)[0];
        replaceThisFacilityArea.sortOrder = facilityArea.sortOrder;

        let otherAreas = orderedFacilityAreas.filter((mArea) => {
            return mArea.id !== facilityArea.id && mArea.id !== replaceThisFacilityArea.id;
        });

        if (replaceThisFacilityArea.subAreaList) {
            const newReplaceSubAreaList = []
            for (const sArea of replaceThisFacilityArea.subAreaList) {
                sArea.sortOrder = replaceThisFacilityArea.sortOrder + .001;
                newReplaceSubAreaList.push(sArea);
                otherAreas = otherAreas.filter((a) => a.id !== sArea.id);
            }

            replaceThisFacilityArea.subAreaList = newReplaceSubAreaList;
        }

        if (facilityArea.subAreaList) {
            const newSubAreaList = [];
            for (const sArea of facilityArea.subAreaList) {
                sArea.sortOrder = newSortOrder + .001;
                newSubAreaList.push(sArea);
                otherAreas = otherAreas.filter((a) => a.id !== sArea.id);
            }

            facilityArea.subAreaList = newSubAreaList;
        }
        facilityArea.sortOrder = newSortOrder;

        otherAreas.push(facilityArea);
        otherAreas.push(replaceThisFacilityArea);

        if (facilityArea.subAreaList) {
            otherAreas = otherAreas.concat(facilityArea.subAreaList);
        }

        if (replaceThisFacilityArea.subAreaList) {
            otherAreas = otherAreas.concat(replaceThisFacilityArea.subAreaList);
        }

        otherAreas.sort((area1, area2) => {
            if (area1.sortOrder < area2.sortOrder) {
                return -1;
            }

            if (area1.sortOrder > area2.sortOrder) {
                return 1;
            }

            return 0;
        });
        setOrderedFacilityAreas(otherAreas);
    }

    const columns = [
        {
            field: 'name',
            renderHeader: (value, row) => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'Area'}
                    </Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '1.0vw' }} ml={params.row.isSubArea ? 2 : 0} fontWeight={params.row.isSubArea ? 'normal' : 'bold'}>
                            {params.value}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'type',
            renderHeader: () => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'Type'}
                    </Typography>
                </Box>
            ),
            flex: 0.25,
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '0.85vw' }}>
                            {params.value}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'searchStatus',
            flex: 0.25,
            renderHeader: () => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'Status'}
                    </Typography>
                </Box>
            ),
            valueGetter: (value, row) => {
                if (row.searchStatus) {
                    return `${row.searchStatus.substring(0, 1).toUpperCase()}${row.searchStatus.substring(1)}`
                } else {
                    return "Active";
                }

            },
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '0.85vw' }}>
                            {params.value}
                        </Typography>
                    </Box>
                );
            }
        },
        {
            field: 'sports',
            renderHeader: () => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'Sports'}
                    </Typography>
                </Box>
            ),
            flex: 1,
            valueGetter: (value, row) => {
                return row.sports ? row.sports.toString() : "Unavailable"
            },
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '0.85vw' }}>
                            {params.value}
                        </Typography>
                    </Box>
                );
            }
        },
        {
            field: 'viewButton',
            headerName: '',
            description: '',
            sortable: false,
            flex: 0.25,
            renderCell: (params) => {

                const mainAreasOnly = orderedFacilityAreas.filter((area) => {
                    return !area.parentAreaId;
                })

                const lastMainAreaId = mainAreasOnly[mainAreasOnly.length - 1].id;
                const firstMainAreaId = mainAreasOnly[0].id;
                return (isReordering ?
                    <Stack spacing={1} mt={1} direction={'row'}>
                        {
                            params.row.id !== firstMainAreaId &&
                            <IconButton
                                size="medium"
                                aria-label="move-area-up"
                                onClick={(e) => moveRowUp(e, params.row)}
                            >
                                <ArrowUpwardOutlinedIcon sx={{ fontSize: '1.25vw' }} fontSize="medium" />
                            </IconButton>
                        }
                        {
                            params.row.id !== lastMainAreaId &&
                            <IconButton
                                size="medium"
                                aria-label="move-area-down"
                                onClick={(e) => moveRowDown(e, params.row)}
                            >
                                <ArrowDownwardOutlinedIcon sx={{ fontSize: '1.25vw' }} fontSize="medium" />
                            </IconButton>
                        }

                    </Stack>
                    : <Stack></Stack>
                );
            },
        }
    ];

    const selectArea = (ids) => {

        const areas = orderedFacilityAreas.filter((a) => {
            return a.id === ids[0]
        });

        if (areas && areas.length > 0) {
            const selectedArea = areas[0];
            setSelectedArea(selectedArea);
        }
    }

    const setupAreaIds = (ids) => {

        if (ids.length > selectedAreaIds.length) {
            const addAreaId = ids[ids.length - 1];
            const areas = orderedFacilityAreas.filter((a) => {
                return a.id === addAreaId
            });

            const areaSelected = areas[0];

            if (areaSelected.subAreaList) {
                for (const a of areaSelected.subAreaList) {
                    if (!ids.includes(a.id)) {
                        ids.push(a.id);
                    }
                }
            }
        }

        setSelectedAreasIds(ids);
    }

    const setupAreasForPricingCopy = (ids) => {

        setSelectedAreasIds(ids);

        const areasToCopyTo = [];
        for (const id of ids) {
            const areas = orderedFacilityAreas.filter((a) => {
                return a.id === id
            });

            const areaForCopy = areas[0];
            areasToCopyTo.push({
                areaId: areaForCopy.id,
                areaParentId: areaForCopy.parentAreaId
            })
        }
        setAreasForPricingCopy(areasToCopyTo);
    }

    const closeViewArea = () => {
        setSelectedArea(null);
    }

    const openAddAreaDialog = () => {
        if (isAdmin) {
            setShowAddAreaDialog(true);
        } else {
            setAlertMessage({ title: "Not Authorized", message: "You are not authorized to add areas" });
        }
    }

    const closeAddAreaDialog = () => {
        setShowAddAreaDialog(false);
    }

    const addNewArea = (isMultipleArea, areaToCopy) => {
        setShowAddAreaDialog(false);
        if (!areaToCopy) {
            const skeletonArea = {
                id: uuid(),
                name: "",
                membershipsOnly: false,
                retailOnly: false,
                sports: [],
                type: isMultipleArea ? "Multiple Area" : "Single Area",
                defaultpricing: [
                    { dayOfWeek: "0", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "1", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "2", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "3", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "4", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "5", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "6", halfHourPrice: 25.0, hourlyPrice: 50.0, }
                ],
                sortOrder: orderedFacilityAreas ? orderedFacilityAreas.length + 1 : 1,
                isNewArea: true
            }

            setSelectedArea(skeletonArea);
        } else {
            setSelectedArea(areaToCopy);
        }
    }

    const inactivateAreas = (e) => {

        if (isAdmin) {
            setUpdateSearchStatusType('inactive');
            setShowInactivateMessage(true);
        } else {
            setAlertMessage({ title: "Not Authorized", message: "You are not authorized to inactivate areas" });
        }

    }

    const activateAreas = (e) => {
        if (isAdmin) {
            setUpdateSearchStatusType('active');
            setShowInactivateMessage(true);
        } else {
            setAlertMessage({ title: "Not Authorized", message: "You are not authorized to activate areas" });
        }
    }

    const startUpdateStatus = () => {
        if (!selectedAreaIds || selectedAreaIds.length <= 0) {
            setAlertMessage({ title: "Area Needed", message: 'You must select at least 1 area to inactive' });
            return;
        } else {

            setIsLoading(true)

            for (const areaId of selectedAreaIds) {
                for (const fArea of orderedFacilityAreas) {
                    if (fArea.id === areaId) {
                        updateSearchStatus(userData.facilityId, areaId, fArea.parentAreaId, updateSearchStatusType);
                        break;
                    }
                }
            }

            setIsLoading(false);
        }
        setUpdateSearchStatusType(null);
        setSelectedAreasIds([]);
        setAlertMessage({ title: "Search Status Updated", message: "The area search status have been updated" });

    }

    const cancelInactive = () => {
        setUpdateSearchStatusType(null);
        setShowInactivateMessage(false);
        setSelectedAreasIds([]);
    }

    const closeSearchStatusAlert = () => {
        setShowInactivateMessage(false);
    }

    const cancel = () => {

        if (updateSearchStatusType) {
            setUpdateSearchStatusType(null);
        }

        if (selectedAreaIds.length > 0) {
            setSelectedAreasIds([]);
        }

        if (AreaCopyPricing) {
            setAreaToCopyPricing(null);
        }

        if (isReordering) {
            setIsReordering(false);
        }
    }

    const openCopyPricingDialog = () => {
        if (isAdmin) {
            setShowCopyPricingDialog(true);
        } else {
            setAlertMessage({ title: "Not Authorized", message: "You are not authorized to copy pricing." });
        }
    }

    const closeCopyPricingDialog = () => {
        setShowCopyPricingDialog(false);
    }

    const copyPricing = (area, copyDayOfWeek, copyExceptions) => {
        if (area) {
            setAreaToCopyPricing({
                areaIdToCopy: area.id,
                areaIdToCopyParentId: area.parentAreaId,
                copyDayOfWeek: copyDayOfWeek,
                copyPricingExceptions: copyExceptions,
            });
            setShowCopyPricingDialog(false);
            setShowCopyInstructions(true);
        }
    }

    const closeCopyInstructions = () => {
        setShowCopyInstructions(false);
    }

    const copyThePricing = async () => {

        setIsLoading(true);

        const response = await copyAreaPricing(userData.facilityId, areasForPricingCopy, areaToCopyPricing);
        if (response && response.data && response.data.status === 'success') {
            setAlertMessage({
                title: "Area Pricing Copied",
                message: "The area pricing has been copied."
            })
        } else {
            setAlertMessage({
                title: "Area Pricing NOT Copied",
                message: "Something went wrong and the area pricing was NOT copied."
            })
        }

        setIsLoading(false);
        setAreaToCopyPricing(null);
        setSelectedAreasIds([]);
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const startReordering = () => {
        setIsReordering(true);
    }

    const saveReorder = async () => {

        setIsLoading(true);
        const response = await saveFacilityAreaReorder(userData.facilityId, orderedFacilityAreas);
        setIsLoading(false);

        if (response.succeeded) {
            setAlertMessage({
                title: "Saved",
                message: response.message
            })
            setIsReordering(false);
        } else {
            setAlertMessage({
                title: "Reorder Error",
                message: response.message
            })
        }
    }

    return (

        <Box sx={{ marginBottom: '3.0vh', marginLeft: '2.0vw', marginRight: '2.0vw', }} position={'fixed'} top={'75px'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'} justifyContent="flex-start" alignItems="center">
            <MySportSpaceAlert isOpen={showInactivateMessage} title={updateSearchStatusType === 'inactive' ? "Take Areas Offline?" : "Bring Areas Online?"} message={updateSearchStatusType === 'inactive' ? 'This will take the area offline and customers will no longer be able to book.' : "Customers will be able to book this area."} okAction={closeSearchStatusAlert} okButtonText={'Yes'} cancelAction={cancelInactive} cancelButtonText={'No'}  ></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={showCopyInstructions} title={"Select Areas"} message={'Select areas and then press "Copy"'} okAction={closeCopyInstructions} okButtonText={'OK'} ></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ""} message={alertMessage ? alertMessage.message : ""} okAction={closeAlert} okButtonText={'OK'} ></MySportSpaceAlert>
            <MySportSpaceLoadingView isOpen={facilityAreasAreLoading || userIsLoading || isLoading}></MySportSpaceLoadingView>
            <CopyPricingDialog isOpen={showCopyPricingDialog} copyPricing={copyPricing} close={closeCopyPricingDialog} ></CopyPricingDialog>
            <AddAreaDialog isOpen={showAreaAreaDialog} close={closeAddAreaDialog} addNewArea={addNewArea}></AddAreaDialog>
            <Modal open={selectedArea ? true : false}>
                <Paper sx={{ overflow: 'scroll' }}>
                    <AreaSetup close={closeViewArea} facilityAreaIn={selectedArea}></AreaSetup>
                </Paper>
            </Modal>
            <Stack sx={{
                height: '100%',
                width: '100%'
            }}>
                <Box mt={1} mb={2} display="flex" justifyContent="flex-start" alignItems="flex-start">
                    <Typography sx={{ width: '50%', fontSize: '2.95vh' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h5" component="div" ml={1} mt={1} mb={1}>
                        Facility Areas
                    </Typography>
                    <Box sx={{ width: '50%' }} display="flex" justifyContent="flex-end" alignItems="center">
                        <Stack spacing={1} direction={'row'}>
                            {
                                !updateSearchStatusType && !areaToCopyPricing && !isReordering &&
                                <Fragment>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<AddCircleOutline />} id={'add-new-area-button'} onClick={openAddAreaDialog} variant="contained" >Add New Area</Button>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<ContentCopyIcon />} id={'copy-area-pricing-button'} onClick={openCopyPricingDialog} variant="contained" >Copy Pricing</Button>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<RefreshOutlinedIcon />} id={'inactivate-area-button'} onClick={activateAreas} variant="contained" >Activate</Button>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<DoDisturbAltIcon />} id={'inactivate-area-button'} onClick={inactivateAreas} variant="contained" >Inactivate</Button>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<ReorderOutlinedIcon />} id={'inactivate-area-button'} onClick={startReordering} variant="contained" >Reorder</Button>
                                </Fragment>
                            }
                            {
                                updateSearchStatusType && !areaToCopyPricing && !isReordering &&
                                <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<SaveOutlinedIcon />} id={'inactivate-area-button'} onClick={startUpdateStatus} variant="contained" >Save</Button>
                            }
                            {
                                isReordering &&
                                <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<SaveOutlinedIcon />} id={'save-reorder-area-button'} onClick={saveReorder} variant="contained" >Save</Button>
                            }
                            {
                                updateSearchStatusType && !areaToCopyPricing &&
                                <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<CancelOutlinedIcon />} id={'camcel-button'} onClick={cancel} variant="contained" >Cancel</Button>

                            }
                            {
                                areaToCopyPricing &&
                                <Fragment>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<ContentCopyIcon />} id={'cancel-button'} onClick={copyThePricing} variant="contained" >Copy</Button>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<CancelOutlinedIcon />} id={'cancel-button'} onClick={cancel} variant="contained" >Cancel</Button>
                                </Fragment>
                            }
                            {
                                isReordering &&
                                <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<CancelOutlinedIcon />} id={'cancel-button'} onClick={cancel} variant="contained" >Cancel</Button>
                            }
                        </Stack>
                    </Box>
                </Box>
                <DataGrid hideFooterPagination sx={{
                    font: 'Helvetica', color: '#14254C',
                    fontWeight: 400, '--DataGrid-overlayHeight': '500px'
                }} getRowId={getRowId} loading={facilityAreasAreLoading} rowCount={1}
                    rows={orderedFacilityAreas} checkboxSelection={updateSearchStatusType || areaToCopyPricing ? true : false} columns={columns} rowSelectionModel={selectedAreaIds} onRowSelectionModelChange={(ids) => {

                        if (!updateSearchStatusType && !areaToCopyPricing) {
                            selectArea(ids);
                        } else if (updateSearchStatusType) {
                            setupAreaIds(ids);
                        } else if (areaToCopyPricing) {
                            setupAreasForPricingCopy(ids);
                        }
                    }}
                    slots={{ noRowsOverlay: CustomNoRowsOverlay }} />
            </Stack>
        </Box>
    )
}

export default AreaManageList;