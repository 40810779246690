import { useState, useContext } from "react";
import { Box, Stack, Button, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { UserContext } from "../../../contexts/user.context";
import { getEditLock, removeEditLock } from "../../../utils/firebase/firebase.utils";
import MySportSpaceAlert from "../../alert/alert.component";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { CustomNoRowsOverlay } from "../../../utils/data-grid-utils/data-grid-utilities";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuid } from 'uuid';
import ReservationTimeAdjustmentSetup from "./reservation-time-adjustment-setup.component";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ReservationTimeAdjustmentsContext } from "../../../contexts/facility/reservation-time-adjustments.context";
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteReservationTimeAdjustments } from "../../../utils/firebase/facility-firebase-utils";

function getRowId(row) {
    return row.id;
}

const ReservationTimeAdjustments = () => {

    const { reservationTimeAdjustments, isLoadingAdjustments } = useContext(ReservationTimeAdjustmentsContext);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [ deleteMessage, setDeleteMessage ] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [editModeAlert, setEditModeAlert] = useState(null);
    const [leaveMessage, setLeaveMessage] = useState(null);
    const { isAdmin, userData } = useContext(UserContext);
    const [editLockName] = useState('ReservationTimeAdjustmentsLock');
    const [addingOrEditing, setAddingOrEditing] = useState(false);
    const [selectedTimeAdjustment, setSelectedTimeAdjustmnet] = useState(null);

    const navigate = useNavigate();

    const columns = [
        {
            field: 'startDate',
            renderHeader: (value, row) => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'Start Date'}
                    </Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '1.0vw' }}>
                            {params.value ? dayjs(params.value, 'YYYY-MM-DD').format("ddd, MMMM D YYYY") : "-"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'endDate',
            renderHeader: (value, row) => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'End Date'}
                    </Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '1.0vw' }}>
                            {params.value ? dayjs(params.value, 'YYYY-MM-DD').format("ddd, MMMM D YYYY") : "-"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'startTime',
            renderHeader: (value, row) => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'Start Time'}
                    </Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '1.0vw' }}>
                            {params.value ? params.value : "-"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'endTime',
            renderHeader: (value, row) => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'End Time'}
                    </Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '1.0vw' }}>
                            {params.value ? params.value : "-"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'hourlyIncrementsOnly',
            renderHeader: (value, row) => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'Hour Increments'}
                    </Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '1.0vw' }}>
                            {params.value ? 'Yes' : "No"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'mandatoryLength',
            renderHeader: (value, row) => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'Mandatory Length'}
                    </Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '1.0vw' }}>
                            {params.value ? `${params.value} minutes` : "No"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'mustRentOnTopOfHour',
            renderHeader: (value, row) => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'Top Of Hour Only'}
                    </Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '1.0vw' }}>
                            {params.value ? 'Yes' : "No"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'minimumReservationLength',
            renderHeader: (value, row) => (
                <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.0vw' }} fontWeight={'bold'} >
                        {'Min Res Length'}
                    </Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box sx={{ height: '100%' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '1.0vw' }}>
                            {params.value ? `${params.value} minutes` : "No"}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    const backToSettingsMenu = () => {
        if (isEditMode) {
            setLeaveMessage("Are you sure you want to leave? Any unsaved information will be lost.");
        } else {
            navigate('/facility/settings');
        }
    }

    const cancelPrompt = () => {
        setEditModeAlert("Are you sure you want to cancel editing?  Any unsaved changes will be lost.");
    }

    const deleteAdjustments = async () => {
        if (selectedTimes.length > 0 ){
            setDeleteMessage("Are you sure you want to delete these reservation adjustments? They cannot be recovered.");
        } else {
            setAlertMessage("You must select at least one adjustment to delete.")
        }
    }

    const deleteTheAdjustments = async () => {
        setIsLoading(true)
        const deleted = await deleteReservationTimeAdjustments(userData.facilityId, selectedTimes);
        if (deleted) {
            setAlertMessage("Your reservation adjustment(s) have been deleted.");
            setDeleteMessage(null);
        } else {
            setAlertMessage("Error - Something went wrong and your adjustment(s) were not deleted.");
        }
        setIsLoading(false);
    }

    const cnacelDelete = async () => {
        setDeleteMessage(null);
    }

    const stayInEditMode = () => {
        setEditModeAlert(null);
    }

    const leaveEditMode = () => {
        setIsEditMode(false);
        setEditModeAlert(null);
        removeEditLock(editLockName, userData);
    }

    const edit = async () => {
        if (userData && userData.isFacilityUserAccount && isAdmin) {
            setIsLoading(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsLoading(false);
            if (retrievedEditLock) {
                setIsEditMode(true);
            } else {
                setAlertMessage(`Another user is currently editing the reservation time adjustments.`);
            }
        } else {
            setAlertMessage(`You are not authorized to edit this facility area.`);
        }
    }

    const leavePage = () => {
        setLeaveMessage(null);
        navigate('/facility/settings');
    };

    const cancelLeavePage = () => {
        setLeaveMessage(null);
    };

    const closeAlert = () => {
        setAlertMessage(null);
    }
    const selectTime = (id) => {
        const filteredAdjustments = reservationTimeAdjustments.filter((adj) => { return adj.id === id });
        setSelectedTimeAdjustmnet(filteredAdjustments[0]);
    }

    const addTimeAdjustment = () => {
        if (userData && userData.isFacilityUserAccount && isAdmin) {
            setAddingOrEditing(true);
        } else {
            setAlertMessage(`You are not authorized to edit this facility area.`);
        }
    }

    const closeSetup = () => {
        setAddingOrEditing(false);
        setSelectedTimeAdjustmnet(null);
    }

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'75px'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
            <MySportSpaceAlert isOpen={leaveMessage ? true : false} title={'Leave Page?'} message={leaveMessage} okButtonText={'Yes'} okAction={leavePage} cancelButtonText={'No'} cancelAction={cancelLeavePage}></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={editModeAlert ? true : false} title={"Cancel Edit?"} message={editModeAlert} okButtonText={'Exit Edit Mode'} okAction={leaveEditMode} cancelButtonText={"Cancel"} cancelAction={stayInEditMode}></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={deleteMessage ? true : false} message={deleteMessage} okButtonText={'Yes'} okAction={deleteTheAdjustments} cancelButtonText={'No'} cancelAction={cnacelDelete} ></MySportSpaceAlert>
            <MySportSpaceLoadingView isOpen={isLoading || isLoadingAdjustments}></MySportSpaceLoadingView>
            <ReservationTimeAdjustmentSetup isOpen={addingOrEditing} timeAdjustmentIn={{ id: uuid() }} closeView={closeSetup} isAdding={true}></ReservationTimeAdjustmentSetup>
            {
                selectedTimeAdjustment &&
                <ReservationTimeAdjustmentSetup isOpen={true} timeAdjustmentIn={selectedTimeAdjustment} closeView={closeSetup} isAdding={false}></ReservationTimeAdjustmentSetup>
            }
            <Stack ml={1} mr={1} sx={{ width: '100%' }} display="flex" alignItems={'center'} >
                <Box marginTop={1} sx={{ width: '100%' }} ml={5} display="flex" justifyContent="flex-start" alignItems="flex-start">
                    <Button onClick={backToSettingsMenu} startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Box>
                <Stack sx={{ width: '96%', height: '90%' }} display={'flex'}>
                    <Stack sx={{ width: '100%' }} direction={'row'} mt={2} mb={2}>
                        <Box sx={{ width: '50%' }}>
                            <Typography sx={{ fontSize: '2.95vh' }} fontWeight={'bold'} fontFamily={'Helvetica'} color={"#14254C"} variant="h5">Reservation Time Adjustments</Typography>
                        </Box>
                        <Box sx={{ width: '50%' }} display="flex" justifyContent="flex-end" alignItems="center">
                            {isEditMode &&
                                <Stack spacing={1} direction={'row'}>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<DeleteIcon />} id={'delete-button'} variant="contained" type="submit" onClick={deleteAdjustments}>Delete</Button>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<CancelOutlinedIcon />} id={'create-button'} variant="contained" onClick={cancelPrompt}>Cancel</Button>
                                </Stack>
                            }
                            {
                                !isEditMode &&
                                <Stack spacing={1} direction={'row'}>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<EditOutlinedIcon />} id={'create-button'} variant="contained" onClick={edit}>Edit</Button>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<AddOutlinedIcon />} id={'add-button'} variant="contained" onClick={addTimeAdjustment}>Add</Button>
                                </Stack>
                            }
                        </Box>
                    </Stack>

                    <DataGrid hideFooterPagination sx={{
                        font: 'Helvetica', color: '#14254C',
                        fontWeight: 400, '--DataGrid-overlayHeight': '500px'
                    }} getRowId={getRowId} loading={isLoading} rowCount={1}
                        rows={reservationTimeAdjustments} checkboxSelection={isEditMode ? true : false} columns={columns} rowSelectionModel={selectedTimes} onRowSelectionModelChange={(ids) => {

                            if (!isEditMode) {
                                selectTime(ids[0]);
                            } else {
                                setSelectedTimes(ids);
                            }
                        }}
                        slots={{ noRowsOverlay: CustomNoRowsOverlay }} />
                </Stack>
            </Stack>
        </Box>
    );

}

export default ReservationTimeAdjustments;