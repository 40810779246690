import React from "react";



const OpenTimeCalendarRow = ({rowNumber, totalColumns, backgroundColor = 'purple', borderColorIn = '#DEDEDE'}) => {
    let borderColor = 'white';
    let borderSize = '0px';
    if (rowNumber % 4 === 0){
        borderColor = borderColorIn;
        borderSize = '1px';
    }

    const pStyle = {
        gridColumn: `1 / ${totalColumns + 1}`,
        gridRow: `${rowNumber}`,
        backgroundColor: backgroundColor,
        borderBottom: `${borderSize} solid ${borderColor}`,
        height: "100%",
        width: "100%"
    };

    return  (
        <div style={pStyle}>
        </div>
    )
}

export default OpenTimeCalendarRow;
