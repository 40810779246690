import { useContext, useState, useEffect } from "react";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import { useNavigate } from "react-router-dom";
import { Stack, Typography, TextField, Button, Box } from '@mui/material';
import BookingsReviewReservations from "../../bookings/bookings-review-reservations/bookings-review-reservations.component";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const SearchForReservationBookingPreview = () => {

    const { clearSearchResultsToBook, searchResultsToBook } = useContext(SearchForReservationContext);
    const navigate = useNavigate();
    const [showClearCart, setShowClearCart] = useState(false);

    const goToBookingSummary = () => {
        navigate('/bookings/booking-summary');
    }

    const clearButtonClicked = () => {
        clearSearchResultsToBook();
        setShowClearCart(false);
    }

    const handleClose = () => {
        setShowClearCart(false);
    };

    const handleClearCart = () => {
        setShowClearCart(true);
    }

    return (
        <Box sx={{ width: 500, backgroundColor: 'white' }} role="presentation">

            <Dialog
                open={showClearCart}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Clear Cart?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to clear the reservation cart?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button variant="contained" onClick={clearButtonClicked} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Typography mt={2} textAlign={'center'} gutterBottom fontWeight={'bold'} variant="h6" color="#14254C" component="div">
                Reservations Cart
            </Typography>
            <BookingsReviewReservations showEmptyResults={true}></BookingsReviewReservations>
            {
                searchResultsToBook.length > 0 &&
                <Stack sx={{ display: 'flex', justifyContent: 'center', marginTop: '1.0vh', marginBottom: '1.0vh' }} spacing={2} direction={'row'}>
                    <Button onClick={goToBookingSummary} variant="contained">
                        Book Reservations
                    </Button>
                    <Button onClick={handleClearCart} variant="outlined">
                        Clear Cart
                    </Button>
                </Stack>
            }
        </Box>
    )

}

export default SearchForReservationBookingPreview