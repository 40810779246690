import styled from 'styled-components';

export const BottomButtonBarContainer = styled.div`
display: flex;
width: 100%;
flex-direction: row;
justify-content: center;
align-items: flex-end;
line-height: 2.5rem;
padding-top: 1.0rem;
padding-bottom: 1.0rem;
`

