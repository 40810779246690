import styled from 'styled-components';

export const LabelWithHeaderContainer = styled.div`

.header {
    display: flex;
    flex-wrap: nowrap;
    text-align: left;
    align-items: center;
    font-weight: bold;
    color: #14254C;
    font-size: ${(props) => `${props.$headerfontsize}`};
    line-height: 1.5rem;
}

.label {
    display: flex;
    text-align: left;
    color: #14254C;
    margin-left: 1.5rem;
    margin-right: 1.0rem;
    font-size: ${(props) => `${props.$labelfontsize}`};
    line-height: 1.5rem;
}

.border-label {
    display: flex;
    text-align: left;
    color: #14254C;
    margin-left: 1.5rem;
    margin-right: 1.0rem;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    padding: 0 15px 0 15px;
    font-size: ${(props) => `${props.$labelfontsize}`};
    line-height: 1.5rem;
    overflow: scroll;
    flex-wrap: nowrap;
}

.border-label:hover {
    background-color: #eaeaea;
  }

.required-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${(props) => `${props.$headerfontsize}`};
}
.required {
    color: red;
}
`