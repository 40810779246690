import styled from 'styled-components';

export const TextAreaContainer = styled.div`

display: flex;
flex-direction: column;
color: #14254C; 
font-size: 1.5vh;
line-height: 1.5vh;

.text-area-input {
    color: #14254C;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    margin-right: 1.0rem;
    box-sizing: border-box;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    background-color: white;
    resize: none;
    line-height: 1.5rem;
    font-family: "Helvetica";
}

.text-area-label {
    display: flex;
    width: 100%;
    text-align: left;
    margin-left: 0.01rem;
    font-weight: bold;
}
`
