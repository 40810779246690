import { useState, useContext } from "react";
import { TextField, Stack, Box, Button } from "@mui/material";
import BottomButtonBar from '../../bottom-button-bar/bottom-button-bar.component';
import { generatePassword } from "../../../utils/password-utils/password-utils";
import MySportSpaceAlert from '../../alert/alert.component';
import validator from 'validator';
import { sendEmailVerification, createCustomerUser, getExistingMySportSpaceCustomer, sendAccountCreateEmailToCustomer, attachCustomerToFacility } from '../../../utils/customer-api/customer-service';
import { UserContext } from '../../../contexts/user.context';
import Typography from '@mui/material/Typography';
import AddressForm from '../../address-form/address-form.component';
import { PhoneFormat } from '../../../utils/text-field-utils/text-field-utils';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const defaultFormFields = {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    state: null,
    zipCode: '',
    phoneNumber: '',
    emailAddress: '',
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};


const FacilityCustomerAdd = ({ isOpen = false, close, refresh, selectCustomer }) => {

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { firstName, lastName, emailAddress, address1, address2, city, state, zipCode, phoneNumber } = formFields;
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const { userData } = useContext(UserContext);
    const [phoneNumberIsFocused, setPhoneNumberIsFocused] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const addCustomer = async () => {

        if (!emailAddress || emailAddress.length <= 0 || !validator.isEmail(emailAddress)) {
            setAlertMessage("Please enter a valid email address");
            return;
        }
        if (!firstName || firstName.length <= 0) {
            setAlertMessage("First Name is required.");
            return;
        }

        if (!lastName || lastName.length <= 0) {
            setAlertMessage("Last Name is required.");
            return;
        }

        if (!phoneNumber || phoneNumber.length <= 0 || !validator.isMobilePhone(phoneNumber)) {
            setAlertMessage("Please enter a valid phone number.");
            return;
        }

        setIsLoading(true);

        const generatedPassword = generatePassword();
        const CreateCustomerUserRequest = {
            password: generatedPassword,
            userEmailAddress: emailAddress,
            userFirstName: firstName,
            userLastName: lastName,
            userPhoneNumber: phoneNumber,
            userMustResetPassword: false,
            createdByFacilityId: userData.facilityId,
            createFirebaseUser: true,
            contactInfo: {
                mailingAddress1: address1,
                mailingAddress2: address2,
                mailingCity: city ? city : "",
                mailingState: state ? state : "",
                mailingZipCode: zipCode
            }
        };
        const createCustomerResponse = await createCustomerUser(CreateCustomerUserRequest);
        if (createCustomerResponse.status === 200 && createCustomerResponse.data.status === 'success') {
            if (selectCustomer) {
                selectCustomer(createCustomerResponse.data.customer);
            }
            sendEmailVerification(emailAddress);
            sendAccountCreateEmailToCustomer(emailAddress, userData.facilityId, firstName, lastName, generatedPassword);
            setSuccessMessage(`A new user has been created.`);
        } else {
            if (createCustomerResponse && createCustomerResponse.data && createCustomerResponse.data.errorMessage) {
                setAlertMessage(createCustomerResponse.data.errorMessage);
            } else {
                setAlertMessage('The customer was not added to your facility.  Make sure you have added all required fields.')
            }
        }

        setIsLoading(false);

    }
    const closeView = () => {

        if (refresh) {
            refresh();
        }

        if (close) {
            close();
        }
    }

    const validateEmail = async () => {
        if (!emailAddress || emailAddress.length < 0 || !validator.isEmail(emailAddress)) {
            setFormFields({ ...formFields, 'emailAddress' : '' });
            setAlertMessage("You must provide a valid email address.");
            return
        }
        setIsLoading(true);
        const existingCustomer = await getExistingMySportSpaceCustomer(emailAddress);
        if (existingCustomer.status === 200 && existingCustomer.data.status === 'success' && existingCustomer.data.customer) {
            const attachResponse = await attachCustomerToFacility(existingCustomer.data.customer.userId, userData.facilityId);
            if (attachResponse.status === 200 && attachResponse.data.status === 'success') {
                if (selectCustomer) {
                    selectCustomer(existingCustomer.data.customer);
                }
                setSuccessMessage("We found a My Sport Space customer with the email address provided.  The customer has been added to your facility customer list.");
            } else {
                setAlertMessage("Something went wrong.  Please try again.");
            }
        } else {
            setPhoneNumberIsFocused(true);
        }

        setIsLoading(false);
    }

    const releasePhoneNumberFocus = () => {
        setPhoneNumberIsFocused(false);
    }

    return (
        <Modal open={isOpen}>
            <Box sx={{ ...style, width: '40%' }}>
                <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
                <Dialog
                    open={successMessage ? true : false}
                    onClose={closeView}
                    aria-labelledby="add-customer-dialog-title"
                    aria-describedby="add-customer-dialog-description"
                >
                    <DialogTitle id="add-customer-dialog-title">
                        {"User Created"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="add-customer-dialog-description">
                            {successMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={closeView} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>

                <Typography sx={{ ml: '1.0vw', mt: '2.0vh', mb: '2.0vh' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div">
                    {'New Customer'}
                </Typography>
                <Stack spacing={1} sx={{ ml: '1.0vw', mr: '1.0vw' }}>
                    <TextField onBlur={validateEmail} required autoComplete="new-password" fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="facility-customer-email" variant="outlined" label="Email Address" type="text" name="emailAddress" value={emailAddress} onChange={handleChange} ></TextField>
                    <TextField InputProps={{
                        inputComponent: PhoneFormat
                    }} autoFocus={phoneNumberIsFocused} onBlur={releasePhoneNumberFocus} required disabled={emailAddress && emailAddress.length > 0 ? false : true} autoComplete="new-password" fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="facility-customer-phone-number" variant="outlined" label="Phone Number" type="text" name="phoneNumber" value={phoneNumber} onChange={handleChange} ></TextField>
                    <TextField required disabled={emailAddress && emailAddress.length > 0 ? false : true} autoComplete="new-password" fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="facility-customer-first-name" variant="outlined" label="First Name" type="text" name="firstName" value={firstName} onChange={handleChange} ></TextField>
                    <TextField required disabled={emailAddress && emailAddress.length > 0 ? false : true} autoComplete="new-password" fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="facility-customer-last-name" variant="outlined" label="Last Name" type="text" name="lastName" value={lastName} onChange={handleChange} ></TextField>
                    <AddressForm disabled={emailAddress && emailAddress.length > 0 ? false : true} turnOffAutoComplete={true} handleChange={handleChange} defaultFormFields={formFields}></AddressForm>
                </Stack>
                <div className='bottom-button-bar'>
                    <BottomButtonBar okButtonText={"Add Customer"} okAction={addCustomer} cancelButtonText={"Cancel"} cancelAction={closeView}></BottomButtonBar>
                </div>
            </Box>
        </Modal>
    )
}

export default FacilityCustomerAdd