import logoImg from "../../../assets/logo-01.png";
import { Box, Stack, Typography, Link } from "@mui/material";

const CustomerFacilityCalendarNotFound = () => {

    return (
        <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
            <Stack mt={5} spacing={5} display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                <Stack display={'flex'} spacing={2} direction={'row'} justifyContent={'space-evenly'}>
                    <Box sx={{width: '50%'}} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                        <img style={{ height: '15.0vh', width: '15.0vw' }} src={logoImg} alt="mysportspace-logo" className={`logo-image`}></img>
                    </Box>
                    <Stack sx={{width: '50%'}}>
                        <Typography mb={2} variant="subtitle1" color={"#14254C"}>A facility calendar was not found</Typography>
                        <Typography variant="subtitle1" color={"#14254C"}>Download the My Sport Space App from the Apple App Store or Google Play to reserve a space from one of our facilities.</Typography>
                    </Stack>
                </Stack >
                <Link sx={{ fontWeight: 'bold', color: 'dark-gray', fontSize: { xs: '1.0vh', sm: '1.75vh' } }} underline="none" variant="subtitle1" href="https://www.mysportspace.info">Click HERE to find out more about My Sport Space</Link>
            </Stack>
        </Box >
    )
}

export default CustomerFacilityCalendarNotFound;