import styled from 'styled-components';

export const BookingsReviewReservationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 0.25vw;
    margin-left: 0.25vw;
    overflow: scroll;

.no-booking-container {
    display: flex;
    flex-direction: column;
    row-gap: 1.0vh;
    margin-top: 2.0vh;
    margin-bottom: 2.0vh;

    .no-reses-to-book {
        font-size: 2.0vh;
        margin-left: 1.0vw;
    }

    .back-to-search-btn {
        display: flex;
        width: 25%;
        margin: auto;
        margin-top: 1.0vh;
    }
}

.all-bookings-container {
    display: flex;
    flex-direction: column;
    row-gap: 1.0vh;
    margin-top: 2.0vh;
    margin-bottom: 2.0vh;
}

.bottom-button-bar {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    margin-bottom: 0.5vh;
    background-color: white;
}

.booking-row {
    display: flex;
    flex-direction: column;
    
    .booking-date-header {
        color: #14254C;
        margin-left: 1.0vw;
        margin-bottom: 1.5vh;
        font-weight: bold;
    }


    .booking-info-container {
        display: flex;
        border-radius: 5px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);              
        margin-left: 1.5vw;
        margin-right: 0.5vw;
        margin-top: 0.5vh;
        margin-bottom: 0.5vh;


        .price-and-remove-container {
            display: flex; 
            justify-content: flex-end;
            align-content: center;
            flex-direction: column;
     
            .price-and-service-charge-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-left: 0.5vw;
                margin-top: 1.0vh;


                .price-container {
                    display: flex;
                    justify-content: flex-end;
                    margin-right: 1.0vw;

                    .price-header-lbl {
                        margin-bottom: 0.5vh;
                        color: #14254C;
                        font-weight: bold;
                        text-align: left;
                        width: 10.0vw;
                    }
                    .booking-price {
                        width: 4.5vw;
                        margin-left: 1.0vw;
                        color: gray;
                        text-align: right;
                    }
                }
                .service-charge-container {
                    display: flex;
                    justify-content: flex-end;
                    margin-right: 1.0vw;

                    .service-charge-header-lbl {
                        margin-bottom: 0.5vh;
                        color: #14254C;
                        font-weight: bold;
                        text-align: left;
                        width: 10.0vw;
                    }
                    .service-charge {
                        width: 4.5vw;
                        margin-left: 1.0vw;
                        color: gray;
                        text-align: right;
                    }
                }

            }

            .remove-booking-btn {
                display: flex;
                padding-left: 1.0vw;
                padding-right: 1.0vw;
                padding-top: 1.0vh;
                padding-bottom: 1.0vh;
                text-align: center;
                border-radius: 5px;
                margin: auto;
                background-color: #DEDEDE;
            }
        }

        
        .booking-info {
            width: 75%;
            display: flex;
            flex-direction: column;
            row-gap: 0.5vh;
            margin-left: 1.5vw;
            margin-right: 0.5vw;
            margin-top: 0.5vh;
            margin-bottom: 0.5vh;

            .facility-name {
                margin-top: 1.0vh;
                margin-left: 0.5vw;
                margin-bottom: 0.25vh;
                color: #14254C;
                font-weight: 600;
                font-size: 2.0vh;
                padding-bottom: 0.5vh;
                border-bottom: 1px solid #DEDEDE;
            }
            .area-name {
                margin-left: 0.5vw;
                color: #14254C;
            }
            .area-type {
                margin-left: 0.5vw;
                color: gray;
            }

            .reserved-for-sport {
                margin-left: 0.5vw;
                color: gray;
            }

            .booking-time {
                text-align: left;
                margin-left: 0.5vw;
                color: #14254C;
            }

            .cancel-policies {
                display: flex;
                flex-direction: column;
                margin-left: 0.5vw;

                .cancel-policy-lbl {
                    color: gray;
                    margin-bottom: 0.25vh;
                }

                .cancel-policy {
                    color: gray;
                    margin-left: 0.5vw;
                }
            }

            .terms-and-conditions {
                margin-top: 1.0vh;
                color: #14254C;
                margin-left: 0.5vw;
            }
        }
    }
}
`