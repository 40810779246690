import { CalendarDatePickerContainer } from './calendar-date-picker.styles'
import Calendar from 'react-calendar'
import Button from '../button/button.component';

const CalendarDatePicker = ({closeCalendar, setDate, date, title, isOpen = true}) => {
    return isOpen ? (
        <CalendarDatePickerContainer>
            <div className="reservation-calendar">
                <div className="close-x" onClick={closeCalendar}>
                    <span>X</span>
                </div>
                <Calendar calendarType='gregory' closeView={closeCalendar} onChange={setDate} value={date} />
                <div className="button-div">
                    <Button onClick={closeCalendar} buttonType='inverted'>{title}</Button>
                </div>
            </div>
        </CalendarDatePickerContainer>
    ) : (
        <></>
    );
}

export default CalendarDatePicker