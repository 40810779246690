import styled from 'styled-components';

export const FacilityMembershipsPickerContainer = styled.div`
display: flex;
position: fixed;
width: 100%;
height: 100%;
flex-direction: column;
background-color: RGBA(20,37,76,0.46);
border-radius: 5px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
z-index: 11;
`


export const FacilityMembershipsPickerListContainer = styled.div`
position: fixed;
height: 85%;
width: 50%;
top: 55%;
left: 50%;
transform: translate(-50%, -50%);
display: flex;
flex-direction: column;
background-color: white;
border-radius: 5px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
z-index: 11;

.membership-list-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  color: #14254C;
  font-size: 0.75rem;
  line-height: 1.5rem;
}

.button-div {
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
`