import { Container, Typography, Stack, Box, Divider, Link } from "@mui/material";
import mssAppImage1 from '../../assets/my_sport_space_app_1.png';
import mssAppImage2 from '../../assets/my_sport_space_app_2.png';
import mssAppImage3 from '../../assets/my_sport_space_app_3.png';
import mssAppImage4 from '../../assets/my_sport_space_app_4.png';
import appDownload from '../../assets/apple-download-on-app-store.webp';
import androidDownload from '../../assets/android-download-on-app-store.webp';
import qrCode from "../../assets/mss_qr_code.png";

const MobileAppRedirect = () => {
    return (

        <Container sx={{ position: 'absolute', top: '12%', left: '0', right: '0', width: '100%' }}>
            <Stack sx={{ width: '100%' }} >
                <Stack mb={4} sx={{width: '100%' }} direction={'row'} spacing={3} display={'flex'} justifyContent={'center'}>
                    <Link sx={{ fontWeight: 'bold', color: '#14254C'}}  variant="h6" href="https://www.mysportspace.info/contact.html">Contact</Link>
                    <Link sx={{ fontWeight: 'bold', color: '#14254C' }} variant="h6" href="https://www.mysportspace.info/facility-infodemo.html">Schedule a Facility Demo</Link>
                </Stack>
                <Box mb={1} display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                    <Stack>
                        <Typography textAlign={'center'} gutterBottom variant="h6" color={'#14254C'} fontFamily={"Helvetica"} fontWeight={'bold'}>Book and Manage Your Sport Space </Typography>
                        <Box display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                            <Typography textAlign={'center'} gutterBottom variant="subtitle1" color={'#14254C'} fontFamily={"Helvetica"}>The My Sport Space App allows you to take your reservations to the next level.  Manage your reservations by canceling or rescheduling all on your phone.</Typography>
                        </Box>
                    </Stack>
                </Box>
                <Divider></Divider>
                <Stack spacing={4} mt={3} mb={3} display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                    <Stack>
                        <Typography gutterBottom variant="h6" color={'#14254C'} textAlign={'center'} fontWeight={'bold'} fontFamily={"Helvetica"}>Scan the QR Code and download our app from the App Store</Typography>
                    </Stack>
                    <Box display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                        <img style={{ objectFit: 'contain', width: '20vw', height: '20vw' }}
                            srcSet={`${qrCode}`}
                            src={`${qrCode}`}
                            alt={'Logo'}
                            loading="lazy"
                        />
                    </Box>
                </Stack>
                <Divider></Divider>
                <Box mt={3} display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                    <Stack>
                        <Stack direction={'row'} display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                            <img style={{ objectFit: 'contain', width: '12vw', height: '25vw' }}
                                srcSet={`${mssAppImage1}`}
                                src={`${mssAppImage1}`}
                                alt={'Logo'}
                                loading="lazy"
                            />
                            <img style={{ objectFit: 'contain', width: '12vw', height: '25vw' }}
                                srcSet={`${mssAppImage2}`}
                                src={`${mssAppImage2}`}
                                alt={'Logo'}
                                loading="lazy"
                            />
                            <img style={{ objectFit: 'contain', width: '12vw', height: '25vw' }}
                                srcSet={`${mssAppImage3}`}
                                src={`${mssAppImage3}`}
                                alt={'Logo'}
                                loading="lazy"
                            />

                            <img style={{ objectFit: 'contain', width: '12vw', height: '25vw' }}
                                srcSet={`${mssAppImage4}`}
                                src={`${mssAppImage4}`}
                                alt={'Logo'}
                                loading="lazy"
                            />
                        </Stack>
                    </Stack>
                </Box>
                <Box display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                    <Stack>
                        <Box display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                            <Typography gutterBottom variant="subtitle1" color={'#14254C'} fontWeight={'bold'} fontFamily={"Helvetica"}>Available on iOS and Android</Typography>
                        </Box>
                        <Stack spacing={2} direction={'row'}>
                            <img style={{ height: '4vh' }} src={appDownload} alt="mysportspace-logo" className={`logo-image`}></img>
                            <img style={{ height: '4vh' }} src={androidDownload} alt="mysportspace-logo" className={`logo-image`}></img>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    )
}

export default MobileAppRedirect;