import { createContext, useEffect, useContext, useReducer } from "react";
import { UserContext } from "../user.context";
import { createAction } from "../../utils/reducer/reducer.utils";
import { getDatabase, ref, onValue } from "firebase/database";
import dayjs from "dayjs";

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

export const ReservationTimeAdjustmentsContext = createContext({
    reservationTimeAdjustments: [],
    setReservationTimeAdjustments: () => [],
    isLoadingAdjustments: false,
    setIsLoadingAdjustments: () => false

});

export const RESERVATION_ADJUSTMENTS_ACTION_TYPES = {
    SET_RES_ADJUSTMENTS: 'SET_RES_ADJUSTMENTS',
    SET_LOADING_ADJUSTMENTS: 'SET_LOADING_ADJUSTMENTS'
}

const INITIAL_STATE = {
    reservationTimeAdjustments: [],
    isLoadingAdjustments: false
}

const timeAdjustmentsReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case RESERVATION_ADJUSTMENTS_ACTION_TYPES.SET_RES_ADJUSTMENTS:
            return {
                ...state, //this keeps everything else and doesn't overwrite
                ...payload
            }
        case RESERVATION_ADJUSTMENTS_ACTION_TYPES.SET_LOADING_ADJUSTMENTS:
            return {
                ...state,
                isLoadingReservations: payload
            }
        default:
            throw new Error(`Unhandled type ${type}`);
    }

}

export const ReservationTimeAdjustmentsProvider = ({ children }) => {

    const [{ reservationTimeAdjustments, isLoadingAdjustments }, dispatch] = useReducer(timeAdjustmentsReducer, INITIAL_STATE);
    const { currentUser, userData } = useContext(UserContext);

    const setReservationTimeAdjustments = (adjustments) => {
        dispatch(createAction(RESERVATION_ADJUSTMENTS_ACTION_TYPES.SET_RES_ADJUSTMENTS, { reservationTimeAdjustments: adjustments }));
    }

    const setIsLoadingAdjustments = (bool) => {
        dispatch(createAction(RESERVATION_ADJUSTMENTS_ACTION_TYPES.SET_LOADING_ADJUSTMENTS, bool));
    }

    const db = getDatabase();
    useEffect(() => {
        if (currentUser && userData) {
            setIsLoadingAdjustments(true);
            const resRef = ref(db, `facility-user-accounts/${userData.facilityId}/reservationTimeAdjustments`)
            const unsubscribe = onValue(resRef, (snapshot) => {
                if (snapshot.exists()) {
                    const resTimeAdjustmentsDict = snapshot.val();
                    const adjustments = [];
                    Object.keys(resTimeAdjustmentsDict).map((id) => {
                        const adjustment = resTimeAdjustmentsDict[id];
                        adjustments.push(adjustment);
                    })

                    //sort these by date.
                    adjustments.sort((adj1, adj2) => {
                        if (adj1.startDate && !adj2.startDate) {
                            return -1;
                        }
            
                        if (adj1.startDate && adj2.startDate) {
                            const startDate1 = dayjs(`${adj1.startDate} 8:00 AM`, 'YYYY-MM-DD 8:00 AM');
                            const startDate2 = dayjs(`${adj2.startDate} 8:00 AM`, 'YYYY-MM-DD 8:00 AM');
                            if (startDate1.isAfter(startDate2)) {
                                return 1;
                            } else {
                                return -1;
                            }
                            
                        }
            
                        return 0;
                    });

                    setReservationTimeAdjustments(adjustments);
                } else {
                    setReservationTimeAdjustments([]);
                }
                setIsLoadingAdjustments(false);
            })
            return unsubscribe;
        }

    }, [userData]);

    const value = {
        reservationTimeAdjustments: reservationTimeAdjustments, isLoadingAdjustments: isLoadingAdjustments
    }
    return <ReservationTimeAdjustmentsContext.Provider value={value}>{children}</ReservationTimeAdjustmentsContext.Provider>
}