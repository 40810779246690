import styled from 'styled-components';

export const AlertContainer = styled.div`
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: RGBA(20,37,76,0.46);
    z-index: 99;
    white-space: pre-line;

    .alert-box {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        color: #14254C;
        border-radius: 5px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        display: flex;
        flex-direction: column;

    }

    .separator {
        background-color: #14254C;
        margin: auto;
        margin-top: 1.0vh;
        margin-bottom: 1.0vh;
        width: 90%;
        height: 0.25vh;
    }

    .alert-title {
        margin-top: 5.0vh;
        margin-bottom: 2.0vh;
        width: 100%;
        text-align: center;
        font-weigth: bold;
        font-size: 3.5vh;
    }

    .message {
        line-height: 1.5rem;
        text-align: left;
        margin-left: 1.0rem;
        margin-right: 1.0rem;
        margin-top: 0.5vh;
        margin-bottom: 0.5vh;
    }

    .centered-message {
        line-height: 1.5rem;
        text-align: center;
        margin-left: 1.0rem;
        margin-right: 1.0rem;
    }

    .bottom-button-bar {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        line-height: 2.5rem;
        padding-top: 1.0rem;
        padding-bottom: 1.0rem;
    }

`