import styled from 'styled-components';

export const CustomerMembershipsContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    color: #14254C;

    .main-container {
        position: relative;
        margin: auto;
        display: grid;
        height: 100%;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-template-areas:
        "logo-area info";
    
        .logo {
            grid-area: logo-area;
            height: 200px;
            line-height: 200px;
            text-align: center;
            
            .logo-image {
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                width: 200px;
            }
        }
    
        .info {
            color: #14254C;
            grid-area: info;
            height: 200px;
            line-height: 200px;
            text-align: center;
            margin-right: 2.5vw;
            font-size: 1.0vw;
            .text-display {
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                text-align: center;
            }
            
        }

    }

    .link {
        width: 100%;
        color: #14254C;
        text-align: center;
        font-size: 1.5vw;
        margin: auto;
    }

`
  