import styled from 'styled-components';

export const CalendarDatePickerContainer = styled.div`
position: absolute;
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
padding: 20px;
border: 1px solid black;
background-color: RGBA(20,37,76,0.46);
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 10;

.reservation-calendar {
    position: absolute;
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid black;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
}

.close-x {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: right;
    font-weight: bold;
    font-size: 1.0rem;
    justify-content: flex-end;
    color: #14254C;
  }

  .close-x: hover {
    cursor: pointer;
  }

  .button-div {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

`