import axios from 'axios';

export default axios.create({
    headers: {
        "Content-Type": 'application/json'
    }
});

export const validateAddress = async (address) => {
    try {
        let url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
        const LocationRequest = {
            address: {
                regionCode: "US",
                addressLines: [`${address}`]
            }
        }
        return await axios.post(url, LocationRequest);
     } catch (err) {
        console.log(err);
        return null;
     }
}