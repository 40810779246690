import { Divider, Typography, Box, Stack, Button, Grid } from "@mui/material";
import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from "react";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import BirthdayPartyInfoCard from "./birthday-info-card.component";
import { GetBirthdayPartiesForGroup } from "../../../utils/birthday-api/birthday-service";
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserContext } from "../../../contexts/user.context";

const logoImageStyle = {
    height: '4.0vh',
    width: '4.0vw',
    objectFit: 'contain'
};


const BirthdayPartyGroupList = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { facilityName, groupSearchTerm } = useParams();
    const [birthdayParties, setBirthdayParties] = useState([]);
    const [groupInfo, setGroupInfo] = useState(null);
    const [facilityLogoUrl, setFacilityLogoUrl] = useState("");
    const { userData } = useContext(UserContext);

    const navigate = useNavigate();

    const { mainTextColor = '#14254C', secondaryTextColor = '#A9A9A9' } = groupInfo && (!userData || (userData && !userData.isFacilityUserAccount)) ? groupInfo : {};

    const fetchPartyGroups = async () => {
        setIsLoading(true);
        const groupsResponse = await GetBirthdayPartiesForGroup(facilityName, groupSearchTerm, userData && userData.isFacilityUserAccount);
        if (groupsResponse && groupsResponse.status == 200 && groupsResponse.data && groupsResponse.data.status === 'success') {
            setBirthdayParties(groupsResponse.data.birthdayParties);
            setGroupInfo(groupsResponse.data.groupInfo);
            if (groupsResponse.data.facilityLogoUrl) {
                setFacilityLogoUrl(groupsResponse.data.facilityLogoUrl)
            }
        } else {
            setBirthdayParties([]);
        }
        setIsLoading(false);
    }

    const closeDetails = () => {
        navigate(`/birthday-parties/${facilityName}`)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPartyGroups();
    }, []);

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'10.0vh'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'} justifyContent={'center'}>
            <Stack sx={{
                height: '100%',
                width: '99%',
            }}>
                <Box>
                    <Stack>
                        <Box display="flex" justifyContent="flex-start" alignItems="center">
                            <Button onClick={closeDetails} startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                        </Box>
                        <Grid alignItems={'center'} justifyContent={'center'} sx={{ width: '98vw', marginLeft: '1.0vw', marginRight: '1.0vw' }} container>
                            <Grid item xs={1}>
                                {facilityLogoUrl &&
                                    <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                                        <img style={logoImageStyle} className="facility-logo-image" src={facilityLogoUrl ? facilityLogoUrl : ""} alt="Logo" />
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={10}>
                                <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                                    <Typography mt={2} fontSize={{ xs: '1.5vh', sm: '1.5vh', md: '2.5vh', lg: '4.0vh', xl: '4.0vh' }} textAlign={'center'} gutterBottom color={mainTextColor ? mainTextColor : "#14254C"} component="div" variant={birthdayParties.length > 0 ? "h2" : "body1"}>
                                        {birthdayParties && birthdayParties.length > 0 ? birthdayParties[0].groupName : !isLoading ? 'We were unable to find the facility you are looking for.' : ''}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                {facilityLogoUrl &&
                                    <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                                        <img style={logoImageStyle} className="facility-logo-image" src={facilityLogoUrl ? facilityLogoUrl : ""} alt="Logo" />
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Stack>
                    <Divider color={`${secondaryTextColor}`} sx={{ marginBottom: '5.0vh', color: `${secondaryTextColor}` }}></Divider>
                    <Stack sx={{ marginBottom: '2.0vh' }} direction="row" spacing={2} useFlexGap flexWrap="wrap" justifyContent={'center'}>
                        {
                            birthdayParties.map((birthdayParty) => {
                                return <BirthdayPartyInfoCard key={birthdayParty.id} birthdayParty={birthdayParty} facilityName={facilityName}></BirthdayPartyInfoCard>
                            })
                        }
                    </Stack>
                </Box>

            </Stack>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
        </Box >
    )
}

export default BirthdayPartyGroupList;