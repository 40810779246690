import { useRef, useEffect } from 'react';

export default function ClickOutside({ children, exceptionRef, onClickInside, onClickOutside, className }) {
    const wrapperRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickListener);

        return () => {
            document.removeEventListener('mousedown', handleClickListener);
        };
    }, []);

    const handleClickListener = (event) => {
        let clickedInside;
        if (exceptionRef) {
            clickedInside = (wrapperRef && wrapperRef.current.contains(event.target)) || exceptionRef.current === event.target || exceptionRef.current.contains(event.target);
        }
        else {
            clickedInside = (wrapperRef && wrapperRef.current.contains(event.target));
        }
        
        if (String(event.target.className).includes("Mui") || String(event.target.className).includes("SVGAnimatedString")) {
            return
        }

        if (clickedInside)  {
            onClickInside();
        }
        else {
            onClickOutside();
        }
            
    }

    return (
        <div ref={wrapperRef} className={`${className || ''}`}>
            {children}
        </div>
    );
};