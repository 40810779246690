import { Routes, Route } from 'react-router-dom';
import FacilityBirthdayPartiesView from '../../components/customer/birthday-party/facility-birthday-parties-view.component';
import BirthdayPartyDetails from '../../components/customer/birthday-party/birthday-party-details.component';
import { BirthdayPartyProvider } from '../../contexts/birthday-party/birthday-party.context';
import BirthdayPartyGroupList from '../../components/customer/birthday-party/birthday-party-group-list.component';

const CustomerBirthdayPartyRoutes = () => {

    return (
        <BirthdayPartyProvider>
            <Routes>
                <Route path=":facilityName" element={<FacilityBirthdayPartiesView />} />
                <Route path=":facilityName/:birthdayPartySearchTerm" element={<BirthdayPartyDetails />} />
                <Route path=":facilityName/:birthdayPartySearchTerm/:birthdayPartyDate" element={<BirthdayPartyDetails />} />
                <Route path=":facilityName/package/:groupSearchTerm" element={<BirthdayPartyGroupList />} />
            </Routes>
        </BirthdayPartyProvider>
    )

}

export default CustomerBirthdayPartyRoutes;