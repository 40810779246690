import Autocomplete from '@mui/material/Autocomplete';
import { LIST_OF_STATES_CAMEL } from '../../utils/constants/constants';
import { TextField } from "@mui/material";

const StateSelect = ({selectState, selectedState, disabled=false}) => {
    const defaultProps = {
        options: LIST_OF_STATES_CAMEL,
        getOptionLabel: (state) => `${state}`,
      };

    const selectValue = (event) => {
        let validValue = event.target.value;
        if (validValue){
            const foundValue = LIST_OF_STATES_CAMEL.filter(v => v.toUpperCase().includes(event.target.value.toUpperCase()));
            if (!foundValue || foundValue.length === 0){
                validValue = `${LIST_OF_STATES_CAMEL[0]}`;
            }
        }
        event.target.value = validValue;
        if (selectState){
            selectState(event);
        }
    }

    return (

        <Autocomplete
            {...defaultProps}
            options={LIST_OF_STATES_CAMEL}
            id={'state-select'}
            freeSolo={false}
            autoHighlight
            autoSelect
            disabled={disabled}
            value={selectedState === '' ? null : selectedState}
            onBlur={(event) => {
                selectValue(event);
            }}
            renderInput={(LIST_OF_STATES_CAMEL) => (
                <TextField {...LIST_OF_STATES_CAMEL} autoComplete='new-password' value={selectedState} fullWidth name="state" label={"State"} variant={'outlined'} sx={{
                    "& .MuiInputBase-input": {
                        color: "#14254C"
                    }
                }}  />
            )}
        />
    )
}

export default StateSelect