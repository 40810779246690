import styled from 'styled-components';

export const FacilityCustomerReservationSearchContainer = styled.div`
    position: absolute;
    top: 10%;
    width: 98%;
    height: 83vh;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 1.0rem;
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
      "searchbar searchresults";

    .search-bar {
        grid-area: searchbar;
        background-color: white;

        .date-container {
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            margin-top: 1.0vh;
            margin-right: 0.5vw;
            margin-bottom: 1.5vh;


            .datelbl-and-checkbox {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-top: 1.0vh;
                margin-left: 1.0vw;
                margin-bottom: 1.0vh;
                .date-btn {
                    margin-left: 2.25vw;
                    margin-right: 1.0vw;
                    height: 2.0vh;
                    width: 2.0vw;
                }

                .title {
                    margin-left: 0.25vw;
                    font-size: 1.5vh;
                    font-weight: bold;
                    color: #14254C;
                    text-align: left;
                }
            }
        }

        .filters {

            display: flex;
            flex-direction: column;
            border-radius: 5px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);


            .check-box-and-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                font-size: 1.75vh;
                color: #14254C;
                margin-left: 2.0vw;
                padding-bottom: 5px;
                margin-right: 1.0vw;
            }

            .header {
                margin-top: 1.0vh;
                margin-left: 0.25vw;
                margin-bottom: 1.0vh;
                font-weight: bold;
                color: gray;
            }

            .filter-btn {
                margin-left: 0.25vw;
                height: 2.0vh;
                width: 2.0vw;
            }
        }

    }

    .search-results {
        grid-area: searchresults;
        background-color: white;

        .page-links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: 12vw;
            margin-right: 12vw;
        }
        
        .page-link {
            display: block;
            color: white;
            text-align: center;
            padding: 16px;
            text-decoration: none;
            color: #14254C;
            border-radius: 5px;
            font-size: 1.75vh;
            font-weight: bold;
          }
        
          .page-link:hover {
            background-color: #14254C;
            color: white;
          }

        .search-container {
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            height: 100%;
            color: #14254C;



            .title-and-pages {
                display: flex;
                flex-direction-row;
                justify-content: space-between;
                align-items: center;

                .title {
                    margin-top: 1.5vh;
                    margin-bottom: 1.0vh;
                    margin-left: 0.5vw;
                    font-size: 2.5vh;
                    font-weight: bold;
                    color: #14254C;
                }

            }

            .reservations-list-container {
                display: flex;
                flex-direction: column;

                .date-header {
                    margin-top: 1.25vh;
                    margin-left: 0.5vw;
                    margin-bottom: 1.0vh;
                    font-size: 2.0vh;
                    font-weight: bold;
                }
            }

            .no-reservations-found {
                margin-top: 2.0vh;
                margin-left: 5.0vw;
                font-weight: bold;
            }
        }
    }

`