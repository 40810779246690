import { Modal, Paper, Stack, Typography, Box, Button, Divider } from "@mui/material";
import { UserContext } from "../../../contexts/user.context";
import { useState, useContext, useEffect } from "react";
import { GetBirthdayPartyGroups } from "../../../utils/birthday-api/birthday-service";
import MySportSpaceAlert from "../../alert/alert.component";
import { DataGrid } from "@mui/x-data-grid";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CustomNoRowsOverlay } from "../../../utils/data-grid-utils/data-grid-utilities";
import MySportSpaceSnackbarAlert from "../../alert-snackbar/alert-snackbar.component";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DeleteBirthdayPartyGroups } from "../../../utils/birthday-api/birthday-service";
import BirthdayPartyGroupSetup from "./birthday-party-group-setup.component";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { getEditLock, removeEditLock } from "../../../utils/firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
import { UpdateBirthdayPartyGroupStatus } from "../../../utils/birthday-api/birthday-service";

function getRowId(row) {
    return row.id;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '90%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};

const GroupedBirthdayParties = ({close}) => {

    const { userData, isAdmin } = useContext(UserContext);
    const [birthdayPartyGroups, setBirthdayPartyGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [openAlert, setOpenAlert] = useState(null);
    const [updateStatus, setUpdateStatus] = useState(null);
    const [editLockName] = useState('groupedbirthdayParty/editLock');
    const [showCreateNewGroupDialog, setShowCreateNewGroupDialog] = useState(false);

    const navigate = useNavigate();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100
    });

    const viewButtonClick = (e, row) => {
        e.stopPropagation();
        setSelectedGroupId(row.id);
    };

    const columns = [
        {
            field: 'groupName',
            renderHeader: () => (
                <strong>
                    {'Name '}
                </strong>
            ),
            flex: 1.5,
        },
        {
            field: 'status',
            flex: 0.5,
            minWidth: 25,
            renderHeader: () => (
                <strong>
                    {'Status '}
                </strong>
            ),
            valueGetter: (value) => {
                if (!value) {
                    return "ACTIVE"
                }

                return value.toUpperCase();
            },
        },
        {
            field: 'searchTerm',
            flex: 0.5,
            minWidth: 25,
            renderHeader: () => (
                <strong>
                    {'Url '}
                </strong>
            ),
        }
    ];

    async function getBirthdayPartyGroups(nextPage) {
        setIsLoading(true);
        let groups = [];
        if (userData && userData.facilityId) {

            let startAfterId = null;
            let startAt = null;
            let endAt = null;

            if (nextPage) {
                startAfterId = birthdayPartyGroups.length > 0 ? birthdayPartyGroups[birthdayPartyGroups.length - 1].groupName : null;
            } else if (birthdayPartyGroups.length > 0) {
                startAt = birthdayPartyGroups[0].groupName;
            }

            const response = await GetBirthdayPartyGroups(userData.facilityId, startAfterId, startAt, endAt, false);
            if (response && response.status === 200) {
                if (!response.data.status || response.data.status === 'success') {
                    groups = response.data.birthdayPartyGroups;
                }
            }

            setBirthdayPartyGroups(groups);
            setIsLoading(false);
        }

    }

    useEffect(() => {

        getBirthdayPartyGroups(true);

        return () => {
            if (userData) {
                removeEditLock(editLockName, userData);
            }
        }

    }, [userData, paginationModel.page]);

    const deleteGroup = () => {

        if (selectedGroups.length <= 0) {
            setOpenAlert({ type: 'needs_selection', message: "Please select at least 1 party group to delete.", buttonText: "" });
            return;
        }

        setShowDeleteDialog(true);

    }

    const edit = async () => {

        if (!isAdmin) {
            setAlertMessage({
                title: 'Not Authorized',
                message: 'You are not authorized to edit.'
            });
        } else {
            setIsLoading(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsLoading(false);
            if (retrievedEditLock) {
                setIsEditMode(true);
            } else {
                setAlertMessage({
                    title: 'Locked',
                    message: 'Another user is currently editing grouped birthday parites.'
                });
            }
        }
    }

    const cancelEdit = () => {
        setSelectedGroups([]);
        setIsEditMode(false);
        removeEditLock(editLockName, userData);
    }
    const closeAlertMessage = () => {
        setAlertMessage(null);
        setOpenAlert(null);
    }

    const setSelectedGroupId = (ids) => {

        const foundGroup = birthdayPartyGroups.filter((group) => {
            return group.id === ids[0];
        });

        if (foundGroup) {
            setSelectedGroup(foundGroup[0]);
        } else {
            setSelectedGroup(null);
        }

    }

    const closeSetup = (e) => {
        setSelectedGroup(null);

        if (close){
            close(null, "1");
        }
    }

    const closeDeleteDialog = () => {
        setShowDeleteDialog(false);
    }

    const clearSelectedRows = () => {
        setSelectedGroups([])
    }

    const refreshList = () => {
        getBirthdayPartyGroups(false);
    }

    const inActivate = () => {
        updateGroupStatus('inactive');
    }

    const activate = () => {
        updateGroupStatus('active');
    }

    const updateGroupStatus = async (status) => {
        setIsLoading(true);
        const response = await UpdateBirthdayPartyGroupStatus(userData.facilityId, selectedGroups, status);
        if (response && response.status === 200 && response.data.status === 'success') {
            setOpenAlert({ type: 'status_update_successful', message: "Status Updated", buttonText: "" });
            getBirthdayPartyGroups(false);
        } else {
            setOpenAlert({ type: 'status_update_error', message: "Status Update Error", buttonText: "" });
            setIsLoading(false);
        }
    }

    const closeUpdateStatusDialog = () => {
        setUpdateStatus(null);
    }

    const deleteTheBirthdayPartyGroups = async () => {
        if (selectedGroups.length > 10) {
            setOpenAlert({ type: 'max_reached', message: "Only 10 Deletes Are Allowed At A Time", buttonText: "Clear" });
        } else {
            setIsLoading(true);
            const response = await DeleteBirthdayPartyGroups(userData.facilityId, selectedGroups);
            if (response && response.status === 200 && response.data.status === 'success') {
                getBirthdayPartyGroups();
                setShowDeleteDialog(false);
                setSelectedGroups([]);
                setOpenAlert({ type: 'delete_successful', message: "Birthday Parties Deleted", buttonText: "" });
            } else {
                setOpenAlert({ type: 'delete_error', message: "Delete Error", buttonText: "" });
                setIsLoading(false);
                setShowDeleteDialog(false);
            }
        }
    }

    const createNewGroup = () => {
        setShowCreateNewGroupDialog(true);
    }

    const closeShowNewGroupDialog = () => {
        setShowCreateNewGroupDialog(false);
    }

    const goToSingleGroupsView = () => {
        navigate('/facility/birthday-parties/manage?index=2');
    }
    return (
        <Box sx={{ position: 'absolute', height: '80%', width: '94%' }} display={'flex'}>
            <Stack display={'flex'} justifyContent={'center'} sx={{
                height: '100%',
                width: '100%'
            }}>
                <MySportSpaceSnackbarAlert isOpen={openAlert ? true : false} close={closeAlertMessage} message={openAlert ? openAlert.message : ""} buttonText={openAlert ? openAlert.buttonText : ''} action={openAlert && openAlert.type === 'max_reached' ? clearSelectedRows : closeAlertMessage} />
                <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
                <Box mt={1} mb={2} display="flex" justifyContent="flex-start" alignItems="flex-start">
                    <Typography sx={{ width: '60%', fontSize: '1.5vh' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#A9A9A9'} variant="subtitle1" component="div" ml={1} mt={1} mb={1}>
                        Birthday party groups will be displayed under 1 card on the facilities customer birthday party page
                    </Typography>
                    <Box sx={{ width: '40%' }} display="flex" justifyContent="flex-end" alignItems="center">
                        {
                            isEditMode &&
                            <Stack spacing={1} direction={'row'}>
                                <Button size="small" startIcon={<AddOutlinedIcon />} id={'create-button'} onClick={createNewGroup} variant="contained" >New</Button>
                                <Button size="small" startIcon={<ToggleOffOutlinedIcon />} id={'create-button'} onClick={inActivate} variant="contained" >Inactive</Button>
                                <Button size="small" startIcon={<ToggleOnOutlinedIcon />} id={'create-button'} onClick={activate} variant="contained" >Activate</Button>
                                <Button size="small" startIcon={<DeleteOutlineOutlinedIcon />} id={'create-button'} onClick={deleteGroup} variant="contained" >Delete</Button>
                                <Button size="small" startIcon={<CancelOutlinedIcon />} id={'create-button'} onClick={cancelEdit} variant="contained" >Cancel</Button>
                            </Stack>
                        }
                        {
                            !isEditMode &&
                            <Stack spacing={1} direction={'row'}>
                                <Button size="small" startIcon={<EditOutlinedIcon />} id={'create-button'} onClick={edit} variant="contained" >Edit</Button>
                            </Stack>
                        }
                    </Box>
                </Box>
                <DataGrid sx={{
                    font: 'Helvetica', color: '#14254C',
                    fontWeight: 400, '--DataGrid-overlayHeight': '500px'
                }} getRowId={getRowId} loading={isLoading} rowCount={1} initialState={{ pagination: paginationModel }} pageSizeOptions={[100]} onPaginationModelChange={setPaginationModel} paginationMode="server"
                    rows={birthdayPartyGroups} columns={columns} checkboxSelection={isEditMode} disableRowSelectionOnClick={isEditMode} rowSelectionModel={selectedGroups} onRowSelectionModelChange={(ids) => {
                        if (!isEditMode) {
                            setSelectedGroupId(ids);
                        } else {
                            setSelectedGroups(ids)
                        }
                    }}
                    slots={{ noRowsOverlay: CustomNoRowsOverlay }} />
            </Stack>
            {
                selectedGroup &&
                <Modal open={true}>
                    <Box sx={{ ...style, width: '90%' }}>
                        <BirthdayPartyGroupSetup index={"2"} close={closeSetup} selectedGroup={selectedGroup} refresh={refreshList}></BirthdayPartyGroupSetup>
                    </Box>
                </Modal>
            }
            <Dialog
                open={showDeleteDialog}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>Delete?</DialogTitle>
                <DialogContent>
                    <DialogContentText mb={2} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        All birthday parties listed under this group will not be deleted, only the group.  To delete each party under this group go to Single Parties after deleting the group.
                    </DialogContentText>
                    <Divider></Divider>
                    <DialogContentText mt={2} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        Deleted birthday groups cannot be recovered. Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog}>No</Button>
                    <Button onClick={deleteTheBirthdayPartyGroups} variant="contained">Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={updateStatus ? true : false}
                onClose={closeUpdateStatusDialog}
            >
                <DialogTitle>Update Status?</DialogTitle>
                <DialogContent>
                    <DialogContentText mb={2} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        This will update the active status for all birthday parties in this group.  Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeUpdateStatusDialog}>No</Button>
                    <Button onClick={updateGroupStatus} variant="contained">Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showCreateNewGroupDialog}
                onClose={closeShowNewGroupDialog}
            >
                <DialogTitle>Add New Birthday Group</DialogTitle>
                <DialogContent>
                    <DialogContentText mb={2} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        Use Ungrouped Birthday Parties to create new parties and group them together.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeShowNewGroupDialog}>Cancel</Button>
                    <Button onClick={goToSingleGroupsView} variant="contained">Continue</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )

}

export default GroupedBirthdayParties;