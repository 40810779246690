import { CustomerMembershipsContainer } from "./customer-memberships.styles";
import logoImg from "../../../assets/logo-01.png";

const CustomerMemberships = () => {

    return (
        <CustomerMembershipsContainer>
            <div className="main-container">
                <div className="logo">
                    <img src={logoImg} alt="mysportspace-logo" className={`logo-image`}></img>
                </div>
                <div className="info">
                    <p className="text-display">Coming Soon!<br></br><br></br>For now download the My Sport Space App from the Apple App Store or Google Play to purchase a membership from one of our facilities.</p>
                </div>
            </div>
            <div className="link">
                <a href="https://www.mysportspace.info">Click HERE to find out more about My Sport Space</a>
            </div>
        </CustomerMembershipsContainer>
    )
}

export default CustomerMemberships;