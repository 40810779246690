
import FacilityReservationBookSummary from "../../../components/facility/facility-reservation-book-summary/facility-reservation-book-summary.component";
import { FacilityReservationBookResultsContainer } from "./facility-reservation-book-results.styles";
import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";

const FacilityReservationBookResults = () => {
    
    const { bookingResponse } = useContext(SearchForReservationContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!bookingResponse){
            navigate("/facility/reservation-search");
        }
    }, []);

    return (

        <FacilityReservationBookResultsContainer>
            <FacilityReservationBookSummary ></FacilityReservationBookSummary>
        </FacilityReservationBookResultsContainer>
    )

}

export default FacilityReservationBookResults