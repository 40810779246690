import { FacilityCustomerViewReservationsContainer } from './facility-customer-view-reservations.styles';
import FacilityCustomerReservationSearch from '../../../components/facility/facility-customer-reservation-search/facility-customer-reservation-search.component';


const FacilityCustomerViewReservations = () => {
    return (
        <FacilityCustomerViewReservationsContainer>
            <FacilityCustomerReservationSearch key={'customer-reservation-search'}></FacilityCustomerReservationSearch>
        </FacilityCustomerViewReservationsContainer>
    )

}

export default FacilityCustomerViewReservations