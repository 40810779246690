import { PatternFormat } from 'react-number-format';
import { forwardRef } from 'react';
import { styled } from "@mui/material/styles";
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

export const PhoneFormat = forwardRef(
    function PhoneFormatCustom(props, ref) {
        const { onChange, id, ...other } = props;

        return (
            <PatternFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                            id: id
                        },
                    });
                }}
                format="+1 (###) ###-####"
            />
        );
    },
)

export const DeciamlFormat = forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, id, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                            id: id
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
            />
        );
    },
);

export const NoDeciamlFormat = forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, id, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                            id: id
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                decimalScale={0}
            />
        );
    },
)

export const CreditCardFormat = forwardRef(
    function CreditCardFormatCustom(props, ref) {
        const { onChange, id, ...other } = props;

        return (
            <PatternFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                            id: id
                        },
                    });
                }}
                format="#### #### #### #### ###"
            />
        );
    },
)

export const CustomDisableInput = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#14254C",
        color: "#000"
    }
}));

// function cardExpiry(val) {
//     let month = limit(val.substring(0, 2), '12');
//     let year = val.substring(2, 4);
  
//     return month + (year.length ? '/' + year : '');
//   }

export const CreditCardExpiryFormat = forwardRef(
    function CreditCardFormatCustom(props, ref) {
        const { onChange, id, ...other } = props;

        return (
            <PatternFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                            id: id
                        },
                    });
                }}
                format="##/##" placeholder="MM/YY" mask={['M', 'M', 'Y', 'Y']} 
            />
        );
    },
)