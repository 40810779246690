import { TextField, Box } from "@mui/material"
import DaysOfWeekListPopover from "./days-of-week-list-popover.component";
import { useState } from "react";
import { getDaysOfWeekText } from "../../utils/date-utilities/date-utilities";

const DaysOfWeekSelection = ({selectedDayOfWeek, dayOfWeekSelected, exludeDow}) => {

    const [ anchorEl, setAnchorEl ] = useState(null);

    const handleDayOfWeekClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const dowSelected = (daysOfWeek, closeView) => {
        dayOfWeekSelected(daysOfWeek, closeView)
    }

    const closePopover = () => {
        setAnchorEl(null);
    }

    return (
        <Box mt={2}>
            <TextField fullWidth id="select-days-of-week" variant="outlined" label="Days Of Week" type="text" onClick={handleDayOfWeekClick} name="pricing-dows" value={selectedDayOfWeek.length > 0 ? getDaysOfWeekText(selectedDayOfWeek) : "Enter Days Of Week"} ></TextField>
            <DaysOfWeekListPopover selectedDayOfWeek={selectedDayOfWeek} dayOfWeekSelected={dowSelected} anchorEl={anchorEl} closeView={closePopover} excludeDow={exludeDow}></DaysOfWeekListPopover>
        </Box>
    )
}

export default DaysOfWeekSelection;