import { Fragment, useContext } from "react";
import * as React from 'react';
import { FacilityAreaContext } from "../../../contexts/facility/facility-area-provider.context";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { Box, IconButton, Popover, Stack } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const FacilityAreaListAll = ({ anchorEl, closeList, selectFacilityAreaId, selectedAreaIds = [], marginTop = 10 }) => {

  const { facilityAreas } = useContext(FacilityAreaContext);
  // const [ selectedAreaIds, setSelectedAreaIds ] = useState(areaIdsSelected)

  const handleClick = (e) => {
    const areaId = e.currentTarget.dataset.id;

    if (selectFacilityAreaId) {
      selectFacilityAreaId(areaId);
    }
  }

  const handleClose = (e) => {
    if (closeList) {
      closeList();
    }
  }

  return (
    <Popover
      id={'facility-list-all-popover'}
      open={anchorEl ? true : false}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }} >
      <Box sx={{backgroundColor: 'white'}} position={'sticky'} top={0} zIndex={99999} key={'close-facility-all-list-btn'} display="flex" justifyContent="flex-end" alignItems="center" >
        <IconButton onClick={handleClose}>
          <CloseOutlinedIcon></CloseOutlinedIcon>
        </IconButton>
      </Box>
      <Box overflow={'scroll'} mt={marginTop} mb={1} ml={1} mr={1}>
        <List sx={{ backgroundColor: 'white' }} overflow={'scroll'} key={'facility-area-list-all-key'}>
          {
            facilityAreas.map((mainArea) => {
              const { name, id, subAreaList } = mainArea
              return <Fragment key={id}>
                {
                  <ListItemButton key={`${id}-list-btn`} selected={selectedAreaIds.includes(id)} divider={true} onClick={handleClick.bind(this)} data-id={id}>
                    <ListItemText key={`${id}-list-item`} primary={name} primaryTypographyProps={{
                      fontWeight: 'bold',
                      letterSpacing: 0,
                      color: '#14254C'
                    }}></ListItemText>
                  </ListItemButton>
                }
                <Fragment key={`sub-list-${id}`}>
                  {subAreaList && subAreaList.length > 0 &&
                    subAreaList.map((subArea) => {
                      const { name, id } = subArea;
                      return <ListItemButton key={`sub-${id}-list-btn`} sx={{ pl: 4, pb: 0 }} selected={selectedAreaIds.includes(id)} divider={false} onClick={handleClick.bind(this)} data-id={id}>
                        <ListItemText key={`sub-${id}-list-item`} primary={name} primaryTypographyProps={{
                          letterSpacing: 0,
                          color: '#14254C'
                        }} ></ListItemText>
                      </ListItemButton>
                    })
                  }
                </Fragment>
              </Fragment>
            })
          }
        </List>
      </Box>
    </Popover >
  )

}

export default FacilityAreaListAll