import styled from 'styled-components';

export const FacilityAreaListContainer = styled.div`
display: flex;
flex-direction: column;
position: relative;
z-index: 999999;
background-color: white;


  .facility-area-list {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    max-height: 50vh;
    overflow: scroll;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-top: -1.0vh;
  }
`

export const FacilityAreaTable = styled.table`
position: absolute;
list-style-type: none;
margin: 5px 0;
padding: 0;
border: 1px solid grey;
background-color: white;
overflow: scroll;
border-radius: 10px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
`

export const MainAreaRow = styled.tr`
width: 100%;
font-weight: bold;
color: #14254C;
cursor: pointer;
line-height: 2.0em;
overflow: hidden;
background-color: ${(props) => `${props.$rowcolor}`};

&:hover {
    background-color: #eaeaea;
  }

`
