import { Fragment, useContext } from "react";
import ReservationCalendar from '../reservation-calendar/reservation-calendar.component';
import { FacilityReservationsContext } from "../../../contexts/facility/facility-reservations.context";
import LoadingIndicator from "../../loading-indicator/loading-indicator.component";
import FacilityViewReservation from "../facility-view-reservation/facility-view-reservation.component";

const FacilityCalendar = () => {

    const { isLoadingReservations, reservationToView } = useContext(FacilityReservationsContext);

    return (
        <Fragment>
            {isLoadingReservations &&
                <LoadingIndicator></LoadingIndicator>
            }
            {!isLoadingReservations &&
                <ReservationCalendar></ReservationCalendar>
            }
            {!isLoadingReservations && reservationToView &&
                <FacilityViewReservation reservationToView={reservationToView}></FacilityViewReservation>
            }
        </Fragment>

    )
}

export default FacilityCalendar