import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DesktopTimePicker } from '@mui/x-date-pickers';

const endOfDay = dayjs().set('hour', 24).endOf('hour');
const startOfDay = dayjs().set('hour', 0).startOf('hour');

const MaterialUITimePicker = ({ headerText = "Time", views = ['hours, minutes'], format, ampm = true, minTime = startOfDay, onChange, value, maxTime, minuteInterval = 15, id, type }) => {

    const timeChanged = (newValue) => {
        if (onChange) {
            if (id){
                onChange(newValue, id, type);
            } else {
                onChange(newValue);
            }
            
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
            <DesktopTimePicker  type="time" label={headerText} views={views} format={format} ampm={ampm} onChange={timeChanged} value={value} minutesStep={minuteInterval} minTime={minTime} maxTime={maxTime ? maxTime : null}
            />
        </LocalizationProvider>
    );
}

export default MaterialUITimePicker;