import styled from 'styled-components';

export const LeftSideBarContainer = styled.div`
position: absolute;
display: flex;
flex-direction: column;
row-gap: 1rem;
background-color: white;
margin-top: 2.0vh;
width: 15vw;
min-width: 250px;
max-width: 300px;
overflow: scroll;
z-index: 14;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.6);
border-radius: 10px;

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.6);
  border-radius: 10px;
  padding: 5px 3px 5px;
  margin: 1rem;
  min-height: 75px;

  .icon-img {
      max-width: 30px;
      display: block;
      margin-left: auto;
      margin-right: auto;
  }

  .icon-title {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: block;
    text-align: center;
    overflow: hidden;
  }
}
  `