import styled from 'styled-components';

export const FacilityCustomerReservationsContainer = styled.div`

max-height: 60vh;
position: relative;
display: flex;
flex-direction: column;
background-color: white;
overflow: scroll;

.reservations-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;

    .no-reses-title {
        margin-top: 1.0vh;
        color: #5A5A5A;
        width: 100%;
        height: 100%;
        margin-left: 1.5rem;
        font-size: 2.0vh;
        font-weight: bold;
    }
}

`