import { createContext, useEffect, useContext, useReducer } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { UserContext } from "../user.context";
import { createAction } from "../../utils/reducer/reducer.utils";

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

export const FacilityOpenBookingTimesContext = createContext({
    openBookingTimes: null,
    setOpenBookingTimes: () => null,
    openBookingTimesAreActive: false,
    setOpenBookingTimesAreActive: () => false
});

export const OPEN_BOOKING_TIMES_ACTION_TYPES = {
    SET_ACTIVE_OPEN_BOOKING_TIMES: 'SET_ACTIVE_OPEN_BOOKING_TIMES',
    SET_OPEN_BOOKING_TIMES: 'SET_OPEN_BOOKING_TIMES'
}

const INITIAL_STATE = {
    openBookingTimes: [],
    openBookingTimesAreActive: false,
}

const openBookingsReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case OPEN_BOOKING_TIMES_ACTION_TYPES.SET_OPEN_BOOKING_TIMES:
            return {
                ...state, //this keeps everything else and doesn't overwrite
                ...payload
            }
        case OPEN_BOOKING_TIMES_ACTION_TYPES.SET_ACTIVE_OPEN_BOOKING_TIMES:
            return {
                ...state,
                openBookingTimesAreActive: payload
            }
        default:
            throw new Error(`Unhandled type ${type}`);
    }
}

export const FacilityOpenBookingTimesProvider = ({ children }) => {

    const [{openBookingTimes, openBookingTimesAreActive}, dispatch] = useReducer(openBookingsReducer, INITIAL_STATE);
    const { currentUser, userData } = useContext(UserContext);
    const db = getDatabase();

    const setOpenBookingTimes = (openBookingTimes) => {
        dispatch(createAction(OPEN_BOOKING_TIMES_ACTION_TYPES.SET_OPEN_BOOKING_TIMES, {openBookingTimes: openBookingTimes}));
    }

    const setOpenBookingTimesAreActive = (bool) => {
        dispatch(createAction(OPEN_BOOKING_TIMES_ACTION_TYPES.SET_ACTIVE_OPEN_BOOKING_TIMES, bool));
    }



    useEffect(() => {

        const setupOpenBookingTimesAreActive = (snapshot) => {
            setOpenBookingTimesAreActive(snapshot.val());
        }

        if (currentUser && userData) {
            const isActiveRef = ref(db, `facility-user-accounts/${userData.facilityId}/openBookingHours`)
            const unsubscribe = onValue(isActiveRef, (snapshot) => {
                if (snapshot.exists()) {
                    setupOpenBookingTimesAreActive(snapshot);
                }
            });
            return unsubscribe;
        } else {
            setOpenBookingTimesAreActive(false);
        }
    }, [userData, db, currentUser]);

    useEffect(() => {

        const setupOpenBookingTimes = (snapshot) => {
            if (snapshot){
                setOpenBookingTimes(snapshot.val());
            } else {
                setOpenBookingTimes(null);
            }
        }

        if (currentUser && userData) {
            const openBookingTimesRef = ref(db, `facility-user-accounts/${userData.facilityId}/openBookingTimes`)
            const unsubscribe = onValue(openBookingTimesRef, (snapshot) => {
                if (snapshot.exists()) {
                    setupOpenBookingTimes(snapshot);
                }
            });
            return unsubscribe;
        } else {
            setupOpenBookingTimes(null);
        }
    }, [currentUser, userData, db]);

    const value = { openBookingTimes, setOpenBookingTimes, openBookingTimesAreActive, setOpenBookingTimesAreActive }
    return <FacilityOpenBookingTimesContext.Provider value={value}>{children}</FacilityOpenBookingTimesContext.Provider>

}