import { TextAreaContainer } from './text-area-input.styles';


const TextAreaInput = ({ label, ...otherProps }) => {
    return (
        <TextAreaContainer>
            <span className="text-area-label">{label}</span>
            <textarea className="text-area-input" {...otherProps} />
        </TextAreaContainer>
    );
}

export default TextAreaInput