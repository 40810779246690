import { useContext } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Stack, Typography, Box } from "@mui/material";
import { CalendarSettingsContext } from "../../../../contexts/customer/calendar-settings.context";

const CalendarAreasHeader = ({facilityAreas, timesColumnWidth = '4.0vw'}) => {

    const mainFontSize = 'clamp(0.01vh, -0.1250vh + 13.5000vh, 1.5vh)';
    const subFontSize =  'clamp(0.01vh, -0.1250vh + 13.5000vh, 1.25vh)';

    const { calendarSettings } = useContext(CalendarSettingsContext);

    return (
        <Grid sx={{ marginLeft: timesColumnWidth }} container columns={facilityAreas.length}>
            {
                facilityAreas.map((facilityArea) => {
                    const { id, name, subAreas, searchStatus } = facilityArea ?? {};
                    return <Grid key={`main-grid-${id}`} xs={1}>
                        {
                            subAreas &&
                            <Stack justifyContent={'flex-end'} sx={{ height: '100%', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
                                <Box sx={{ height: '100%', backgroundColor: `${searchStatus === 'inactive' ? 'red' : 'white'}`, marginBottom: '0.25vh' }} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                                    <Typography sx={{ fontSize: `${mainFontSize}` }} fontFamily={"Helvetica"} color={`${calendarSettings ? calendarSettings.mainFacilityColor : "black"}`} fontWeight={'bold'} textAlign={'center'}>{name}</Typography>
                                </Box>
                                <Grid sx={{ width: '100%', height: '100%' }} container columns={subAreas.length}>
                                    {
                                        facilityArea.subAreas.map((subArea) => {
                                            return <Grid key={`sub-grid-${subArea.id}`} xs={1}>
                                                <Box sx={{height: '100%', backgroundColor: `${subArea.searchStatus === 'inactive' ? 'red' : '#eaeaea'}`, boxShadow: '0 3px 10px rgb(0 0 0 / 0.5)', marginLeft: '0.15vw', marginRight: '0.15vw' }} display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                                                    <Typography key={`sub-area-hdr-${subArea.id}`} marginLeft={'0.25vw'} marginRight={'0.25vw'} fontFamily={"Helvetica"} color={`${calendarSettings ? calendarSettings.mainFacilityColor : "black"}`}  sx={{ fontSize: `${subFontSize}`}} textAlign={'center'}>{subArea.name}</Typography>
                                                </Box>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Stack>

                        }
                        {
                            !facilityArea.subAreas &&
                            <Box sx={{ height: '100%', width: '100%', backgroundColor: `${searchStatus === 'inactive' ? 'red' : 'white'}`, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                <Typography fontFamily={"Helvetica"} color={`${calendarSettings ? calendarSettings.mainFacilityColor : "black"}`} fontWeight={'bold'} sx={{ width: '100%', fontSize: `${mainFontSize}` }} textAlign={'center'}>{name}</Typography>
                            </Box>

                        }
                    </Grid>
                })
            }
        </Grid>
    )

}

export default CalendarAreasHeader;