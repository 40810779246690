import { FacilityReservationsContext } from '../../../../contexts/facility/facility-reservations.context';
import { useContext, useState } from "react";
import { DisplayContext } from '../../../../contexts/dispaly-options.context';
import { FacilityOpenBookingTimesContext } from '../../../../contexts/facility/facility-open-booking-times.context';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Stack, Typography, Box, IconButton, Popover, Divider } from '@mui/material';
import ZoomOutMapRoundedIcon from '@mui/icons-material/ZoomOutMapRounded';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AddIcon from '@mui/icons-material/Add';


const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const defaultCalendarDimensions = {
    facility_calendar_height: '100%',
    facility_calendar_width: '100%',
    area_column_height: '80vh',
    zoom_multiplier: 1
}
const largeCalendarDimensions = {
    facility_calendar_height: '2000px',
    facility_calendar_width: '2000px',
    area_column_height: '2000px',
    zoom_multiplier: 2
}
const largerCalendarDimensions = {
    facility_calendar_height: '2500px',
    facility_calendar_width: '2500px',
    area_column_height: '2500px',
    zoom_multiplier: 3
}
const largestCalendarDimensions = {
    facility_calendar_height: '3000px',
    facility_calendar_width: '3000px',
    area_column_height: '3000px',
    zoom_multiplier: 4
}


const ReservationCalendarMenuBar = ({ openMenu }) => {

    const { setReservationsDate, reservationsDate } = useContext(FacilityReservationsContext);
    const { openBookingTimesAreActive } = useContext(FacilityOpenBookingTimesContext);
    const { setCalendarDimensions } = useContext(DisplayContext);
    const [menuLegendEl, setMenuLegendEl] = useState(null);
    const [menuLegendMessage, setMenuLegendMessage] = useState(null);

    const gotToToday = () => {
        setReservationsDate(new Date());
    }

    const goForward = () => {
        const dateCopy = new Date(reservationsDate);
        dateCopy.setDate(reservationsDate.getDate() + 1);
        setReservationsDate(dateCopy);
    }

    const goBackward = () => {
        const dateCopy = new Date(reservationsDate);
        dateCopy.setDate(reservationsDate.getDate() - 1);
        setReservationsDate(dateCopy);
    }

    const toggleCalendarSettingsMenuIsOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const showLeftMenu = () => {
        if (openMenu) {
            openMenu();
        }
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fitToPage = () => {
        setCalendarDimensions(defaultCalendarDimensions);
        setAnchorEl(null);
    }

    const fitToLarge = () => {
        setCalendarDimensions(largeCalendarDimensions);
        setAnchorEl(null);
    }

    const fitToLarger = () => {
        setCalendarDimensions(largerCalendarDimensions);
        setAnchorEl(null);
    }

    const fitToLargest = () => {
        setCalendarDimensions(largestCalendarDimensions);
        setAnchorEl(null);
    }

    const handleMenuLegendClosedSlotOpen = (event) => {
        setMenuLegendEl(event.currentTarget);
        setMenuLegendMessage('Area is closed to customers');
    };

    const handleMenuLegendOpenBookingSlotOpen = (event) => {
        setMenuLegendEl(event.currentTarget);
        setMenuLegendMessage('Open Booking TIme - Available to customers');
    }

    const handleMenuLegendClose = () => {
        setMenuLegendEl(null);
        setMenuLegendMessage(null);
    };

    const dateString = moment(reservationsDate).format("dddd, MMMM D, YYYY");
    const prevDateString = moment(reservationsDate).add(-1, 'day').startOf('day').format("ddd, MMMM D");
    const nextDateString = moment(reservationsDate).add(1, 'day').startOf('day').format("ddd, MMMM D");
    return (
        <Box sx={{ position: 'fixed' }}>
            <Grid alignItems={'center'} justifyContent={'center'} sx={{ width: '100vw', marginLeft: '1.0vw', marginRight: '1.0vw', marginTop: '1.0vh' }} container>
                <Grid item xs={1}>
                    <Stack direction={'row'}>
                        <IconButton
                            size="large"
                            aria-label="calendar"
                            onClick={showLeftMenu}
                        >
                            <AddIcon sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 30 } }} />
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="calendar"
                            onClick={showLeftMenu}
                        >
                            <CalendarMonthOutlinedIcon sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 30 } }}/>
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid item xs={9}>
                    <Stack sx={{ width: '100%' }} spacing={5} direction={'row'} display={'flex'} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
                        <Button sx={{fontSize: { xs:8, sm: 10, md: 12, lg: 15 } }}  onClick={goBackward} startIcon={<ArrowBackIosIcon sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 30 } }}  />}>
                            {prevDateString}
                        </Button>
                        <Typography sx={{fontSize: { xs: 10, sm: 8, md: 20, lg: 25 } }}  textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={'#14254C'} variant="h6" component="div">
                            {dateString}
                        </Typography>
                        <Button sx={{fontSize: { xs: 8, sm: 10, md: 12, lg: 15 } }}  onClick={goForward} endIcon={<ArrowForwardIosIcon sx={{fontSize: { xs: 5, sm: 16, md: 25, lg: 30 } }}  />}>
                            {nextDateString}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={1}>
                    <Box display="flex"
                        justifyContent="flex-end"
                        alignContent={'center'} alignItems={'center'}>
                        {
                            openBookingTimesAreActive &&

                            <Stack spacing={1} direction={'row'}>
                                <Box onMouseEnter={handleMenuLegendClosedSlotOpen} onMouseLeave={handleMenuLegendClose} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', width: '2.0vh', height: '2.0vh', borderRadius: '50%' }}></Box>
                                <Box onMouseEnter={handleMenuLegendOpenBookingSlotOpen} onMouseLeave={handleMenuLegendClose} sx={{ backgroundColor: 'RGBA(72, 113, 247, 0.25)', width: '2.0vh', height: '2.0vh', borderRadius: '50%' }}></Box>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={menuLegendMessage ? true : false}
                                    anchorEl={menuLegendEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handleMenuLegendClose}
                                    disableRestoreFocus
                                >
                                    <Box>
                                        <Typography sx={{ marginLeft: '2.0vw', marginRight: '2.0vw' }} textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div" ml={1}>
                                            {menuLegendMessage}
                                        </Typography>
                                    </Box>
                                </Popover>
                            </Stack>
                        }
                        {/* <Button size='large' onClick={toggleCalendarSettingsMenuIsOpen} endIcon={<ZoomOutMapRoundedIcon />}>Zoom</Button> */}
                        <IconButton
                            size="large"
                            aria-label="calendar-zoom"
                            onClick={toggleCalendarSettingsMenuIsOpen}
                        >
                            <ZoomOutMapRoundedIcon sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 30 } }} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Divider></Divider>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem sx={{ color: '#14254C' }} onClick={fitToPage}>Fit To Page</MenuItem>
                <MenuItem sx={{ color: '#14254C' }} onClick={fitToLarge}>Large</MenuItem>
                <MenuItem sx={{ color: '#14254C' }} onClick={fitToLarger}>Larger</MenuItem>
                <MenuItem sx={{ color: '#14254C' }} onClick={fitToLargest}>Largest</MenuItem>
            </Menu>
        </Box>

    )
}

export default ReservationCalendarMenuBar