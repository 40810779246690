import FacilityCalendar from '../../../components/facility/facility-calendar/facility-calendar.component'
import { FacilityCalendarHomeContainer } from './facility-calendar-home.styles'
import { UserContext } from "../../../contexts/user.context";
import LoadingIndicator from '../../../components/loading-indicator/loading-indicator.component';
import { useContext, useEffect } from "react";
import { DisplayContext } from '../../../contexts/dispaly-options.context';

const FacilityCalendarHome = () => {

    const { userData, userIsLoading } = useContext(UserContext);
    const { calendarDimensions, openViews } = useContext(DisplayContext);
    //this goes with the text width below
    // const { facilityAreas } = useContext(FacilityAreaContext);

    // useEffect(() => {
    //     const html = document.querySelector("html");
    //     if (html) {
    //         html.style.overflow = openViews.length > 1 ? "hidden" : "scroll";
    //     }
    // }, [openViews]);

    //leaving this here just in case we want to use it 
    // const width = useTextWidth({text: GetAllAreaNamesString(facilityAreas), font: '20px Times'});
    return (
        <FacilityCalendarHomeContainer $height={calendarDimensions.facility_calendar_height} $width={calendarDimensions.facility_calendar_width} >
            {userIsLoading &&
                <LoadingIndicator />
            }
            {
                !userIsLoading && userData && userData.isFacilityUserAccount &&
                <div>
                    <FacilityCalendar />
                </div>
            }
            {
                !userData || !userData.isFacilityUserAccount && !userIsLoading &&
                <div>
                    <spa>You are not authorized to view this page.</spa>
                </div>
            }
        </FacilityCalendarHomeContainer>
    );

};

export default FacilityCalendarHome;