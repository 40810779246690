import { createContext, useEffect, useContext, useReducer } from "react";
import { UserContext } from "../user.context";
import { getFacilityMemberships } from "../../utils/facility-api/facility-service";
import { createAction } from "../../utils/reducer/reducer.utils";

export const FacilityMembershipsContext = createContext({
    memberships: [],
    setMemberships: () => [],

});

export const FACILITY_MEMBERSHIPS_ACTION_TYPES = {
    SET_FACILITY_MEMBERSHIPS: 'SET_FACILITY_MEMBERSHIPS',
}

const INITIAL_STATE = {
    memberships: null,
}

const facilityMembershipsReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case FACILITY_MEMBERSHIPS_ACTION_TYPES.SET_FACILITY_MEMBERSHIPS:
            return {
                ...state, //this keeps everything else and doesn't overwrite
                ...payload
            }
        default:
            throw new Error(`Unhandled type ${type}`);
    }

}

export const FacilityMembershipsProvider = ({ children }) => {

    const [ {memberships }, dispatch ] = useReducer(facilityMembershipsReducer, INITIAL_STATE);
    const { currentUser, userData } = useContext(UserContext);

    const setMemberships = (memberships) => {
        dispatch(createAction(FACILITY_MEMBERSHIPS_ACTION_TYPES.SET_FACILITY_MEMBERSHIPS, {memberships: memberships}));
    }

    useEffect(() => {
        async function getMemberships() {
            if (currentUser && userData) {
                const response = await getFacilityMemberships(userData.facilityId);
                if (response && response.data && response.data.memberships) {
                    setMemberships(response.data.memberships);
                } else {
                    setMemberships({});
                }
            }
        }
        getMemberships();
    }, [currentUser, userData]);

    const value = {
        memberships, setMemberships
    }
    return <FacilityMembershipsContext.Provider value={value}>{children}</FacilityMembershipsContext.Provider>
}