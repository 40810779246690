import React from "react";
import { Box, Typography } from "@mui/material";
import { v4 as uuid } from 'uuid';


const TimeRow = ({ rowTime, rowNumber, totalColumns, hoursOfOperation }) => {

    let borderColor = 'gray';
    if (rowTime.isSameOrAfter(hoursOfOperation.facilityOpenDateTime) && rowTime.isBefore(hoursOfOperation.facilityCloseDateTime)) {
        borderColor = '#A3B339';
    }

    const p2Style = {
        gridColumn: `1 / ${totalColumns + 1}`,
        gridRow: `${rowNumber}`,
        display: 'grid',
        gridTemplateRows: 'repeat(15, 1fr)',
        borderRight: `solid 5px ${borderColor}`
    };

    const minuteArray = [];
    var i = 1;
    while (i <= 15) {
        minuteArray.push(i++);
    }

    const id = uuid();
    const displayTime = rowTime.format('mm') === "00" ? rowTime.format('h a') : ""
    return (

        <div style={p2Style}>
            {
                minuteArray.map((minute) => {
                    return <Box key={`time-row-${id}-${minute}`} sx={{ gridRow: `${minute} / ${minute}`}}>
                        {
                            minute === 1 && displayTime !== "" &&
                            <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} sx={{ height: '0px' }}>
                                <Typography sx={{ zIndex: '9999', color: 'black', fontSize: {xs: '2.75vw', sm: '1.75vw' , md: '0.75vw', l: '0.75vw', xl: '0.75vw'}  }}>{displayTime}</Typography>
                            </Box>
                        }
                    </Box>
                })
            }
        </div>

    )
}

export default TimeRow;