import { FacilityCustomerReservationSearchRowContainer } from './facility-customer-reservation-search-row.styles';
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context';
import { useContext } from 'react';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const formatAmount = (amount) => {

    let floatAmount = 0.0;
    if (amount > 0) {
        floatAmount = parseFloat(amount / 100);
    }

    return `$${floatAmount.toFixed(2)}`
}


const FacilityCustomerReservationSearchRow = ({ reservation }) => {

    const { setReservationToView } = useContext(FacilityReservationsContext);

    const viewReservation = () => {
        const viewThisRes = {
            reservationId: reservation.reservationId,
            dateKey: reservation.reservationDate,
            facilityId: reservation.facilityId,
            customerId: reservation.customerId,
            isInternalBooking: reservation.isInternalBooking
        };
        setReservationToView(viewThisRes)
    }

    return (
        <FacilityCustomerReservationSearchRowContainer onClick={viewReservation} key={reservation.reservationId} >
            <span className="main-area" >{reservation.mainAreaName}</span>
            <span className="reservation-date-time ">{reservation.startTime} - {reservation.endTime}</span>
            <span className="reservation-title">{reservation.title}</span>
            {
                reservation.paidStatus &&
                <span className="reservation-paid-detials">{reservation.paidStatus.toUpperCase()} - {formatAmount(reservation.reservationTotal)} Service Charge: {formatAmount(reservation.serviceCharge)}</span>
            }
            {
                reservation.paymentFailed &&
                <span className="reservation-failed-payment">PAYMENT FAILED</span>
            }
            {
                reservation.wasCanceled && reservation.canceledOn &&
                <div className="canceled-details">
                    <span className='canceled-info' >Canceled On: {moment(reservation.canceledOn).format("dddd, MMM D, YYYY h:mm a")}</span>
                    {
                        reservation.cancelChargeAmount &&
                        <span className='canceled-info'> Cancel Charge Amount: {formatAmount(reservation.cancelChargeAmount)}</span>
                    }

                </div>
            }
            <span className='reservation-id'>Reservation Id - {reservation.reservationId}</span>
        </FacilityCustomerReservationSearchRowContainer>
    )
}

export default FacilityCustomerReservationSearchRow