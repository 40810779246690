import * as React from 'react';
import { getServiceChargeAmount } from '../../../utils/service-charge-utils/service-charge-utils';
import emptycheckboximg from "../../../assets/empty-checkbox.svg";
import checkedboximag from "../../../assets/checked-checkbox.svg";
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const FacilityReservationListRow = ({ searchResultsForDate, searchResultWasClicked, searchResultIsSelected }) => {

    const refs = searchResultsForDate.reduce((acc, value) => {
        acc[value.reservationId] = React.createRef();
        return acc;
    }, {});

    const handleClick = (e) => {
        e.preventDefault();
        const reservationId = e.currentTarget.dataset.id;
        const searchResult = searchResultsForDate.find(result => result.reservationId === reservationId);
        searchResultWasClicked(searchResult);
    }

    return (
        searchResultsForDate.map(searchResult => (
            <li key={searchResult.reservationId} data-id={searchResult.reservationId} onClick={handleClick.bind(this)}
                ref={refs[searchResult]}
                className={`search-result${searchResultIsSelected(searchResult) ? '-selected' : "-unselected"}`}>

                <img src={searchResultIsSelected(searchResult) ? checkedboximag : emptycheckboximg} alt='img' className='checkbox-img' />
                <div className='box-and-area-container'>
                    <span key={`search-area-reserved-${searchResult.reservationId}`} className="search-result-areaReserved">{searchResult.areaReserved} - {moment(searchResult.startDate, "YYYY-MM-DD h:mm a").format('h:mm a')} to {moment(searchResult.endDate, "YYYY-MM-DD h:mm a").format('h:mm a')}</span>
                    <span key={`search-resut-price-${searchResult.reservationId}`} className="search-result-price">Price: ${parseFloat(searchResult.price).toFixed(2)} - Service Charge: ${getServiceChargeAmount(searchResult.charges)}</span>
                </div>
            </li>
        ))
    )
}

export default FacilityReservationListRow