import { Box, FormControl, Typography, Stack, Button, Modal, FormControlLabel, Switch, List, ListItem, FormLabel, IconButton, Paper, Collapse, Divider } from "@mui/material";
import DragAndDropFile from "../../files/drag-and-drop-file.compoent";
import { useState, Fragment, useContext, useEffect } from "react";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { CustomDisableInput } from "../../../utils/text-field-utils/text-field-utils";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApplicationDataContext } from "../../../contexts/application-data-context";
import { NoDeciamlFormat } from "../../../utils/text-field-utils/text-field-utils";
import MySportSpaceAlert from "../../alert/alert.component";
import SelectSports from "../../sports-and-subtype/select-sports-component";
import SelectSubTypeForSport from "../../sports-and-subtype/select-sub-type-for-sport-component";
import AddIcon from '@mui/icons-material/Add';
import { saveFacilityArea } from "../../../utils/firebase/facility-area-utils";
import { UserContext } from "../../../contexts/user.context";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import { validateAddress } from "../../../utils/location-utils/location-utils";
import { uploadFileToStorage } from "../../../utils/firebase/upload-facility-image";
import TextFieldAddPopup from "../../textfield-add-popup/textfield-add-popup.compoent";
import AreaPricing from "./area-pricing.cmponent";
import { v4 as uuid } from 'uuid';
import { getEditLock, removeEditLock } from "../../../utils/firebase/firebase.utils";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll',
    width: '90%',
    height: '90%'
};

const GetSubTypeTextForSport = (sport, subType, sportToSubAreas) => {
    const subTypeMapping = sportToSubAreas[sport];
    if (subTypeMapping && subTypeMapping.subTypes) {
        const sortedSubTypes = Object.keys(subTypeMapping.subTypes);
        for (const key of sortedSubTypes) {
            if (subTypeMapping.subTypes[key] === subType) {
                return key;
            }
        }
    }
    return subType;
}

const AreaSetup = ({ facilityAreaIn, close, updateArea, saveTheArea = true }) => {

    const [isEditMode, setIsEditMode] = useState(false);
    const [isSaving, setIsSaving] = useState(false)
    const { userData, isAdmin } = useContext(UserContext);
    const [facilityArea, setFacilityArea] = useState(Object.assign({}, facilityAreaIn));
    const [originalFacilityArea, setOriginalFacilityArea] = useState(Object.assign({}, facilityAreaIn))
    const [areaIsAtAddress, setAreaIsAtAddress] = useState(facilityAreaIn && facilityAreaIn.address ? false : true);
    const { sportToSubAreas } = useContext(ApplicationDataContext);
    const [alert, setAlert] = useState(null);
    const [alertAndExitMessage, setAlertAndExitMessage] = useState(null);
    const [sportsPopoverAnchorEl, setSportsPopOverAnchorEl] = useState(null);
    const [subtypesPopoverAnchorEl, setSubtypesPopoverAnchorEl] = useState(null);
    const [specsPopoverAnchorEl, setSpecsPopoverAnchorEl] = useState(null);
    const [newImageFile, setNewImageFile] = useState(null);
    const [newSpecifications, setNewSpecifications] = useState(null);
    const [selectedSubArea, setSelectedSubArea] = useState(null);
    const [editLockName, setEditLockName] = useState('facilityAreas/editLock');
    const { name, type, address, subAreaList, sports, sportSubTypes, specs, description, retailOnly, membershipsOnly, capacity, isNewArea, copiedFromAreaName } = facilityArea;

    useEffect(() => {

        function configureEditMode(area) {
            setIsEditMode(area.isNewArea ? true : false);
            const editLock = `facilityArea/${area.id}`;
            setEditLockName(editLock);
            return editLock;
        }

        let editLock = 'editlock';
        if (facilityAreaIn) {
            editLock = configureEditMode(facilityAreaIn)
        }

        return () => {
            removeEditLock(editLock, userData);
        }

    }, [facilityAreaIn]);

    const saveArea = async () => {

        if (!name || name.length <= 0) {
            setAlert({ title: "No Area Name", message: `An area must have a name.`, type: 'ok' });
            return;
        }
        if (isNewArea && !newImageFile && !facilityArea.imageLocationURL) {
            setAlert({ title: "No Area Image", message: `You must provide an image for this area.`, type: 'ok' });
            return;
        }

        if (isNewArea && type === 'Multiple Area' && subAreaList.length < 2) {
            setAlert({ title: "Need More SubAreas", message: `A multiple area must have more than 1 sub area.`, type: 'ok' });
            return;
        }

        if (!areaIsAtAddress && (!facilityArea.address || facilityArea.address === "")) {
            setAlert({ title: "No Facility Address", message: `You have indicated that this area is not at the facility address.  Please provide an address for this area.`, type: 'ok' });
            return;
        }

        if (specs) {
            const emptySpecs = specs.filter((s) => {
                return s === "";
            });
            if (emptySpecs && emptySpecs.length > 0) {
                setAlert({ title: "Empty Specification", message: `A specification cannot be empty`, type: 'ok' });
                return;
            }
        }

        if (!facilityArea.sports || facilityArea.sports.length <= 0) {
            setAlert({ title: "No Sports Selected", message: `An area must have at least 1 sport.`, type: 'ok' });
            return;
        } else {

            for (const sport of facilityArea.sports) {
                const subTypesAvailable = sportToSubAreas[sport] && sportToSubAreas[sport].subTypes;
                if (subTypesAvailable) {
                    const subTypesForSport = sportSubTypes[sport];
                    if (!subTypesForSport || subTypesForSport.length <= 0) {
                        setAlert({ title: "Sport Sub Type Required", message: `${sport} must have at least 1 subtype.  To select go to ${sport} and click +`, type: 'ok' });
                        return;
                    }
                }
            }
        }

        if (updateArea) {
            if (newImageFile) {
                facilityArea.imageLocationURL = URL.createObjectURL(newImageFile);
            }
            updateArea(facilityArea);
        }
        if (saveTheArea) {
            if (userData && userData.isFacilityUserAccount && isAdmin) {
                setIsSaving(true);
                if (!areaIsAtAddress) {
                    const addressResponse = await validateAddress(facilityArea.address);
                    if (!addressResponse.data.result.verdict.addressComplete) {
                        setIsSaving(false);
                        setAlert({ title: "Invalid Area Address", message: `The area address is invalid`, type: 'ok' });
                        return;
                    } else if (addressResponse.data.result.address.formattedAddress) {
                        facilityArea.address = addressResponse.data.result.address.formattedAddress;
                    }
                }

                if (newImageFile) {
                    try {
                        const uploadResponse = await uploadFileToStorage(newImageFile);
                        facilityArea.imageLocationURL = uploadResponse.downloadURL;
                        facilityArea.imageLocation = uploadResponse.storageLocation;
                    } catch (error) {
                        console.log(error);
                        setIsSaving(false);
                        setAlert({ title: "Image Upload Error", message: `Unable to upload the new area image.  Please try again.`, type: 'ok' });
                        return;
                    }
                }

                const saved = await saveFacilityArea(userData.facilityId, facilityArea);
                setIsSaving(false);
                if (saved) {
                    setIsEditMode(false);
                    if (!facilityArea.isNewArea) {
                        setAlertAndExitMessage({ title: "Saved", message: `The facility area has been saved.` });
                    } else {
                        setAlert({ title: "Saved", message: `The facility area has been saved.`, type: 'ok' });
                        setOriginalFacilityArea(Object.assign({}, facilityArea));
                    }

                } else {
                    setAlert({ title: "Save Error", message: `The facility area has NOT been saved.`, type: 'ok' });
                }
            } else {
                setAlert({ title: "Invalid User", message: `You must be logged in as facility admin user to save.`, type: 'ok' });
            }
        } else {
            exit();
        }
    }

    const updateAreaPricing = (defaultPricesToDow) => {
        const keys = Object.keys(defaultPricesToDow);
        const newDefaultPricing = {};
        for (const dowKey of keys) {
            const pricingForDow = defaultPricesToDow[dowKey];
            for (const pricing of pricingForDow) {
                newDefaultPricing[pricing.id] = pricing;
            }
        }
        setFacilityArea({ ...facilityArea, ['defaultpricing']: newDefaultPricing });
    }

    const updateAreaExceptionPricing = (exceptionPricing) => {
        const keys = Object.keys(exceptionPricing);
        const newExceptionPricing = {};
        for (const key of keys) {
            const pricingForDow = exceptionPricing[key];
            for (const pricing of pricingForDow) {
                newExceptionPricing[pricing.id] = pricing;
            }
        }
        setFacilityArea({ ...facilityArea, ['pricingexceptions']: newExceptionPricing });
    }

    const closeView = () => {
        if (isEditMode && !facilityArea.isNewArea) {
            setAlert({ title: "Edit Mode", message: `You currently are in edit mode, cancel or save before leaving.`, type: 'ok' });
        } else if (isEditMode && facilityArea.isNewArea) {
            setAlert({ title: "New Area Not Saved", message: `Are you sure you want to leave?  This new area has not been saved.` });
        }
        else {
            close();
        }
    }

    const exit = () => {
        close();
    }

    const updateCopiedAreaAndExit = () => {
        if (updateArea) {
            if (newImageFile) {
                facilityArea.imageLocationURL = URL.createObjectURL(newImageFile);
            }
            updateArea(facilityArea);
        }

        close();
    }

    const edit = async () => {

        if (userData && userData.isFacilityUserAccount && isAdmin) {

            setIsSaving(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsSaving(false);

            if (retrievedEditLock) {
                setIsEditMode(true);
            } else {
                setAlert({ title: "Edit Lock", message: `Another user is currently editing this area.`, type: 'ok' });
            }
        } else {
            setAlert({ title: "Not Authorized", message: `You are not authorized to edit this facility area.`, type: 'ok' });
        }

    }

    const cancelEdit = () => {
        removeEditLock(editLockName, userData)
        if (facilityArea.isNewArea) {
            if (!saveTheArea && facilityArea.isBeingCopied) {
                setFacilityArea(originalFacilityArea);
                setAlert(null);
                setNewImageFile(null);
            } else {
                close();
            }
        } else {
            setFacilityArea(originalFacilityArea);
            setIsEditMode(false);
            setAlert(null);
            setNewImageFile(null);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'capacity' && value === "") {
            setFacilityArea({ ...facilityArea, [name]: null });
            return;
        }

        if (name === 'retailOnly') {
            setFacilityArea({ ...facilityArea, [name]: !retailOnly });
            return;
        }

        if (name === 'membershipsOnly') {
            setFacilityArea({ ...facilityArea, [name]: !membershipsOnly });
            return;
        }

        setFacilityArea({ ...facilityArea, [name]: value });

    }

    const handleAddressToggle = (event) => {
        if (areaIsAtAddress) {
            setFacilityArea({ ...facilityArea, ['address']: null });
        }
        setAreaIsAtAddress(!areaIsAtAddress);
    }

    const handleDeleteSport = (e) => {
        const sport = e.currentTarget.dataset.id;
        const newSports = sports.filter((s) => {
            return s !== sport;
        });

        setFacilityArea({ ...facilityArea, ['sports']: newSports });
    }

    const handleSportSelected = (sport) => {

        let newSports = [];
        if (sports) {
            const sportFound = sports.filter((s) => {
                return s === sport;
            });

            if (sportFound && sportFound.length > 0) {
                return;
            }

            newSports = sports.concat(sport).sort((s1, s2) => {
                if (s1 & s2) {
                    if (s1.sortOrder < s2.sortOrder) {
                        return -1;
                    }

                    if (s1.sortOrder > s2.sortOrder) {
                        return 1;
                    }
                }
                return 0;
            });
        } else {
            newSports = [sport];
        }

        setFacilityArea({ ...facilityArea, ['sports']: newSports });

    }

    const handleDeleteSubType = (e) => {
        const sportToSubType = e.currentTarget.dataset.id.split(`~`);
        const subTypeInt = parseInt(sportToSubType[1]);
        const sport = sportToSubType[0];
        const subTypesForSport = sportSubTypes[sport];

        let newSubTypesForSport = subTypesForSport.filter((sub) => {
            return sub !== subTypeInt;
        });

        if (newSubTypesForSport.length <= 0) {
            setAlert({ title: "Sub Type Needed", message: `${sport} areas must have at least 1 Sub Type.`, type: 'ok' });
            return;
        } else {
            const newSportToSubMappings = Object.assign({}, sportSubTypes);
            newSportToSubMappings[sport] = newSubTypesForSport;
            setFacilityArea({ ...facilityArea, ['sportSubTypes']: newSportToSubMappings });
        }
    }

    const handleAddSubType = (sport, subTypeInt) => {
        const subTypeAsInt = parseInt(subTypeInt)
        let newSportToSubMappings = Object.assign({}, sportSubTypes);
        if (sportSubTypes) {
            const subTypesForSport = sportSubTypes[sport];
            if (subTypesForSport) {
                let subTypeFound = subTypesForSport.filter((sub) => {
                    return sub === subTypeAsInt;
                });

                if (subTypeFound && subTypeFound.length > 0) {
                    return;
                }

                newSportToSubMappings[sport] = subTypesForSport.concat(subTypeAsInt);
            } else {
                newSportToSubMappings[sport] = [subTypeAsInt];
            }
        } else {
            newSportToSubMappings[sport] = [subTypeAsInt];
        }
        setFacilityArea({ ...facilityArea, ['sportSubTypes']: newSportToSubMappings });
    }

    const handleAddSubtypeForSport = (event) => {
        setSubtypesPopoverAnchorEl(event.currentTarget);
    }

    const handleAddSport = (event) => {
        setSportsPopOverAnchorEl(event.currentTarget);
    }

    const closeSports = () => {
        setSportsPopOverAnchorEl(null);
    }

    const closeSubTypes = () => {
        setSubtypesPopoverAnchorEl(null);
    }

    const handleAddSpecification = (event) => {
        setSpecsPopoverAnchorEl(event.currentTarget);
    }

    const handleSpecChange = (spec) => {

        let newSpecs = [];
        if (specs) {
            const filteredSpecs = specs.filter((s) => {
                return s !== spec;
            });

            newSpecs = filteredSpecs.concat([spec]);
        } else {
            newSpecs = [spec];
        }

        setFacilityArea({ ...facilityArea, ['specs']: newSpecs });
    }

    const handleDeleteSpecification = (event) => {
        const spec = event.currentTarget.dataset.id;
        const filteredSpecs = specs.filter((s) => {
            return s !== spec;
        })

        setFacilityArea({ ...facilityArea, ['specs']: filteredSpecs });

        const newSpecs = Object.assign({}, newSpecifications);
        newSpecs[spec] = null;
        setNewSpecifications(newSpecs);

    }

    const closeSpecificationPopup = () => {
        setSpecsPopoverAnchorEl(null);
    }

    const cancelPrompt = () => {
        if (facilityArea.isNewArea) {
            if (!saveTheArea && facilityArea.isBeingCopied) {
                setAlert({ title: "Reset Changes?", message: "Are you sure you want to reset this area to the original values?" });
            } else {
                if (copiedFromAreaName){
                    setAlert({ title: "Cancel Area Copy", message: "Are you sure you want to cancel copying this area?  Any unsaved changes will be lost." });
                } else {
                    setAlert({ title: "Cancel New Area?", message: "Are you sure you want to cancel adding this area?  Any unsaved changes will be lost." });
                }
            }
        } else {
            setAlert({ title: "Cancel Edit?", message: "Are you sure you want to cancel editing?  Any unsaved changes will be lost." });
        }

    }

    const doNoCancel = () => {
        setAlert(null);
    }

    const handleFileError = (error) => {
        setAlert({ title: "Image Error", message: `${error}`, type: 'ok' });
    }

    const newImageFileUploaded = (file) => {
        setNewImageFile(file);
    }

    const selectSubArea = (e) => {

        const id = e.currentTarget.dataset.id;

        const areas = subAreaList.filter((a) => {
            return a.id === id
        });

        if (areas && areas.length > 0) {
            setSelectedSubArea(areas[0]);
        }
    }

    const closeViewArea = () => {
        setSelectedSubArea(null);
    }

    const handleAddSubArea = () => {

        const skeletonSubArea = {
            id: uuid(),
            name: "",
            membershipsOnly: false,
            retailOnly: false,
            parentAreaId: facilityArea.id,
            sports: [],
            type: "Sub Area",
            defaultpricing: [
                { dayOfWeek: "0", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "1", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "2", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "3", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "4", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "5", halfHourPrice: 25.0, hourlyPrice: 50.0, }, { dayOfWeek: "6", halfHourPrice: 25.0, hourlyPrice: 50.0, }
            ],
            sortOrder: subAreaList && subAreaList.length > 0 ? subAreaList[subAreaList.length - 1].sortOrder + .01 : facilityArea.sortOrder + .01,
            isNewArea: true
        }

        setSelectedSubArea(skeletonSubArea);
    }

    const updateSubArea = (subArea) => {

        let newSubAreaList = [];
        if (subAreaList && subAreaList.length > 0) {
            const filteredList = subAreaList.filter((sub) => { return subArea.id !== sub.id });
            newSubAreaList = filteredList.concat([subArea])
        } else {
            newSubAreaList = [subArea];
        }

        let newSubAreas = Object.assign({}, facilityArea.subAreas);
        // delete newSubAreas[subArea.id];
        newSubAreas[subArea.id] = subArea
        setFacilityArea({ ...facilityArea, ['subAreaList']: newSubAreaList, ['subAreas']: newSubAreas });

    }

    return (
        <Box sx={{ ...style, }}>
            <MySportSpaceAlert isOpen={alert ? true : false} message={alert ? alert.message : null} title={alert ? alert.title : null} okButtonText={alert && alert.type && alert.type === 'ok' ? "OK" : "Yes"} cancelButtonText={alert && alert.type && alert.type === 'ok' ? null : "No"} okAction={alert && alert.type && alert.type === 'ok' ? doNoCancel : cancelEdit} cancelAction={doNoCancel} ></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={alertAndExitMessage ? true : false} message={alertAndExitMessage ? alertAndExitMessage.message : null} title={alertAndExitMessage ? alertAndExitMessage.title : null} okButtonText={"OK"} okAction={exit}></MySportSpaceAlert>
            <MySportSpaceLoadingView isOpen={isSaving}></MySportSpaceLoadingView>
            <Modal open={selectedSubArea ? true : false}>
                <Paper sx={{ overflow: 'scroll' }}>
                    <AreaSetup close={closeViewArea} facilityAreaIn={selectedSubArea} updateArea={updateSubArea} saveTheArea={facilityArea.isNewArea ? false : true} ></AreaSetup>
                </Paper>
            </Modal>
            <Stack overflow={'scroll'} direction={'row'} mt={2} mb={2}>
                <Box sx={{ width: '50%' }}>
                    <Stack direction={'row'} display={'flex'} alignItems={'center'}>
                        {
                            saveTheArea &&
                            <IconButton onClick={closeView}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        }
                        {
                            !saveTheArea && facilityArea.isBeingCopied &&
                            <Button onClick={updateCopiedAreaAndExit} startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                        }

                        <Typography ml={2} fontWeight={'bold'} fontFamily={'Helvetica'} color={"#14254C"} variant="h5"> {isNewArea && !copiedFromAreaName ? 'New Area Setup' : isNewArea &&  copiedFromAreaName ? `Copying Area - ${copiedFromAreaName}` :'Facility Area Setup'}  </Typography>
                    </Stack>
                </Box>
                <Box sx={{ width: '50%' }} display="flex" justifyContent="flex-end" alignItems="center">
                    {isEditMode &&
                        <Stack spacing={1} direction={'row'}>
                            {
                                !facilityArea.isBeingCopied && 
                                <Button size="small" startIcon={<SaveOutlinedIcon />} id={'create-button'} variant="contained" type="submit" onClick={saveArea}>{saveTheArea ? 'Save' : 'Add'}</Button>
                            }
                            <Button size="small" startIcon={<CancelOutlinedIcon />} id={'create-button'} variant="contained" onClick={cancelPrompt}>{!saveTheArea && facilityArea.isBeingCopied ? 'Reset' : 'Cancel'}</Button>
                        </Stack>
                    }
                    {
                        !isEditMode &&
                        <Stack spacing={1} direction={'row'}>
                            <Button size="small" startIcon={<EditOutlinedIcon />} id={'create-button'} variant="contained" onClick={edit}>Edit</Button>
                        </Stack>
                    }
                </Box>
            </Stack>
            <Stack direction={'row'} width={'100%'}>
                <Stack mt={5} ml={2} mr={2} spacing={1} width={'35vh'}>
                    <Box sx={{ height: '35vh', width: '30vh', position: 'absolute', backgroundColor: 'lightgray' }}>
                        <img
                            srcSet={`${newImageFile ? URL.createObjectURL(newImageFile) : facilityArea.imageLocationURL && facilityArea.imageLocationURL.includes('blob') ? facilityArea.imageLocationURL : facilityArea.imageLocationURL + '&w=164&h=164&fit=crop&auto=format&dpr=2 2x'}`}
                            src={`${newImageFile ? URL.createObjectURL(newImageFile) : facilityArea.imageLocationURL && facilityArea.imageLocationURL.includes('blob') ? facilityArea.imageLocationURL : facilityArea.imageLocationURL + '&w=164&h=164&fit=crop&auto=format&dpr=2 2x'}`}
                            alt={facilityArea.name}
                            loading='lazy'
                            width={'100%'}
                            height={'100%'}
                            style={{ backgroundColor: `${facilityArea.imageLocationURL || newImageFile ? 'white' : 'gray'}`, objectFit: 'contain' }}
                        />
                        <Box sx={{ top: '0', height: '35vh', position: 'absolute', width: '30vh', zIndex: '999' }}>
                            {
                                isEditMode &&
                                <DragAndDropFile fileUploaded={newImageFileUploaded} fileError={handleFileError} textColor="white" dropText="Drag and Drop New Image Here"></DragAndDropFile>
                            }
                        </Box>
                    </Box>
                </Stack>
                <Box >
                    <form autoComplete="off" onSubmit={saveArea}>
                        <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }}>
                            <Stack spacing={2}>
                                <Stack spacing={1} direction={'row'}>
                                    <CustomDisableInput InputProps={{ readOnly: !isEditMode }} disabled={!isEditMode} required fullWidth sx={{ color: '#14254C' }} id="name" variant="outlined" label="Name" type="text" name="name" value={name} onChange={handleChange} ></CustomDisableInput>
                                    <CustomDisableInput disabled={!isEditMode} fullWidth sx={{ color: '#14254C' }} id="type" variant="outlined" label="Type" type="text" name="type" value={type} ></CustomDisableInput>
                                </Stack>
                                <Stack spacing={1} direction={'row'}>
                                    <CustomDisableInput disabled={!isEditMode} fullWidth sx={{ color: '#14254C' }} id="description" variant="outlined" label="Area Description" type="text" name="description" value={description} helperText={"A unique description of this area, this will replace the area type that is shown to customers ie Full Court"} onChange={handleChange} ></CustomDisableInput>
                                    <CustomDisableInput InputProps={{
                                        step: "1",
                                        min: "1",
                                        inputComponent: NoDeciamlFormat,
                                    }} disabled={!isEditMode} fullWidth sx={{ color: '#14254C' }} id="capacity" variant="outlined" label="Area Capacity" type="number" name="capacity" value={capacity} helperText={"The max number of people this area can hold."} onChange={handleChange}  ></CustomDisableInput>
                                </Stack>
                                {
                                    (type === 'Multiple Area' || type === 'Full Area') &&
                                    <Stack spacing={1} mt={2}>
                                        <Stack direction={'row'}>
                                            <Typography variant="subtitle1" color={"#14254C"} fontFamily={'Helvetica'} fontWeight={'bold'}>
                                                Sub Areas
                                            </Typography>
                                            {
                                                isEditMode &&
                                                <Fragment>
                                                    <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddSubArea}></Button>
                                                </Fragment>
                                            }
                                        </Stack>
                                        <Divider sx={{ marginBottom: '1.0vh' }}></Divider>
                                        <Stack spacing={1} direction={'row'}>
                                            {
                                                subAreaList && subAreaList.length > 0 && subAreaList.map((sArea) => {
                                                    return <Button key={sArea.id} variant="outlined" onClick={selectSubArea.bind(this)} data-id={sArea.id}>
                                                        {sArea.name}
                                                    </Button>
                                                })
                                            }
                                        </Stack>
                                    </Stack>
                                }
                                <Stack>
                                    <Stack>
                                        <Typography variant="subtitle1" color={"#14254C"} fontFamily={'Helvetica'} fontWeight={'bold'}>
                                            Area Location
                                        </Typography>
                                        <Typography ml={1} variant="caption" color={"gray"} fontFamily={'Helvetica'}>
                                            Indicates if this area is not located at your main facility address.
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ marginBottom: '1.0vh' }}></Divider>
                                    <Stack direction={'row'}>
                                        <FormControlLabel sx={{ marginLeft: '1.0vw', color: '#14254C' }} control={<Switch disabled={!isEditMode} checked={areaIsAtAddress} onChange={handleAddressToggle} />} label="Located At Facility" />
                                        {
                                            !areaIsAtAddress &&
                                            <CustomDisableInput disabled={!isEditMode} fullWidth sx={{ ml: '1.0vw', mr: '1.0vw', color: '#14254C' }} id="type" variant="outlined" label="Area Address" type="text" name="address" value={address} onChange={handleChange} helperText={"Street City,State Zip Code"} ></CustomDisableInput>
                                        }
                                    </Stack>
                                </Stack>
                                <Stack>
                                    <Stack>
                                        <Typography variant="subtitle1" color={"#14254C"} fontFamily={'Helvetica'} fontWeight={'bold'}>
                                            Reservation Type Restrictions
                                        </Typography>
                                        <Typography ml={1} variant="caption" color={"gray"} fontFamily={'Helvetica'}>
                                            Use this to restrict to certain a type of customer
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ marginBottom: '0.25vh' }}></Divider>
                                    <Stack direction={'row'}>
                                        <FormControlLabel sx={{ marginLeft: '1.0vw', color: '#14254C' }} control={<Switch disabled={!isEditMode} name="retailOnly" checked={retailOnly} onChange={handleChange} />} label="Retail Only" />
                                        <FormControlLabel sx={{ marginLeft: '1.0vw', color: '#14254C' }} control={<Switch disabled={!isEditMode} name="membershipsOnly" checked={membershipsOnly} onChange={handleChange} />} label="Memberships Only" />
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'}>
                                <Stack width={'50%'}>
                                    <Stack mt={2} spacing={1} direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                        <Stack>
                                            <Typography variant="subtitle1" color={"#14254C"} fontFamily={'Helvetica'} fontWeight={'bold'}>
                                                Sports
                                            </Typography>
                                            <Typography ml={1} variant="caption" color={"gray"} fontFamily={'Helvetica'}>
                                                *REQUIRED - These are the sports your area can accomodate
                                            </Typography>
                                        </Stack>
                                        {
                                            isEditMode &&
                                            <Fragment>
                                                <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddSport}></Button>
                                                <SelectSports isOpen={Boolean(sportsPopoverAnchorEl)} anchorEl={sportsPopoverAnchorEl} sportSelected={handleSportSelected} closeView={closeSports} ></SelectSports>
                                            </Fragment>
                                        }
                                    </Stack>
                                    <Divider sx={{ marginLeft: '1.0vw', marginRight: '1.0vw', marginTop: '0.15vh', marginBottom: '1.0vh' }}></Divider>
                                    <Stack ml={2}>
                                        {

                                            (!sports || sports.length <= 0) &&
                                            <FormLabel key={`no-sports-lbl`} sx={{ ml: 1, mr: 1, color: "#14254C" }}>No Sports</FormLabel>
                                        }
                                        {
                                            sports && sports.length > 0 &&
                                            <List component="div" disablePadding>
                                                {
                                                    sports.map((sport) => {
                                                        const subAreaTypes = sportSubTypes ? sportSubTypes[sport] : [];
                                                        const subTypesAvailable = sportToSubAreas[sport] && sportToSubAreas[sport].subTypes;
                                                        return <Fragment key={`${sport}-fragment`}>
                                                            <ListItem key={`del-btn-${sport}`} sx={{ marginBottom: '0.25vh' }} disablePadding
                                                                secondaryAction={isEditMode &&
                                                                    <Box>
                                                                        {
                                                                            subTypesAvailable &&
                                                                            <IconButton key={`add-sub-icon-btn-${sport}`} edge="end" aria-label="add-sub-type" onClick={handleAddSubtypeForSport.bind(this)} data-id={sport}>
                                                                                <AddIcon key={`add-sub-type-icon-${sport}`} />
                                                                            </IconButton>
                                                                        }
                                                                        <IconButton key={`del-icon-btn-${sport}`} edge="end" aria-label="delete" onClick={handleDeleteSport.bind(this)} data-id={sport}>
                                                                            <DeleteIcon key={`del-icon-${sport}`} />
                                                                        </IconButton>
                                                                    </Box>
                                                                }>
                                                                <Typography key={`sport-typo-${sport}`} color={'#14254C'}>{sport}</Typography>
                                                                <SelectSubTypeForSport isOpen={subtypesPopoverAnchorEl && subtypesPopoverAnchorEl.dataset.id === sport ? true : false} anchorEl={subtypesPopoverAnchorEl} sport={sport} subTypeSelected={handleAddSubType} closeView={closeSubTypes}></SelectSubTypeForSport>
                                                            </ListItem>
                                                            {
                                                                subAreaTypes && subAreaTypes.length > 0 &&
                                                                <Collapse key={`collapse-sub-${sport}`} in={true}>
                                                                    <List key={`list-sub-${sport}`} sx={{ marginBottom: '0.15vh' }} component="div" disablePadding>
                                                                        {
                                                                            subAreaTypes.sort((s1, s2) => { return s1 < s1 ? 1 : s1 > s2 ? -1 : 0 }).map((subType) => {
                                                                                return <ListItem key={`list-subtype-${subType}`} sx={{ marginLeft: '1.0vw' }} disablePadding>
                                                                                    {isEditMode &&
                                                                                        <IconButton key={`del-icon-btn-${subType}`} edge="start" aria-label="delete" onClick={handleDeleteSubType.bind(this)} data-id={`${sport}~${subType}`}>
                                                                                            <DeleteIcon key={`del-icon-${subType}`} />
                                                                                        </IconButton>}
                                                                                    <Typography key={`sport-sub-type-typo-${subType}`} ml={2} color={'#14254C'}>{GetSubTypeTextForSport(sport, subType, sportToSubAreas)}</Typography>
                                                                                </ListItem>
                                                                            })
                                                                        }
                                                                    </List>
                                                                </Collapse>
                                                            }
                                                        </Fragment>
                                                    })
                                                }
                                            </List>
                                        }
                                    </Stack>
                                </Stack>
                                <Stack ml={2} width={'55%'}>
                                    <Stack mt={2} spacing={1} direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                        <Stack>
                                            <Typography variant="subtitle1" color={"#14254C"} fontFamily={'Helvetica'} fontWeight={'bold'}>
                                                Specifications
                                            </Typography>
                                            <Typography ml={1} variant="caption" color={"gray"} fontFamily={'Helvetica'}>
                                                Area features, ex.  Goals can lower, Volleyball net included etc.
                                            </Typography>
                                        </Stack>
                                        {
                                            isEditMode &&
                                            <Fragment>
                                                <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddSpecification}></Button>
                                                <TextFieldAddPopup isOpen={specsPopoverAnchorEl ? true : false} anchorEl={specsPopoverAnchorEl} textFieldLabel={'Specification'} addItem={handleSpecChange} closeView={closeSpecificationPopup}></TextFieldAddPopup>
                                            </Fragment>
                                        }
                                    </Stack>
                                    <Divider sx={{ marginLeft: '1.0vw', marginRight: '1.0vw', marginTop: '0.15vh', marginBottom: '1.0vh' }}></Divider>
                                    <Stack ml={2}>
                                        {

                                            (!specs || specs.length <= 0) &&
                                            <FormLabel sx={{ ml: 1, mr: 1, color: "#14254C" }}>No Specifications</FormLabel>
                                        }
                                        {
                                            specs && specs.length > 0 &&
                                            <List component="div" disablePadding>
                                                {
                                                    specs.map((spec) => {
                                                        return <Fragment key={`${specs.length * Math.random()}-frag`}>
                                                            <ListItem key={spec} sx={{ marginTop: `${isEditMode ? '1.0vh' : '0vh'}`, marginBottom: '0.25vh' }} disablePadding
                                                                secondaryAction={isEditMode &&
                                                                    <IconButton edge="end" aria-label="delete" onClick={handleDeleteSpecification.bind(this)} data-id={spec}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                }>
                                                                <Typography key={`sport-typo-${spec}`} color={'#14254C'}>{spec}</Typography>
                                                            </ListItem>
                                                        </Fragment>
                                                    })
                                                }
                                            </List>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>
                        </FormControl >
                    </form>
                </Box>
            </Stack>
            <Divider sx={{ marginBottom: '4.0vh' }}></Divider>
            <Stack ml={2} mr={2} width={'100%'} overflow={'scroll'} spacing={4} direction={'row'} display={'flex'} justifyContent={'space-evenly'}>
                <AreaPricing area={facilityArea} isEditMode={isEditMode} updateAreaPricing={updateAreaPricing}></AreaPricing>
                <Divider sx={{ height: '100%' }} orientation="vertical" flexItem> </Divider>
                <AreaPricing area={facilityArea} isEditMode={isEditMode} updateAreaPricing={updateAreaExceptionPricing} isExceptions></AreaPricing>
            </Stack>
        </Box>
    )
}

export default AreaSetup;