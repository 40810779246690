import baseApiService from "../base-api/base-api-service";
import { myEncrypt } from "../encryption-helper/encryption-helper";


export const getSportsSupported = async () => {
    try {
        let url = `application/sports-supported`;
        return await baseApiService.get(url);
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const sendEmailVerification = async (emailAddress) => {
    try {
        let url = `user/send-email-verification`;
        const EmailVerificationRequest = {
            emailAddress: emailAddress
        };
        return await baseApiService.post(url, EmailVerificationRequest);
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const attachCustomerToFacility = async (userId, facilityId) => {
    try {
        let url = `user/attach-user-to-facility`;
        const AttachCustomerRequest = {
            userId: userId,
            facilityId: facilityId,
        };
        return await baseApiService.post(url, AttachCustomerRequest);
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const bookReservations = async (customerId, reservationsToBook, paymentMethodId, title, onlyBookIfAllPass = true, bookingType = 'websearch') => {

    const bookTheseReservationIds = [];
    for (const resToBook of reservationsToBook) {
       bookTheseReservationIds.push(resToBook.reservationId);
    }
 
    const ReservationBookingRequest = {
       reservationIds: bookTheseReservationIds,
       isPayingWithCredit: paymentMethodId === 'Invoice' ? false: paymentMethodId ? true : false,
       paymentMethodId: paymentMethodId === 'Invoice' ? null : paymentMethodId,
       isPayingByInvoice: paymentMethodId === 'Invoice' ? true: false,
       customerId: customerId,
       onlyBookIfAllPass: onlyBookIfAllPass,
       title: title,
       isInternalBooking: false,
       isIntegrationTest: false,
       bookedById: customerId,
       bookedByDevice: bookingType
    }
    try {
       let url = `bookings/reservation-book`;
       return await baseApiService.post(url, ReservationBookingRequest);
    } catch (err) {
       console.log(err);
       return err;
    }
 
 }

export const sendAccountCreateEmailToCustomer = async (emailAddress, facilityId, firstName, lastName, password) => {
    try {
        let url = `facility/send-new-account-email`;
        const AccountCreateEmailRequest = {
            emailAddress: emailAddress,
            facilityId: facilityId,
            firstName: firstName,
            lastName: lastName,
            password: password
        };
        return await baseApiService.post(url, AccountCreateEmailRequest);
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const getExistingMySportSpaceCustomer = async (emailAddress) => {
    try {
        let url = `user/existing-my-sport-space-customer?emailAddress=${emailAddress}`;
        return await baseApiService.get(url);
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const createCustomerUser = async (createCustomerRequest) => {
    try {
        let url = `user/create-user-customer`;
        return await baseApiService.post(url, createCustomerRequest);
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const getPaymentMethods = async (stripeCustomerId) => {
    try {
       let url = `stripe/get-customer-payment-methods`;
       const PaymentMethodsRequest = {
          stripeCustomerId: stripeCustomerId
       };
       return await baseApiService.post(url, PaymentMethodsRequest);
    } catch (err) {
       console.log(err);
       return err;
    }
 }

export const addFormOfPayment = async (type, cardDetails, customer) => {

    try {
        let url = `stripe/create-payment-method`;
        const AddPaymentMethodRequest = {
            useJsEncryption: true,
            type: type,
            card: {
                number: myEncrypt(cardDetails.creditCardNumber,  `${process.env.REACT_APP_CRYPTO_PASSWORD}`),
                exp_month: cardDetails.expMonth,
                exp_year: cardDetails.expYear,
            },
            stripeCustomerId: customer.stripeCustomerId,
            billing_details: {
                email: customer.userEmailAddress,
                phone: customer.userPhoneNumber,
                name: `${customer.userFirstName.trim()} ${customer.userLastName.trim()}`,
                address: {
                    state: customer.contactInfo && customer.contactInfo.billingState ? customer.contactInfo.billingState : "",
                    country: "US",
                    line2: customer.contactInfo && customer.contactInfo.billingAddress2 ? customer.contactInfo.billingAddress2 : "",
                    city: customer.contactInfo && customer.contactInfo.billingCity ? customer.contactInfo.billingCity : "",
                    line1: customer.contactInfo && customer.contactInfo.billingAddress1 ? customer.contactInfo.billingAddress1 : "",
                    postal_code: cardDetails.zipCode
                }
            }
        };

        return await baseApiService.post(url, AddPaymentMethodRequest);
    } catch (err) {
        console.log(err);
        return err;
    }
};  