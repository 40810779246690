import FacilityReservationBookInfo from "../../../components/facility/facility-reservation-book-info/facility-reservation-book-info.component";
import { FacilityReservationSearchContext } from "../../../contexts/facility/facility-reservation-search.context";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import { FacilityReservationBookContainer } from "./facility-reservation-book.styles";
import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';

const FacilityReservationBook = () => {

    const { searchResultsToBook } = useContext(SearchForReservationContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!searchResultsToBook){
            navigate('/facility/reservation-search');
        }
    }, []);

    return (

        <FacilityReservationBookContainer>
            <FacilityReservationBookInfo></FacilityReservationBookInfo>
        </FacilityReservationBookContainer>

    )

}

export default FacilityReservationBook