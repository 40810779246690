import styled from 'styled-components';

export const BookingSummaryContainer = styled.div`
position: relative;
background: white;
width: 90vw;
margin-top: 1.5vh;
margin-bottom: 2.0vh;
margin-left: 2.0vw;
margin-right: 2.0vw;
color: #14254C;
display: flex;
flex-direction: column;
margin-top: 5%;

.back-to-search-container {
    display: flex;
    margin-bottom: 1.0vh;
    margin-bottom: 2.0vh;
    
    .back-img {
        width: 1.5vw;
        margin-left: 1.5vw;
        cursor: pointer;
    }

    .back-text {
        margin: auto;
        margin-left: 0.5vw;
        color: gray;
        cursor: pointer;
    }
}

.summary-container {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 5.0vw;
    height: 75vh;
    grid-template-areas: 
    "reviewreses checkout";
}

.summary-header-container {
    display: flex;
    width: 100%;
    height: 5vh;
    margin-top: 1.0vh;

    .summary-header-lbl {
        width: 100%;
        margin: auto;
        text-align: left;
        font-size: 2.5vh;
        color: gray;
        font-weight: bold;
    }
}

.review-reservations-container {
    grid-area: reviewreses
    display: flex;
    flex-direction: column;
}

.checkout-with-fop-container {
    grid-area: checkout;
    display: grid;
    height: 75vh;
    grid-template-rows: 50% 50%;
    grid-template-areas: 
    "formofpayment"
    "summary";

    .form-of-payment-container {
        grid-area: formofpayment;
        display: flex;
        flex-direction: column; 
        margin-top: 1.0vh;

        .form-of-payment-header {
            width: 100%;
            margin-left: 1.0vw;
            margin-top: 1.0vh;
            margin-bottom: 1.0vh;
            text-align: left;
            font-size: 2.5vh;
            color: gray;
            font-weight: bold;
        }

        .select-form-of-payment-container {
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);  
        }
    }
}

.checkout-without-fop-container {
    height: 90vh;
    display: flex;
    width: 100%;
}

.order-summary-container {
    grid-area: summary;
    display: flex;
    flex-direction: column;
    margin-top: 1.0vh;
    width: 100%;

    .order-summary-header {
        margin-left: 1.0vw;
        margin-top: 1.0vh;
        margin-bottom: 1.0vh;
        width: 100%;
        text-align: left;
        font-size: 2.5vh;
        color: gray;
        font-weight: bold;
    }

    .order-summary-inner-container {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);  
    }
}
`