import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment, useContext, useState } from 'react';
import FormOfPayment from '../../form-of-payment/form-of-payment.component';
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import { UserContext } from '../../../contexts/user.context';
import { bookFacilityReservation } from '../../../utils/facility-api/facility-service';
import LoadingIndicator from '../../loading-indicator/loading-indicator.component';
import { useNavigate } from 'react-router-dom';
import MySportSpaceAlert from '../../alert/alert.component';

const FacilityReservationBookDialog = ({ open, close }) => {

    const { reservationSearchData, searchResultsToBook, bookingInput, setBookingInput, setBookingResponse } = useContext(SearchForReservationContext);
    const { userData } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    const navigate = useNavigate();

    const handleClose = () => {
        if (close) {
            close();
        }
    };

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const bookReservations = async () => {

        if (!searchResultsToBook || searchResultsToBook.length <= 0) {
            setAlertMessage("You must select at least 1 reservation to book.")
            return;
        }

        if (!bookingInput.title || bookingInput.title.length <= 0) {
            setAlertMessage("You must provide a reservation title.")
            return;
        }

        if (needsFormOfPayment() && !bookingInput.formOfPaymentType){
            setAlertMessage("You must select a form of payment type.")
            return;
        }

        if (bookingInput.formOfPaymentType === 'Credit Card' && !bookingInput.formOfPayment) {
            setAlertMessage("You must select a form of payment to pay by Credit Card.")
            return;
        }
        setIsLoading(true);
        const response = await bookFacilityReservation(userData.facilityId, reservationSearchData.customer, searchResultsToBook, bookingInput, reservationSearchData.isInternalBooking, userData.internalBookingId);
        setBookingResponse(response);
        setIsLoading(false);

        navigate("/facility/reservation-booking-results");

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBookingInput({ ...bookingInput, [name]: value });
    }

    const selectFormOfPaymentType = (type) => {
        setBookingInput({ ...bookingInput, 'formOfPaymentType' : type });
    }

    const selectFormOfPayment = (fop) => {
        setBookingInput({ ...bookingInput, 'formOfPayment' : fop });
    }

    const needsFormOfPayment = () => {
        if (reservationSearchData.isInternalBooking) {
            return false;
        }

        for (const searchResult of searchResultsToBook) {
            if (searchResult.price && parseFloat(searchResult.price) > 0) {
                return true;
            }
        }
        return false;
    }

    return (
        <Fragment>
            {isLoading &&
                <LoadingIndicator key={'booking-loading-indicator-key'}></LoadingIndicator>
            }
            {
                !isLoading && alertMessage &&
                <MySportSpaceAlert key={'booking-alert-message-key'} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            }
            {!isLoading &&

                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            bookReservations();
                        },
                    }}
                >
                    <DialogTitle>Booking Information</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the following to book these reservations.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="title"
                            name="title"
                            label="Reservation Title"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            id="notes"
                            name="notes"
                            label="Reservation Notes"
                            type="text"
                            fullWidth
                            multiline
                            variant="outlined"
                            onChange={handleChange}
                        />
                        {
                            needsFormOfPayment() && 
                            <FormOfPayment key={'booking-form-of-payment-type-key'} facilityCustomer={reservationSearchData.customer} isInternalBooking={reservationSearchData.isInternalBooking} formOfPayment={bookingInput.formOfPayment} formOfPaymentType={bookingInput.formOfPaymentType} selectFormOfPayment={selectFormOfPayment} selectFormOfPaymentType={selectFormOfPaymentType} ></FormOfPayment>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        { searchResultsToBook && searchResultsToBook.length > 0 && 
                            <Button variant="contained" type="submit">Book Reservations</Button>
                        }
                        
                    </DialogActions>
                </Dialog>
            }

        </Fragment>
    )

}
export default FacilityReservationBookDialog