import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Divider, Box } from '@mui/material';
import { DAYS_OF_WEEK_INT_TO_TEXT, DAYS_OF_WEEK_SORT_ORDER } from '../../../utils/constants/constants';
import { useState } from 'react';

import DaysOfWeekSelection from '../../days-of-week-selection/days-of-week-selection.component';


const CopyDowPricingDialog = ({ isOpen = false, copyPricing, close, copyingFromDow }) => {

    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState(null);

    const handleClose = () => {
        if (close) {
            close();
        }
        
    }

    const copyDowPricing = () => {
        copyPricing(selectedDaysOfWeek);
    }

    const getSelectedDaysOfWeek = () => {
        const dowSelected = [];
        if (selectedDaysOfWeek) {
            for (const dayOfWeekInt of selectedDaysOfWeek) {
                dowSelected.push(DAYS_OF_WEEK_INT_TO_TEXT[parseInt(dayOfWeekInt)]);
            }
        }
        return dowSelected;
    }

    const selectDayOfWeek = (value, closeView) => {
        const convertedDowsOfWeek = [];
        for (const dow of value) {
            convertedDowsOfWeek.push(DAYS_OF_WEEK_SORT_ORDER[dow]);
        }
        setSelectedDaysOfWeek(convertedDowsOfWeek);
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="add-area-dialog-title"
            aria-describedby="add-area-dialog-description">
            <DialogTitle sx={{ color: '#14254C' }} id="add-area-dialog-title">
                {copyingFromDow ? `Copy ${DAYS_OF_WEEK_INT_TO_TEXT[copyingFromDow]} Pricing` : 'Copy Pricing'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="add-area-dialog-description">
                    Select the days of the you want to copy to
                </DialogContentText>
                <Box mt={1} ml={4} mr={5}>
                    <DaysOfWeekSelection selectedDayOfWeek={getSelectedDaysOfWeek()} dayOfWeekSelected={selectDayOfWeek} exludeDow={copyingFromDow} ></DaysOfWeekSelection>
                </Box>
                <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }} ></Divider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={copyDowPricing} autoFocus>
                    Copy Pricing
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CopyDowPricingDialog;