import { createContext, useState, useEffect, useContext } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { UserContext } from "./user.context";
import { getSportsSupported } from "../utils/customer-api/customer-service";

export const ApplicationDataContext = createContext({
    sportToSubAreas: {},
    setSportToSubAreas: () => [],
    sportsSupported: [],
    setSportsSupported: () => []
});

export const ApplicationDataProvider = ({ children }) => {

    const [sportToSubAreas, setSportToSubAreas] = useState([]);
    const [sportsSupported, setSportSupported] = useState([]);
    const { currentUser } = useContext(UserContext);

    const db = getDatabase();

    useEffect(() => {

        const fetchSports = async () => {
            const sportsResponse = await getSportsSupported();
            if (sportsResponse.status === 200 && sportsResponse.data.status === 'success') {
                setSportSupported(sportsResponse.data.supportedSports);
                setSportToSubAreas(sportsResponse.data.sportToAreaType);
            }
        }

        if (currentUser) {
            const sportsSupportedRef = ref(db, `sports-supported`)
            const unsubscribe = onValue(sportsSupportedRef, (snapshot) => {
                if (snapshot.exists()) {
                    setSportSupported(snapshot.val());
                } else {
                    setSportSupported([]);
                }
            })
            return unsubscribe;
        } else {
            fetchSports();
        }
    }, []);

    useEffect(() => {
        if (currentUser) {
            const sportToSubAreasRef = ref(db, `sport-to-sub-areas`)
            const unsubscribe = onValue(sportToSubAreasRef, (snapshot) => {
                if (snapshot.exists()) {
                    setSportToSubAreas(snapshot.val());
                } else {
                    setSportToSubAreas([]);
                }

            })
            return unsubscribe;
        } 

    }, []);

    const value = { sportToSubAreas: sportToSubAreas, sportsSupported: sportsSupported }

    return <ApplicationDataContext.Provider value={value}>{children}</ApplicationDataContext.Provider>

}