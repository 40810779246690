import { Fragment, useState, useContext } from "react";
import FacilityReservationListRow from "./facility-reservation-list-row.component";
import { FacilityReservationListContainer } from './facility-reservation-list.styles';
import { FacilityReservationSearchResultsContainer } from './facility-reservation-list.styles';
import MySportSpaceAlert from "../../alert/alert.component";
import LoadingIndicator from "../../loading-indicator/loading-indicator.component";
import searchIcon from "../../../assets/search-icon-gray.svg";
import searchErrorIcon from "../../../assets/search-error.svg";
import Button from "../../../components/button/button.component";
import { useNavigate } from 'react-router-dom';
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const FacilityReservationList = () => {

    const [alertMessage, setAlertMessage] = useState(null);
    const {addOrRemoveSearchResultToBook, clearSearchResultsToBook, setSearchResultsToBook, isSearchingForReservations, searchResults, setReservationWarnings, reservationWarnings, displaySearchResults, searchResultsToBook } = useContext(SearchForReservationContext);

    const navigate = useNavigate();

    const searchResultWasClicked = (searchResult) => {
        addOrRemoveSearchResultToBook(searchResult);
    }

    const selectAllReservations = () => {
        setSearchResultsToBook(searchResults);
    }

    const searchResultIsSelected = (searchResultIn) => {
        return searchResultsToBook.find((searchResult) => searchResult.reservationId === searchResultIn.reservationId);
    }

    const clearAllSelections = () => {
        clearSearchResultsToBook();
    }

    const showAlert = (message) => {
        setAlertMessage(message);
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const bookReservations = () => {
        if (searchResultsToBook.length <= 0) {
            showAlert("You must select at atleast 1 reservation to book.")
            return;
        }

        navigate("/facility/reservation-book", {
            searchWasPerformed: true
        });

    }

    const clearWarnings = () => {
        setReservationWarnings([]);
    }

    let warningMessage = '';
    if (reservationWarnings.length > 0) {
        for (const warning of reservationWarnings) {
            warningMessage = `${warningMessage}\n${warning}`
        }
    }

    return (
        <FacilityReservationSearchResultsContainer>

            {
                alertMessage &&
                <MySportSpaceAlert key={`fac-list-alert-key`} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            }
            {reservationWarnings && reservationWarnings.length > 0 &&
                <MySportSpaceAlert key={'warning-message-key'} message={warningMessage} okButtonText={'OK'} okAction={clearWarnings} ></MySportSpaceAlert>
            }
            <FacilityReservationListContainer>
                {isSearchingForReservations &&
                    <LoadingIndicator></LoadingIndicator>
                }
                {
                    !searchResults && !isSearchingForReservations &&
                    <div className="default-view">
                        <div className="icon-container">
                            <img src={searchIcon} alt='img' className='icon-img' />
                            <span className='icon-title'>Search</span>
                        </div>
                    </div>
                }

                {

                searchResults && Object.keys(searchResults).length === 0 && !isSearchingForReservations &&
                    <div className="error-view">
                        <div className="icon-container">
                            <img src={searchErrorIcon} alt='img' className='icon-img' />

                            <span className='icon-title'>The reservation search did not return any results. Please refine your search.</span>
                        </div>
                    </div>
                }
                {
                    searchResults && Object.keys(searchResults).length > 0 && !isSearchingForReservations &&

                    <Fragment>
                        <ul key={`${'fac-list-search-result'}`} className='search-results'>
                            {
                                Object.keys(searchResults).map((datekey) => {
                                    return <Fragment key={`date-key-${datekey}`}>
                                        <li key={`date-header-${datekey}`} className="date-header">
                                            <span key={`'date-span-${datekey}`} className="date-header-text">{datekey}</span>
                                        </li>
                                        <FacilityReservationListRow key={`fac-row-${datekey}`} searchResultsForDate={searchResults[datekey]} searchResultWasClicked={searchResultWasClicked} searchResultIsSelected={searchResultIsSelected}>
                                        </FacilityReservationListRow>
                                    </Fragment>
                                }
                                )
                            }
                        </ul>

                    </Fragment>
                }

            </FacilityReservationListContainer>
            {!isSearchingForReservations && searchResults && Object.keys(searchResults).length > 0 &&
                <div className="bottom-button-bar">
                    <Button id='book-reses-button' buttonType='inverted' onClick={bookReservations} >Book Reservations</Button>
                    <Button id='select-all-button' onClick={selectAllReservations} >Select All</Button>
                    <Button id='clear-selection-button' onClick={clearAllSelections} >Clear Selections</Button>
                </div>
            }

        </FacilityReservationSearchResultsContainer>
    )
}

export default FacilityReservationList