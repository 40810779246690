


import styled from 'styled-components';

export const FacilityReservationBookSummaryContainer = styled.div`
  position: relative;
  top: 10%;
  height: 87vh;
  width: 100%;
  display: flex;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin-top: 1.0vh;
  margin-left: 1.0vw;
`
