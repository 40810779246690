import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Stack, Box, Button, Typography, Divider, Grid, TextField, Paper } from "@mui/material";
import Modal from '@mui/material/Modal';
import dayjs from "dayjs";
import Link from '@mui/material/Link';
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '90%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};

let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    let matchWithOne = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (matchWithOne) {
        return matchWithOne[1] + '(' + matchWithOne[2] + ') ' + matchWithOne[3] + '-' + matchWithOne[4]
    };

    return str
};

const BirthdayPartyViewBooked = ({ bookedParty = null, close }) => {

    const closeView = () => {
        if (close) {
            close();
        }
    }

    const { customer = {}, depositData, ancillaryTotal = "0.00", serviceChargeTotal = "0.00", serviceChargeOnDeposit = "0.00", totalAmount = "0.00", totalAmountDueAfterDeposit = "0.00", refundableDeposit = false, acceptedAncillaries, birthdayPartyName, customFields = [], partyDate, startTime, endTime, reservationCharge = {}, cancelData = {}, paymentMethod } = bookedParty ? bookedParty : {};
    const { userFirstName, userLastName, userEmailAddress, userPhoneNumber } = customer;
    const { cancelType, canceledOnUTC } = cancelData;

    return (
        <Modal open={bookedParty ? true : false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Paper sx={{ overflow: 'scroll' }}>
                <Box sx={{ ...style, width: '80%' }}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Button onClick={closeView} startIcon={<CloseOutlinedIcon />}>
                        </Button>
                    </Box>
                    <Box mb={1} mt={1} display="flex" justifyContent="flex-start" alignItems="center" >
                        <Typography ml={2} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h5">
                            {birthdayPartyName}
                        </Typography>
                    </Box>
                    <Stack>
                        <Typography ml={4} mt={1} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h6">
                            Birthday Party Date and Time
                        </Typography>
                        <Typography ml={4} mt={1} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="body1">
                            {dayjs(partyDate).format('dddd, MMM D, YYYY')} {startTime} {endTime}
                        </Typography>
                        {
                            canceledOnUTC &&
                            <Typography ml={4} mt={1} textAlign={'left'} gutterBottom color={"red"} component="div" variant="body1">
                                Canceled On {dayjs.utc(canceledOnUTC, 'YYYY-MM-DD h:mm a').tz(dayjs.tz.guess()).format('dddd, MMM D, YYYY h:mm a')} - Method {cancelType === 'apply_policies' ? "Policies Applied" : cancelType === 'refund_deposit' ? "Deposit Refunded" : "Full Refund"}
                            </Typography>
                        }
                        <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                        <Typography ml={4} mt={1} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h6">
                            Customer
                        </Typography>
                        <Box sx={{ width: '95%' }} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ width: '100%' }} ml={5} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                {userFirstName} {userLastName}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '95%' }} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ width: '75%' }} ml={5} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                {userEmailAddress}
                            </Typography>
                            <Typography sx={{ width: '25%' }} textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                {formatPhoneNumber(userPhoneNumber)}
                            </Typography>
                        </Box>
                    </Stack>
                    <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                    <Box sx={{ width: '98%' }}>
                        <Typography ml={4} mt={1} mb={1} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h6">
                            Custom Fields
                        </Typography>
                        <Stack sx={{ marginLeft: '2.0vw' }} spacing={2}>
                            {
                                customFields && Array.isArray(customFields) && customFields.map((field) => {
                                    return <TextField key={field.id} InputProps={{ readOnly: true }} multiline fullWidth label={field.text} value={field.answer}></TextField>
                                })
                            }
                            {
                                !customFields || !Array.isArray(customFields) || customFields.length <= 0 &&
                                <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                    None
                                </Typography>

                            }
                        </Stack>
                    </Box>
                    <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                    <Box sx={{ width: '100%' }}>
                        <Grid container ml={2}>
                            <Typography ml={2} mt={1} mb={1} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h6">
                                Summary
                            </Typography>

                            {
                                acceptedAncillaries && acceptedAncillaries.map((anc) => {
                                    return <Box key={anc.id} sx={{ width: '95%' }} ><Grid container mt={2} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                {anc.name} - {anc.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box display="flex" justifyContent="center" alignItems="center" >
                                                <Typography textAlign={'center'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    {anc.quantity}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                ${anc.price}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                ${anc.total}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                        <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                                    </Box>
                                })
                            }
                        </Grid>
                        {/* <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider> */}
                        <Box sx={{ width: '100%' }}>
                            <Grid container ml={2}>
                                {/* <Typography ml={2} mt={1} mb={1} textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="h6">
                                    Totals
                                </Typography> */}
                                <Box sx={{ width: '95%' }} >
                                    <Grid container mt={2} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                Optional Items Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    ${ancillaryTotal}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                Price
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    ${bookedParty ? bookedParty.price : 0.00}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                {refundableDeposit ? 'Deposit (Refundable)' : 'Deposit'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    ${bookedParty ? bookedParty.initialDeposit : 0.00}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                Service Charge
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    ${serviceChargeTotal}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4}>
                                        <Grid item xs={6}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                ${totalAmount}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                Deposit - PAID
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    {`$ (${bookedParty ? bookedParty.initialDeposit : 0.00})`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={8}>
                                            <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                                <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                    Deposit Service Charge - PAID
                                                </Typography>
                                                {
                                                    depositData && depositData.depositReceipt &&
                                                    <Link href={depositData.depositReceipt} underline="none">
                                                        View Receipt
                                                    </Link>
                                                }
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    {`$ (${serviceChargeOnDeposit})`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4}>
                                        <Grid item xs={6}>
                                            <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                                <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                    {reservationCharge.paidStatus && reservationCharge.paidStatus.toUpperCase() === 'PAID' ? `Total Amount - PAID` : 'Total Amount Due'}
                                                </Typography>
                                                {
                                                    reservationCharge && reservationCharge.receipt &&
                                                    <Link ml={2} href={reservationCharge.receipt} underline="none">
                                                        View Receipt
                                                    </Link>
                                                }
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                ${totalAmountDueAfterDeposit}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {
                                        paymentMethod && paymentMethod.card &&
                                        <Grid container mt={1} ml={4}>
                                            <Grid item xs={6}>
                                                <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                    Payment Method
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    {`${paymentMethod.card.brand.toUpperCase()} - ${paymentMethod.card.last4} ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }

                                    {
                                        reservationCharge && reservationCharge.paidOnUTC &&
                                        <Grid container mt={1} ml={4}>
                                            <Grid item xs={6}>
                                                <Typography textAlign={'left'} gutterBottom color={"#14254C"} fontWeight={'bold'} component="div" variant="body1">
                                                    Paid On
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography textAlign={'right'} gutterBottom color={"#14254C"} component="div" variant="body1">
                                                    {dayjs(reservationCharge.paidOnUTC).format('dddd, MMM D, YYYY h:mm A')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                            </Grid>
                        </Box>
                        <Divider sx={{ marginRight: '2.0vw', marginLeft: '2.0vw', marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                    </Box>
                </Box>
            </Paper>

        </Modal>
    )
}

export default BirthdayPartyViewBooked;