import { BookingsConfirmationContainer } from './booking-confirmation.styles';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../contexts/user.context';
import mssLogo from '../../../assets/logo-01.png';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Decimal = require('decimal.js');
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const defaultBookingTotals = {
    totalWithService: new Decimal(0),
    totalWithoutService: new Decimal(0),
    totalService: new Decimal(0),
}

const getServiceCharge = (booking) => {
    let serviceCharge = '';
    if (booking.charges) {
        for (const charge of booking.charges) {
            const { type, amount } = charge;
            if (type === 'serviceCharge') {
                let serviceDecimal = new Decimal(amount).div(100.0);
                serviceDecimal = serviceDecimal.toDecimalPlaces(2);
                serviceCharge = `$${serviceDecimal.toFixed(2)}`;
            }
        }
    }

    return serviceCharge;
}

const BookingsConfirmation = ({ reservationsBooked, title, backToSearchUrl }) => {

    const { userData } = useContext(UserContext);
    const [bookingsByDate, setBookingsByDate] = useState({});
    const [bookingTotals, setBookingTotals] = useState(defaultBookingTotals);

    useEffect(() => {

        window.scrollTo(0, 0);

        const bookingsByDate = {};
        let totalWithService = new Decimal(0);
        let totalWithoutService = new Decimal(0);
        let totalService = new Decimal(0);
        for (const result of reservationsBooked) {
            const startKey = moment(result.reservationDate, "YYYY-MM-DD").format('dddd, MMM DD, YYYY');
            let bookingsForDateKey = bookingsByDate[startKey];
            if (!bookingsForDateKey) {
                bookingsForDateKey = [];
            }
            bookingsForDateKey.push(result);

            bookingsByDate[startKey] = bookingsForDateKey;

            totalWithService = totalWithService.plus(new Decimal(result.price));
            totalWithoutService = totalWithoutService.plus(new Decimal(result.price));
            if (result.charges) {
                for (const charge of result.charges) {
                    const { type, amount } = charge;
                    if (type === 'serviceCharge') {
                        let serviceDecimal = new Decimal(amount).div(100.0);
                        totalWithService = totalWithService.plus(serviceDecimal);
                        totalService = totalService.plus(serviceDecimal);
                    }
                }
            }
        }

        const bookingTotals = {
            totalWithService: totalWithService,
            totalWithoutService: totalWithoutService,
            totalService: totalService,
        }
        setBookingTotals(bookingTotals);
        setBookingsByDate(bookingsByDate);

    }, []);

    const navigate = useNavigate();

    const backToReservationSearch = () => {

        if (backToSearchUrl) {
            navigate(backToSearchUrl);
        } else {
            navigate('/');
        }

    }

    return (
        <BookingsConfirmationContainer>
            <Box mb={2} display="flex"
                justifyContent="flex-start"
                alignItems="flex-start">
                <Button onClick={backToReservationSearch} startIcon={<ArrowBackIcon />}>
                    Back To Search
                </Button>
            </Box>
            <span className='thank-you-header'>Thank you for booking with My Sport Space</span>
            <span className='email-verification-message'>An email confirmation has been sent to {userData ? userData.userEmailAddress : "your email address."}</span>
            <span className='email-verification-message'></span>
            <div className='reservation-title-container'>
                <span className='reservation-title-header'>Reservation Title:</span>
                <span className='reservation-title'>{title}</span>
            </div>
            <div className='separator'></div>
            <div className='reservation-summary'>
                <div className='billing-data-container'>
                    <ul>
                        <li className="message">
                            <span>Reservations within 48 hours of the reservation will be charged immediately.</span>
                        </li>
                        <li className="message">
                            <span>Reservations outside of 48 hours will be charged 48 hours prior to the reservation.</span>
                        </li>
                        <li className="message">
                            <span>For invoice customers, your invoice will be updated.</span>
                        </li>
                        <li className='message'>
                            <span><b>All cancelations are subject to any appliciable cancellation and/or service fees.</b></span>
                        </li>
                    </ul>
                </div>
                <div className='totals-container'>
                    <span className='subtotal-header'>
                        Reservations(s) Total
                    </span>
                    <span className='subtotal'>${bookingTotals.totalWithoutService.toFixed(2)}</span>
                    <span className='service-charge-header'>
                        Total Service Charges
                    </span>
                    <span className='service-charge-total'>${bookingTotals.totalService.toFixed(2)}</span>
                    <span className='total-header'>
                        Total
                    </span>
                    <div className='total-separator'>
                        <div className='ttl-sep'></div>
                    </div>
                    <span className='total'>${bookingTotals.totalWithService.toFixed(2)}</span>
                </div>
            </div>
            <div className='separator'></div>

            <div className='reservation-details-container'>
                <span className='reservation-details-header'></span>
                {Object.keys(bookingsByDate).length > 0 &&
                    <div className="all-bookings-container">
                        {
                            Object.keys(bookingsByDate).map((date) => {
                                return <div key={date} className="booking-row">
                                    <span key={date} className="booking-date-header">{date}</span>
                                    {
                                        bookingsByDate[date].map((booking) => {
                                            return <div key={booking.reservationId} className="booking-info-container">
                                                <img className="area-photo-image" src={booking.areaImageUrl ? booking.areaImageUrl : mssLogo} alt="Logo" />
                                                <div className="booking-info">
                                                    <span className="facility-name">{booking.facilityName}</span>
                                                    <span className="area-name">{booking.areaReserved}</span>
                                                    <span className="area-type">{booking.facilityAreaType}</span>
                                                    <span className='reserved-for-sport'>{booking.reservedForSport}</span>
                                                    <span className="booking-time">{moment(`${booking.reservationDate} ${booking.startTime}`, "YYYY-MM-DD h:mm a").format("h:mm a")} - {moment(`${booking.reservationDate} ${booking.endTime}`, "YYYY-MM-DD h:mm a").format("h:mm a")}</span>
                                                    {new Decimal(booking.price).gt(new Decimal(0)) &&
                                                        <div className='cancel-policies'>
                                                            <span className='cancel-policy-lbl'>Cancel Policy</span>
                                                            {
                                                                booking.cancelPolicies.map((policy) => {
                                                                    return <span className='cancel-policy'>{policy}</span>
                                                                })
                                                            }
                                                        </div>
                                                    }

                                                </div>
                                                <div className="price-and-remove-container">
                                                    <div className="price-and-service-charge-container">
                                                        <div className="price-container">
                                                            <span className="price-header-lbl">Price</span>
                                                            <span className="booking-price">${new Decimal(booking.price).toFixed(2)}</span>
                                                        </div>
                                                        <div className="service-charge-container">
                                                            <span className="service-charge-header-lbl">Service Charge</span>
                                                            <span className='service-charge'>{getServiceCharge(booking)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            })
                        }
                    </div>
                }
            </div>
        </BookingsConfirmationContainer>
    )
}

export default BookingsConfirmation;