import styled from 'styled-components';

export const FacilityViewCustomerContainer = styled.div`
position: absolute;
top: 10%;
width: 98%;
height: 87vh;
display: flex;
flex-direction: row;
background-color: white;
border-radius: 5px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
margin: 1.0rem;

.bottom-button-bar {
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 2.0rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

`

export const ReservationCalendarButton = styled.button`
    width:  4.0vw;
    height: 2.0vw;
    border-color: white;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.25vw;

    &:hover {
      background-color: #14254C;
      color: white;
    }

    .title: {
        width: 100%;
        height: 100%;
        font-size: 1.0vh;
    }

    .calendar-img {
        height: 100%;
        width: 100%;
    }
`

export const FacilityViewCustomerFormContainer = styled.div`

    width: 100%;
    display: grid;
    grid-template-columns: 15% 85%;
    grid-template-rows: 100%;
    grid-template-areas: 
    "userimage userprofile";

    .user-image {
        grid-area: userimage;
        background-color: white;
        padding-left: 15px;
        padding-right: 15px;

        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 15% 85%;
        grid-template-areas: 
        "userprofileimage"
        "userprofileactions";

        .user-profile-img {
            margin-top: 5.0rem;
            grid-area: userprofileimage;
            position: relative;
            object-fit: contain;
            width: 100%;
        }

        .user-profile-actions {
            grid-area: userprofileactions;
        }
    }

  .user-profile {
    grid-area: userprofile;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "userdetails reservations";

    .reservations {
        display: flex;
        flex-direction: column;
        grid-area: reservations;
        width: 100%;
        background-color: white;
        overflow: scroll;

        .reservation-section {
            position: relative;
            display: flex;
            height: 50%;
            flex-direction: column;
            background-color: white;
            border-radius: 5px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            margin: 0.5vw;
            overflow: scroll;

            .reservation-choices-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 0.25vh;
            }

            .title {
                width: 100%;
                margin-left: 0.45vw;    
                font-size: 2.0vh;
                font-weight: bold;
                background-color: white;
                margin-top: 0.25vh;
            }
        }
    }

    .user-details {
        grid-area: userdetails;
        position: relative;
        height: 100%;
        background-color: white;
        overflow: scroll;
        margin-right: 1.0vw;
        .input-group {
            display: flex;
            flext-direction: row;
        }

        .phone-number-container {
            display: flex;
            flex-direction: column;
            color: #14254C;
            justify-content: flex-start;
            width: 100%;
        }
        .phone-number {
            display: flex;
            text-align: left;
            color: #14254C;
            margin-left: 1.5rem;
            margin-right: 1.0rem;
            margin-bottom: 0.5rem;
            border-radius: 5px;
            border: 1px solid #eaeaea;
            padding: 0 15px 0 15px;
            font-size: 1.45vh;
            line-height: 1.5rem;
            background-color: #fbfbfb;
        }

        .phone-number-label {
            display: flex;
            text-align: left;
            align-items: center;
            font-weight: bold;
            font-size: 1.50vh;
            color: #14254C;
        }

        .button-header {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .edit-btn-img {
            height: 3.0vh;
            width: 3.0vh;
            cursor: pointer;
            background-color: white;
            border-radius: 5px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            margin: 0.5rem;
        }

        .save-btn-img {
            height: 3.0vh;
            width: 3.0vh;
            cursor: pointer;
            background-color: white;
            border-radius: 5px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            margin: 0.5rem;
        }

        .edit-btn-img:hover {
            cursor: pointer;
            background-color: #DEDEDE
        }

        .section-header {
            display: flex;
            flex-direction: column;
            margin-top: 0.5rem;
            margin-bottom: 1.0rem;
        }

        .section-header-text {
            display: flex;
            margin-bottom: 0.75rem;
            text-align: left;
            align-items: center;
            font-weight: bold;
            font-size: 1.50vh;
            color: #14254C;
            padding-bottom: 5px;
            border-bottom: #DEDEDE solid 1px;
        }

        .invoice-info-container {
            display: flex;
            flex-direction: column;
            margin-top: 0.5rem;

            .check-box-and-title {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                font-size: 1.50vh;
                color: #14254C;
                margin-left: 2.0vw;
                padding-bottom: 5px;
            }

            .invoicing-btn-edit {
                cursor: pointer;
                height: 2.0vh;
                width: 2.0vw;

            }

            .invoicing-btn {
                height: 2.0vh;
                width: 2.0vw;
            }

            .invoice-billing-details {
                display: flex;
                flex-direction: row;
                font-size: 1.50vh;
                margin-left: 2.0vw;
                margin-top: 1.0vh;
                margin-bottom: 1.0vh;
                color: #14254C;

                .bill-to-title {
                    padding: 5px; 5px; 5px; 5px;
                }

                .bill-to-name {
                    padding: 5px; 5px; 5px; 5px;
                    background-color: #eaeaea;
                }

                .bill-to-name-edit {
                    margin-left: 1.0vw;
                    border-radius: 5px;
                    padding: 5px; 5px; 5px; 5px;
                    border: #DEDEDE solid 1px;
                }

                .bill-to-name-edit {
                    margin-left: 1.0vw;
                    border-radius: 5px;
                    padding: 5px; 5px; 5px; 5px;
                    border: #DEDEDE solid 1px;
                }

                .bill-to-name-edit:hover {
                    cursor: pointer;
                    background-color: #DEDEDE;
                }
            }

            .invoice-text-and-checkboxes {
                display: flex;
                flex-direction: row;
                border-bottom: #DEDEDE solid 1px;

                .invoice-header-text {
                    display: flex;
                    text-align: left;
                    align-items: center;
                    font-weight: bold;
                    font-size: 1.50vh;
                    color: #14254C;
                    padding-bottom: 5px;
                }
            }

        }

        .additional-emails-container {
            display: flex;
            flex-direction: column;
            margin-top: 0.5rem;
        }

        .additional-emails-header-text {
            display: flex;
            text-align: left;
            align-items: center;
            font-weight: bold;
            font-size: 1.50vh;
            color: #14254C;
            padding-bottom: 5px;
        }

        .add-email-btn {
            cursor: pointer;
            width: 1.0rem;
            height: 1.0rem;
            margin-left: 3.0rem;
            margin-bottom: 0.5rem;
            border-radius: 5px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        }

        .add-email-btn:hover {
            cursor: pointer;
            background-color: #DEDEDE;
        }

        .additional-emails-text-and-buttons {
            display: flex;
            flex-direction: row;
            border-bottom: #DEDEDE solid 1px;
        }

        .additional-emails-list-container {
            display: flex;
            flex-direction: column;
            background-color: white;
            margin-top: 0.5rem;
            overflow: scroll;

            .additional-email-row {
                display: flex;
                justify-content: stretch;
                align-items: center;
                height: 5vh;
            }

            .additional-email-input {
                display: flex;
                width: 95%;
                text-align: left;
                color: #14254C;
                margin-left: 1.5rem;
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
                border-radius: 5px;
                border: 1px solid #eaeaea;
                padding: 0 15px 0 15px;
                font-size: 1.45vh;
                line-height: 1.5rem;
            }

            .delete-email-btn {
                cursor: pointer;
                width: 1.0rem;
                height: 1.0rem;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
                border-radius: 5px;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            }

            .delete-email-btn:hover {
                cursor: pointer;
                background-color: #DEDEDE;
            }
        }

        .customer-buttons-container {
            display: flex;
            position: relative;
            justify-content: center;
            margin-top: 1.5rem;
            margin-bottom: 0.5rem;
        }
    }

  }


`