import styled from 'styled-components';

export const CreditCardFormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  z-index: 9999;
  background-color: white;

  .credit-card-form {
    margin: 1.5vw;
  }

  .expiry-and-cvc {
    margin-right: 0.5vw;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .billing-address-header {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.25vh;
      margin-bottom: 0.25vh;
      margin-left: 0.5vw;
      margin-right: 0.5vw;


      .billing-address-lbl {
        text-align: left;
        width: 50%;
      }

      .use-billing-address-container {
          display: flex;
          justify-content: flex-end;
          width: 50%;
       
          .use-billing-address-lbl {
            color: gray;
          }

          .check-box-img {
            height: 2.0vh;
            width: 2.0vw;
            margin: auto;
            margin-left: 0.25vw;
          }
      }
  }

  .buttons-container {
      display: flex;
      justify-content: center;
      margin-top: 0.5vh;
      margin-bottom: 0.5vh;
  }

  .button {
    margin-left: 0.5vw;
    margin-right: 0.5vw;
  }
  
`