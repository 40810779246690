import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const MaterialUIDatePicker = ({ labelText = "Date", onChange, value, minDate, maxDate, disabled = false }) => {
    // const [pickerValue, setPickerValue] = useState(value);

    const dateChanged = (newValue) => {
        // setPickerValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
            <DatePicker
                label={labelText}
                value={value}
                onChange={dateChanged}
                maxDate={maxDate}
                minDate={minDate}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
}

export default MaterialUIDatePicker;