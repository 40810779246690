import { createContext, useState, useEffect } from 'react'

const addAnOpenView = (openViews, viewToAdd) => {

    //search for existing cart item , using the find method.
    const existingOpenView = openViews.find((openView) => openView === viewToAdd);
    if (existingOpenView) {
        return openViews;
    }
    //this just creates a new arrray 
    return [...openViews, viewToAdd]
}

const removeAnOpenView = (openViews, viewToRemove) => {
    //search for existing cart item , using the find method.
    const existingOpenView = openViews.find((openView) => openView === viewToRemove);
    if (existingOpenView) {
        return openViews.filter(openView => openView !== viewToRemove);
    }

    return  [...openViews];
}

const removeAllOpenViewsExcept = (openViews, viewsNotToRemove) => {
    return openViews.filter(openView => viewsNotToRemove.includes(openView));
}

const defaultCalendarDimensions = {
    facility_calendar_height: '100%',
    facility_calendar_width: '100%',
    area_column_height: '80vh',
    zoom_multiplier: 1
}

export const DisplayContext = createContext({
    isViewOpen: () => false,
    addOpenView: () => {},
    removeOpenView: () => {},
    openViews: [],
    setOpenViews: () => {},
    resetAllDisplays: () => {},
    removeAllViewsExcept: () => {},
    calendarDimensions: {},
    setCalendarDimensions: () => defaultCalendarDimensions
});

export const DisplayProvider = ({ children }) => {

    const [openViews, setOpenViews] = useState([])
    const [calendarDimensions, setCalendarDimensions] = useState(defaultCalendarDimensions);

    const resetAllDisplays = () => {
        setOpenViews([]);
    }

    const addOpenView = (viewToAdd) => {
        setOpenViews(addAnOpenView(openViews, viewToAdd));
    }

    const removeOpenView = (viewtoRemove) => {
        const viewsAfterRemove = removeAnOpenView(openViews, viewtoRemove);
        setOpenViews(viewsAfterRemove);
    }

    const isViewOpen = (view) => {
        return openViews.find((openView) => openView === view);
    }

    const removeAllViewsExcept = (viewsNotToRemove) => {
        setOpenViews(removeAllOpenViewsExcept(openViews, viewsNotToRemove));
    }

    const value = {removeAllViewsExcept, addOpenView, removeOpenView, resetAllDisplays, isViewOpen, openViews, calendarDimensions, setCalendarDimensions};

    return <DisplayContext.Provider value={value}>{children}</DisplayContext.Provider>;
}