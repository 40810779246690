import { Box, Button, Stack, Typography } from "@mui/material";
import { UserContext } from "../../../contexts/user.context";
import { useContext } from "react";
import FacilityCustomerSearch from "../../facility/facility-customer-search/facility-customer-search.component";
import { BirthdayPartyContext } from "../../../contexts/birthday-party/birthday-party.context";
import dayjs from "dayjs";
import MaterialUIDatePicker from '../../material-ui/date-picker/material-ui-date-picker.component';
import { DAYS_OF_WEEK_SORT_ORDER } from "../../../utils/constants/constants";

const BirthdayPartyTimeSlots = ({ birthdayPartyDetails = {}, searchForParty, close }) => {

    const { userData } = useContext(UserContext);
    const { partyInput, setPartyInput, setIsLoading } = useContext(BirthdayPartyContext);
    const { date } = partyInput;
    const { timeSlots } = birthdayPartyDetails ? birthdayPartyDetails : [];

    const cancel = () => {
        if (close) {
            close();
        }
    }

    const selectPartyDate = (value) => {
        setPartyInput({ ...partyInput, 'date': value });
    }

    const handleCustomerChange = (customerName, customer) => {
        setPartyInput({ ...partyInput, 'customer': customer, 'customerName': customerName });
    }

    const setSearchingForReservations = (isSearching) => {
        setIsLoading(isSearching);
    }

    const timeSlotChosen = (e) => {
        const timeSlotId = e.currentTarget.dataset.id;
        const foundTimeSlotById = timeSlots.filter((ts) => { return ts.id === timeSlotId });
        if (foundTimeSlotById.length > 0) {
            const foundTimeSlot = foundTimeSlotById[0];
            if (searchForParty) {
                searchForParty(partyInput.date, dayjs(foundTimeSlot.startTime), dayjs(foundTimeSlot.endTime), timeSlotId);
            }
        }
    }

    return (
        <Box sx={{ margin: '2.0vw' }}>
            <MaterialUIDatePicker labelText="Birthday Party Date:" minDate={dayjs().startOf('day')} onChange={selectPartyDate} value={partyInput.date}></MaterialUIDatePicker>
            <Typography mt={2} variant="body1">Select a Party Time</Typography>
            <Stack mt={2} mb={2} spacing={2}>
                {
                    timeSlots && timeSlots.length > 0 &&
                    timeSlots.filter((ts) => { return DAYS_OF_WEEK_SORT_ORDER[ts.dow] === date.day() }).map((timeSlot) => {
                        return <Button onClick={timeSlotChosen.bind(this)} data-id={timeSlot.id} variant="contained">{`${dayjs(timeSlot.startTime).format("h:mm a")} - ${dayjs(timeSlot.endTime).format("h:mm a")}`}</Button>
                    })
                }
                {
                    userData && userData.facilityId &&
                    <FacilityCustomerSearch customer={partyInput.customer} searchCustomerName={partyInput.customerName} handleCustomerChange={handleCustomerChange} setIsSearchingForCustomers={setSearchingForReservations} marginLeft="0" marginRight="0"></FacilityCustomerSearch>
                }
                {
                    timeSlots && timeSlots.length > 0 &&
                    timeSlots.filter((ts) => { return DAYS_OF_WEEK_SORT_ORDER[ts.dow] === date.day() }).length <= 0 &&
                    <Typography ml={2} color={'secondary'} textAlign={'center'} mt={2} variant="body1">No Times Available</Typography>
                }
            </Stack>
            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Button onClick={cancel}>Close</Button>
            </Box>

        </Box >
    )
}

export default BirthdayPartyTimeSlots;