import styled from 'styled-components';

export const FacilityReservationBookInfoContainer = styled.div`
position: relative;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
height: 87vh;
width: 100%;
display: flex;
flex-direction: row;
background-color: white;
border-radius: 5px;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
margin: 1.0rem;

.bottom-button-bar {
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 2.0rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

`

export const FacilityReservationBookInfoFormContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
justify-content: space-evenly;
background-color: white;
border-radius: 5px;

.container-row {
  display: flex;  
  flex-direction: column;
  justify-content:flex-start;
  margin-left: 2.0rem;
  margin-right: 1rem;
  color: #14254C;
}

.form-of-payment-type-container {
  width: 50%;
}
.form-of-payment-container {
  width: 50%;
}
`