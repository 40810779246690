import { HomePageFacilitiesContainer } from "./home-page-facilities.styles";
import FacilityCard from "../facility-card/facility-card.component";
import { GetFacilitiesByName } from '../../../utils/facility-api/facility-service';
import { useState, useEffect } from "react";
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import LoadingIndicator from "../../loading-indicator/loading-indicator.component";
import { Stack, Typography } from "@mui/material";


const HomePageFacilities = ({ filterType }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [allFacilities, setAllFacilities] = useState([]);

    const fetchFacilities = async (startAfter) => {
        setIsLoading(true);
        const response = await GetFacilitiesByName(startAfter, filterType);
        if (response && response.status == 200 && response.data.status === 'success') {
            const { facilities } = response.data;
            if (startAfter) {
                setAllFacilities(allFacilities.concat(facilities));
            } else {
                setAllFacilities(facilities);
            }
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchFacilities();
    }, []);

    const showMoreFacilities = () => {
        let startAfter = null;
        if (allFacilities.length > 0) {
            startAfter = allFacilities.length;
        }
        fetchFacilities(startAfter);
    }

    let filteredFacilities = [];
    if (!filterType) {
        filteredFacilities = allFacilities.filter((facility) => {
            return facility.allowOnlineReservations;
        })
    } else {
        filteredFacilities = allFacilities;
    }

    return (
        <HomePageFacilitiesContainer>
            {isLoading &&
                <LoadingIndicator></LoadingIndicator>
            }
            <div className="facility-display-container">
                {!isLoading && filteredFacilities.length > 0 &&
                    filteredFacilities.map((facility) => {
                        return <FacilityCard key={`facility-card-${facility.facilityId}`} facility={facility} filterType={filterType}></FacilityCard>
                    })
                }
                {
                    !isLoading && allFacilities.length <= 0 &&
                    <Stack>
                        {
                            filterType === 'birthday-parties' &&
                            <Typography ml={1} fontWeight={'bold'} mt={2} textAlign={'left'} gutterBottom color={"gray"} component="div" variant="h5">
                                At this time, there are not any My Sport Space facilities offering Birthday Party packages.  Please check back soon!
                            </Typography>
                        }
                        <Typography ml={1} fontWeight={'bold'} mt={2} textAlign={'left'} gutterBottom color={"gray"} component="div" variant="h5">
                            Would you like your preferred facility on the My Sport Space network?  Submit a referral at www.mysportspace.info.
                        </Typography>
                    </Stack>
                }
            </div>
            <div className="search-buton-container">
                {
                    !isLoading && allFacilities.length > 0 &&
                    <div className="search-button" onClick={showMoreFacilities}>
                        <span className="search-button-text">Show More</span>
                    </div>
                }
            </div>


        </HomePageFacilitiesContainer>
    )
}

export default HomePageFacilities;