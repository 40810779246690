import MainMenu from "../../../components/facility/facility-settings/main-menu/main-menu.component"

const FacilitySettings = () => {

    return (
        <MainMenu></MainMenu>
    )

}

export default FacilitySettings