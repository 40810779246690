import FacilityCustomersView from "../facility-customers-view/facility-customers-view.component";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button, Modal, Stack, Box, Typography, IconButton } from '@mui/material';

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};

const FacilityCustomersPicker = ({ isOpen = true, closeView, selectFacilityCustomer, selectedCustomers, clearCustomers, allowMultipleSelection = false }) => {

    const closePicker = () => {
        closeView();
    }

    const clearSelections = () => {
        clearCustomers();
    }

    return (
        <Modal open={isOpen}>
            <Box sx={{ ...style, height: '85%', width: '80%' }}>
                <Stack direction={'row'} display={'flex'} justifyContent={'flex-start'} alignContent={'center'} alignItems={'center'}>
                    <Typography width={'50%'} mt={2} fontWeight={'bold'} textAlign={'left'} color={"#14254C"} component="div" variant="h5">
                        {allowMultipleSelection ? 'Select Customers' : 'Select Customer'}
                    </Typography>
                    <Box width={'50%'} display={'flex'} justifyContent={'flex-end'} alignContent={'center'} alignItems={'center'}>
                        <IconButton mt={2} onClick={closePicker}>
                            <CloseOutlinedIcon></CloseOutlinedIcon>
                        </IconButton>
                    </Box>

                </Stack>
                <Box sx={{ overflow: 'scroll', height: '80%' }} display={'flex'} justifyContent={'center'}>
                    <FacilityCustomersView marginTop="1.0vh" selectRow={true} selectFacilityCustomer={selectFacilityCustomer} selectedCustomers={selectedCustomers}></FacilityCustomersView>
                </Box>
                {
                    allowMultipleSelection &&
                    <Stack spacing={2} mt={2} display={'flex'} justifyContent={'center'} direction={'row'}>
                        <Button onClick={clearSelections} variant='outlined'>Clear All</Button>
                        <Button onClick={closePicker} variant='contained'>Done</Button>
                    </Stack>
                }
            </Box>
        </Modal>
    )
}

export default FacilityCustomersPicker;