import styled from 'styled-components';

export const SearchForReservationResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    
    .back-to-search-container {
        display: flex;
        margin-bottom: 1.0vh;
        margin-bottom: 2.0vh;
        
        .back-img {
            width: 1.5vw;
            margin-left: 1.5vw;
            cursor: pointer;
        }
    
        .back-text {
            margin: auto;
            margin-left: 0.5vw;
            color: gray;
            cursor: pointer;
        }
    }

    .booking-cart-header {
        display: flex;
        margin: auto;
        margin-right: 2.0vw;
        height: 5vh;
    }

    .search-results-for-date-container {
        display: flex;
        flex-direction: column;
        margin-left: 1.0vw;
        margin-right: 1.0vw;
        overflow: ${(props) => `${props.$overflow}`};

        .date-header-lbl {
            margin-left: 2.5vw;
            margin-bottom: 2.5vh;
            color: #14254C;
            font-size: 2.5vh;
        }
    }

    .search-results-for-date-container: hover {
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .search-results-display-container {
        display: flex;
        height: 100%;
        flex-wrap: ${(props) => `${props.$resultswrap}`};
        row-gap: 1.0vh;
        column-gap: 1.0vw;
        justify-content: ${(props) => `${props.$justifycontent}`};
        align-content: flex-start;
        background: white;
        overflow: ${(props) => `${props.$overflow}`};
        margin-bottom: 2.5vh;
        
    }

    .search-buton-container {
        margin: auto;
        margin-bottom: 2.5vh;

        .search-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10.0vw;
            height: 5vh;
            text-align: center;
            background-color: #14254C;
            border-radius: 5px;
            margin-left: 0.25vw;
            margin-right: 0.25vw;
            .search-button-text {
                color: white;
            }
            cursor: pointer;
        }
    }


`