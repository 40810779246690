import { createContext, useEffect, useContext, useReducer } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { UserContext } from "../user.context";
import { createAction } from "../../utils/reducer/reducer.utils";
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

export const FacilityReservationsContext = createContext({
    reservations: {},
    setReservations: () => [],
    reservationsDate: "",
    setReservationsDate: () => "",
    isLoadingReservations: false,
    setIsLoadingReservations: () => false,
    clearReservationData: () => {},
    reservationToView: null,
    setReservationToView: () => {}
});

export const FACILITY_RESERVATION_ACTION_TYPES = {
    SET_RESERVATION_DATA: 'SET_RESERVATION_DATA',
    SET_LOADING_RESERVATIONS: 'SET_LOADING_RESERVATIONS'
}

const INITIAL_STATE = {
    reservationsDate: new Date(),
    reservations: [],
    isLoadingReservations: false,
    reservationToView: null
}


const facilityResSearchReducer = (state, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case FACILITY_RESERVATION_ACTION_TYPES.SET_RESERVATION_DATA:
        return {
          ...state, //this keeps everything else and doesn't overwrite
          ...payload
        }
      case FACILITY_RESERVATION_ACTION_TYPES.SET_LOADING_RESERVATIONS:
        return {
          ...state,
          isLoadingReservations: payload
        }
      default:
        throw new Error(`Unhandled type ${type}`);
    }
  }

export const FacilityReservationsProvider = ({ children }) => {

    const [{reservationsDate, reservations, isLoadingReservations, reservationToView}, dispatch] = useReducer(facilityResSearchReducer, INITIAL_STATE);
    const { currentUser, userData } = useContext(UserContext);

    const clearReservationData = () => {
        setReservations([[], null]);
    }

    const setIsLoadingReservations = (bool) => {
        dispatch(createAction(FACILITY_RESERVATION_ACTION_TYPES.SET_LOADING_RESERVATIONS, bool));
    }

    const setReservationsDate = (date) => {
        dispatch(createAction(FACILITY_RESERVATION_ACTION_TYPES.SET_RESERVATION_DATA, { reservationsDate: date }));
    }

    const setReservationToView = (reservation) => {
        dispatch(createAction(FACILITY_RESERVATION_ACTION_TYPES.SET_RESERVATION_DATA, { reservationToView: reservation }));
    }

    const setReservations = (allReservations, date) => {
        const reservationsDetails = {
            allReservations: allReservations,
            reservationsDate: date
        }
        dispatch(createAction(FACILITY_RESERVATION_ACTION_TYPES.SET_RESERVATION_DATA, { reservations: reservationsDetails }));
    }
    const db = getDatabase();
    useEffect(() => {

        const setupReservationsData = (snapshot) => {
            setIsLoadingReservations(true);
            if (snapshot.exists()) {
                const allReservationsByResId = snapshot.val();
                const allReservationsForArea = [];
                Object.keys(allReservationsByResId).map((resId) => {
                    const reservation = allReservationsByResId[resId];
                    reservation.startDate = moment(reservation.dateKey + ' ' + reservation.resStartTime, "YYYY-MM-DD h:mm A");
                    let endDate = moment(reservation.dateKey + ' ' + reservation.resEndTime, "YYYY-MM-DD h:mm A");
                    if (reservation.resEndTime === '12:00 AM') {
                        endDate = endDate.add(1, 'day');
                    }
                    reservation.endDate = endDate;
                    allReservationsForArea.push(reservation);

                    return;
                })
                setReservations(allReservationsForArea, reservationsDate);
            } else {
                setReservations([], reservationsDate ? reservationsDate : new Date());
            }
            setIsLoadingReservations(false);
        }

        if (currentUser && userData) {
            let resDateKey = moment().format("YYYY-MM-DD");
            if (reservationsDate) {
                resDateKey = moment(reservationsDate).format("YYYY-MM-DD");
            }
            
            const resRef = ref(db, `facility-user-accounts/${userData.facilityId}/reservations/${resDateKey}`)
            const unsubscribe = onValue(resRef, (snapshot) => {
                setupReservationsData(snapshot);
            })
            return unsubscribe;
        }

    }, [currentUser, userData, reservationsDate, db]);

    const value = { reservations: reservations, setReservations: setReservations, reservationsDate, setReservationsDate, reservationToView, setReservationToView, isLoadingReservations, setIsLoadingReservations, clearReservationData }

    return <FacilityReservationsContext.Provider value={value}>{children}</FacilityReservationsContext.Provider>

}