import * as React from 'react';
import { Paper } from '@mui/material'; 
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const isEqual = (option, value) => {
    return option === value;
}


const ListWithTextInput = ({ id = "list-with-text", variant = 'standard', headerText, list, selectFromList, selectedItem, allowInput = false, width = 4/4 }) => {

    const defaultProps = {
        options: list,
        getOptionLabel: (listItem) => `${listItem}`,
      };

    const selectValue = (value) => {
        let validValue = value;
        if (!allowInput && value){
            const foundValue = list.filter(v => v.toUpperCase() === value.toUpperCase());
            if (!foundValue || foundValue.length === 0){
                validValue = `${list[0]}`;
            }
        }
        if (selectFromList){
            selectFromList(validValue);
        }
    }

    return (

        <Autocomplete
            {...defaultProps}
            options={list}
            id={id}
            freeSolo={allowInput}
            value={selectedItem}
            onChange={(event, newValue) => {
                selectValue(newValue);
            }}
            PaperComponent={({ children }) => (
                <Paper style={{ color: "#14254C" }}>{children}</Paper>
              )}
            onBlur={(event) => {
                selectValue(event.target.value);
            }}
            renderInput={(list) => (
                <TextField {...list} value={selectedItem} fullWidth label={headerText} variant={variant} sx={{
                    "& .MuiInputBase-input": {
                        color: "#14245C"
                    }
                }}  />
            )}
            sx={{
                width: `${width}`
            }}
            isOptionEqualToValue={(option, value) => isEqual(option, value)}
        />
    )

}

export default ListWithTextInput;