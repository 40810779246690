import { FacilityMembershipsContext } from "../../contexts/facility/facility-memberships.context";
import { Fragment, useContext, useState } from "react";
import { MembershipListAllContainer } from './memberships-list-all.styles';
import MembershipsListAllRow from './memberships-list-all-row.component';
import FormHeader from "../form-header/form-header.component";

const MembershipsListAll = ({selectMembership, selectedMemberships}) => {

    const { memberships } = useContext(FacilityMembershipsContext);
    let row = 0;
    return (
        <MembershipListAllContainer>
            <ul key={'membership-list-key'} className='membership-list'>
            {
                Object.keys(memberships).map(key => (
                    <MembershipsListAllRow key={memberships[key].facilityMembershipId} row={row++} membership={memberships[key]} selectedMemberships={selectedMemberships} selectMembership={selectMembership}></MembershipsListAllRow>
                ))
            }
            </ul>
        </MembershipListAllContainer>
    )
}

export default MembershipsListAll;