import styled from 'styled-components';

export const FacilityReservationSearchContainer = styled.div`
    height: 100vh;
    width: 99vw;
    position: absolute;
    top:0;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    z-index: 10;
    background-color: white;
`
