import styled from 'styled-components';

export const FacilityReservationBookResultsContainer = styled.div`
height: 100vh;
width: 98vw;
position: absolute;
top:0;
display: flex;
z-index: 10;
background-color: white;
    `