import { Routes, Route } from 'react-router-dom';
import FacilityInfoView from '../../components/facility-info-view/facility-info-view.component';

const FacilityTour = () => {
    return (
        <Routes>
            <Route path=":facilityId" element={<FacilityInfoView />} />
        </Routes>
    )
}

export default FacilityTour;