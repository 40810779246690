import { ButtonContainer } from "./two-button-bar.styles"
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

const TwoButtonBar = ({ button1Text, button1Action, button2Text, button2Action }) => {

    return (
        <ButtonContainer>
            {/* <div className="button" onClick={button1Action}>
                <span className="button-text">{button1Text}</span>
            </div>
            <div className="button" onClick={button2Action}>
                <span className="button-text">{button2Text}</span>
            </div> */}
            <ButtonGroup variant="contained" aria-label="Basic button group">
                <Button sx={{mr: 0.25}} onClick={button1Action}>{button1Text}</Button>
                <Button onClick={button2Action} >{button2Text}</Button>
            </ButtonGroup>
        </ButtonContainer>
    )
}

export default TwoButtonBar