import { createContext, useState, useEffect, useContext, useReducer } from "react";
import { createAction } from "../../utils/reducer/reducer.utils";
import { reservationSearchDataDefault } from "../search-for-reservation/search-for-reservation.context";

const reservationBookingInput = {
  formOfPaymentType: null,
  formOfPayment: null,
  title: null,
  notes: "",
  isInternalBooking: false
}

export const FacilityReservationSearchContext = createContext({
  reservationSearchInput: Object.assign({}, reservationSearchDataDefault),
  setReservationSearchInput: () => [],
  bookingInput: Object.assign({}, reservationBookingInput),
  setBookingInput: () => [],
  reservationSearchResults: [],
  setReservationSearchResults: () => [],
  displaySearchResults: false,
  setDisplaySearchResults: () => false,
  reservationWarnings: [],
  setReservationWarnings: () => [],
  clearSearchInput: () => { },
  clickToBookOpenSlot: null,
  setClickToBookOpenSlot: () => [],
  searchingForReservation: false,
  setSearchingForReservation: () => false,
  reservationsToBook: null,
  setReservationsToBook: () => [],
  bookingResponse: null,
  setBookingResponse: () => { }
});

const INITIAL_STATE = {
  reservationSearchInput: Object.assign({}, reservationSearchDataDefault),
  clickToBookOpenSlot: null,
  reservationSearchResults: [],
  reservationWarnings: [],
  searchingForReservation: false,
  displaySearchResults: false,
  reservationsToBook: null,
  bookingInput: Object.assign({}, reservationBookingInput),
  bookingResponse: null
}

export const FACILITY_RES_SEARCH_ACTION_TYPES = {
  SET_OBJECT: 'SET_OBJECT',
  SET_SEARCHING_FOR_RESERVATION: 'SET_SEARCHING_FOR_RESERVATION',
  SET_DISPLAY_SEARCH_RESULTS: 'SET_DISPLAY_SEARCH_RESULTS',
}

const facilityResSearchReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case FACILITY_RES_SEARCH_ACTION_TYPES.SET_OBJECT:
      return {
        ...state, //this keeps everything else and doesn't overwrite
        ...payload
      }
    case FACILITY_RES_SEARCH_ACTION_TYPES.SET_SEARCHING_FOR_RESERVATION:
      return {
        ...state,
        searchingForReservation: payload
      }
    case FACILITY_RES_SEARCH_ACTION_TYPES.SET_DISPLAY_SEARCH_RESULTS:
      return {
        ...state,
        displaySearchResults: payload
      }
    default:
      throw new Error(`Unhandled type ${type}`);
  }
}

export const FacilityReservationSearchProvider = ({ children }) => {

  const [{reservationSearchInput, clickToBookOpenSlot, reservationSearchResults, reservationWarnings, searchingForReservation, displaySearchResults, reservationsToBook, bookingInput, bookingResponse}, dispatch] = useReducer(facilityResSearchReducer, INITIAL_STATE);

  const setReservationSearchInput = (input) => {
    dispatch(createAction(FACILITY_RES_SEARCH_ACTION_TYPES.SET_OBJECT, {reservationSearchInput: input }));
  }

  const setReservationSearchResults = (results) => {
    dispatch(createAction(FACILITY_RES_SEARCH_ACTION_TYPES.SET_OBJECT, {reservationSearchResults: results}));
  }

  const setDisplaySearchResults = (bool) => {
    dispatch(createAction(FACILITY_RES_SEARCH_ACTION_TYPES.SET_DISPLAY_SEARCH_RESULTS, bool));
  }

  const setSearchingForReservation = (bool) => {
    dispatch(createAction(FACILITY_RES_SEARCH_ACTION_TYPES.SET_SEARCHING_FOR_RESERVATION, bool));
  }

  const setReservationsToBook = (input) => {
    dispatch(createAction(FACILITY_RES_SEARCH_ACTION_TYPES.SET_OBJECT, {reservationsToBook: input}));
  }

  const setBookingInput = (input) => {
    dispatch(createAction(FACILITY_RES_SEARCH_ACTION_TYPES.SET_OBJECT, {bookingInput: input}));
  }

  const setBookingResponse = (response) => {
    dispatch(createAction(FACILITY_RES_SEARCH_ACTION_TYPES.SET_OBJECT, {bookingResponse: response}));
  }

  const setReservationWarnings = (warnings) => {
    dispatch(createAction(FACILITY_RES_SEARCH_ACTION_TYPES.SET_OBJECT, {reservationWarnings: warnings}));
  }

  const setClickToBookOpenSlot = (slot) => {
    dispatch(createAction(FACILITY_RES_SEARCH_ACTION_TYPES.SET_OBJECT, {clickToBookOpenSlot: slot}));
  }

  const clearSearchInput = () => {
    setReservationSearchInput(Object.assign({}, reservationSearchDataDefault));
    setReservationSearchResults([]);
    setDisplaySearchResults(false);
    setSearchingForReservation(false);
    setReservationsToBook(null);
    setBookingInput(Object.assign({}, reservationBookingInput));
    setBookingResponse(null);
    setReservationWarnings([]);
    setClickToBookOpenSlot(null);
  }

  const value = { reservationSearchInput, setReservationSearchInput, setBookingInput, bookingInput, bookingResponse, setBookingResponse, clearSearchInput, clickToBookOpenSlot, setClickToBookOpenSlot, reservationSearchResults, setReservationSearchResults, reservationWarnings, setReservationWarnings, searchingForReservation, setSearchingForReservation, displaySearchResults, setDisplaySearchResults, reservationsToBook, setReservationsToBook };

  return <FacilityReservationSearchContext.Provider value={value}>{children}</FacilityReservationSearchContext.Provider>
}