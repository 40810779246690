import { Popover, Box, Stack, Button, List, ListItemButton, ListItemText } from "@mui/material";
import { ApplicationDataContext } from "../../contexts/application-data-context";
import { useState, useEffect, useContext, Fragment } from "react";



const SelectSubTypeForSport = ({ isOpen = false, anchorEl, sport, subTypeSelected, closeView }) => {

    const { sportToSubAreas } = useContext(ApplicationDataContext);
    // const [subTypesForSport, setSubTypesForSport] = useState([]);
    const [selectedSubTypes, setSelectedSubTypes] = useState([]);

    // useEffect(() => {
    //     const subTypeMapping = sportToSubAreas[sport];
    //     if (subTypeMapping && subTypeMapping.subTypes) {
    //         setSubTypesForSport(subTypeMapping.subTypes);
    //     } else {
    //         setSubTypesForSport([]);
    //     }
    // }, [sport]);

    const close = () => {
        if (closeView) {
            closeView();
        }
        setSelectedSubTypes([]);
    }

    const selectSubType = (e) => {
        const sType = e.currentTarget.dataset.id;
        if (subTypeSelected){
            subTypeSelected(sport, sType);
        }
    }
    
    let subTypesForSport = [];
    const subTypeMapping = sportToSubAreas[sport];
    if (subTypeMapping && subTypeMapping.subTypes) {
        subTypesForSport = subTypeMapping.subTypes;
    } 

    return (
        <Popover
            id={'select-subtype-for-sport-popover'}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box mt={1} mb={1} ml={1} mr={1}>
                <Box display={'flex'} justifyContent={'center'}>
                    <List component="div" disablePadding>
                        {
                            Object.keys(subTypesForSport).filter((s) => {
                                return s !== 'Any';
                            }).map((key) => {
                                const subTypeValue = subTypesForSport[key];
                                return <Fragment key={`stype-${key}-frag`}>
                                    <ListItemButton data-id={subTypeValue} onClick={selectSubType.bind(this)} selected={selectedSubTypes.includes(subTypeValue)}>
                                        <ListItemText sx={{color: '#14254C'}} primary={key} />
                                    </ListItemButton>
                                </Fragment>
                            })
                        }
                    </List>
                </Box>
                <Stack mt={2} spacing={1} direction={'row'} display={'flex'} justifyContent={'center'}>
                    <Button variant="contained" onClick={close}>Done</Button>
                </Stack>
            </Box>
        </Popover>
    )
}

export default SelectSubTypeForSport;