import { Box, Typography, Button, Stack } from "@mui/material";
import { useEffect, useRef } from "react";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { styled } from '@mui/material/styles';

const validFileTypes = ["image/png", "image/jpeg", "image/webp"];

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const DragAndDropFile = ({ textColor = 'black', dropText = "Drag and drop files here", fileError, fileUploaded }) => {

    const drop = useRef();

    useEffect(() => {
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('drop', handleDrop);

        return () => {
            if (drop.current) {
                drop.current.removeEventListener('dragover', handleDragOver);
                drop.current.removeEventListener('drop', handleDrop);
            }
        };
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {

        e.preventDefault();
        e.stopPropagation();

        const { files } = e.dataTransfer;

        if (files && files.length) {
            for (const file of files) {
                if (!validFileTypes.includes(file.type)) {
                    if (fileError) {
                        fileError("Image must be in .png, .jpeg or .webp format.");
                        return;
                    }
                }

                const size = (file.size / 1024 / 1024).toFixed(2);
                if (size > 4) {
                    fileError("File size cannot be greater than 4MB.");
                    return;
                }
            }
        }

        onUpload(files[0]);
    };

    const onUpload = (file) => {
        if (fileUploaded) {
            fileUploaded(file);
        }
    }

    const handleFileChosen = (event) => {
        const files = Array.from(event.target.files);

        if (files.length > 1) {
            fileError("Only one file is allowed.");
            return;
        }

        if (files && files.length) {
            for (const file of files) {
                if (!validFileTypes.includes(file.type)) {
                    fileError("Image must be in .png, .jpeg or .webp format.");
                    return;
                }

                const size = (file.size / 1024 / 1024).toFixed(2);
                if (size > 4) {
                    fileError("File size cannot be greater than 4MB.");
                    return;
                }
            }
        }

        onUpload(files[0]);
    }

    return (

        <Box ref={drop} position={'absolute'} width={'100%'} height={'100%'} borderRadius={'5px'} border={'2px #c3c3c3 dashed'} display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
            <Stack width={'100%'} spacing={1}>
                <Typography padding={1} textAlign={'center'} sx={{backgroundColor: '#14254C'}} color={'white'} variant="button">
                    {dropText}
                </Typography>
                <Stack sx={{width: '100%'}} direction={'row'}  display={'flex'} justifyContent={'center'}>
                    <Button sx={{width: '100%'}}
                    variant="contained"
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                    >
                        Upload from Computer
                        <VisuallyHiddenInput onChange={handleFileChosen} type="file" />
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
}

export default DragAndDropFile;