import { Popover, Typography, Divider, Box, Stack, Button } from "@mui/material";
import AreaSelect from "./area-select.component";
import { useState } from "react";


const AreaCopyPricing = ({ copyPricing, anchorEl, copyType, closePopover }) => {

    const [ pricingToCopy, setPricingToCopy ] = useState(null);

    const close = () => {
        if (closePopover) {
            closePopover()
        }
    }

    const areaSelected = (area) => {

        if (copyType === 'pricingexceptions') {
            if (!area.pricingexceptions) {
                alert('This area does not have pricing exceptions, please choose another area.');
                return;
            }
        }

            setPricingToCopy({
                type: copyType,
                pricing: copyType === 'exception' ? area.pricingexceptions : area.defaultpricing
            })
    }

    const copy = () => {

        if (!pricingToCopy){
            alert("You must select an area to copy its pricing");
            return;
        }

        if (copyPricing) {
            copyPricing(pricingToCopy)
        }

        if (closePopover){
            closePopover();
        }

        setPricingToCopy(null);
        
    }

    return (
        <Popover
            id={'copy-area-pricing-popover'}
            open={anchorEl ? true : false}
            anchorEl={anchorEl}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{ width: '50vh' }} mt={1} mb={1} ml={1} mr={1}>
                <Stack>
                    <Typography variant="subtitle1" color={"#14254C"} fontFamily={'Helvetica'} fontWeight={'bold'}>
                        Copy Area Pricing
                    </Typography>
                    <Typography ml={1} variant="caption" color={"gray"} fontFamily={'Helvetica'}>
                        Select the area to copy
                    </Typography>
                </Stack>
                <Divider sx={{ marginBottom: '1.25vh' }}></Divider>
                <AreaSelect areaSelected={areaSelected}></AreaSelect>
                <Stack spacing={2} mt={2} mb={2} direction={'row'} display={'flex'} justifyContent={'center'}>
                    <Button variant="outlined" onClick={close}>Cancel</Button>
                    <Button variant="contained" onClick={copy}>Copy</Button>
                </Stack>

            </Box>
        </Popover>
    )
}

export default AreaCopyPricing;