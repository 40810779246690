import styled from 'styled-components';

export const FacilityCustomerReservationSearchRowContainer = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 10px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        background-color: white;
        margin-right: 0.45vw;
        margin-left: 0.55vw;
        margin-top: 1.0vh;

        &:hover{
            background-color: #DEDEDE};
            cursor: pointer;
        }

        .main-area {
            font-size: 2.0vh;
            font-weight: bold;
            margin-top: 0.25rem;
            margin-bottom: 0.5rem;
            margin-left: 0.25vw;
        }
        .reservation-date-time {
            font-size: 1.5vh;
            margin-bottom: 0.25rem;
            color: #5A5A5A;
            margin-left: 1.25vw;
        }

        .reservation-title {
            font-size: 1.5vh;
            margin-bottom: 0.25rem;
            color: #5A5A5A;
            margin-left: 1.25vw;
            margin-top: 0.25vh;
            font-weight: bold;
        }

        .reservation-failed-payment {
            font-size: 1.5vh;
            margin-bottom: 0.25rem;
            color: red;
            margin-left: 1.25vw;
            margin-top: 0.25vh;
            font-weight: bold;
        }

        .reservation-id {
            font-size: 1.5vh;
            margin-bottom: 0.25rem;
            color: #5A5A5A;
            margin-left: 1.25vw;
            margin-top: 0.25vh;
        }

        .reservation-paid-detials {
            font-size: 1.5vh;
            margin-bottom: 0.25rem;
            color: #5A5A5A;
            margin-left: 1.25vw;
        }

        .canceled-details {
            display: flex;
            flex-direction: row;

            .canceled-info {
                font-size: 1.5vh;
                font-weight: bold;
                margin-bottom: 0.25rem;
                color: red;
                margin-left: 1.25vw;
                margin-top: 0.25vh;
            }

        }


`