import { Box, Stack, Tab, Typography } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import GroupedBirthdayParties from "./birthday-party-groups.component";
import UngroupedBirthdayParties from "./birthday-party-ungrouped.component";

const BirthdayPartyManageParties = () => {

    const [searchParams] = useSearchParams();

    const [value, setValue] = useState("1");

    useEffect(() => {
        const index = searchParams.get('index');
        if (index){
            console.log(index)
            setTabValue(index);
        }
    }, [searchParams]);

    const setTabValue = (index) => {
        setValue(index);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'85px'} left={'0%'} right={'0%'} bottom={'1%'} display={'flex'}>
            <Stack ml={2} width={'100%'}>
                <Typography sx={{ width: '50%' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h5" component="div" ml={1} mt={1} mb={1}>
                    Manage Birthday Parties
                </Typography>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginRight: '1.0vw' }}>
                        <TabList textColor="primary"
                            indicatorColor="primary" onChange={handleChange} aria-label="manage birthday parties">
                            <Tab sx={{
                                '&.Mui-selected': {
                                    backgroundColor: `#A9A9A9`,
                                    borderRadius: '5px',
                                    borderColor: '#14254C',
                                    borderWidth: '2px',
                                    borderStyle: 'solid'
                                }
                            }} label="Grouped" value="1" />
                            <Tab sx={{
                                '&.Mui-selected': {
                                    backgroundColor: `#A9A9A9`,
                                    borderRadius: '5px',
                                    borderColor: '#14254C',
                                    borderWidth: '2px',
                                    borderStyle: 'solid'
                                }
                            }} label="Ungrouped" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <GroupedBirthdayParties close={handleChange} index={"1"}></GroupedBirthdayParties>
                    </TabPanel>
                    <TabPanel value="2">
                        <UngroupedBirthdayParties close={handleChange}></UngroupedBirthdayParties>
                    </TabPanel>

                </TabContext>
            </Stack>
        </Box>
    )

}

export default BirthdayPartyManageParties;