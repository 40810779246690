import { UserContext } from '../../contexts/user.context';
import { useNavigate } from 'react-router-dom'
import { FacilityReservationsContext } from '../../contexts/facility/facility-reservations.context';
import { signOutUser } from '../../utils/firebase/firebase.utils';
import { DisplayContext } from '../../contexts/dispaly-options.context';
import { SearchForReservationContext } from "../../contexts/search-for-reservation/search-for-reservation.context";
import logo from '../../assets/logo-01.png'
import { Fragment, useContext } from "react";
import { Box, Stack, AppBar, Link, Button, Grid, Typography } from "@mui/material";

const NavigationCustomer = () => {

    const { currentUser, userData, setCurrentUser } = useContext(UserContext);
    const { clearReservationData } = useContext(FacilityReservationsContext)
    const { resetAllDisplays } = useContext(DisplayContext);
    const { reset } = useContext(SearchForReservationContext);

    const navigate = useNavigate();

    const getTheApp = () => {
        navigate('/mobile-app');
    }

    const signOut = async () => {
        await signOutUser();
        setCurrentUser(null, null);
        clearReservationData();
        resetAllDisplays();
        reset();
        navigate("/");
    }

    return (
        <AppBar component={'nav'} sx={{ backgroundColor: 'white', maxHeight: '9.0vh', overflow: 'hidden' }} position='fixed'>
            <Stack spacing={1} ml={4} mr={4}>
                <Grid ml={2} mr={2} direction={'row'} container>
                    <Grid item xs={3}>
                        <Link className="logo-container" href='/'>
                            <Box sx={{ height:'100%'}} display={'flex'} justifyContent="flex-start" alignContent={'center'} alignItems="center">
                                <img style={{height: '4.0vh', width:'4.0vw'}} className="logo-image" src={logo} alt="Logo" />
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{height: '100%'}} mb={1} display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                            <Button sx={{ fontFamily: 'Helvetica', fontWeight: 'bold', fontSize: { xs: '0.5vh', sm: '1.1vh', xl: '1.75vh' }, borderRadius: '5px' }} onClick={getTheApp} variant='outlined' >Get The App</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                            <Stack alignItems={'center'} alignContent={'center'} spacing={2} direction={'row'}>
                                <Link sx={{ fontWeight: 'bold', color: 'dark-gray', fontSize: { xs: '1.0vh', sm: '1.75vh' } }} underline="none" variant="subtitle1" href="https://www.mysportspace.info/how-it-works.html">Help</Link>
                                <Link sx={{ fontWeight: 'bold', color: 'dark-gray', fontSize: { xs: '1.0vh', sm: '1.75vh' } }} underline="none" variant="subtitle1" href="https://www.mysportspace.info/contact.html">Contact</Link>
                            </Stack>
                            <Stack ml={5} justifyContent='flex-end'>
                                {currentUser && userData &&
                                    <Fragment>
                                        <Typography sx={{ color: '#14254C', fontWeight: 'bold', fontSize: { xs: '1.0vh', sm: '1.75vh' } }} variant="subtitle1" component="div">{userData.userEmailAddress}</Typography>
                                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                                            <Button sx={{ color: '#14254C', fontSize: { xs: '1.0vh', sm: '1.75vh' } }} onClick={signOut}>
                                                Sign Out
                                            </Button>
                                        </Box>
                                    </Fragment>
                                }
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </AppBar>
    )
}

export default NavigationCustomer