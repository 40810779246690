import * as React from 'react';
import { UserContext } from '../../../contexts/user.context';
import { useState, useContext, useEffect } from "react";
import { getFacilityInvoiceCustomers } from '../../../utils/facility-api/facility-service';
import { Popover, Box, List, ListItemText, ListItemButton } from '@mui/material';
import { Fragment } from 'react';

const FacilityInvoiceCustomersList = ({ isOpen, closeList, selectCustomer }) => {

    const [isLoading, setIsLoading] = useState(true);
    const { userData } = useContext(UserContext);
    const [facilityCustomers, setFacilityCustomers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        if (closeList) {
            closeList();
        }
        setAnchorEl(null);
    };

    const getFacilityCustomers = async () => {

        setIsLoading(true);
        const response = await getFacilityInvoiceCustomers(userData.facilityId);
        if (response.status === 200 && response.data) {
            setFacilityCustomers(response.data.customers);
        } else {
            setFacilityCustomers([]);
        }
        setIsLoading(false);
    };

    const refs = facilityCustomers.reduce((acc, value) => {
        acc[value.userId] = React.createRef();
        return acc;
    }, {});

    const handleClick = (e) => {
        const facCustomerId = e.currentTarget.dataset.id;
        const selectedCustomer = facilityCustomers.find(facCustomer => facCustomer.userId === facCustomerId);
        selectCustomer(selectedCustomer);
    }

    const close = (e) => {
        closeList();
    }

    useEffect(() => {
        if (userData && userData.facilityId) {
            getFacilityCustomers();
        }
    }, [userData]);

    const open = Boolean(anchorEl);
    const id = open ? 'faclity-invoice-customers-popover' : undefined;
    return (
        <Fragment>
            <Popover
                id={id}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box>
                    <List key={'facility-customers-for-search-list-key'}>
                        {
                            facilityCustomers.map((customer) => {

                                const { userId, userEmailAddress, userFirstName, userLastName } = customer
                                return <ListItemButton divider={true} key={userId} onClick={handleClick.bind(this)} data-id={userId}>
                                    <ListItemText key={`${userId}-facility-invoice-customer`} primary={`${userFirstName} ${userLastName}`} secondary={userEmailAddress} primaryTypographyProps={{
                                        letterSpacing: 0,
                                        color: '#14254C'
                                    }}></ListItemText>
                                </ListItemButton>
                            })
                        }
                    </List>
                </Box>
            </Popover>
        </Fragment>
    )
}

export default FacilityInvoiceCustomersList