import styled from 'styled-components';

export const BaseButton = styled.button`
    height: 5.0vh;
    font-size: 1.5vh;
    color: white;
    background-color: #14254C;
    font-weight: bolder;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    `

export const GoogleSignInButton = styled(BaseButton)`
  
      background-color: #4285f4;
      color: white;
  
      &:hover {
        background-color: #357ae8;
        border: none;
      }
    }
    `

export const InvertedButton = styled(BaseButton)`

      background-color: white;
      color: #14254C;
  
      &:hover {
        background-color: #eaeaea;
        color: #14254C;
        border: #14254C;
      }
    }
    `

export const LeftAlignedButton = styled(BaseButton)`
      justify-content: left;
    `
