import { createContext, useReducer, useContext } from "react";
import { createAction } from "../../utils/reducer/reducer.utils";
import { ApplicationDataContext } from "../application-data-context";
import dayjs from "dayjs";

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const Decimal = require('decimal.js');

var isNumeric = function (obj) {
    return !Array.isArray(obj) && (obj - parseFloat(obj) + 1) >= 0;
}

const tenPm = dayjs().startOf('day').add(22, 'hour');

export const reservationSearchDataDefault = {
    selectedSport: null,
    selectedSubType: null,
    numberOfSpaces: 1,
    startTime: dayjs().startOf('hour'),
    endTime: dayjs().startOf('hour').add(1, 'hour').isAfter(tenPm) ? dayjs().startOf('hour').add(1, 'hour') : tenPm,
    duration: 1.0,
    selectedCity: null,
    selectedState: null,
    selectedMiles: null,
    searchDate: dayjs().startOf('day'),
    repeatsUntilDate: null,
    repeatsInterval: null,
    daysOfWeek: [],
    exceptionDates: [],
    isInternalBooking: false,
    customer: null,
    facilityArea: null,
    reservationLength: 1,
    subTypesForSport: [],
    searchByDistance: false,
    searchLatitude: 0.0,
    searchLongitude: 0.0,
    searchRadiusInMiles: 50,
    searchByCityState: false,
    searchStateFull: '',
    searchStateAbb: '',
    searchCity: '',
    reschedulingResId: null,
    isClickToBook: false,
    searchCustomerName: ''
}

const reservationBookingInput = {
    formOfPaymentType: null,
    formOfPayment: null,
    title: null,
    notes: "",
    isInternalBooking: false
}

const GetSubTypeForSport = (sport, sportToSubAreas) => {
    const subTypeMapping = sportToSubAreas[sport];
    if (subTypeMapping) {
        if (subTypeMapping.subTypes) {
            const sortedSubTypes = Object.keys(subTypeMapping.subTypes).sort((sub1, sub2) => {

                var sub1SortValue = sub1;
                var sub2SortValue = sub2;

                if (isNumeric(sub1.substring(0, 1))) {
                    sub1SortValue = parseInt(sub1.substring(0, 1));
                }

                if (isNumeric(sub2.substring(0, 1))) {
                    sub2SortValue = parseInt(sub2.substring(0, 1));
                }

                if (isNumeric(sub1.substring(0, 2))) {
                    sub1SortValue = parseInt(sub1.substring(0, 2));
                }

                if (isNumeric(sub2.substring(0, 2))) {
                    sub2SortValue = parseInt(sub2.substring(0, 2));
                }

                if (sub1SortValue < sub2SortValue) {
                    return -11;
                }

                if (sub1SortValue > sub2SortValue) {
                    return 1;
                }

                return 0;


            });

            const subTypeArray = [];
            for (const sType of sortedSubTypes) {
                subTypeArray.push(`${sType}`);
            }
            return subTypeArray;
        } else {
            const subTypeArray = [];
            for (const key of Object.keys(subTypeMapping)) {
                subTypeArray.push(`${key}`);
            }
            return subTypeArray;
        }
    } else {
        return ["Full Area", "Half Area"];
    }
}

const addOrRemoveSearchResult = (searchResultsToBook, searchResultToAdd) => {
    //search for existing cart item , using the find method.
    //if a result is found, then we need to remove from the selected results
    const existingSearchResult = searchResultsToBook.find((searchResult) => searchResult.reservationId === searchResultToAdd.reservationId);
    if (existingSearchResult) {
        return searchResultsToBook.filter(searchResult => searchResult.reservationId !== searchResultToAdd.reservationId);
    }

    //this just creates a new arrray from the product to add with an extra quanity property
    return [...searchResultsToBook, { ...searchResultToAdd }]
}

const addOrRemoveSearchResultByReservationId = (searchResultsToBook, searchResultToAdd) => {
    //search for existing cart item , using the find method.
    //if a result is found, then we need to remove from the selected results
    const existingSearchResult = searchResultsToBook.find((searchResult) => searchResult.reservationId === searchResultToAdd.reservationId);
    if (existingSearchResult) {
        return searchResultsToBook.filter(searchResult => searchResult.reservationId !== searchResultToAdd.reservationId);
    }

    //this just creates a new arrray from the product to add with an extra quanity property
    return [...searchResultsToBook, { ...searchResultToAdd }]
}

const removeSearchResultById = (searchResultsToBook, reservationId) => {
    return searchResultsToBook.filter(searchResult => searchResult.reservationId !== reservationId);
}

const sortDataCriteria = (sortData) => {
    return sortData.sort((sortData1, sortData2) => {

        if (sortData1.facilityId === sortData2.facilityId){
            if (sortData1.sortDate.isBefore(sortData2.sortDate) ) {
                return -1;
            }
    
            if (sortData1.sortDate.isAfter(sortData2.sortDate) ) {
                return 1;
            }
    
            return 0;
        } else {
            if (sortData1.distanceFromLocation <= sortData2.distanceFromLocation) {
                return -1;
            }

            if (sortData1.distanceFromLocation > sortData2.distanceFromLocation) {
                return 1;
            }

            return 0;
        }
    });
}

export const SearchForReservationContext = createContext({
    reservationSearchData: Object.assign({}, reservationSearchDataDefault),
    setReservationSearchData: () => { },
    backToSearchUrl: '',
    setBackToSearchUrl: () => { },
    searchResults: {},
    setSearchResults: () => [],
    searchResultsToBook: [],
    addSearchResultToBook: () => { },
    removeSearchResultToBook: () => { },
    searchResultsToBookCount: 0,
    previewIsShowing: false,
    setPreviewIsShowing: () => { },
    isSearchingForReservations: false,
    setIsSearchingForReservations: () => { },
    totalWithServiceCharge: new Decimal(0),
    totalWithoutServiceCharge: new Decimal(0),
    totalServiceCharge: new Decimal(0),
    formOfPayment: {},
    setFormOfPayment: () => { },
    reset: () => { },
    reservationWarnings: {},
    setReservationWarnings: () => [],
    bookingInput: Object.assign({}, reservationBookingInput),
    setBookingInput: () => [],
    bookingResponse: null,
    setBookingResponse: () => { },
    clickToBookOpenSlot: null,
    setClickToBookOpenSlot: () => [],
});

export const SEARCH_FOR_RESERVATION_ACTION_TYPES = {
    SET_SEARCH_DATA: 'SET_SEARCH_DATA',
    SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
    SET_SEARCH_RESULTS_TO_BOOOK: 'SET_SEARCH_RESULTS_TO_BOOOK',
    SET_PREVIEW_IS_SHOWING: 'SET_PREVIEW_IS_SHOWING',
    SET_IS_SEARCHING: 'SET_IS_SEARCHING',
    SET_FORM_OF_PAYMENT: 'SET_FORM_OF_PAYMENT',
    SET_BACK_TO_SEARCH_URL: 'SET_BACK_TO_SEARCH_URL',
    RESET: 'RESET',
}

const INITIAL_STATE = {
    reservationSearchData: Object.assign({}, reservationSearchDataDefault),
    backToSearchUrl: '/',
    searchResults: null,
    searchResultsToBook: [],
    previewIsShowing: false,
    isSearchingForReservations: false,
    totalWithServiceCharge: new Decimal(0),
    totalWithoutServiceCharge: new Decimal(0),
    totalServiceCharge: new Decimal(0),
    formOfPayment: null,
    reservationWarnings: [],
    bookingInput: Object.assign({}, reservationBookingInput),
    bookingResponse: null,
    clickToBookOpenSlot: null,
}

const searchForReservationReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT:
            return {
                ...state, //this keeps everything else and doesn't overwrite
                ...payload
            }
        case SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_PREVIEW_IS_SHOWING:
            return {
                ...state,
                previewIsShowing: payload
            }
        case SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_IS_SEARCHING:
            return {
                ...state,
                isSearchingForReservations: payload
            }
        case SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_FORM_OF_PAYMENT:
            return {
                ...state,
                formOfPayment: payload
            }
        case SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_BACK_TO_SEARCH_URL:
            return {
                ...state,
                backToSearchUrl: payload
            }
        case SEARCH_FOR_RESERVATION_ACTION_TYPES.RESET:
            return payload
        default:
            throw new Error(`Unhandled type ${type}`);
    }
}

export const SearchForReservationsProvider = ({ children }) => {

    const { sportToSubAreas } = useContext(ApplicationDataContext);

    const [{ reservationSearchData, clickToBookOpenSlot, backToSearchUrl, searchResults, formOfPayment, totalWithServiceCharge, totalServiceCharge, totalWithoutServiceCharge, searchResultsToBook, previewIsShowing, isSearchingForReservations, reservationWarnings, bookingInput, bookingResponse }, dispatch] = useReducer(searchForReservationReducer, INITIAL_STATE);

    const setBookingInput = (input) => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT, { bookingInput: input }));
    }

    const resetBookingInput = () => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT, { bookingInput: reservationBookingInput }));
    }

    const setBookingResponse = (response) => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT, { bookingResponse: response }));
    }

    const setReservationSearchData = (data) => {

        data.subTypesForSport = GetSubTypeForSport(data.selectedSport, sportToSubAreas);

        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT, { reservationSearchData: data }));
    }

    const setSearchResults = (reservationsearchResults, newFormOfPayment) => {

        if (reservationsearchResults) {
            const searchResultsByDate = {};
            for (const result of reservationsearchResults) {
                const startKey = moment(result.startDate, "YYYY-MM-DD").format('dddd, MMM DD, YYYY');
                let seachResultsForDateKey = searchResultsByDate[startKey];
                if (!seachResultsForDateKey) {
                    seachResultsForDateKey = [];
                }
                result.dateKey = startKey;
                seachResultsForDateKey.push(result);

                searchResultsByDate[startKey] = seachResultsForDateKey;
            }

            const sortedResults = {};
            const dateKeys = Object.keys(searchResultsByDate);
            for (const dateKey of dateKeys) {
                const searchResultsForDate = searchResultsByDate[dateKey];
                let resesByFacility = {};
                for (const searchResultByDate of searchResultsForDate) {
                    let reses = [];
                    if (resesByFacility[searchResultByDate.facilityId]) {
                        reses = resesByFacility[searchResultByDate.facilityId];
                    }
                    reses.push(searchResultByDate);

                    const sortedReses = reses.sort((res1, res2) => {
                        const facilitySortOrder1 = res1.facilityAreaSortOrder ? res1.facilityAreaSortOrder : 0;
                        const facilitySortOrder2 = res2.facilityAreaSortOrder ? res2.facilityAreaSortOrder : 0;
                        const res1StartDate = moment(res1.startDate, "YYYY-MM-DD h:mm a");
                        const res2StartDate = moment(res2.startDate, "YYYY-MM-DD h:mm a");

                        if (facilitySortOrder1 < facilitySortOrder2 && res1StartDate.isBefore(res2StartDate)) {
                            return -1;
                        }

                        if (facilitySortOrder1 > facilitySortOrder2) {
                            return 1;
                        }

                        return 0;
                    });
                    resesByFacility[searchResultByDate.facilityId] = sortedReses;
                }

                const facilitySortCriteriaToReses = [];
                const facilityKeys = Object.keys(resesByFacility);
                for (const fKey of facilityKeys) {
                    const values = resesByFacility[fKey];
                    const sortCriteria = { facilityId: fKey, hasPriorReses: false, isPreferredFacility: false, distanceFromLocation: 0.0, sortDate: null, hasActiveMembership: false, facilityName: "" }
                    const hasActiveMembership = values.filter((res) => {
                        return res.activeMembership;
                    });
                    sortCriteria.hasActiveMembership = hasActiveMembership.length > 0;

                    let hasPriorReses = values.filter((res) => {
                        return res.hasPriorReses;
                    });
                    sortCriteria.hasPriorReses = hasPriorReses.length > 0;

                    const isAtPreferredFacility = values.filter((res) => {
                        return res.isPreferredFacility;
                    });
                    sortCriteria.isPreferredFacility = isAtPreferredFacility.length > 0;

                    if (values.length > 0) {
                        sortCriteria.distanceFromLocation = parseFloat(values[0].distance ? values[0].distance : 0.0);
                        sortCriteria.facilityName = values[0].facilityName;
                        for (const value of values) {

                            const sortDate = moment(value.startDate, "YYYY-MM-DD h:mm A");
                            if (!sortCriteria.sortDate || sortDate.isBefore(sortCriteria.sortDate)) {
                                sortCriteria.sortDate = sortDate.clone();
                            }

                        }
                        facilitySortCriteriaToReses.push(sortCriteria);
                    }
                }

                let membershipFacilities = facilitySortCriteriaToReses.filter((sortData) => {
                    return sortData.hasActiveMembership;
                });
                if (membershipFacilities.length > 0) {
                    membershipFacilities = sortDataCriteria(membershipFacilities);
                }

                let preferredAndPriorResFacilities = facilitySortCriteriaToReses.filter((sortData) => {
                    return !sortData.hasActiveMembership && sortData.isPreferredFacility && sortData.hasPriorReses;
                });

                if (preferredAndPriorResFacilities.length > 0) {
                    preferredAndPriorResFacilities = sortDataCriteria(preferredAndPriorResFacilities);
                }

                let preferredFacilities = facilitySortCriteriaToReses.filter((sortData) => {
                    return !sortData.hasActiveMembership && sortData.isPreferredFacility && !sortData.hasPriorReses
                });

                if (preferredFacilities.length > 0) {
                    preferredFacilities = sortDataCriteria(preferredFacilities);
                }

                let priorResFacilities = facilitySortCriteriaToReses.filter((sortData) => {
                    return !sortData.hasActiveMembership && !sortData.isPreferredFacility && sortData.hasPriorReses;
                });

                if (priorResFacilities.length > 0) {
                    priorResFacilities = sortDataCriteria(priorResFacilities);
                }

                let allOtherResFacilties = facilitySortCriteriaToReses.filter((sortData) => {
                    return !sortData.hasActiveMembership && !sortData.isPreferredFacility && !sortData.hasPriorReses;
                });
                if (allOtherResFacilties.length > 0) {
                    allOtherResFacilties = sortDataCriteria(allOtherResFacilties);
                }

                let sortedReses = [];
                for (const sortData of membershipFacilities) {
                    if (resesByFacility[sortData.facilityId]) {
                       sortedReses = sortedReses.concat(resesByFacility[sortData.facilityId]);
                    }
                }

                for (const sortData of preferredAndPriorResFacilities) {
                    if (resesByFacility[sortData.facilityId]) {
                       sortedReses = sortedReses.concat(resesByFacility[sortData.facilityId]);
                    }
                }

                for (const sortData of preferredFacilities) {
                    if (resesByFacility[sortData.facilityId]) {
                       sortedReses = sortedReses.concat(resesByFacility[sortData.facilityId]);
                    }
                }

                for (const sortData of priorResFacilities) {
                    if (resesByFacility[sortData.facilityId]) {
                       sortedReses = sortedReses.concat(resesByFacility[sortData.facilityId]);
                    }
                }

                for (const sortData of allOtherResFacilties) {
                    if (resesByFacility[sortData.facilityId]) {
                       sortedReses = sortedReses.concat(resesByFacility[sortData.facilityId]);
                    }
                }

                sortedResults[dateKey] = sortedReses;
            }

            dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT, { searchResults: sortedResults, formOfPayment: newFormOfPayment }));
        } else {
            dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT, { searchResults: null, formOfPayment: null }));
        }
    }

    const setFormOfPayment = (newFormOfPyament) => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_FORM_OF_PAYMENT, newFormOfPyament ? { formOfPayment: newFormOfPyament } : null));
    }

    const updateSearchResultsToBook = (newSearchResultsToBook) => {
        let totalWithService = new Decimal(0);
        let totalWithoutService = new Decimal(0);
        let totalService = new Decimal(0);
        for (const booking of newSearchResultsToBook) {
            totalWithService = totalWithService.plus(new Decimal(booking.price));
            totalWithoutService = totalWithoutService.plus(new Decimal(booking.price));
            if (booking.charges) {
                for (const charge of booking.charges) {
                    const { type, amount } = charge;
                    if (type === 'serviceCharge') {
                        let serviceDecimal = new Decimal(amount).div(100.0);
                        totalWithService = totalWithService.plus(serviceDecimal);
                        totalService = totalService.plus(serviceDecimal);
                    }
                }
            }
        }

        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT, { searchResultsToBook: newSearchResultsToBook, totalWithServiceCharge: totalWithService, totalWithoutServiceCharge: totalWithoutService, totalServiceCharge: totalService, bookingInput }));
    }

    const addOrRemoveSearchResultToBook = (searchResultToAdd) => {
        const newSearchResultsToBook = addOrRemoveSearchResult(searchResultsToBook, searchResultToAdd);
        updateSearchResultsToBook(newSearchResultsToBook);
    }

    const addSearchResultByReservationId = (reservationId) => {
        const newSearchResultsToBook = addOrRemoveSearchResultByReservationId(searchResultsToBook, reservationId);
        updateSearchResultsToBook(newSearchResultsToBook);
    }

    const removeSearchResultByReservationId = (reservationId) => {
        const newSearchResultsToBook = removeSearchResultById(searchResultsToBook, reservationId);
        updateSearchResultsToBook(newSearchResultsToBook);
    }

    const clearSearchResultsToBook = () => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT, { searchResultsToBook: [] }));
    }

    const setSearchResultsToBook = (resultsToBook) => {
        updateSearchResultsToBook(resultsToBook);
    }

    const setPreviewIsShowing = (bool) => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_PREVIEW_IS_SHOWING, bool));
    }

    const reset = () => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.RESET, INITIAL_STATE));
    }

    const setIsSearchingForReservations = (bool) => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_IS_SEARCHING, bool));
    }

    const setBackToSearchUrl = (url) => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_BACK_TO_SEARCH_URL, url));
    }

    const setReservationWarnings = (warnings) => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT, { reservationWarnings: warnings }));
    }

    const setClickToBookOpenSlot = (slot) => {
        dispatch(createAction(SEARCH_FOR_RESERVATION_ACTION_TYPES.SET_OBJECT, { clickToBookOpenSlot: slot }));
    }

    const value = { reservationSearchData, setReservationSearchData, clickToBookOpenSlot, setClickToBookOpenSlot, resetBookingInput, setBookingInput, bookingInput, setBookingResponse, bookingResponse, reset, backToSearchUrl, setBackToSearchUrl, searchResults, setSearchResults, formOfPayment, setFormOfPayment, searchResultsToBook, totalWithServiceCharge, totalServiceCharge, totalWithoutServiceCharge, previewIsShowing, setPreviewIsShowing, isSearchingForReservations, setIsSearchingForReservations, addOrRemoveSearchResultToBook, clearSearchResultsToBook, removeSearchResultByReservationId, setReservationWarnings, reservationWarnings, setSearchResultsToBook, addSearchResultByReservationId };

    return <SearchForReservationContext.Provider value={value}>{children}</SearchForReservationContext.Provider>

}