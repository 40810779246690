import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from "@mui/material";

const AlertWithTitle = ({ title, message, okButtonText, cancelButtonText, okAction, cancelAction, isOpen = true }) => {

    return (
        <Dialog
            open={isOpen}
            onClose={cancelAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelAction}>{cancelButtonText}</Button>
                <Button variant="contained" onClick={okAction} autoFocus>
                    {okButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertWithTitle;