import { CustomerListContainer } from './customer-list.styles';
import * as React from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';

const CustomerList = ({ customers, selectCustomer, removeCustomer, closeList, goUp, isEditable = true, header = 'Customers' }) => {

    const add = (e) => {
        selectCustomer();
    }

    const remove = (e) => {
        // removeCustomer(e.target.dataset.id);
    }

    let bottom = '1';
    if (goUp) {
        bottom = '0%';
    }

    return (
        <CustomerListContainer $bottom={bottom}>
            <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" mb={1} ml={1}>
                {header}
            </Typography>
            <List>
                {
                    customers.map(customer => (
                        <ListItem key={customer.userId} divider={true} data-id={customer.userId}>
                            <ListItemText sx={{ my: 0, ml: 1 }} primary={`${customer.userFirstName} ${customer.userLastName}`} primaryTypographyProps={{
                                fontWeight: '500',
                                letterSpacing: 0,
                                color: '#14254C'
                            }} ></ListItemText>
                            <Button hidden={!isEditable} aria-label={customer.userId} onClick={remove.bind(this)}>
                                Remove
                            </Button>
                        </ListItem>
                    ))
                }
                {
                    <ListItem key={'customer-add-key'} divider={false} onClick={add.bind(this)} data-id={'customer-add'}>
                        <ListItemText sx={{ my: 0, ml: 1 }} primary={`Add Customer`} primaryTypographyProps={{
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} ></ListItemText>
                    </ListItem>
                }
            </List>
        </CustomerListContainer>
    )
}

export default CustomerList;