import { MainMenuContainer } from './main-menu-styles';
import IconOnClickMenu from '../../../icon-onclick-menu/icon-onclick-menu.component';
import { useNavigate } from 'react-router-dom';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';

const MainMenu = () => {

    const navigate = useNavigate();

    const FacilitySettingsMenuItems = [
        {
            id: "facility-settings-menu-web-calendar",
            title: "Web Settings",
            description: "Use these settings to help control your web calendar that the customer sees",
            img: <CalendarMonthOutlinedIcon color='primary' sx={{ height: '5.0vh', width: '5.0vw' }} />,
        },
        {
            id: "facility-reservation-settings",
            title: "Reservation Settings",
            description: "These settings are for customizing your facitilies reservations.",
            img: <BookOnlineOutlinedIcon color='primary' sx={{ height: '5.0vh', width: '5.0vw' }} />,
        },
        {
            id: "facility-reservation-time-adjustments",
            title: "Reservation Time Adjustments",
            description: "Adjust reservation time slots, only allow hourly rentals, mandatory length, etc ",
            img: <HourglassBottomOutlinedIcon color='primary' sx={{ height: '5.0vh', width: '5.0vw' }} />,
        },
    ];

    const selected = (e) => {
        const settingId = e.currentTarget.dataset.id;
        switch (settingId) {
            case 'facility-settings-menu-web-calendar':
                navigate('/facility/web-calendar-settings');
                break;
            case 'facility-reservation-settings':
                navigate('/facility/reservation-settings');
                break;
            case 'facility-reservation-time-adjustments':
                navigate('/facility/reservation-time-adjustments');
                break;
            default:
                console.log("Error!");
        }
    }

    return (

        <MainMenuContainer>
            <IconOnClickMenu width='95vw' height='80vh' zindex={'9'} minheight='10vh' minwidth='10vw' selectItem={selected} iconMenuItems={FacilitySettingsMenuItems}></IconOnClickMenu>
        </MainMenuContainer>

    )
}

export default MainMenu;