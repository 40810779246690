import styled from 'styled-components';

export const FacilityViewResContainer = styled.div`
position: fixed;
height: 100%;
width: 100%;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
flex-direction: column;
z-index: 10;
overflow: scroll;
color: #14254C;

.header-and-details {
  position: fixed;
  height: 85%;
  width: 50%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    height: 85%;
    width: 75%;
    margin-top: 1.0em;
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 5px;
}

.main-body {
    display: flex;
    flex-direction: column;
    row-gap: 1.0em;
    justify-content: space-evenly;
    margin: 2.0em;
    overflow: scroll;
}

.label-grouping {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25px, 1fr));
    grid-template-rows: auto;
    row-gap: 1em;
    overflow: scroll;
}

.reserved-for-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
  }

  .reserved-for-list {
    margin-left: 1.5em;
  }

  .reserved-for-label {
    display: flex;
    width: 100%;
    text-align: left;
    margin-left: .01em;
    padding: 0 15px 0 15px;
    align-items: center;
    font-weight: bold;
    font-size: 1.0em;
}

`