import { IconButton, Stack, TextField } from "@mui/material";
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import { useState } from "react";
import { PhoneFormat } from "../../../utils/text-field-utils/text-field-utils";


const AdditionalPhoneNumberRow = ({ isEditMode, phoneNumber, index, editAdditionalPhoneNumber, deleteAdditionalPhoneNumber }) => {

    const [additionalPhonenNumber, setAdditionalPhoneNumber] = useState(phoneNumber);

    const handleChange = (event) => {
        const { value } = event.target;
        setAdditionalPhoneNumber(value);
    }

    const update = () => {
        editAdditionalPhoneNumber(additionalPhonenNumber, index);
    }

    const deleteRow = () => {
        deleteAdditionalPhoneNumber(phoneNumber);
    }

    return (
        <Stack direction={'row'}>
            <TextField InputProps={{
                        inputComponent: PhoneFormat
                    }}  size="small" fullWidth key={phoneNumber} className="additional-phone-number-input" value={additionalPhonenNumber} onChange={handleChange} onBlur={update} disabled={!isEditMode}></TextField>
            {
                isEditMode &&
                <IconButton key={`${phoneNumber}-delete-btn`} onClick={deleteRow}>
                    <RemoveCircleSharpIcon></RemoveCircleSharpIcon>
                </IconButton>
            }
        </Stack>
    )

}

export default AdditionalPhoneNumberRow;