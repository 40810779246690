import { FacilityReservationsContext } from "../../../contexts/facility/facility-reservations.context";
import { useContext } from "react";
import { Typography, Card, CardContent } from '@mui/material';

const getReservationTotal = (reservation) => {

    let reservationTotal = parseFloat(reservation.reservationTotal / 100);

    if (reservation.charges) {

        for (const charge of reservation.charges) {
            if (charge.type === 'serviceCharge') {
                const serviceChargeAmount = parseFloat(charge.amount / 100);
                reservationTotal += serviceChargeAmount;
            }
        }
    }
    return `$${reservationTotal.toFixed(2)}`
}


const FacilityViewCustomerReservationRow = ({ reservation }) => {

    const { setReservationToView } = useContext(FacilityReservationsContext);

    const viewReservation = () => {
        setReservationToView(reservation)
    }

    return (
        <Card onClick={viewReservation} sx={{
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.5)', ':hover': {
                bgcolor: '#eaeaea',
                cursor: 'pointer'
            }
        }}>
            <CardContent>
                <Typography fontWeight={'bold'} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                    {reservation.mainAreaName}
                </Typography>
                <Typography variant="body1" color={"#14254C"}>
                    {reservation.resStartDate} {reservation.resStartTime} - {reservation.resEndTime}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {reservation.title}
                </Typography>
                <Typography variant="body1" color={"#14254C"}>
                    {reservation.paidOrUnpaid} - {getReservationTotal(reservation)}
                </Typography>
                {
                    reservation.canceledOn &&
                    <Typography fontWeight={'bold'} variant="body1" color={'red'}>
                       Canceled
                    </Typography>
                }
            </CardContent>
        </Card>
    )
}

export default FacilityViewCustomerReservationRow