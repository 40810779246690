import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, remove } from "firebase/database";


const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const saveFacilityArea = async (facilityId, facilityArea) => {
    try {
        delete facilityArea.isNewArea;
        delete facilityArea.parentName;
        delete facilityArea.subAreaList;
        delete facilityArea.isBeingCopied;
        delete facilityArea.copiedFromAreaName;

        const { subAreas } = facilityArea;
        if (subAreas){
            const subAreaKeys = Object.keys(subAreas);
            for (const sKey of subAreaKeys){
                let subArea = subAreas[sKey];
                delete subArea.isNewArea;
                delete subArea.parentName;
                delete subArea.isBeingCopied;

                if (!subArea.imageLocationURL){
                    delete subArea.imageLocationURL;
                }

            }
        }

        if (facilityArea.parentAreaId) {
            const facilityAreaRef = ref(getDatabase(), `facility-user-accounts/${facilityId}/facilityAreas/${facilityArea.parentAreaId}/subAreas/${facilityArea.id}`);
            set(facilityAreaRef, facilityArea);

            // //this gets added by the facility area provider and we don't want to save it
            // const facilityAreaParentNameRef = ref(getDatabase(), `facility-user-accounts/${facilityId}/facilityAreas/${facilityArea.parentAreaId}/subAreas/${facilityArea.id}/parentName`);
            // remove(facilityAreaParentNameRef);
        } else {
            const facilityAreaRef = ref(getDatabase(), `facility-user-accounts/${facilityId}/facilityAreas/${facilityArea.id}`);
            set(facilityAreaRef, facilityArea);

            // //this gets added by the facility area provider and we don't want to save it
            // const facilityAreaSubAreaListRef = ref(getDatabase(), `facility-user-accounts/${facilityId}/facilityAreas/${facilityArea.id}/subAreaList`);
            // remove(facilityAreaSubAreaListRef);
        }

    } catch (error) {
        console.log(error);
        return false;
    }
    return true;
}

export const updateSearchStatus = async (facilityId, areaId, parentAreaId, status) => {

    try {
        if (parentAreaId) {
            const facilityAreaRef = ref(getDatabase(), `facility-user-accounts/${facilityId}/facilityAreas/${parentAreaId}/subAreas/${areaId}/searchStatus`);
            set(facilityAreaRef, status);
        } else {
            const facilityAreaRef = ref(getDatabase(), `facility-user-accounts/${facilityId}/facilityAreas/${areaId}/searchStatus`);
            set(facilityAreaRef, status);
        }
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

