import styled from 'styled-components';

export const FacilityAreaContainer = styled.div`

    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow: scroll;

    .facility-area-list {
        list-style:none;
        padding-left: 0;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        min-height: 100px;
        background-color: white;
        overflow: scroll;
    }

`