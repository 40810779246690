import FacilityCustomersView from '../../../components/facility/facility-customers-view/facility-customers-view.component';
import { Fragment, useContext } from 'react';
import { UserContext } from '../../../contexts/user.context';


const FacilityCustomers = () => {

    const {userData, userIsLoading } = useContext(UserContext);
   
    return (
        <Fragment>
            {
                !userData || !userData.isFacilityUserAccount && !userIsLoading &&
                <div>
                    <spa>You are not authorized to view this page.</spa>
                </div>
            }
            {
                userData && userData.isFacilityUserAccount &&
                <FacilityCustomersView selectRow={false}></FacilityCustomersView>
            }
        </Fragment>

    )

}

export default FacilityCustomers