import { Popover, Box, Stack, Button, TextField } from "@mui/material";
import { useState } from "react";


const TextFieldAddPopup = ({ isOpen = false, anchorEl, textFieldLabel, addItem, closeView }) => {

    const [item, setItem] = useState(null);

    const handleItemChange = (event) => {
        event.preventDefault();
        const { value } = event.target;
        setItem(value);
    }

    const close = () => {
        if (closeView) {
            closeView();
        }
        setItem(null);
    }

    const addAndClose = () => {
        if (addItem) {
            addItem(item);
        }

        if (closeView) {
            closeView();
        }

        setItem(null);
    }

    const addAndAddAnother = () => {
        if (addItem) {
            addItem(item);
        }
        setItem("");
    }

    return (
        <Popover
            id={'textfield-add-item-popover'}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{ width: '50vh' }} mt={1} mb={1} ml={1} mr={1}>
                <TextField fullWidth variant="outlined" label={textFieldLabel} name="item" value={item} onChange={handleItemChange}></TextField>
                <Stack mt={2} spacing={1} direction={'row'} display={'flex'} justifyContent={'center'}>
                    <Button variant="outlined" onClick={close}>Close</Button>
                    <Button variant="contained" onClick={addAndClose}>Add</Button>
                    <Button variant="contained" onClick={addAndAddAnother}>Add And Add Another</Button>
                </Stack>
            </Box>
        </Popover>
    )
}

export default TextFieldAddPopup;