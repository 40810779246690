import { CalendarSettingsContext } from '../../../../contexts/customer/calendar-settings.context';
import { CalendarReservationContainer, BlackoutTitleSpanContainer } from './calendar-reservation.styles'
import { ResDetailDivContainer, ResTitleSpanContainer, ResTitleAndEmojiContainer } from './calendar-reservation.styles';
import { Fragment, useContext } from 'react';

function wc_hex_is_light(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
}

const sportToEmoji = (reservation) => {
    const upperCase = reservation.reservedForSport ? reservation.reservedForSport.toUpperCase() : "";
    let emoji = null;
    if (upperCase.includes("BASKETBALL")) {
        emoji = "🏀";
    }

    if (upperCase.includes("BASEBALL")) {
        emoji = "⚾️";
    }

    if (upperCase.includes("LACROSSE")) {
        emoji = "🥍";
    }

    if (upperCase.includes("SOCCER")) {
        emoji = "⚽️";
    }

    if (upperCase.includes("SOFTBALL")) {
        emoji = "🥎";
    }

    if (upperCase.includes("TRAINING")) {
        emoji = "⏱️";
    }

    if (upperCase.includes("VOLLEYBALL")) {
        emoji = "🏐";
    }

    if (emoji) {
        return `${emoji}`;
    } else {
        return null;
    }

}

const CalendarReservation = ({ facilityArea, reservation, hoursOfOperation }) => {

    const { calendarSettings } = useContext(CalendarSettingsContext);

    if (!hoursOfOperation) {
        return null;
    }

    const { subAreas } = facilityArea;

    const handleClick = (e) => {
        e.preventDefault();
    }

    const { title, mainAreaReservedType, subTitle } = reservation;
    let numberOfColumns = 1;
    let spanColumns = '1 / 1';
    if (subAreas) {

        subAreas.sort((area1, area2) => {
            if (area1.sortOrder < area2.sortOrder) {
                return -1;
            }

            if (area1.sortOrder > area2.sortOrder) {
                return 1;
            }

            return 0;
        });

        if (mainAreaReservedType !== 'sub') {
            let columnStart = -1;
            let areasFound = 0;
            let subCount = 1;
            for (const subArea of subAreas) {
                for (const reservationAreaId of reservation.areaIds) {
                    if (subArea.id === reservationAreaId) {
                        areasFound += 1;
                        if (columnStart === -1) {
                            columnStart = subCount;
                        }
                    }
                }
                subCount += 1;
            }
            spanColumns = `${columnStart} / ${areasFound + 1}`;
            numberOfColumns = areasFound;
        } else {
            let subAreaCount = 1;
            let columnNumber = 1;
            for (const subArea of subAreas) {
                if (reservation.areaIds.includes(subArea.id)) {
                    columnNumber = subAreaCount;
                }
                subAreaCount += 1;
            }
            if (columnNumber > 0) {
                spanColumns = `${columnNumber} / ${columnNumber + 1}`;
            } else {
                spanColumns = `1 / 1`;
            }

        }
    }

    let titleColumnSpan = spanColumns;
    if (mainAreaReservedType === 'sub') {
        titleColumnSpan = '1';
    }

    const rowStartTimesToRowStart = {};
    if (hoursOfOperation) {

        let startTime = hoursOfOperation.startDateTime.clone();
        let endTime = hoursOfOperation.endDateTime.clone();

        let rowStart = 0;
        while (startTime.isBefore(endTime)) {
            rowStartTimesToRowStart[startTime.format('h A')] = rowStart
            startTime.add(60, 'minutes');
            rowStart += 4;
        }
    }

    const resStartMinutes = parseInt(reservation.startDate.format("m"));
    let rowsAdd = 1;
    if (resStartMinutes === 15) {
        rowsAdd += 1
    } else if (resStartMinutes === 30) {
        rowsAdd += 2
    } else if (resStartMinutes === 45) {
        rowsAdd += 3
    }

    const startResInRow = (rowStartTimesToRowStart[reservation.startDate.format('h A')]) + rowsAdd;
    const resLengthInMinutes = reservation.endDate.diff(reservation.startDate, 'minutes');
    const fifteenMinutesIntervals = resLengthInMinutes / 15;
    const endResInRow = startResInRow + fifteenMinutesIntervals;

    let resTitleBackgroudColor = `${calendarSettings.secondaryFacilityColor}`;
    let resTitleColor = `${calendarSettings.mainFacilityColor}`;

    if (!wc_hex_is_light(resTitleColor)) {
        resTitleColor = 'white';
    }

    if (reservation.isBlackOutPeriod) {
        resTitleColor = 'white';
        resTitleBackgroudColor = '#00000075';
    }

    return (
        <CalendarReservationContainer $numberofcolumns={numberOfColumns} $gridrowstart={`${startResInRow}`} $gridrowend={`${endResInRow}`} $gridcolumn={spanColumns}>
            <ResDetailDivContainer $spancolumn={titleColumnSpan} $spanrow={'1'} $backgroundcolor={resTitleBackgroudColor}>
                {
                    !reservation.isBlackOutPeriod &&
                    <ResTitleAndEmojiContainer $backgroundcolor={resTitleBackgroudColor}>
                        <ResTitleSpanContainer $fontSize={'1.5vh'} $titlecolor={resTitleColor} $backgroundcolor={resTitleBackgroudColor} onClick={handleClick.bind(this)}>
                            {`${reservation.isInternalBooking ? calendarSettings.showInternalBookingTitlesOnWebCalendar ? title : 'Internal': calendarSettings.showReservationTitlesOnWebCalendar ? title : 'Reserved'}`}
                        </ResTitleSpanContainer>
                    </ResTitleAndEmojiContainer>
                }
                {
                    reservation.isBlackOutPeriod &&
                    <Fragment>
                        <BlackoutTitleSpanContainer onClick={handleClick.bind(this)} $fontSize={'1.5vh'} $backgroundcolor={resTitleBackgroudColor}>
                        {`${calendarSettings.showBlackoutTitlesOnWebCalendar ? !subTitle ? `${title}` : `${title} - ${subTitle}` : 'Blacked Out'}`}
                        </BlackoutTitleSpanContainer>
                    </Fragment>
                }
            </ResDetailDivContainer>
        </CalendarReservationContainer>
    )

}

export default CalendarReservation;