import { LabelWithHeaderContainer } from './label-with-header.styles'


const LabelWithHeader = ({ headerText, labelText, headerFont, labelFont }) => {
    let headerFontSize = '0.75rem';
    if (headerFont) {
        headerFontSize = headerFont;
    }

    let labelFontSize = '0.75rem';
    if (labelFont) {
        labelFontSize = labelFont;
    }
    return (
        <LabelWithHeaderContainer $headerfontsize={headerFontSize} $labelfontsize={labelFontSize} className="container">
            <span className="header">{headerText}</span>
            <span className="label">{labelText}</span>
        </LabelWithHeaderContainer>
    )
}

export default LabelWithHeader