import { Box, Stack, Typography, IconButton, Divider } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import { DAYS_OF_WEEK_INT_TO_TEXT } from "../../../utils/constants/constants";
import DeleteIcon from '@mui/icons-material/Delete';
import { AddCircleOutlineOutlined, CopyAllOutlined } from "@mui/icons-material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditAreaPrice from "./area-edit-price.component";
import { v4 as uuid } from 'uuid';
import { getDaysOfWeekTextFromInt } from "../../../utils/date-utilities/date-utilities";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AreaCopyPricing from "./area-copy-pricing.componet";
import CopyDowPricingDialog from "./copy-dow-pricing-dialog.component";

const AreaPricing = ({ area, isEditMode, updateAreaPricing, isExceptions = false }) => {

    const [defaultPricingToDow, setDefaultPricingToDow] = useState({});
    const [editPopoverEl, setEditPopoverEl] = useState(null);
    const [copyDowPricingPopoverEl, setCopyDowPricingPopoverEl] = useState(null);
    const [pricingDataToEdit, setPricingDataToEdit] = useState(null);
    const [copyAreaPopoverEl, setCopyAreaPopoverEl] = useState(null);
    const [copyAreaPricingType, setCopyAreaPricingType] = useState(null);
    const [copyThisDow, setCopyThisDow] = useState(null);

    const configurePricing = (pricing) => {
        const pricingKeys = Object.keys(pricing).sort((key1, key2) => {

            if (isExceptions) {
                const beginDate1 = dayjs(`${key1}`, 'YYYY-MM-DD');
                const beginDate2 = dayjs(`${key2}`, 'YYYY-MM-DD');

                if (beginDate1.isBefore(beginDate2)) {
                    return 1;
                }

                if (beginDate1.isAfter(beginDate2)) {
                    return -1;
                }

                return 0;
            } else {
                return 0;
            }
        });

        let pricingForPeriodDict = {};
        for (const pricingKey of pricingKeys) {
            const dowPrice = pricing[pricingKey];
            dowPrice.id = pricingKey;
            let pricingPeriodKey = dowPrice.dayOfWeek ? dowPrice.dayOfWeek : pricingKey;
            const pricingForPeriod = pricingForPeriodDict[pricingPeriodKey];
            if (pricingForPeriod) {
                pricingForPeriodDict[pricingPeriodKey] = [].concat(pricingForPeriod, [dowPrice]).sort((pricing1, pricing2) => {
                    if (pricing1.beginDate) {
                        const beginDate1 = dayjs(`${pricing1.beginDate}`, 'YYYY-MM-DD');
                        const beginDate2 = dayjs(`${pricing2.beginDate}`, 'YYYY-MM-DD');

                        if (beginDate1.isBefore(beginDate2)) {
                            return -1;
                        }

                        if (beginDate1.isAfter(beginDate2)) {
                            return 1;
                        }

                        return 0;
                    } else {

                        if (!pricing1.beginTime && pricing2.beginTime) {
                            return -1;
                        }
                        const beginTime1 = dayjs(`1970-01-01 ${pricing1.beginTime}`, 'YYYY-MM-DD h:mm A');
                        const beginTime2 = dayjs(`1970-01-01 ${pricing2.beginTime}`, 'YYYY-MM-DD h:mm A');

                        if (beginTime1.isBefore(beginTime2)) {
                            return -1;
                        }

                        if (beginTime1.isAfter(beginTime2)) {
                            return 1;
                        }

                        return 0;
                    }
                });
            } else {
                pricingForPeriodDict[pricingPeriodKey] = [dowPrice];
            }
        }
        setDefaultPricingToDow(pricingForPeriodDict);
    }

    useEffect(() => {
        const { defaultpricing, pricingexceptions } = area;
        if (!isExceptions && defaultpricing) {
            configurePricing(defaultpricing);
        } else if (isExceptions) {
            configurePricing(pricingexceptions ? pricingexceptions : [] );
        }

    }, [area]);

    const handleDelete = (event) => {
        const id = event.currentTarget.dataset.id;
        const keys = Object.keys(defaultPricingToDow);
        let dayOfWeekKeyFound = null;
        for (const dowKey of keys) {
            const dowPrices = defaultPricingToDow[dowKey];

            const pricesForId = dowPrices.filter((dowPrice) => {
                return dowPrice.id === id
            });

            if (pricesForId && pricesForId.length > 0) {
                dayOfWeekKeyFound = dowKey;
            }
        }

        if (dayOfWeekKeyFound) {
            const dowPrices = defaultPricingToDow[dayOfWeekKeyFound];
            const filteredPrices = dowPrices.filter((price) => {
                return price.id !== id;
            });

            const newDefaultPricesToDow = Object.assign(defaultPricingToDow, {});
            if (filteredPrices && filteredPrices.length > 0) {
                newDefaultPricesToDow[dayOfWeekKeyFound] = filteredPrices;
            } else {
                delete newDefaultPricesToDow[dayOfWeekKeyFound];
            }

            setDefaultPricingToDow(newDefaultPricesToDow);
            updateAreaPricing(newDefaultPricesToDow);
        }

    }

    const handleDowCopy = (event) => {
        const id = event.currentTarget.dataset.id;
        setCopyThisDow(id);
        setCopyDowPricingPopoverEl(event.currentTarget);
    }

    const closeCopyDowPricingCopy = () => {
        setCopyDowPricingPopoverEl(null);
        setCopyThisDow(null);
    }

    const handleAdd = (event) => {
        const id = event.currentTarget.dataset.id;
        if (id === 'exception') {
            setPricingDataToEdit({
                id: uuid(),
                dayOfWeeks: [],
                beginTime: "8:00 AM",
                endTime: "9:00 AM",
                halfHourPrice: 25.0,
                hourlyPrice: 50.0,
                beginDate: dayjs().format("YYYY-MM-DD"),
                endDate: dayjs().format("YYYY-MM-DD"),
            });
        } else {
            setPricingDataToEdit({
                id: uuid(),
                dayOfWeek: id,
                beginTime: "8:00 AM",
                endTime: "9:00 AM",
                halfHourPrice: 25.0,
                hourlyPrice: 50.0
            });
        }

        setEditPopoverEl(event.currentTarget);
    }

    const handleACopyPricing = (event) => {
        const id = event.currentTarget.dataset.id;
        setCopyAreaPopoverEl(event.currentTarget);
        setCopyAreaPricingType(id);
    }

    const copyAreaPricing = (pricingToCopy) => {
        configurePricing(pricingToCopy.pricing);
        alert("The pricing has been copied.  Click Save to update this area.");
    }

    const closeCopyPricing = () => {
        setCopyAreaPopoverEl(null);
    }

    const handleEdit = (event) => {
        const id = event.currentTarget.dataset.id;
        const keys = Object.keys(defaultPricingToDow);
        for (const dowKey of keys) {
            const dowPrices = defaultPricingToDow[dowKey];

            const foundDowPriceForId = dowPrices.filter((dowPrice) => {
                return dowPrice.id === id
            })

            if (foundDowPriceForId && foundDowPriceForId.length > 0) {
                setPricingDataToEdit(foundDowPriceForId[0]);
            }

        }
        setEditPopoverEl(event.currentTarget);
    }
    const closeEdit = () => {
        setEditPopoverEl(null);
    }

    const updateAreaPrice = (priceData) => {

        const dowPrices = priceData.dayOfWeek ? defaultPricingToDow[priceData.dayOfWeek] : defaultPricingToDow[priceData.id];
        if (!priceData.beginDate && priceData.beginTime) {

            const newPriceBegin = dayjs(`1970-01-01 ${priceData.beginTime}`, 'YYYY-MM-DD h:mm A');
            //need to check for overlapping times
            for (const price of dowPrices) {
                if (price.id !== priceData.id) {
                    const priceBegin = dayjs(`1970-01-01 ${price.beginTime}`, 'YYYY-MM-DD h:mm A');

                    if (newPriceBegin.isSameOrAfter(priceBegin) && newPriceBegin.isSameOrBefore(priceBegin)) {
                        alert('Price time ranges for a day of week cannot overlap.')
                        return;
                    }
                }
            }
        }

        let newPrices = [];

        if (dowPrices) {
            const filteredPrices = dowPrices.filter((price) => {
                return price.id !== priceData.id;
            })

            newPrices = [].concat(filteredPrices, [priceData]).sort((pricing1, pricing2) => {
                if (pricing1.beginDate) {
                    const beginDate1 = dayjs(`${pricing1.beginDate}`, 'YYYY-MM-DD');
                    const beginDate2 = dayjs(`${pricing2.beginDate}`, 'YYYY-MM-DD');

                    if (beginDate1.isBefore(beginDate2)) {
                        return 1;
                    }

                    if (beginDate1.isAfter(beginDate2)) {
                        return -1;
                    }

                    return 0;
                } else {

                    if (!pricing1.beginTime && pricing2.beginTime) {
                        return -1;
                    }
                    const beginTime1 = dayjs(`1970-01-01 ${pricing1.beginTime}`, 'YYYY-MM-DD h:mm A');
                    const beginTime2 = dayjs(`1970-01-01 ${pricing2.beginTime}`, 'YYYY-MM-DD h:mm A');

                    if (beginTime1.isBefore(beginTime2)) {
                        return -1;
                    }

                    if (beginTime1.isAfter(beginTime2)) {
                        return 1;
                    }

                    return 0;
                }
            });
        } else {
            newPrices = [priceData];
        }

        const newDefaultPricesToDow = Object.assign(defaultPricingToDow, {});
        newDefaultPricesToDow[priceData.dayOfWeek ? priceData.dayOfWeek : priceData.id] = newPrices;

        setDefaultPricingToDow(newDefaultPricesToDow);

        updateAreaPricing(newDefaultPricesToDow);
    }

    const copyDowPricing = (dowWeeksToCopyTo) => {

        const copyThisPricing = defaultPricingToDow[copyThisDow];
        const pricingKeys = Object.keys(defaultPricingToDow)
        
        for (const pricingKey of pricingKeys){
            for (const dowToCopyTo of dowWeeksToCopyTo) {
                if (`${dowToCopyTo}` === pricingKey) {
                    const oldPricingData = defaultPricingToDow[pricingKey];
                    const newPricingData = JSON.parse(JSON.stringify(copyThisPricing));
                    for (const dowIndex in newPricingData) {
                        const data = newPricingData[dowIndex];
                        data.dayOfWeek = `${dowToCopyTo}`;
                        if (dowIndex <= oldPricingData.length - 1) {
                            data.id = oldPricingData[dowIndex].id;
                        } else {
                            data.id = uuid();
                        }
                    }
                    defaultPricingToDow[dowToCopyTo] = newPricingData;
                }
            }
        }
        updateAreaPricing(defaultPricingToDow);
        closeCopyDowPricingCopy();
        alert("The pricing has been copied.  Click Save to update this area.");
    }

    return (
        <Box width={'100%'} display={'flex'}>
            <AreaCopyPricing anchorEl={copyAreaPopoverEl} copyType={copyAreaPricingType} copyPricing={copyAreaPricing} closePopover={closeCopyPricing} ></AreaCopyPricing>
            <Stack>
                <Stack direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <Typography fontWeight={'bold'} fontFamily={'Helvetica'} color={"#14254C"} variant="subtitle1">{!isExceptions ? 'Pricing' : 'Pricing Exceptions'}</Typography>
                    <Stack spacing={1} direction={'row'}>
                        {isEditMode && isExceptions &&
                            <IconButton key={`add-exception-icon-btn`} edge="end" aria-label="addexception" onClick={handleAdd.bind(this)} data-id={`exception`}>
                                <AddCircleOutlineOutlined key={`add-icon-exception`} />
                            </IconButton>
                        }
                        {isEditMode &&
                            <IconButton key={`copy-pricing-icon-btn`} edge="end" aria-label="copypricing" onClick={handleACopyPricing.bind(this)} data-id={`${isExceptions ? `pricingexceptions` : 'defaultpricing'}`}>
                                <ContentCopyIcon key={`copy-pricing-icon`} />
                            </IconButton>
                        }
                    </Stack>
                </Stack>
                <Typography ml={1} variant="caption" color={"gray"} fontFamily={'Helvetica'}>
                    {!isExceptions ? 'Set daily pricing for this area' : 'Set pricing exceptions for this area'}
                </Typography>
                <Divider></Divider>

                <EditAreaPrice isExceptions={isExceptions} isOpen={editPopoverEl ? true : false} anchorEl={editPopoverEl} priceDataIn={pricingDataToEdit} updatePrice={updateAreaPrice} closeView={closeEdit}></EditAreaPrice>
                <CopyDowPricingDialog isOpen={copyDowPricingPopoverEl ? true : false} copyPricing={copyDowPricing} close={closeCopyDowPricingCopy} copyingFromDow={copyThisDow}> </CopyDowPricingDialog>
                <Box>
                    {
                        Object.keys(defaultPricingToDow).map((periodKey) => {
                            const dowPricing = defaultPricingToDow[periodKey]
                            return <Stack ml={2} key={`${periodKey}-stack`} >
                                <Stack direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                    {
                                        dowPricing[0].dayOfWeek &&
                                        <Fragment>
                                            <Typography fontWeight={'bold'} fontFamily={'Helvetica'} color={"#14254C"} variant="subtitle1">{DAYS_OF_WEEK_INT_TO_TEXT[parseInt(periodKey)]}</Typography>
                                            {isEditMode &&
                                                <Stack direction={'row'}>
                                                    <IconButton key={`add-icon-btn-${periodKey}`} edge="end" aria-label="add" onClick={handleAdd.bind(this)} data-id={`${periodKey}`}>
                                                        <AddCircleOutlineOutlined key={`add-icon-${periodKey}`} />
                                                    </IconButton>
                                                    <IconButton key={`copy-icon-btn-${periodKey}`} edge="end" aria-label="copy" onClick={handleDowCopy.bind(this)} data-id={`${periodKey}`}>
                                                        <CopyAllOutlined key={`copy-icon-${periodKey}`} />
                                                    </IconButton>
                                                </Stack>
                                            }
                                        </Fragment>
                                    }
                                    {
                                        dowPricing[0].beginDate &&
                                        <Typography fontWeight={'bold'} fontFamily={'Helvetica'} color={"#14254C"} variant="subtitle1">{`${dayjs(dowPricing[0].beginDate, 'YYYY-MM-DD').format('dddd, MMM DD, YYYY')} to ${dayjs(dowPricing[0].endDate, 'YYYY-MM-DD').format('dddd, MMM DD, YYYY')} `}</Typography>
                                    }
                                </Stack>
                                <Stack ml={2}>
                                    {
                                        dowPricing.map((pricingData => {
                                            const { beginTime, endTime, halfHourPrice, hourlyPrice, id, daysOfWeek } = pricingData;
                                            return <Stack mb={1} key={`main-${id}`} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                                {daysOfWeek &&
                                                    <Typography width={'100%'} textAlign={'left'} fontFamily={'Helvetica'} color={"#14254C"} variant="subtitle1">{getDaysOfWeekTextFromInt(daysOfWeek)}</Typography>
                                                }
                                                <Stack key={`sub-${id}`} sx={{ width: '100%' }} spacing={2} direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'} justifyContent={'space-between'}>
                                                    <Stack>
                                                        <Typography sx={{ width: '15vw' }} key={`${id}-price-header`} color={'#14254C'}>{beginTime ? `${beginTime} - ${endTime}` : 'Default'}</Typography>
                                                        {
                                                            !beginTime &&
                                                            <Typography ml={1} variant="caption" color={"gray"} fontFamily={'Helvetica'}>
                                                                This is the default price
                                                            </Typography>
                                                        }
                                                    </Stack>
                                                    <Typography key={`${id}-halfhour-header`} color={'gray'}>Half Hour</Typography>
                                                    <Typography key={`${id}-halfhour-price`} color={'#14254C'}>{`$${parseFloat(halfHourPrice).toFixed(2)}`}</Typography>
                                                    <Typography key={`${id}-hour-header`} color={'gray'} variant="bodystyle1">Hourly</Typography>
                                                    <Typography key={`${id}-hour-price`} color={'#14254C'}>{`$${parseFloat(hourlyPrice).toFixed(2)}`}</Typography>
                                                    {
                                                        isEditMode &&
                                                        <IconButton key={`edit-icon-btn-${periodKey}-${id}`} edge="end" aria-label="delete" onClick={handleEdit.bind(this)} data-id={id}>
                                                            <EditOutlinedIcon key={`edit-icon-${periodKey}-${id}`} />
                                                        </IconButton>
                                                    }
                                                    {
                                                        isEditMode && beginTime &&
                                                        <IconButton key={`del-icon-btn-${periodKey}-${id}`} edge="end" aria-label="delete" onClick={handleDelete.bind(this)} data-id={id}>
                                                            <DeleteIcon key={`del-icon-${periodKey}-${id}`} />
                                                        </IconButton>
                                                    }
                                                </Stack>
                                                <Divider sx={{ width: '98%', marginLeft: '1.0vw' }}></Divider>
                                            </Stack>
                                        }))
                                    }
                                </Stack>
                            </Stack>
                        })
                    }
                </Box>
            </Stack>
        </Box>
    )
}

export default AreaPricing