import { HomeContainer } from "./customer.home.styles";
import HomePage from '../../components/home-page/home-page.component';

const CustomerHome = () => {

    return (
        <HomeContainer>
            <HomePage></HomePage>
        </HomeContainer>
    )
}

export default CustomerHome;