import { FacilityCustomerViewRowContainer } from './facility-customers-view-row.styles'

let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    let matchWithOne = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (matchWithOne) {
        return matchWithOne[1] + '(' + matchWithOne[2] + ') ' + matchWithOne[3] + '-' + matchWithOne[4]
    };

    return str
};

const FacilityCustomerViewRow = ({ row, facilityCustomer, isOpenRow, selectRow, selectFacilityCustomer, selectedCustomers, viewCustomer }) => {

    const { userEmailAddress, userFirstName, userLastName, userPhoneNumber, userId } = facilityCustomer ?? {};

    function isOdd(number) {
        return number % 2 !== 0;
    }

    const clickCustomerCell = () => {
        if (!isOpenRow && selectRow) {
            selectFacilityCustomer(facilityCustomer);
        } else {
            viewCustomer(facilityCustomer);
        }
    }

    let rowColor = "white";
    if (!isOpenRow && isOdd(row)) {
        rowColor = "#eaeaea"
    }

    let hoverColor = '#14254C';
    let borderRadius = 'none';
    let boxShadow = 'none';
    if (isOpenRow) {
        hoverColor = rowColor;
    } else {
        borderRadius = '5px';
        boxShadow = '0 3px 10px rgb(0 0 0 / 0.2)';
    }

    let textColor = '#14254C';
    if (selectedCustomers && selectedCustomers.includes(userId)){
        rowColor = '#14254C';
        textColor = 'white';
    }



    return (
        <FacilityCustomerViewRowContainer onClick={clickCustomerCell} $textcolor={textColor} $rowcolor={rowColor} $rowstart={row} $rowend={row} $hovercolor={hoverColor} $rowboxshadow={boxShadow} $rowborderadius={borderRadius}>
            {
                !isOpenRow &&
                <div className='user-name'><span>{userLastName}, {userFirstName}</span></div>
            }

            {
                isOpenRow &&
                <div className='user-name'><span></span></div>
            }

            <div className='user-email'><span>{userEmailAddress}</span></div>
            <div className='user-phone-number'><span>{formatPhoneNumber(userPhoneNumber)}</span></div>
        </FacilityCustomerViewRowContainer>
    )
}

export default FacilityCustomerViewRow;