import { MembershipListContainer } from './membership-list.styles';
import * as React from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';

const MembershipList = ({ memberships, selectMembership, removeMembership, closeList, goUp, isEditable = true, header = 'Memberships' }) => {

    const add = (e) => {
        selectMembership();
    }

    const remove = (e) => {
        removeMembership(e.currentTarget.dataset.id);
    }

    let bottom = '1';
    if (goUp) {
        bottom = '0%';
    }

    return (
        <MembershipListContainer $bottom={bottom}>
            <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" mb={1} ml={1}>
                {header}
            </Typography>
            <List>
                {
                    memberships.map(membership => (
                        <ListItem key={membership.facilityMembershipId} divider={true} data-id={membership.facilityMembershipId}>
                            <ListItemText sx={{ my: 0, ml: 1 }} primary={membership.name} primaryTypographyProps={{
                                fontWeight: '500',
                                letterSpacing: 0,
                                color: '#14254C'
                            }} ></ListItemText>
                            <Button hidden={!isEditable} aria-label="remove-membership" onClick={remove.bind(this)}>
                                Remove
                            </Button>
                        </ListItem>
                    ))
                }
                {
                    <ListItem key={'membership-add-key'} divider={false} onClick={add.bind(this)} data-id={'membership-add'}>
                        <ListItemText sx={{ my: 0, ml: 1 }} primary={`Add Membership`} primaryTypographyProps={{
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} ></ListItemText>
                    </ListItem>
                }
            </List>
        </MembershipListContainer>
    )

}

export default MembershipList;