import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Fragment } from 'react';

const MySportSpaceSnackbarAlert = ({message, buttonText, isOpen = false, close, action}) => {

    const handleClose = () => {
        if (close){
            close();
        }
    }

    const performAction = () => {
        if (action){
            action();
        }
    }

    const snackBarAction = (
        <Fragment>
          <Button sx={{color: 'white'}} size="medium" onClick={performAction}>
            {buttonText}
          </Button>
          <IconButton
            size="medium"
            aria-label="close"
            onClick={handleClose}
            sx={{color: 'white'}}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Fragment>
      );
    
    return (
      
        <Snackbar
        open={isOpen}
        autoHideDuration={10000}
        onClose={handleClose}
        message={message}
        action={snackBarAction}
      />
    )
}

export default MySportSpaceSnackbarAlert;