import { initializeApp } from 'firebase/app';
import {
    ref,
    uploadBytes,
    getDownloadURL,
    getStorage,
    uploadBytesResumable
} from 'firebase/storage';

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const uploadFileToStorage = async (file) => {
    return new Promise(function (resolve, reject) {
        const fileLocation = `user-facility-images/${file.name}`;
        const storageRef = ref(getStorage(), fileLocation);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
            (error) => {
                console.log(error);
                reject();
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve({
                        downloadURL: downloadURL,  storageLocation: fileLocation
                    });
                });
            }
        );
    })
}

export const uploadFileToStorageLocation = async (file, storageLocation) => {
    return new Promise(function (resolve, reject) {
        const fileLocation = `${storageLocation}/${file.name}`;
        const storageRef = ref(getStorage(), fileLocation);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                // console.log(progress);
            },
            (error) => {
                console.log(error);
                reject();
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve({
                        downloadURL: downloadURL,  storageLocation: fileLocation
                    });
                });
            }
        );
    })
}