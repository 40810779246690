import styled from 'styled-components';

export const FormHeaderContainer = styled.div`

display: flex;  
flex-direction: column;
justify-content: flex-start;
margin-left: 1.0vw;
margin-right: 1.0vw;
margin-top: 1.0vh;
color: #14254C;
line-height: 1.5rem;


.description-and-title {
  display: flex;

  .title {
    display: flex;
    width: 95%;
    text-align: left;
    font-size: 2.5vh;
    margin-left: 1.0rem;
    font-weight: bold;
    justify-content: flex-start;
    align-items: center;
}

.close-x {
    width: 5%;
    display: flex;
    text-align: left;
    font-weight: bold;
    font-size: 2.0vh;
    justify-content: center;
    align-items: center;
  }

  .close-x: hover {
    cursor: pointer;
  }
}


  .form-description {
    margin-left: 1.75vw;
    display: flex;
    color: gray;
  }

`