import { FacilityAreaContext } from '../../../contexts/facility/facility-area-provider.context';
import { useContext } from "react";
import { FacilityAreaContainer } from './facility-areas.styles';
import FacilityAreaRow from './facility-area-row.component';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';


const FacilityAreas = ({ selectFacilityArea, selectedFacilityAreas, header = 'Facility Areas' }) => {

    const { facilityAreas } = useContext(FacilityAreaContext);

    const allFacilityAreas = [];
    for (const mArea of facilityAreas) {
        allFacilityAreas.push(mArea);
        if (mArea.subAreas) {
            for (const subAreaId of Object.keys(mArea.subAreas)) {
                const subArea = mArea.subAreas[subAreaId];
                subArea.id = subAreaId;
                allFacilityAreas.push(subArea);
            }
        }
    }

    let row = 0;
    return (
        <FacilityAreaContainer>
            <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" mb={1} ml={1}>
                {header}
            </Typography>
            <List>
                {
                    allFacilityAreas.map(facilityArea => (
                        <FacilityAreaRow key={facilityArea.id} row={row++} facilityArea={facilityArea} selectedFacilityAreas={selectedFacilityAreas} selectFacilityArea={selectFacilityArea}></FacilityAreaRow>
                    ))
                }
            </List>
        </FacilityAreaContainer>
    )
}

export default FacilityAreas;