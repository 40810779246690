import styled from 'styled-components';

export const FacilityCustomerViewRowContainer = styled.div`
grid-column: 1;
grid-row: ${(props) => `${props.$rowstart + 1}`} / ${(props) => `${props.$rowend + 1}`};
display: flex;
width: 100%;
height: 5vh;
flex-direction: row;
justify-content: space-evenly;
font-size: 1.0rem;
color: ${(props) => `${props.$textcolor}`};
background-color: ${(props) => `${props.$rowcolor}`};
cursor: pointer;
border-radius: ${(props) => `${props.$rowborderadius}`};
box-shadow: ${(props) => `${props.$rowboxshadow}`};
align-items: center;

.user-name {
    width: 100%;
    display: flex;
    cursor: pointer;
    margin: 1.0rem;
    text-align: left;
}

.user-email {
    width: 100%;
    display: flex;
    cursor: pointer;
    margin: 1.0rem;
    text-align: center;
}

.user-phone-number {
    width: 100%;
    display: flex;
    cursor: pointer;
    margin: 1.0rem;
    text-align: right;
}
&:hover{
    background-color: ${(props) => `${props.$hovercolor}`};
    color: ${(props) => `${props.$rowcolor}`};
}
`
