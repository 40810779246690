import styled from 'styled-components';

export const LoadingContainer = styled.div`
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    z-index: 999999999999;
    background-color: white;

    .center-container {
      display: flex;
      flex-direction: column;
      margin: auto;
      margin-top: 5vh;
      height: 50%;
    }

    .logo-img {
        margin: auto;
        margin-top: 15.0vh;
        height: 15vh;
        width: 15vw;
    }
`

export const LoadingSpinner = styled.div`
    margin: auto;
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: ${(props) => `${props.$bordertop}`};
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;

    @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
`