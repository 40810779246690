const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

export const DAYS_OF_WEEK_SORT_ORDER = { "Sunday": 0, "Monday": 1, "Tuesday": 2, "Wednesday": 3, "Thursday": 4, "Friday": 5, "Saturday": 6 };

export const getRepeatsOnDaysOfWeekText = (repeatDaysOfWeek) => {
    let daysOfWeek = "Not Applicable";
    if (repeatDaysOfWeek) {
        if (repeatDaysOfWeek.length > 0) {
            daysOfWeek = '';
            for (const dow of repeatDaysOfWeek) {
                daysOfWeek = `${daysOfWeek} ${dow.substring(0, 2)}`
            }

        }
    }

    return daysOfWeek;
}

export const getReservationLength = (resLength) => {
    const decimalIndex = resLength.toString().indexOf(".");
    if (decimalIndex >= 0) {
        const dec = resLength.toString().substring(decimalIndex + 1);
        const whole = resLength.toString().substring(0, decimalIndex);
        switch (dec) {
            case "0":
                return `${whole}`;
            case "25":
                return `${whole}:15`;
            case "5":
                return `${whole}:30`;
            case "75":
                return `${whole}:45`;
            default:
                return `${whole}`;
        }
    } else {
        return resLength;
    }
}

export const getReservationLengths = () => {

    const reservationLengths = [];
    let resLength = 0.5;
    while (resLength <= 24.0) {

        reservationLengths.push(getReservationLength(resLength));

        resLength += 0.25;
    }
    return reservationLengths;
}

export const getRepeatExceptionDatesText = (repeatExceptionDates) => {
    if (!repeatExceptionDates || repeatExceptionDates.length <= 0) {
        return 'None';
    }

    let dateText = '';
    for (const date of repeatExceptionDates) {
        dateText = `${dateText} ${moment(date).format('dd, MMM D, YYYY')}`
    }
    return dateText;
}

export const getDaysOfWeekSelectedList = (repeatDaysOfWeek, dayOfWeekIn) => {
    let daysOfWeek = [];
    if (dayOfWeekIn === "All" || dayOfWeekIn === "Not Applicable" ) {
        return daysOfWeek;
    } else {

        const existingOpenView = repeatDaysOfWeek.find((dow) => dow === dayOfWeekIn);
        if (existingOpenView) {
            daysOfWeek = repeatDaysOfWeek.filter((dow) => dow !== dayOfWeekIn);
        } else {
            daysOfWeek = [...repeatDaysOfWeek, dayOfWeekIn];
        }

        daysOfWeek.sort((dow1, dow2) => {

            let sortOrder1 = DAYS_OF_WEEK_SORT_ORDER[dow1];
            let sortOrder2 = DAYS_OF_WEEK_SORT_ORDER[dow2];

            if (sortOrder1 < sortOrder2) {
                return -1;
            }

            if (sortOrder1 > sortOrder2) {
                return 1;
            }

            return 0;
        });

        return daysOfWeek;
    }
}

export const sportSubTypesForSport = (sport) => {
    if (sport) {
        if (sport.indexOf("Batting Cage") >= 0) {
            return ["Full Length", "3/4 Length", "Half Length", "1/4 Length"];
        } else if (sport.indexOf('Field') >= 0) {
            return ["6u", "7u", "8u", "9u", "10u", "11u", "12u", "13u", "14u", "Prep"];
        }
    }
    return [];
}

export const areaTypesForSport = (sport) => {
    const sportUpper = sport.toUpperCase();
    if (sportUpper.indexOf("BASKETBALL") >= 0 || sportUpper.indexOf("PICKLEBALL") >= 0) {
        return ["Full Court", "Half Court"]
    } else if (sportUpper.indexOf("SOCCER") >= 0 || sportUpper.indexOf("FUTSOL") >= 0 || sportUpper.indexOf("FOOTBALL") >= 0) {
        return ["Full Field", "Half Field"]
    } else if (sportUpper.indexOf("TRAINING") >= 0 || sportUpper.indexOf("FITNESS") >= 0) {
        return ["Full Area", "Half Area"]
    }

    return [];
}

export const findSportsForAreas = (facilityAreas) => {

    let sportsForAreas = [];
    for (const area of facilityAreas) {
        if (area.sports){
            for (const sport of area.sports) {
                const existingSport = sportsForAreas.find((sportFromList) => sportFromList === sport);
                if (!existingSport) {
                    sportsForAreas.push(sport);
                }
            }
        }
        if (area.subAreas) {
            let sAreaIds = Object.keys(area.subAreas);
            for (const sAreaId of sAreaIds) {
                const sArea = area.subAreas[sAreaId];
                if (sArea.sports){
                    for (const sSport of sArea.sports) {
                        const existingSport = sportsForAreas.find((sportFromList) => sportFromList === sSport);
                        if (!existingSport) {
                            sportsForAreas.push(sSport);
                        }
                    }
                }
            }
        }

    }

    sportsForAreas.sort((sport1, sport2) => {
        if (sport1 < sport2) {
            return -1;
        }

        if (sport1 > sport2) {
            return 1;
        }

        return 0;
    });

    return sportsForAreas;

}

export const findSportsForArea = (area) => {

    const sportsForAreas = [];

    for (const sport of area.sports) {
        const existingSport = sportsForAreas.find((sportFromList) => sportFromList === sport);
        if (!existingSport) {
            sportsForAreas.push(sport);
        }
    }

    if (area.subAreas) {
        let sAreaIds = Object.keys(area.subAreas);
        for (const sAreaId of sAreaIds) {
            const sArea = area.subAreas[sAreaId];
            for (const sSport of sArea.sports) {
                const existingSport = sportsForAreas.find((sportFromList) => sportFromList === sSport);
                if (!existingSport) {
                    sportsForAreas.push(sSport);
                }
            }
        }
    }

    return sportsForAreas;

}