import React from 'react';
import { Navigate } from 'react-router-dom';

const FacilityUserGuardedRoute = ({userIsLoading, userData, children }) => {

    if (userData && !userData.isFacilityUserAccount) {
      return <Navigate to="/" replace />;
    }

    if (!userData && !userIsLoading ){
      return <Navigate to="/" replace />;
    }
  
    return children;
  };

export default FacilityUserGuardedRoute;


