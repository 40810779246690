import styled from 'styled-components';

export const HomePageFacilitiesContainer = styled.div`
    display: flex;
    flex-direction: column;

    .facility-display-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 2.5vh;
        justify-content: space-evenly;
        align-content: space-evenly;
        background: white;
        margin-left: 2.5vw;
        margin-right: 2.5vw;
        margin-bottom: 2.5vh;
    }

    .search-buton-container {
        margin: auto;
        margin-bottom: 2.5vh;

        .search-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10.0vw;
            height: 5vh;
            text-align: center;
            background-color: #14254C;
            border-radius: 5px;
            margin-left: 0.25vw;
            margin-right: 0.25vw;
            .search-button-text {
                color: white;
            }
            cursor: pointer;
        }
    }


`