import styled from 'styled-components';

export const PaginationBarContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 12vw;
    margin-right: 12vw;
    height: 100%;

    .page-list {
        display: flex;
        justify-content: flex-end;
          list-style-type: none;
          margin: 0;
          padding: 0;
          overflow: hidden;
          color: #14254C;
          background-color: white;
    }

    .page-item {
        float: left;
        color: #14254C;
        text-align: center;
    }

    .page-link {
      display: block;
      color: white;
      text-align: center;
      padding: 16px;
      text-decoration: none;
      color: #14254C;
      background-color: white;
    }

    .page-link-active {
      display: block;
      color: white;
      text-align: center;
      padding: 16px;
      text-decoration: none;
      color: white;
      background-color: #14254C;
      border-radius: 5px;
    }

    .page-link:hover {
      background-color: #DEDEDE;
      color: white;
    }

`