import * as React from 'react';
import { IconOnClickMenuContainer } from './icon-onclick-menu.styles';
import { Stack, Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';


const IconOnClickMenu = ({ iconMenuItems, selectItem, height = '20vh', width = '20vw', zindex = '9999', minwidth = '5vw', minheight = '5vh' }) => {

    return (
        <IconOnClickMenuContainer $width={width} $height={height} $zindex={zindex} $minwidth={minwidth} $minheight={minheight}>
            <Stack spacing={1} direction={'row'} ml={2} mt={2} >
                {iconMenuItems.map((iconMenuItem) => (
                    <Card key={iconMenuItem.id} sx={{ width: '15.0vw', height: '25.0vh' }}>
                        <CardActionArea onClick={selectItem.bind(this)} data-id={iconMenuItem.id}>
                            <CardContent>
                                <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                                {
                                    iconMenuItem.img
                                }
                                </Box>
                                <Typography textAlign={'center'} color={"#14254C"} gutterBottom variant="h5" component="div">
                                    {iconMenuItem.title}
                                </Typography>
                                <Typography textAlign={'center'} variant="body2" color="text.secondary">
                                    {iconMenuItem.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Stack>

        </IconOnClickMenuContainer>
    );
}

export default IconOnClickMenu