import { IconButton, Stack, TextField } from "@mui/material";
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import { useState } from "react";


const AdditionalEmailRow = ({ isEditMode, email, index, editAdditionalEmail, deleteAdditionalEmail }) => {

    const [additionalEmail, setAdditionalEmail] = useState(email);

    const handleEmailChange = (event) => {
        const { value } = event.target;
        setAdditionalEmail(value);
    }

    const updateEmails = () => {
        editAdditionalEmail(additionalEmail, index);
    }

    const deleteRow = () => {
        deleteAdditionalEmail(email);
    }

    return (
        <Stack direction={'row'}>
            <TextField size="small" fullWidth key={email} className="additional-email-input" value={additionalEmail} onChange={handleEmailChange} onBlur={updateEmails} disabled={!isEditMode}></TextField>
            {
                isEditMode &&
                <IconButton key={`${email}-delete-btn`} onClick={deleteRow}>
                    <RemoveCircleSharpIcon></RemoveCircleSharpIcon>
                </IconButton>
            }
        </Stack>
    )

}

export default AdditionalEmailRow