import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActions, Stack, TextField, CardMedia } from '@mui/material';
import { DAYS_OF_WEEK_INT_TO_TEXT } from '../../../utils/constants/constants';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/user.context';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const StyledTextField = styled(TextField)`
  .MuiInput-input:hover {
    cursor: pointer;
  }
`;

const BirthdayPartyInfoCard = ({ birthdayParty, facilityName }) => {

    const { userData } = useContext(UserContext);
    const { name, price, details = {}, imageLocationURL } = birthdayParty;
    const { maxCapicity, daysOffered = [], description } = details;
    const { mainTextColor = "#14254C", secondaryTextColor = "#A9A9A9" } = !userData || (userData && !userData.isFacilityUserAccount) && details ? details : {};

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        selectBirthdayParty(birthdayParty, e);
    }

    const selectBirthdayParty = (birthdayParty, event) => {
        navigate(`/birthday-parties/${facilityName}/${birthdayParty.searchTerm ? birthdayParty.searchTerm : birthdayParty.id}`);
    }

    let dowText = "";
    for (const dow of daysOffered) {
        dowText = `${dowText} ${DAYS_OF_WEEK_INT_TO_TEXT[dow]}`
    }

    return (
        <Card onClick={handleClick} sx={{
            width: "20vw", minHeight: { xs: "15vh", sm: "15vh", md: "15vh", lg: "40vh", xl: "45vh" }, display: "flex", flexDirection: 'column', alignItems: 'stretch', cursor: "pointer", ":hover": { boxShadow: `0 3px 10px ${secondaryTextColor}` }
        }}>
            <CardMedia
                component="img"
                image={imageLocationURL ? imageLocationURL : "/birthday-party-table-image.webp"}
                height="140"
                alt="facility photo"
                sx={{ borderRadius: "4px" }}
            />
            <CardContent sx={{ display: "flex", flexDirection: 'column', flexGrow: '1', alignItems: 'stretch' }}>
                <Typography sx={{ fontSize: '1.5vw' }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={mainTextColor} variant="h5" component="div">
                    {name}
                </Typography>
                <Stack sx={{ height: '100%' }} spacing={2} justifyContent={'flex-end'} alignItems={'stretch'}>
                    <Box sx={{ width: '100%', marginBottom: '1.0vh', height: '100%' }} display="flex" justifyContent="center" alignItems="center">
                        {/* <StyledTextField sx={{ marginLeft: '1.0vw', marginRight: '1.0vw', width: '100%', fontSize: '0.85vw' }} variant="standard" multiline InputProps={{ readOnly: true }} value={description}></StyledTextField> */}
                        <Typography sx={{ fontSize: '0.95vw' }} textAlign={'left'} fontStyle={'Helvetica'} color={secondaryTextColor} variant="h6" component="div" mt={0}>
                            {description}
                        </Typography>
                    </Box>
                    <Stack sx={{ width: '100%' }} spacing={2} direction={'row'}>
                        <Typography sx={{ fontSize: '1.25vw' }} textAlign={'left'} fontStyle={'Helvetica'} color={mainTextColor} fontWeight={'bold'} variant="h6" component="div" mt={1}>
                            Party of
                        </Typography>
                        <Box sx={{ width: '50%' }} justifyContent={'flex-end'} >
                            <Typography sx={{ fontSize: '1.45vw' }} textAlign={'right'} fontStyle={'Helvetica'} color={mainTextColor} paragraph gutterBottom variant="h6" component="div" mt={0}>
                                {maxCapicity}
                            </Typography>
                        </Box>
                    </Stack>
                    <Typography sx={{ fontSize: '1.45vw' }} textAlign={'left'} fontStyle={'Helvetica'} color={mainTextColor} fontWeight={'bold'} variant="h6" component="div" mt={0}>
                        Available on
                    </Typography>
                    <Typography sx={{ minHeight: '5.0vh', fontSize: '1.0vw' }} textAlign={'left'} fontStyle={'Helvetica'} color={secondaryTextColor} variant="body1" component="div" ml={1} mt={1}>
                        {dowText}
                    </Typography>
                    <Typography sx={{ fontSize: '1.5vw' }} textAlign={'right'} fontStyle={'Helvetica'} fontWeight={'bold'} color={secondaryTextColor} paragraph gutterBottom variant="h5" component="div" mt={0} mb={0}>
                        ${price}
                    </Typography>
                </Stack>

            </CardContent>
            <CardActions>
                <Box sx={{ width: '100%', marginBottom: '1.0vh' }} display="flex" justifyContent="center" alignItems="center">
                    {
                        userData && userData.facilityId &&
                        <Button sx={{ backgroundColor: `${mainTextColor}` }} variant="contained" onClick={handleClick}>
                            Book Party
                        </Button>
                    }
                    {
                        !userData &&
                        <Button sx={{
                            backgroundColor: `${mainTextColor}`
                        }} variant="contained" onClick={handleClick}>
                            View More
                        </Button>
                    }
                    {
                        userData && !userData.facilityId &&
                        <Button sx={{
                            backgroundColor: `${mainTextColor}`
                        }} variant="contained" onClick={handleClick}>
                            View More
                        </Button>
                    }
                </Box>
            </CardActions>
        </Card>
    )

}

export default BirthdayPartyInfoCard;