import { useContext } from "react";
import { BirthdayPartyContext } from "../../../contexts/birthday-party/birthday-party.context";
import { Stack, Box, Button, Typography, Divider, Grid, Paper } from "@mui/material";
import Modal from '@mui/material/Modal';
import dayjs from "dayjs";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '90%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};

let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    let matchWithOne = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (matchWithOne) {
        return matchWithOne[1] + '(' + matchWithOne[2] + ') ' + matchWithOne[3] + '-' + matchWithOne[4]
    };

    return str
};


const BirthdayConfirmation = ({ close }) => {

    const { confirmation, reset } = useContext(BirthdayPartyContext);

    const closeConfirmation = () => {
        reset();
        if (close) {
            close();
        }
    }

    const { customer = {}, ancillaryTotal = "0.00", serviceChargeTotal = "0.00", serviceChargeOnDeposit = "0.00", totalAmount = "0.00", totalAmountDueAfterDeposit = "0.00", refundableDeposit = false, acceptedAncillaries, birthdayPartyName, facilityName, partyDate, startTime, endTime } = confirmation ? confirmation : {};
    const { address1 = "", address2 = "", city = "", state = "", zipCode = "" } = confirmation ? confirmation.facilityAddress : {};
    const { emailAddress = "", phoneNumber = "" } = confirmation ? confirmation.facilityContactInfo : {};
    const { userFirstName, userLastName, userEmailAddress, userPhoneNumber } = customer;
    return (
        <Modal open={confirmation ? true : false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Paper sx={{ overflow: 'scroll' }}>
                <Box sx={{ ...style, width: '80%' }}>
                    <Box mb={1} mt={2} display="flex" justifyContent="flex-start" alignItems="center" >
                        <Typography ml={2} mt={2} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h5">
                            Booking Confirmation
                        </Typography>
                    </Box>
                    <Stack>
                        <Typography ml={4} mt={1} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h6">
                            Party Package
                        </Typography>
                        <Divider sx={{ width: '50%', marginLeft: '2.0vw' }} ></Divider>
                        <Typography ml={6} mt={1} textAlign={'left'} gutterBottom component="div" variant="h6">
                            {birthdayPartyName}
                        </Typography>
                        <Typography ml={4} mt={1} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h6">
                            Birthday Party Date and Time
                        </Typography>
                        <Divider sx={{ width: '50%', marginLeft: '2.0vw' }} ></Divider>
                        <Typography ml={6} mt={2} textAlign={'left'} gutterBottom component="div" variant="body1">
                            {dayjs(partyDate).format('dddd, MMM D, YYYY')}, {startTime} - {endTime}
                        </Typography>
                        <Typography ml={4} mt={1} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h6">
                            Customer
                        </Typography>
                        <Divider sx={{ width: '50%', marginLeft: '2.0vw' }} ></Divider>
                        <Box mt={2} sx={{ width: '95%' }} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ width: '100%' }} ml={5} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                {userFirstName} {userLastName}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '95%' }} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ width: '75%' }} ml={5} textAlign={'left'} gutterBottom component="div" variant="body1">
                                {userEmailAddress}
                            </Typography>
                            <Typography sx={{ width: '25%' }} textAlign={'right'} gutterBottom component="div" variant="body1">
                                {formatPhoneNumber(userPhoneNumber)}
                            </Typography>
                        </Box>
                        <Typography ml={4} mt={1} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h6">
                            Party Location
                        </Typography>
                        <Divider sx={{ width: '50%', marginLeft: '2.0vw' }} ></Divider>
                        <Typography ml={6} mt={1} textAlign={'left'} gutterBottom component="div" variant="h6">
                            {facilityName}
                        </Typography>
                        <Box ml={2} sx={{ width: '95%' }} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ width: '75%' }} ml={5} textAlign={'left'} gutterBottom component="div" variant="body1">
                                {address1} {address2}
                            </Typography>
                            <Typography sx={{ width: '25%' }} textAlign={'right'} gutterBottom component="div" variant="body1">
                                {emailAddress}
                            </Typography>
                        </Box>
                        <Box ml={2} sx={{ width: '95%' }} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ width: '75%' }} ml={5} textAlign={'left'} gutterBottom component="div" variant="body1">
                                {city}, {state} {zipCode}
                            </Typography>
                            <Typography sx={{ width: '25%' }} textAlign={'right'} gutterBottom component="div" variant="body1">
                                {formatPhoneNumber(phoneNumber)}
                            </Typography>
                        </Box>
                    </Stack>
                    <Typography ml={4} mt={1} mb={1} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h6">
                        Additional Items
                    </Typography>
                    <Divider sx={{ width: '50%', marginLeft: '2.0vw' }} ></Divider>
                    <Box sx={{ width: '100%' }}>
                        <Grid container ml={2}>
                            <Box sx={{ width: '95%' }} ><Grid container mt={2} ml={4} mr={4}>
                                <Grid item xs={9}>
                                    <Typography textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography width={'100%'} textAlign={'center'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                        Quantity
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography width={'100%'} textAlign={'center'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                        Price
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography width={'100%'} textAlign={'center'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                        Total
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Box>
                            {
                                acceptedAncillaries && acceptedAncillaries.map((anc) => {
                                    return <Box key={anc.id} sx={{ width: '95%' }} ><Grid container mt={2} ml={4} mr={4}>
                                        <Grid item xs={9}>
                                            <Typography textAlign={'left'} gutterBottom component="div" variant="body1">
                                                {anc.name} - {anc.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box display="flex" justifyContent="center" alignItems="center" >
                                                <Typography textAlign={'center'} gutterBottom component="div" variant="body1">
                                                    {anc.quantity}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography textAlign={'center'} gutterBottom component="div" variant="body1">
                                                ${anc.price}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography textAlign={'center'} gutterBottom component="div" variant="body1">
                                                ${anc.total}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                        <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                                    </Box>
                                })
                            }
                        </Grid>
                        {/* <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider> */}
                        <Box sx={{ width: '100%' }}>
                            <Grid container ml={2}>
                                {/* <Typography ml={2} mt={1} mb={1} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h6">
                                    Totals
                                </Typography> */}
                                <Box sx={{ width: '95%' }} >
                                    <Grid container mt={2} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                                Optional Items Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                                    ${ancillaryTotal}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                                Price
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                                    ${confirmation ? confirmation.price : 0.00}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                                {refundableDeposit ? 'Deposit (Refundable)' : 'Deposit'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                                    ${confirmation ? confirmation.initialDeposit : 0.00}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                                Service Charge
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                                    ${serviceChargeTotal}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4}>
                                        <Grid item xs={6}>
                                            <Typography textAlign={'right'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                                Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                                ${totalAmount}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                                Deposit - PAID
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                                    {`$ (${confirmation ? confirmation.initialDeposit : 0.00})`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4} mr={4}>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                                Deposit Service Charge - PAID
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                                <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                                    {`$ (${serviceChargeOnDeposit})`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} ml={4}>
                                        <Grid item xs={6}>
                                            <Typography textAlign={'right'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                                Total Amount Due
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                                ${totalAmountDueAfterDeposit}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                        <Divider sx={{ marginRight: '2.0vw', marginLeft: '2.0vw', marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                    </Box>
                    <Box mb={5} mt={5} display="flex" justifyContent="center" alignItems="center" >
                        <Stack direction={'row'} spacing={5}>
                            <Button variant='contained' onClick={closeConfirmation}>Done</Button>
                        </Stack>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    )
}

export default BirthdayConfirmation;