import styled from 'styled-components';

export const MembershipAllListRowContainer = styled.li`

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5rem;
    cursor: pointer;
    line-height: 2.0rem;
    color: ${(props) => `${props.$textcolor}`};
    background-color: white;
    cursor: pointer;
    font-size: 1.0rem;
    font-weight: bold;

    &:hover {
        background-color: #14254C;
        color: white;
    }

    .checkbox-img {
        height: 100%;
        max-width: 25px;
        margin-right: 1.0rem;
      }
`