import { Popover, Box, Stack, Button } from "@mui/material";
import { ApplicationDataContext } from "../../contexts/application-data-context";
import { useState, useEffect, useContext } from "react";
import ListWithTextInput from "../list-with-text-input/list-with-text-input.component";


const SelectSports = ({ isOpen = false, anchorEl, sportSelected, closeView }) => {

    const { sportsSupported } = useContext(ApplicationDataContext);
    const [ sport, setSport ] = useState(null);

    const sportWasSelected = (sport) => {
        setSport(sport)
    }

    const close = () => {
        if (closeView) {
            closeView();
        }
        setSport(null);
    }

    const addAndClose = () => {
        if (sportSelected) {
            sportSelected(sport);
        }

        if (closeView) {
            closeView();
        }

        setSport(null);
    }

    const addAndAddAnother = () => {
        if (sportSelected) {
            sportSelected(sport);
        }
        setSport(null);
    }

    return (
        <Popover
            id={'select-sport-and-subtype-popover'}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{ width: '50vh' }} mt={1} mb={1} ml={1} mr={1}>
                <ListWithTextInput variant='outlined' headerText={'Sport'} list={sportsSupported} selectedItem={sport} selectFromList={sportWasSelected} allowInput={false} ></ListWithTextInput>
                <Stack mt={2} spacing={1} direction={'row'} display={'flex'} justifyContent={'center'}>
                    <Button variant="outlined" onClick={close}>Close</Button>
                    <Button variant="contained" onClick={addAndClose}>Add</Button>
                    <Button variant="contained" onClick={addAndAddAnother}>Add And Add Another</Button>
                </Stack>
            </Box>
        </Popover>
    )
}

export default SelectSports;