import { useState, useContext, forwardRef } from "react";
import { Button, TextField } from '@mui/material';
import { adjustReservationAmount } from "../../../utils/facility-api/facility-service";
import { getReservation } from "../../../utils/reservation-api/reservation-service";
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context';
import MySportSpaceAlert from '../../alert/alert.component';
import { NumericFormat } from 'react-number-format';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';

const defaultFormFields = {
    amount: ''
}

const NumberFormat = forwardRef(
    function NumberFormatCustom(props, ref) {
        const { onChange, id, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                            id: id
                        },
                    });
                }}
                decimalScale={2}
            />
        );
    },
)

const FacilityReservationAdjustAmount = ({ reservation, closeView, isOpen }) => {

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { amount } = formFields;
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const { reservationToView, setReservationToView } = useContext(FacilityReservationsContext);

    const closeThisView = () => {
        setAlertMessage(null);
        closeView();
    }

    const refundReservation = () => {
        adjustReservation('refund');
    }

    const chargeReservation = () => {
        adjustReservation('charge');
    }

    const adjustReservation = async (type) => {
        setIsLoading(true);

        const response = await adjustReservationAmount(reservation, amount, type, reservation.paymentMethodId);
        if (response.status === 200) {
            if (response.data.status === 'success') {
                let message = "The reservation has been adjusted.";
                if (response.data.message) {
                    message = response.data.message;
                }

                const reservationResponse = await getReservation(reservationToView.facilityId, reservationToView.isInternalBooking ? null : reservationToView.customerId, reservationToView.dateKey, reservationToView.reservationId);
                if (reservationResponse && reservationResponse.status === 200 && reservationResponse.data.status === 'success') {
                    setReservationToView(reservationResponse.data.reservation);
                }

                setAlertMessage(message);

            } else {
                setAlertMessage(response.data.error);
            }

        } else {
            setAlertMessage('The reservation was not paid.  Please try again.');
        }
        setIsLoading(false);
    }

    const handleChange = (event) => {
        setFormFields({ ...formFields, 'amount' : event.target.value });
    };

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="adjust-amount-dialog-title"
            aria-describedby="adjust-amount-dialog-description"
        >
            <MySportSpaceAlert isOpen={!isLoading && alertMessage ? true : false} key='adjust-res-alert' message={alertMessage} okButtonText={'OK'} okAction={closeThisView}></MySportSpaceAlert>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <DialogTitle id="adjust-amount-dialog-title">
                Adjust Reservation Amount
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the adjustment amount
                </DialogContentText>
                <TextField InputProps={{
                    inputComponent: NumberFormat,
                }} required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw', mt: '1.0vh' }} id="res-adjust-amount" variant="outlined" label="Adustment Amount" type="email" name="amount" value={amount} onChange={handleChange} ></TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeThisView}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={refundReservation}>
                    Refund
                </Button>
                <Button variant='contained' onClick={chargeReservation}>
                    Charge
                </Button>
            </DialogActions>
        </Dialog>
        // <FacilityReservationAdjustAmountContainer>
        //     {
        //         isLoading &&
        //         <LoadingIndicator></LoadingIndicator>
        //     }
        //     {
        //         !isLoading && alertMessage &&
        //         <MySportSpaceAlert key='adjust-res-alert' message={alertMessage} okButtonText={'OK'} okAction={closeThisView}></MySportSpaceAlert>
        //     }
        //     {!isLoading &&
        //         <FacilityAdjustAmountFormContainer>
        //             <span className="header">Adjust Reservation Amount </span>
        //             <span className="sub-header">Enter the adjustment amount </span>
        //             <div className='amount-container'>
        //                 <div className='amount-form '>
        //                     <span className='dollar-sign'>$</span>
        //                     <NumericFormat onValueChange={handleChange}  value={amount} decimalScale={2} fixedDecimalScale/>
        //                 </div>
        //             </div>
        //             <div className='bottom-button-bar'>
        //                 <Button onClick={refundReservation}>{`Refund`}</Button>
        //                 <Button onClick={chargeReservation} >{`Charge`}</Button>
        //                 <Button onClick={closeThisView} >{`Cancel`}</Button>
        //             </div>
        //         </FacilityAdjustAmountFormContainer>
        //     }
        // </FacilityReservationAdjustAmountContainer>
    )
}

export default FacilityReservationAdjustAmount