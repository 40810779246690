import { Routes, Route } from 'react-router-dom';
import BookingSummary from '../../components/bookings/booking-summary.component';
import CustomerUserGuardedRoute from '../guarded-routes/customer-user-guarded-route.component';

const Bookings = () => {

    return (
        <Routes>
            <Route path="booking-summary" element={<CustomerUserGuardedRoute><BookingSummary /></CustomerUserGuardedRoute>}></Route>
        </Routes>
    )

}

export default Bookings;