
import { Fragment, useContext, useEffect, useState } from "react";
import { FacilityReservationBookSummaryContainer } from './facility-reservation-book-summary.styles';
import LoadingIndicator from "../../loading-indicator/loading-indicator.component";
import MySportSpaceAlert from "../../alert/alert.component";
import { useNavigate } from "react-router-dom";
import { bookFacilityReservation } from '../../../utils/facility-api/facility-service';
import { UserContext } from "../../../contexts/user.context";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';


const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const Decimal = require('decimal.js');

const columns = [
    {
        field: 'reservationDate',
        type: 'date',
        renderHeader: () => (
            <strong>
                {'Reservation Date '}
            </strong>
        ),
        flex: 0.5,
        minWidth: 175,
        valueGetter: (value) => {
            if (!value) {
                return null;
            }
            return moment(value, 'YYYY-MM-DD').toDate();
        },
    },
    {
        field: 'startTime',
        renderHeader: () => (
            <strong>
                {'Start Time '}
            </strong>
        ),
        flex: 0.5,
        minWidth: 75,
    },
    {
        field: 'endTime',
        flex: 0.5,
        minWidth: 75,
        renderHeader: () => (
            <strong>
                {'End Time '}
            </strong>
        ),
    },
    {
        field: 'areaReserved', minWidth: 125, flex: 1, renderHeader: () => (
            <strong>
                {'Facility Area'}
            </strong>
        ),
    },
    {
        field: 'price',
        renderHeader: () => (
            <strong>
                {'Price'}
            </strong>
        ),
        flex: 0.5,
        minWidth: 75,
        valueGetter: (value) => {
            if (!value) {
                return ""
            }

            return `$${parseFloat(value).toFixed(2)}`
        }
    },
    {
        field: 'applicationFee',
        renderHeader: () => (
            <strong>
                {'MSS Fee'}
            </strong>
        ),
        flex: 0.5,
        minWidth: 75,
        valueGetter: (value) => {
            if (!value) {
                return "$0.00"
            }

            return `$${parseFloat(value / 100).toFixed(2)}`
        }
    },
    {
        field: 'total',
        renderHeader: () => (
            <strong>
                {'Total'}
            </strong>
        ),
        flex: 0.5,
        minWidth: 75,
        valueGetter: (value) => {
            if (!value) {
                return "$0.00"
            }

            return `$${parseFloat(value).toFixed(2)}`
        }
    },
    {
        field: 'status',
        renderHeader: () => (
            <strong>
                {'Status'}
            </strong>
        ),
        flex: 0.5,
        minWidth: 75,
        valueGetter: (value) => {
            if (!value) {
                return "Unknown"
            }
            const upperValue = value.toUpperCase();
            switch (upperValue) {
                case 'SUCCESS':
                    return 'Success'
                case 'LOCKED':
                    return 'Not Available';
                case 'FAILED':
                    return "Failed";
                case 'MASTER_LOCKED':
                    return "Failed";
                case 'SELECTABLE':
                    return "Available";
                default:
                    return null;
            }
        }
    },
    {
        field: 'status_reason',
        renderHeader: () => (
            <strong>
                {'Reason'}
            </strong>
        ),
        flex: 0.5,
        minWidth: 75,
        valueGetter: (value) => {
            if (!value) {
                return "N/A";
            }
            return value;
        }
    },
];

function getRowId(row) {
    return row.reservationId;
}

function getBookingTotal(bookingResults) {
    let total = new Decimal(0);
    for (const booking of bookingResults) {
        total = total.plus(new Decimal(booking.total));
    }

    return total.toFixed(2);
}

const getCreditCardInfo = (fop) => {
    if (fop) {
        if (fop.card) {
            const card = fop.card;
            let exp_month = `${card.exp_month}`;
            if (card.exp_month < 10) {
                exp_month = `0${exp_month}`
            }
            return `${card.brand.toUpperCase()} *${card.last4} EXP ${exp_month}/${card.exp_year}`
        }

        return ""
    }
}

const FacilityReservationBookSummary = () => {

    const [reservationBookingResults, setReservationBookingResults] = useState([]);
    const { bookingResponse = {}, bookingInput, searchResults, searchResultsToBook, setSearchResultsToBook, reservationSearchData, setBookingResponse, reset } = useContext(SearchForReservationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const { userData } = useContext(UserContext);
    const [showCheckboxes, setShowCheckBoxes] = useState(false);
    const [showBookingButton, setShowBookingButton] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        processResponse();
        setIsLoading(false);
    }, [bookingResponse]);

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const processResponse = () => {
        let allowCheckboxes = false;
        if (bookingResponse && bookingResponse.status === 200 && bookingResponse.data) {
            switch (bookingResponse.data.status) {
                case "success":
                    setReservationBookingResults(bookingResponse.data.bookingResults);
                    break;
                case "failed":
                    let bookingResults = bookingResponse.data.bookingResults;
                    if (bookingResponse.data.bookingsStillAvailable) {
                        bookingResults = bookingResults.concat(bookingResponse.data.bookingsStillAvailable);
                        setShowBookingButton(true);
                    }
                    setReservationBookingResults(bookingResults);
                    if (bookingResults && bookingResults.length > 0) {
                        allowCheckboxes = true;
                        setAlertMessage({ title: "Booking Alert", message: "There were some issues with booking your reservation(s).  Please see status column for reason." });
                    } else {
                        if (reservationSearchData.isInternalBooking) {
                            setAlertMessage({ title: "Booking Alert", message: "We were unable to book your Internal Booking(s)" });
                        } else {
                            setAlertMessage({ title: "Booking Alert", message: "We were unable to book your reservation(s)" });
                        }
                    }
                    break;
                case "locked":
                    setAlertMessage({ title: "Not Available", message: "The reservation(s) are no longer available." });
                    break;
                case "master_locked":
                    setAlertMessage({ title: "Booking Alert", message: "We were unable to complete your booking(s) at the moment.  Please try again.\n\n If the issue persists please visit our help desk at https://mysportspace.freshdesk.com/support/home \n" });
                    break;
                default:
                    setAlertMessage({ title: "Booking Alert", message: "We were unable to book your reservation(s)" });
                    break;
            }
        } else {
            setReservationBookingResults([]);
            setAlertMessage({ title: "Booking Alert", message: "We were unable to to book your reservation(s).  Please try again.\n\n If the issue persists please visit our help desk at https://mysportspace.freshdesk.com/support/home" });
        }

        setShowCheckBoxes(allowCheckboxes);
    }

    const returnToReservationSearch = () => {
        reset();
        navigate("/facility/reservation-search");
    }

    const returnToCalendar = () => {
        reset();
        navigate("/facility/calendar");
    }

    const bookSelectedReservations = async () => {
        setIsLoading(true);

        const response = await bookFacilityReservation(userData.facilityId, reservationSearchData.customer, searchResultsToBook, bookingInput, reservationSearchData.isInternalBooking, userData.internalBookingId);
        setBookingResponse(response);
    }

    return (
        <FacilityReservationBookSummaryContainer>
            {isLoading &&
                <LoadingIndicator key={'booking-loading-indicator-key'}></LoadingIndicator>
            }
            {
                !isLoading && alertMessage &&
                <MySportSpaceAlert key={'booking-alert-message-key'} title={alertMessage.title} message={alertMessage.message} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            }
            {
                !isLoading &&

                <Stack sx={{
                    height: '100%',
                    width: '100%',
                    marginRight: '0.5vw',
                    marginLeft: '0.5vw'
                }} spacing={1} >
                    <Box sx={{
                        height: Object.keys(searchResultsToBook).length > 0 ? '88%' : '100%',
                    }}>
                        <DataGrid sx={{
                            font: 'Helvetica', color: '#14254C',
                            fontWeight: 400
                        }} isRowSelectable={(params) => params.row.status === 'selectable'}
                            getRowId={getRowId} checkboxSelection={showCheckboxes} disableRowSelectionOnClick rows={reservationBookingResults} columns={columns} onRowSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const searchResultKeys = Object.keys(searchResults)
                                const selectedResults = [];
                                for (const selectedResId of selectedIDs) {
                                    for (const key of searchResultKeys) {
                                        const searchResult = searchResults[key].find((searchResult) => searchResult.reservationId === selectedResId);
                                        if (searchResult) {
                                            selectedResults.push(searchResult);
                                        }
                                    }
                                }
                                setSearchResultsToBook(selectedResults);
                            }} />
                    </Box>
                    {
                        !showBookingButton &&
                        <Stack mr={5} direction={'row'} spacing={2} justifyContent={'right'} >
                            {
                                bookingResponse && bookingResponse.data.paymentDetails && 
                                <Typography textAlign={'right'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                                    Form Of Payment: {bookingInput.formOfPaymentType === 'Credit Card' ? getCreditCardInfo(bookingResponse.data.paymentDetails) : bookingInput.formOfPaymentType}
                                </Typography>
                            }
                            <Typography textAlign={'right'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                                Total: ${getBookingTotal(reservationBookingResults)}
                            </Typography>
                        </Stack>
                    }
                    <Stack mt={5} direction={'row'} spacing={2} justifyContent={'center'} >
                        {
                            showBookingButton &&
                            <Button variant="contained" size="large" onClick={bookSelectedReservations} >Book Selected</Button>
                        }
                        {
                            !reservationSearchData.isClickToBook &&
                            <Button variant={showBookingButton ? "outlined" : "contained"} size="large" onClick={returnToReservationSearch} >Return To Search</Button>
                        }
                        <Button variant={showBookingButton ? "outlined" : "contained"} size="large" onClick={returnToCalendar} >Return To Calendar</Button>
                    </Stack>
                </Stack>
            }


        </FacilityReservationBookSummaryContainer>
    )
}

export default FacilityReservationBookSummary

