import { UserContext } from "../../contexts/user.context";
import { Fragment, useContext, useEffect } from "react";
import CustomerHome from "../customer-home/customer.home.component";
import { useNavigate } from 'react-router-dom';
import MySportSpaceLoadingView from "../../components/my-sport-space-loading-view/my-sport-space-loading-view.component";

const Home = () => {

    const { currentUser, userData, userIsLoading } = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {

        if (currentUser && userData && userData.isFacilityUserAccount) {
            navigate("/facility/calendar");
        } 

    }, [currentUser, userData, navigate]);

    return (

        <Fragment>
            {
                !userIsLoading && 
                <CustomerHome></CustomerHome>
            }
            <MySportSpaceLoadingView isOpen={userIsLoading}></MySportSpaceLoadingView>
        </Fragment>
        
    )
};

export default Home;