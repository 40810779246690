import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const LoadingIndicator = ({ color }) => {

    let spinnerColor = '#14254C';
    if (color) {
        spinnerColor = color;
    }

    return (
        <Backdrop
        sx={{ color: "#fff", backgroundColor: '#14254C80', zIndex: 9999999999}}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    );
}

export default LoadingIndicator