import { FormControlLabel } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';

const FacilityAreaCheckBox = ({ label, facilityArea, selectedArea = {}, onChange, color = "#14254C" }) => {

  const handleChange = () => {
    onChange(facilityArea)
  };

  return (
    <FormControlLabel sx={{color: `${color}`}} control={<Checkbox sx={{marginLeft: '0.25vw', '&.Mui-checked': {
      color: `${color}`,
    }, '&.MuiCheckbox-root': {
      color: `${color}`,
    }}}
      edge="start"
      checked={selectedArea && selectedArea.id === facilityArea.id}
      tabIndex={-1}
      disableRipple
      onChange={handleChange}
      size="small"
    />} label={label} />
  );
}

export default FacilityAreaCheckBox