import { Outlet } from "react-router-dom"
import { Fragment, useContext } from "react";
import './navigation.styles.scss'
import { UserContext } from "../../contexts/user.context";
import NavigationFacility from "../../components/navigation-facility/navigation-facility.component";
import NavigationCustomer from "../../components/navigation-customer/navigation-customer.component";
import NavigationSignedout from "../../components/navigation-signed-out/navigation-signed-out.component";
import useDeviceDetection from "../../utils/device-detection/device-detection";

const Navigation = () => {

    const device = useDeviceDetection();
    const { userData, currentUser, userIsLoading } = useContext(UserContext);

    return (
        <Fragment>
            {
               currentUser && userData && userData.isFacilityUserAccount &&
                <NavigationFacility></NavigationFacility>
            }
            {
                currentUser && userData && !userData.isFacilityUserAccount &&
                <NavigationCustomer></NavigationCustomer>
            }
            {
                !currentUser && !userIsLoading && device !== 'Mobile' &&
                <NavigationSignedout></NavigationSignedout>
            }
            <Outlet />
        </Fragment>
    );
};

export default Navigation;