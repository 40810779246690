import { FacilityReservationBookInfoContainer, FacilityReservationBookInfoFormContainer } from './facility-reservation-book-info.styles';
import { FacilityReservationSearchContext } from "../../../contexts/facility/facility-reservation-search.context";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import { UserContext } from '../../../contexts/user.context';
import { useContext, useState, useEffect } from "react";
import FormHeader from "../../form-header/form-header.component";
import FormOfPayment from "../../form-of-payment/form-of-payment.component";
import TextAreaInput from '../../text-area-input/text-area-input.component';
import TextFormInput from '../../text-form-input/text-form-input.component';
import LoadingIndicator from '../../loading-indicator/loading-indicator.component';
import MySportSpaceAlert from '../../alert/alert.component';
import LabelWithHeader from '../../label-with-header/label-with-header.component';
import { bookFacilityReservation } from '../../../utils/facility-api/facility-service';
import { useNavigate } from 'react-router-dom';
import Button from '../../button/button.component';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const FacilityReservationBookInfo = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const { reservationSearchData, bookingInput, setBookingInput, searchResultsToBook, setBookingResponse, reset } = useContext(SearchForReservationContext);
    const { clickToBookOpenSlot } = useContext(FacilityReservationSearchContext);
    const { userData } = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (!searchResultsToBook || searchResultsToBook.length <= 0) {
            navigate("/facility/reservation-search");
        }
    }, []);

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const cancelFacilityBooking = () => {
        reset();
        navigate("/facility/calendar");
    }

    const returnToReservationSearch = () => {
        navigate("/facility/reservation-search");
    }

    const setNotes = (event) => {
        const { name, value } = event.target;
        setBookingInput({ ...bookingInput, [name]: value });
    }

    const setTitle = (event) => {
        const { name, value } = event.target;
        setBookingInput({ ...bookingInput, [name]: value });
    }

    const bookReservation = async () => {

        if (!bookingInput.title || bookingInput.title.length <= 0) {
            setAlertMessage("You must provide a reservation title.")
            return
        }

        if (bookingInput.formOfPaymentType === 'Credit Card' && !bookingInput.formOfPayment) {
            setAlertMessage("You must select a form of payment to pay by Credit Card.")
            return
        }
        setIsLoading(true);
        const response = await bookFacilityReservation(userData.facilityId, reservationSearchData.customer, searchResultsToBook, bookingInput, reservationSearchData.isInternalBooking, userData.internalBookingId);
        setBookingResponse(response);
        setIsLoading(false);

        navigate("/facility/reservation-booking-results");

    }

    const needsFormOfPayment = () => {
        if (reservationSearchData.isInternalBooking) {
            return false;
        }

        for (const searchResult of searchResultsToBook) {
            if (searchResult.price && parseFloat(searchResult.price) > 0) {
                return true;
            }
        }
        return false;
    }

    const selectFormOfPaymentType = (type) => {
        setBookingInput({ ...bookingInput, 'formOfPaymentType' : type });
    }

    const selectFormOfPayment = (fop) => {
        setBookingInput({ ...bookingInput, 'formOfPayment' : fop });
    }

    let title = "Book Reservation";
    if (searchResultsToBook.length > 0) {
        title = "Book Reservations"
    }

    if (reservationSearchData.isInternalBooking) {
        title = "Book Internal";
    }

    return (
        <FacilityReservationBookInfoContainer>
            {isLoading &&
                <LoadingIndicator key={'booking-loading-indicator-key'}></LoadingIndicator>
            }
            {
                !isLoading && alertMessage &&
                <MySportSpaceAlert key={'booking-alert-message-key'} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            }
            {!isLoading &&
                <FacilityReservationBookInfoFormContainer>
                    <FormHeader key={'booking-form-header-key'} title={title}></FormHeader>
                    <div className='container-row'>
                        <div className='form-of-payment-type-container'>
                            {needsFormOfPayment() &&
                                <FormOfPayment key={'booking-form-of-payment-type-key'} facilityCustomer={reservationSearchData.customer} formOfPayment={bookingInput.formOfPayment} formOfPaymentType={bookingInput.formOfPaymentType} selectFormOfPayment={selectFormOfPayment} selectFormOfPaymentType={selectFormOfPaymentType} ></FormOfPayment>
                            }
                        </div>
                        <div className='form-of-payment-container'>
                            {
                                !needsFormOfPayment() &&
                                <LabelWithHeader headerFont={'1.5vh'} labelFont={'1.45vh'} key={'booking-form-of-payment-key'} headerText={"Form Of Payment Type:"} labelText={"Not Applicable"}></LabelWithHeader>
                            }
                        </div>

                    </div>
                    <div className='container-row'>
                        <TextFormInput key={'booking-reservation-title-key'} label="Reservation Title:" type="text" required onChange={setTitle} name="title" value={bookingInput.title ? bookingInput.title : ""} />
                    </div>
                    <div className='container-row'>
                        <TextAreaInput key={'booking-reservation-notes-key'} label="Reservation Notes:" name="notes" onChange={setNotes} rows={20} value={bookingInput.notes} />
                    </div>
                    <div className="bottom-button-bar">
                        <Button id='book-reservation-button' buttonType='inverted' onClick={bookReservation} >{title}</Button>
                        {
                            clickToBookOpenSlot &&
                            <Button id='return-button' onClick={returnToReservationSearch} >Return To Search</Button>
                        }
                        <Button id='return-button' onClick={cancelFacilityBooking} >Cancel</Button>
                    </div>

                </FacilityReservationBookInfoFormContainer>
            }


        </FacilityReservationBookInfoContainer>
    )
}

export default FacilityReservationBookInfo