import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    disablePortal: true,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            whiteSpace: 'normal'
        },
    },
};

const MaterialUISelectionList = ({ id, label, items, selectItem, noSelectionText = "None", required = false }) => {

    const [selectedItems, setSelectedItems] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedItems(
            typeof value === 'string' ? value.split(',').filter(v => v !== noSelectionText) : value.filter(v => v !== noSelectionText) ,
        );

        if (selectItem) {
            selectItem(value);
        }
    };

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel required={required} id="selection-list-label">{label}</InputLabel>
                <Select
                    labelId={`selection-list-label-${label}`}
                    id={id ? id : `selection-list-label-${label}-id`}
                    multiple
                    fullWidth
                    value={selectedItems.length > 0 ? selectedItems : [noSelectionText]}
                    onChange={handleChange}
                    input={<OutlinedInput multiline label={label} sx={{
                        "& .MuiInputBase-input": {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: 'left',
                            color: '#14254C'
                        }
                    }}  />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {items.map((item) => (
                        <MenuItem key={item} value={item}>
                            <Checkbox checked={selectedItems.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>

    )
}

export default MaterialUISelectionList;