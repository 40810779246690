import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';

const SelectBirthdayPartyGroup = ({ groups, selectGroup, groupSelected, disabled = false }) => {

    const handleChange = (event) => {
        const name = event.target.value;
        if (selectGroup){
            selectGroup(groups[name]);
        }
    }

    return (

        <FormControl fullWidth>
            <InputLabel id="birthday-party-group-select-label">Group Name</InputLabel>
            <Select
                labelId="birthday-party-group-select-label"
                id="birthday-party-group-select"
                value={groupSelected}
                label="Group Name"
            >
                {
                    groups.map((group) => {
                        return  <MenuItem key={group} onClick={handleChange} value={group}>{group}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    )
}

export default SelectBirthdayPartyGroup