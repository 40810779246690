import { Box, Stack } from "@mui/material"
import BirthdayPartySetup from "./birthday-party-setup.component"

const BirthdayPartyCreate = () => {
    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'65px'} left={'0%'} right={'0%'} bottom={'1%'} display={'flex'}>
            <Stack ml={3} sx={{
                height: '100%',
                width: '100%'
            }}>
                <BirthdayPartySetup key={'birthday-party-create'}></BirthdayPartySetup>
            </Stack>
        </Box>
    )
}

export default BirthdayPartyCreate;
